import { AxiosResponse } from 'axios';
import { backendApiInstance } from './backendApiInstance';
import { AddSoundParamType, Sound } from '../redux/sound/soundSlice';
import { SortOrder } from '../utils/enums/sort-order.enum';
import { SoundType } from '../utils/enums/sound-type.enum';

export interface GetAllSoundParamType {
  offset: number;
  limit: number;
  sortBy: string;
  sortOrder: SortOrder;
  searchText: string;
  soundType: SoundType;
}

export async function getSoundList({
  offset,
  limit,
  sortBy,
  sortOrder,
  searchText,
  soundType,
}: GetAllSoundParamType): Promise<AxiosResponse> {
  return backendApiInstance.get('sounds', {
    params: { offset, limit, sortBy, sortOrder, type: soundType, query: searchText },
  });
}

export async function getSoundById(soundId: string): Promise<AxiosResponse> {
  return backendApiInstance.get(`sounds/${soundId}`);
}

export async function deleteSound(soundDetails: { soundId: string; password: string }): Promise<AxiosResponse> {
  return backendApiInstance.delete(`sounds`, { data: soundDetails });
}

export async function addSoundMinio({ audio, soundData }: AddSoundParamType): Promise<AxiosResponse> {
  const formData = new FormData();
  if (audio) {
    formData.append('audio', audio);
    formData.append('soundType', soundData.type as string);
  }

  return backendApiInstance.post('minio/audio', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export async function addSound(sound: Sound): Promise<AxiosResponse> {
  return backendApiInstance.post('sounds', sound);
}

export async function addAlbumArtMinio({ image }: AddSoundParamType): Promise<AxiosResponse> {
  const formData = new FormData();
  if (image) {
    formData.append('image', image);
  }

  return backendApiInstance.post('minio/albumArt', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export async function getSoundPresignedUrl(soundName: string): Promise<AxiosResponse> {
  return backendApiInstance.get(`sounds/pre-signed-url/${soundName}`);
}
