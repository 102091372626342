import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetAllSoundParamType } from '../../api/soundApi';
import { SoundType } from '../../utils/enums/sound-type.enum';
import _ from 'lodash';
import { Crop } from 'react-image-crop/dist/types';

export interface Sound {
  soundId?: string;
  title?: string;
  type?: SoundType;
  duration: number;
  createdAt?: Date;
  name?: string;
  isStop?: boolean;
  fileName?: string;
  loading?: boolean;
  audio?: any;
  url?: string;
  createdBy?: string;
  albumArt?: string;
  preSignedUrl?: string;
  albumArtFileName?: string;
}

export interface AddSoundParamType {
  soundData: Sound;
  audio: File | null;
  image: File | null;
}
export interface ISoundSlice {
  totalSoundCount: number;
  soundList: Sound[];
  isAddSoundPopupOpen: boolean;
  isAddSoundSuccessModalOpen: boolean;
  newSoundTrack: { soundData: Sound; audio: File | null; image: File | null };
  isSoundTrackSubmitting: boolean;
  deleteSoundTrack: Sound;
  componentStatus: { [name: string]: undefined | string | boolean | Crop };
}

const newSoundData: Sound = {
  title: '',
  type: SoundType.RELIEF,
  duration: 0,
  fileName: '',
  createdBy: '',
  albumArt: '',
};

const initialState: ISoundSlice = {
  totalSoundCount: 0,
  soundList: [],
  newSoundTrack: { soundData: newSoundData, audio: null, image: null },
  isAddSoundPopupOpen: false,
  isAddSoundSuccessModalOpen: false,
  isSoundTrackSubmitting: false,
  componentStatus: {},
  deleteSoundTrack: newSoundData,
};

const soundSlice = createSlice({
  name: 'sound',
  initialState,
  reducers: {
    setComponentStatus(state, action: PayloadAction<{ [key: string]: undefined | string | boolean | Crop }>) {
      state.componentStatus = { ...state.componentStatus, ...action.payload };
    },
    clearComponentStatus(state, action: PayloadAction<string[] | undefined>) {
      if (action.payload?.length) {
        state.componentStatus = _.omit(state.componentStatus, action.payload);
      } else {
        state.componentStatus = {};
      }
    },
    setSoundCount(state, action: PayloadAction<number>) {
      state.totalSoundCount = action.payload;
    },
    setSoundList(state, action: PayloadAction<Sound[]>) {
      state.soundList = action.payload;
    },
    getSoundListAsync: (state, action: PayloadAction<GetAllSoundParamType>) => {},
    setNewSoundTrackAndOpenSuccessModal(state, action: PayloadAction<AddSoundParamType>) {
      state.componentStatus = {
        ...state.componentStatus,
        isAddSoundPopupOpen: false,
        isAddSoundSuccessModalOpen: true,
      };
      state.newSoundTrack = action.payload;
    },
    setAddSoundSuccessModalClose(state) {
      state.componentStatus = {
        ...state.componentStatus,
        isAddSoundSuccessModalOpen: false,
      };
      state.newSoundTrack.soundData = newSoundData;
      state.newSoundTrack.audio = null;
      state.newSoundTrack.image = null;
    },
    clearAddNewSoundTrack(state) {
      state.newSoundTrack.soundData = newSoundData;
      state.newSoundTrack.audio = null;
      state.newSoundTrack.image = null;
    },
    setDeleteSoundTrack(state, action: PayloadAction<Sound>) {
      state.deleteSoundTrack = action.payload;
    },
    addSoundTrackAsync: (state, action: PayloadAction<AddSoundParamType>) => {},
    getDeleteSoundAsync(state, action: PayloadAction<string>) {},
    deleteSoundAsync(state, action: PayloadAction<{ soundId: string; password: string }>) {},
    setAlbumArtSrc: (state, action: PayloadAction<string>) => {
      state.newSoundTrack.soundData.albumArt = action.payload;
    },
    setSoundData: (state, action: PayloadAction<AddSoundParamType>) => {
      state.newSoundTrack.soundData = action.payload.soundData;
      state.newSoundTrack.audio = action.payload.audio;
      state.newSoundTrack.image = action.payload.image;
    },
    getSoundPresignedUrlAsync(state, action: PayloadAction<{ soundId: string; soundName: string }>) {},
    setSoundPresignedUrl(state, action: PayloadAction<{ soundId: string; url: string }>) {
      const soundList = state.soundList;
      const index = _.findIndex(soundList, { soundId: action.payload.soundId });
      if (index >= 0) {
        soundList[index].preSignedUrl = action.payload.url;
        state.soundList = soundList;
      }
    },
  },
});

export const { actions: soundActions, reducer: soundReducer } = soundSlice;
