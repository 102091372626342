import React from 'react';
import { Grid, Stack, TextField, Typography } from '@mui/material';
import { styled, Theme, useTheme } from '@mui/material/styles';
import * as yup from 'yup';
import { useFormik } from 'formik';
import CustomModal from '../../../components/CustomModal/CustomModal';
import GreenCheckCircleSVG from '../../../assets/img/check-circle-bold-duotone.svg';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import _ from 'lodash';
import { getBrowserAndOSInfo } from '../../../utils/get-brower-and-device-details';
import { SaveFeedbackPayload, userActions } from '../../../redux/user/userSlice';
import { LoadingButton } from '@mui/lab';
import { useSearchParams } from 'react-router-dom';

const CustomButton = styled(LoadingButton)(({ theme }: { theme: Theme }) => ({
  borderRadius: '10px',
  height: '43px',
  gap: '10px',
  textAlign: 'center',
  textTransform: 'none',
  marginTop: '16px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '297px',
    width: '75vw',
  },
}));

function SendFeedback() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const componentStatus = useAppSelector((state: RootState) => state.user.componentStatus);
  const isSendFeedbackSuccessModalOpen = _.get(componentStatus, 'isSendFeedbackSuccessModalOpen', false) as boolean;
  const isFeedbackSubmitting = _.get(componentStatus, 'isFeedbackSubmitting', false) as boolean;
  const feedbackSubmittedPage = searchParams.get('feedbackSubmittedPage') ?? '';

  const formik = useFormik({
    initialValues: {
      feedback: '',
    },
    validationSchema: yup.object({
      feedback: yup.string().required('Feedback is required'),
    }),
    onSubmit: ({ feedback }) => {
      const { device, deviceOS, deviceOSVersion, browser, browserVersion } = getBrowserAndOSInfo();
      const payload: SaveFeedbackPayload = {
        device,
        deviceOS,
        deviceOSVersion,
        browser,
        browserVersion,
        releaseVersion: null,
        feedbackSubmittedPage,
        feedback,
      };
      dispatch(userActions.saveFeedback(payload));
    },
  });

  const handleCloseSuccess = () => {
    formik.resetForm();
    dispatch(userActions.setComponentStatus({ isSendFeedbackSuccessModalOpen: false }));
  };
  return (
    <>
      <CustomModal title="Send Feedback" align="left">
        <Grid container flexDirection="column">
          <Typography variant="meta" component="p" mb={1}>
            Your message
            <Typography component="span" color="primary">
              &nbsp;*
            </Typography>
          </Typography>
          <TextField
            name="feedback"
            multiline
            minRows={5}
            placeholder="Type here..."
            value={formik.values.feedback}
            onChange={formik.handleChange}
            error={formik.touched.feedback && Boolean(formik.errors.feedback)}
            helperText={formik.touched.feedback && formik.errors.feedback}
            FormHelperTextProps={{ style: { ...theme.typography.meta } }}
          />
          <CustomButton loading={isFeedbackSubmitting} variant="contained" onClick={() => formik.handleSubmit()}>
            Send Feedback
          </CustomButton>
        </Grid>
      </CustomModal>

      <CustomModal
        modal
        open={isSendFeedbackSuccessModalOpen}
        title="Feedback Sent!"
        handleButtonOnClick={handleCloseSuccess}
        icon={GreenCheckCircleSVG}
        btnText={'Dismiss'}
        width="374px"
      >
        <Stack>
          <Typography variant="bodyRegular" component="p" textAlign="center" color={theme.palette.info.main}>
            Your feedback was successfully submitted. We appreciate your efforts to improve the user experience of the
            application!
          </Typography>
        </Stack>
      </CustomModal>
    </>
  );
}

export default SendFeedback;
