import { AxiosResponse } from 'axios';
import { backendApiInstance } from './backendApiInstance';
import {
  AddEditPatientDataType,
  C2CTransferApproveParamType,
  C2CTransferRejectParamType,
  EqDataType,
  RequestCositDataType,
  RequestTinnitusAvatarHistory,
} from '../redux/patient/patientSlice';
import { SortOrder } from '../utils/enums/sort-order.enum';

export interface GetAllPatientParamType {
  clinicianId?: string;
  offset: number;
  limit: number;
  sortBy: string;
  sortOrder: SortOrder;
  searchText?: string;
  gender?: string;
  minProgress?: number;
  maxProgress?: number;
  minAge?: number;
  maxAge?: number;
  startDateMin?: Date;
  startDateMax?: Date;
  clinicId?: string;
  isClinicPatientsPage?: boolean;
}

export interface TransferPatientParamType {
  clinicianId: string | undefined;
  password: string;
  patientsId: string[];
}

export interface GetCositHistoryParamType {
  patientUserId: string;
  offset: number;
  limit: number;
}

export async function getPatientsByClinician({
  clinicianId,
  offset,
  limit,
  sortBy,
  sortOrder,
  searchText,
}: GetAllPatientParamType): Promise<AxiosResponse> {
  return backendApiInstance.get(`patients/clinician/${clinicianId}`, {
    params: { offset, limit, sortBy, sortOrder, query: searchText },
  });
}

export async function getAllPatients({
  clinicianId,
  offset,
  limit,
  sortBy,
  sortOrder,
  searchText,
  gender,
  minProgress,
  maxProgress,
  minAge,
  maxAge,
  startDateMin,
  startDateMax,
  clinicId,
  isClinicPatientsPage,
}: GetAllPatientParamType): Promise<AxiosResponse> {
  return backendApiInstance.get('patients', {
    params: {
      offset,
      limit,
      sortBy,
      sortOrder,
      query: searchText,
      clinicianId,
      gender,
      minProgress,
      maxProgress,
      minAge,
      maxAge,
      startDate: startDateMin,
      endDate: startDateMax,
      clinicId,
      isClinicPatientsPage,
    },
  });
}

export async function createPatient(patientInfo: AddEditPatientDataType): Promise<AxiosResponse> {
  return backendApiInstance.post('patients', patientInfo);
}

export async function updatePatient(
  patientId: string,
  patientInfo: Partial<AddEditPatientDataType>,
): Promise<AxiosResponse> {
  return backendApiInstance.put('patients', { patientId, ...patientInfo });
}

export async function transferPatient(data: TransferPatientParamType): Promise<AxiosResponse> {
  return backendApiInstance.put('patients/transfer/clinician', { ...data });
}

export async function getPatient(userId: string): Promise<AxiosResponse> {
  return backendApiInstance.get(`patients/${userId}`);
}

export async function getCositByPatientId({ patientId }: { patientId: string }): Promise<AxiosResponse> {
  return backendApiInstance.get(`patients/${patientId}/goals`, {});
}

export async function createCositByPatientId(cositData: RequestCositDataType): Promise<AxiosResponse> {
  return backendApiInstance.post(`patients/${cositData.patientId}/goals`, cositData);
}

export async function updateCositByPatientId(cositData: RequestCositDataType): Promise<AxiosResponse> {
  return backendApiInstance.put(`patients/${cositData.patientId}/goals`, cositData);
}

export async function getTinnitusAvatarByPatientId({ patientId }: { patientId: string }): Promise<AxiosResponse> {
  return backendApiInstance.get(`patients/${patientId}/avatar`, { params: { status: 'ACTIVE', offset: 0, limit: 1 } });
}

export async function getTinnitusAvatarHistoryByPatientId({
  patientId,
  offset,
  limit,
}: RequestTinnitusAvatarHistory): Promise<AxiosResponse> {
  return backendApiInstance.get(`patients/${patientId}/avatar`, { params: { offset, limit } });
}

export async function getPatientSoundConfiguration(patientUserId: string): Promise<AxiosResponse> {
  return backendApiInstance.get(`patients/${patientUserId}/configure-sound`);
}

export async function updatePatientSoundConfiguration(
  isSoundConfigSet: boolean,
  patientUserId: string,
  eqData: EqDataType,
  tanRight: number[],
  tanLeft: number[],
  isCompleted: boolean,
): Promise<AxiosResponse> {
  const data = {
    overallThresholdRight: eqData.rightNoiseThreshold,
    overallThresholdLeft: eqData.leftNoiseThreshold,

    nbfThresholdRight_5: eqData.rightEqThreshold[0],
    nbfThresholdRightApplicable_5: eqData.rightEqThresholdStatus[0],
    nbfThresholdRight_1: eqData.rightEqThreshold[1],
    nbfThresholdRightApplicable_1: eqData.rightEqThresholdStatus[1],
    nbfThresholdRight_2: eqData.rightEqThreshold[2],
    nbfThresholdRightApplicable_2: eqData.rightEqThresholdStatus[2],
    nbfThresholdRight_4: eqData.rightEqThreshold[3],
    nbfThresholdRightApplicable_4: eqData.rightEqThresholdStatus[3],
    nbfThresholdRight_8: eqData.rightEqThreshold[4],
    nbfThresholdRightApplicable_8: eqData.rightEqThresholdStatus[4],
    nbfThresholdRight_16: eqData.rightEqThreshold[5],
    nbfThresholdRightApplicable_16: eqData.rightEqThresholdStatus[5],

    nbfThresholdLeft_5: eqData.leftEqThreshold[0],
    nbfThresholdLeftApplicable_5: eqData.leftEqThresholdStatus[0],
    nbfThresholdLeft_1: eqData.leftEqThreshold[1],
    nbfThresholdLeftApplicable_1: eqData.leftEqThresholdStatus[1],
    nbfThresholdLeft_2: eqData.leftEqThreshold[2],
    nbfThresholdLeftApplicable_2: eqData.leftEqThresholdStatus[2],
    nbfThresholdLeft_4: eqData.leftEqThreshold[3],
    nbfThresholdLeftApplicable_4: eqData.leftEqThresholdStatus[3],
    nbfThresholdLeft_8: eqData.leftEqThreshold[4],
    nbfThresholdLeftApplicable_8: eqData.leftEqThresholdStatus[4],
    nbfThresholdLeft_16: eqData.leftEqThreshold[5],
    nbfThresholdLeftApplicable_16: eqData.leftEqThresholdStatus[5],

    nbfThresholdMinMaskingRight_5: eqData.rightEqMml[0],
    nbfThresholdMinMaskingRightApplicable_5: eqData.rightEqMmlStatus[0],
    nbfThresholdMinMaskingRight_1: eqData.rightEqMml[1],
    nbfThresholdMinMaskingRightApplicable_1: eqData.rightEqMmlStatus[1],
    nbfThresholdMinMaskingRight_2: eqData.rightEqMml[2],
    nbfThresholdMinMaskingRightApplicable_2: eqData.rightEqMmlStatus[2],
    nbfThresholdMinMaskingRight_4: eqData.rightEqMml[3],
    nbfThresholdMinMaskingRightApplicable_4: eqData.rightEqMmlStatus[3],
    nbfThresholdMinMaskingRight_8: eqData.rightEqMml[4],
    nbfThresholdMinMaskingRightApplicable_8: eqData.rightEqMmlStatus[4],
    nbfThresholdMinMaskingRight_16: eqData.rightEqMml[5],
    nbfThresholdMinMaskingRightApplicable_16: eqData.rightEqMmlStatus[5],

    nbfThresholdMinMaskingLeft_5: eqData.leftEqMml[0],
    nbfThresholdMinMaskingLeftApplicable_5: eqData.leftEqMmlStatus[0],
    nbfThresholdMinMaskingLeft_1: eqData.leftEqMml[1],
    nbfThresholdMinMaskingLeftApplicable_1: eqData.leftEqMmlStatus[1],
    nbfThresholdMinMaskingLeft_2: eqData.leftEqMml[2],
    nbfThresholdMinMaskingLeftApplicable_2: eqData.leftEqMmlStatus[2],
    nbfThresholdMinMaskingLeft_4: eqData.leftEqMml[3],
    nbfThresholdMinMaskingLeftApplicable_4: eqData.leftEqMmlStatus[3],
    nbfThresholdMinMaskingLeft_8: eqData.leftEqMml[4],
    nbfThresholdMinMaskingLeftApplicable_8: eqData.leftEqMmlStatus[4],
    nbfThresholdMinMaskingLeft_16: eqData.leftEqMml[5],
    nbfThresholdMinMaskingLeftApplicable_16: eqData.leftEqMmlStatus[5],

    tanRight_5: tanRight[0],
    tanRight_1: tanRight[1],
    tanRight_2: tanRight[2],
    tanRight_4: tanRight[3],
    tanRight_8: tanRight[4],
    tanRight_16: tanRight[5],

    tanLeft_5: tanLeft[0],
    tanLeft_1: tanLeft[1],
    tanLeft_2: tanLeft[2],
    tanLeft_4: tanLeft[3],
    tanLeft_8: tanLeft[4],
    tanLeft_16: tanLeft[5],
    isCompleted,
  };

  return backendApiInstance.post(`patients/${patientUserId}/configure-sound`, data);
}

export async function getTreatmentTimelineDetails(patientUserId: string): Promise<AxiosResponse> {
  return backendApiInstance.get(`patients/${patientUserId}/get-treatment-timeline`);
}

export async function getCositHistory({
  patientUserId,
  offset,
  limit,
}: GetCositHistoryParamType): Promise<AxiosResponse> {
  return backendApiInstance.get(`patients/${patientUserId}/cosit-history`, { params: { offset, limit } });
}

export async function getAllPatientFeedback(patientId: string): Promise<AxiosResponse> {
  return backendApiInstance.get(`patients/${patientId}/all-feedback`);
}

export async function clinicToClinicTransferApprove(data: C2CTransferApproveParamType): Promise<AxiosResponse> {
  return backendApiInstance.post(`patients/approve-clinic-to-clinic-transfer`, data);
}

export async function clinicToClinicTransferReject(data: C2CTransferRejectParamType): Promise<AxiosResponse> {
  return backendApiInstance.post(`patients/reject-clinic-to-clinic-transfer`, data);
}

export async function clinicToClinicTransferPedingDetails({
  notificationId,
}: {
  notificationId: string;
}): Promise<AxiosResponse> {
  return backendApiInstance.get(`patients/pending-c2c-transfer/${notificationId}`);
}
