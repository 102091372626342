import React, { type ReactElement, type ReactNode, useEffect } from 'react';
import {
  Stack,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  List,
  ListItem,
  useTheme,
  Theme,
  CSSObject,
  Button,
  useMediaQuery,
  Grid,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { authActions } from '../../redux/auth/authSlice';
import { theme } from '../../theme/theme';
import { styled as materialStyled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { useNavigate } from 'react-router-dom';
import QuestionCircleSVG from '../../assets/img/question-circle-linear.svg';
import ArrowLeftSVG from '../../assets/img/arrow-left-linear.svg';
import { SIDEBAR_OPTIONS } from '../../utils/sidebar-schema';
import { useAuth } from '../../context/AuthContext';
import { Roles } from '../../utils/enums/roles.enum';
import { RootState } from '../../redux/store';
import { getGreetingMessage } from '../../utils/time-util';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import AvatarEdit from '../AvatarEdit/AvatarEdit';
import MenuIcon from '@mui/icons-material/Menu';
import { NotificationWindow } from '../NotificationPane/NotificationWindow';
import { notificationAction } from '../../redux/notification/notificationSlice';
import CustomModal from '../CustomModal/CustomModal';
import { LoadingButton } from '@mui/lab';
import * as _ from 'lodash';

interface BlankLayoutProps {
  children: ReactNode;
}

const AppName = materialStyled(Typography)(({ theme }: { theme: Theme }) => ({
  textAlign: 'center',
  opacity: '20%',
  color: theme.palette.primary.main,
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: notificationDrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `0px`,
  [theme.breakpoints.up('sm')]: {
    width: `0px`,
  },
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const drawerWidth = 240;
const notificationDrawerWidth = 520;
const AppBar = materialStyled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = materialStyled('div')(({ theme: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = materialStyled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: notificationDrawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  position: 'absolute',
  zIndex: theme.zIndex.drawer + 2,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      justifyContent: 'space-between',
      border: 'none',
      boxShadow: theme.shadows[1],
      ...openedMixin(theme),
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      justifyContent: 'space-between',
      border: 'none',
      boxShadow: theme.shadows[1],
      ...closedMixin(theme),
    },
  }),
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  },
}));

const FooterNote = materialStyled(Typography)(({ theme }: { theme: Theme }) => ({
  textAlign: 'center',
  color: `${theme.palette.info.main}66`,
}));

const CustomContainer = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  overflow: 'auto',
  height: 'calc(100vh - 64px)',
  flexGrow: 1,
  padding: '25px',
  marginTop: '64px',
  borderRadius: '40px 0 0 0',
  backgroundColor: theme.palette.secondary.main,
}));

const CustomButton = materialStyled(Button)(({ theme, isselected }: { theme: Theme; isselected: string }) => ({
  width: '200px',
  height: '56px',
  justifyContent: 'left',
  paddingLeft: '15px',
  borderRadius: '15px',
  textTransform: 'none',
  backgroundColor: isselected === 'true' ? theme.palette.secondary.main : 'inherit',
  color: isselected === 'true' ? theme.palette.primary.main : theme.palette.info.main,
}));

function DashboardLayout({ children }: BlankLayoutProps): ReactElement {
  const { user, isLogin } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const { lastActiveTime } = useAppSelector((state: RootState) => state.auth);
  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const isLogoutModalOpen = _.get(componentStatus, 'isLogoutModalOpen', false) as boolean;
  const isLogoutProcessing = _.get(componentStatus, 'isLogoutProcessing', false) as boolean;

  const [openSideBar, setOpenSideBar] = React.useState(false);
  const matchesMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (matchesMdUp) {
      setOpenSideBar(true);
    }
    if (matchesMdDown) {
      setOpenSideBar(false);
    }
  }, [matchesMdUp, matchesMdDown]);

  useEffect(() => {
    if (isLogin) {
      dispatch(notificationAction.loadNotificationCountAsync());
      const interval = setInterval(() => {
        dispatch(notificationAction.loadNotificationCountAsync());
      }, 120 * 1000); //within 2 minute
      return () => clearInterval(interval);
    }
  }, [dispatch, isLogin]);

  useEffect(() => {
    dispatch(authActions.inactiveAutoLogOutAsync());
  }, [lastActiveTime]);

  const handleDrawerOpen = () => {
    setOpenSideBar(!openSideBar);
  };

  const handleSuccessDismiss = () => {
    dispatch(authActions.clearComponentStatus());
  };

  const topBarView = useAppSelector((state: RootState) => state.auth.topBarView);
  const isNotificationView = useAppSelector((state: RootState) => state.notification.isView);
  const notificationCount = useAppSelector((state: RootState) => state.notification.notificationCount);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleSidebarClick = (path: string) => {
    navigate(path, { replace: true });

    if (matchesMdDown) {
      setOpenSideBar(false);
    }
  };
  const handleLogOut = () => {
    dispatch(authActions.logOutAsync());
  };

  const handleMyProfileOnClick = () => {
    navigate('/my-profile', { replace: true });
  };

  const handleTopBarViewOnClick = () => {
    if (topBarView?.path) {
      navigate(topBarView.path, { replace: true });
    }
  };

  const handleMyClinicProfile = () => {
    navigate(`/my-clinic`, { replace: true });
  };

  const handleBackButtonOnClick = () => {
    if (topBarView?.backButton) {
      if (topBarView.backButtonOnClick) {
        topBarView.backButtonOnClick();
      } else if (topBarView.backNav) {
        navigate(-1);
      } else {
        navigate(topBarView.backButton.path, { replace: true });
      }
    }
  };

  const handleNotificationIconClick = () => {
    dispatch(notificationAction.setNotificationView(true));
    dispatch(notificationAction.loadNotificationAsync(true));
  };

  const handleOnClickSupport = () => {
    if (window.location.pathname !== '/support') {
      navigate(`/support?feedbackSubmittedPage=${window.location.pathname.split('/')[1]}`, { replace: true });
    }
  };

  return (
    <Box display="flex">
      <CssBaseline />
      <AppBar
        sx={{ border: 'none' }}
        elevation={0}
        variant="outlined"
        color="transparent"
        position="fixed"
        open={openSideBar}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ display: { xs: 'flex', md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Stack direction="row" alignItems="center" justifyContent="start" spacing={1} overflow={'hidden'}>
            {!topBarView && (
              <Typography ml={2} variant="h5" color={theme.palette.primary.main}>
                {getGreetingMessage(new Date())}
              </Typography>
            )}
            {topBarView?.backButton && (
              <IconButton role="top-bar-back-btn" onClick={handleBackButtonOnClick} size="small">
                <Box component="img" src={ArrowLeftSVG}></Box>
              </IconButton>
            )}
            {topBarView?.text && (
              <Typography
                noWrap
                ml={2}
                variant="h5"
                onClick={handleTopBarViewOnClick}
                sx={{ cursor: 'pointer' }}
                color={theme.palette.primary.main}
              >
                {topBarView.text}
              </Typography>
            )}

            {topBarView?.button && (
              <Button
                variant="outlined"
                sx={{ borderRadius: '10px', whiteSpace: 'nowrap', minWidth: 'fit-content' }}
                onClick={topBarView.button.onClick}
                color={topBarView.button.color}
                startIcon={topBarView.button?.icon}
              >
                <Typography variant="meta">{topBarView.button.text}</Typography>
              </Button>
            )}

            {topBarView?.customElement}
          </Stack>

          <Box flexGrow={1} />
          <Box sx={{ display: 'flex' }}>
            <IconButton
              role="notification-bell-icon-btn"
              size="medium"
              aria-label={`show ${notificationCount} new notifications`}
              color="inherit"
              onClick={handleNotificationIconClick}
            >
              <Badge badgeContent={notificationCount} color="error">
                <NotificationsIcon fontSize="medium" color="primary" />
              </Badge>
            </IconButton>
            <Box marginLeft={2} flexGrow={0}>
              <Box display="flex" alignItems="center">
                <AvatarEdit
                  avatar={user.avatar}
                  editable={false}
                  name={user.firstName}
                  name2={user.lastName}
                  font={theme.typography.h6}
                  size="40px"
                />
                <Typography
                  sx={{ display: { xs: 'none', md: 'flex' } }}
                  marginLeft={1}
                  variant="bodyBold"
                  color="black"
                >
                  Hello, {user.firstName}!
                </Typography>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <ExpandMoreIcon />
                </IconButton>
              </Box>

              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    handleMyProfileOnClick();
                  }}
                >
                  <Typography variant="bodyRegular" color="info" textAlign="center">
                    My Profile
                  </Typography>
                </MenuItem>
                <MenuItem
                  sx={{ display: user.role === Roles.CLINIC_ADMIN ? 'inherit' : 'none' }}
                  onClick={() => {
                    handleCloseUserMenu();
                    handleMyClinicProfile();
                  }}
                >
                  <Typography variant="bodyRegular" color="info" textAlign="center">
                    Clinic Profile
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    dispatch(authActions.setComponentStatus({ isLogoutModalOpen: true }));
                  }}
                >
                  <Typography variant="bodyRegular" color="info" textAlign="center">
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <MuiDrawer
        sx={{
          width: { xs: 0, md: drawerWidth },
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
          '& .MuiPaper-root': { justifyContent: 'space-between', border: 'none' },
        }}
        variant="persistent"
        anchor="left"
        open={openSideBar}
      >
        <Box>
          <DrawerHeader sx={{ marginBottom: '10px', justifyContent: 'center' }}>
            <AppName variant="h4">TrueSilence</AppName>
          </DrawerHeader>

          <List>
            {Object.values(SIDEBAR_OPTIONS)
              .filter((option) => option.roles.includes(user.role))
              .map((option) => {
                return (
                  <ListItem key={option.name} disablePadding sx={{ display: 'block', mb: 1 }}>
                    <Box key={'Dashboard'} display="flex" alignItems="center">
                      <Box
                        sx={{
                          width: '5px',
                          height: '20px',
                          borderRadius: '0 10px 10px 0',
                          backgroundColor:
                            option.path === topBarView?.sideMenuPath ? theme.palette.primary.main : 'transparent',
                        }}
                      ></Box>
                      <Box sx={{ width: 'inherit', px: 2 }}>
                        <CustomButton
                          theme={theme}
                          isselected={`${option.path === topBarView?.sideMenuPath}`}
                          startIcon={
                            <Box
                              component="img"
                              width="32px"
                              src={
                                option.path === topBarView?.sideMenuPath ? option.icon.selected : option.icon.unselected
                              }
                            ></Box>
                          }
                          onClick={() => handleSidebarClick(option.path)}
                        >
                          <Typography
                            component="span"
                            variant={option.path === topBarView?.sideMenuPath ? 'bodyBold' : 'bodyRegular'}
                          >
                            {option.name}
                          </Typography>
                        </CustomButton>
                      </Box>
                    </Box>
                  </ListItem>
                );
              })}
            {}
          </List>
        </Box>

        <Box paddingX={2.5} mb={1}>
          {(user.role === Roles.CLINIC_ADMIN || user.role === Roles.CLINICIAN) && (
            <Box
              mb={2}
              display="flex"
              justifyContent="left"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
              onClick={handleOnClickSupport}
            >
              <Box mx={2} component="img" src={QuestionCircleSVG}></Box>
              <Typography variant="bodyRegular">Support</Typography>
            </Box>
          )}
          <FooterNote variant="subtitle1">{new Date().getFullYear()} TrueSlience. All rights reserved.</FooterNote>
        </Box>
      </MuiDrawer>

      <CustomContainer>{children}</CustomContainer>

      <Drawer anchor="right" open={isNotificationView} variant={'permanent'}>
        <NotificationWindow />
      </Drawer>
      <CustomModal
        modal={true}
        open={isLogoutModalOpen}
        handleButtonOnClick={handleSuccessDismiss}
        title={'Confirm logout'}
      >
        <Stack>
          <Typography variant="bodyRegular" component="p" textAlign="center" color={theme.palette.info.main}>
            Are you sure you want to logout?
          </Typography>

          <Grid container marginTop={3} columnSpacing={2} rowGap={2}>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                variant="outlined"
                onClick={() => {
                  dispatch(authActions.setComponentStatus({ isLogoutModalOpen: false }));
                }}
                fullWidth
                sx={{ borderRadius: '10px', height: '43px' }}
              >
                Dismiss
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                loading={isLogoutProcessing}
                onClick={() => {
                  dispatch(authActions.setComponentStatus({ isLogoutProcessing: true }));
                  handleLogOut();
                  handleCloseUserMenu();
                }}
                variant="contained"
                fullWidth
                color="error"
                sx={{ borderRadius: '10px', height: '43px' }}
              >
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
      </CustomModal>
    </Box>
  );
}

export default DashboardLayout;
