import React, { ChangeEvent, useRef } from 'react';
import { Avatar, Box, IconButton, useTheme, Skeleton } from '@mui/material';
import EditSVG from '../../assets/img/edit.svg';
import DummyAvatar1 from '../../assets/img/avatar-icon.jpg';
import DummyAvatar2 from '../../assets/img/avatar-icon1.jpg';
import DummyAvatar3 from '../../assets/img/avatar-icon2.png';

interface AvatarEditProps {
  editable: boolean;
  selectedAvatar?: string;
  size: string;
  name?: string;
  name2?: string;
  avatar?: string;
  alt?: string;
  needImgTag?: boolean;
  font: React.CSSProperties;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
  dummy?: boolean;
}

function AvatarEdit({
  editable,
  selectedAvatar,
  size,
  name = '',
  name2 = '',
  alt = 'profile picture',
  needImgTag = false,
  avatar,
  font,
  onChange,
  isLoading = false,
  dummy = false,
}: AvatarEditProps) {
  const theme = useTheme();
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  if (avatar) avatar = avatar.trim();

  const bgColors = [
    theme.palette.shades.purpleBorder,
    '#ff8a80',
    '#ff5252',
    '#ff1744',
    '#d50000',
    '#ff80ab',
    '#ff4081',
    '#f50057',
    '#c51162',
    '#ea80fc',
    '#e040fb',
    '#d500f9',
    '#aa00ff',
    '#b388ff',
    '#7c4dff',
    '#651fff',
    '#6200ea',
    '#8c9eff',
    '#536dfe',
    '#3d5afe',
    '#304ffe',
    '#82b1ff',
    '#448aff',
    '#2979ff',
    '#2962ff',
    '#80d8ff',
    '#40c4ff',
    '#00b0ff',
    '#0091ea',
    '#84ffff',
    '#18ffff',
    '#00e5ff',
    '#00b8d4',
    '#a7ffeb',
    '#64ffda',
    '#1de9b6',
    '#00bfa5',
    '#b9f6ca',
    '#69f0ae',
    '#00e676',
    '#00c853',
    '#ccff90',
    '#b2ff59',
    '#76ff03',
    '#64dd17',
    '#f4ff81',
    '#eeff41',
    '#c6ff00',
    '#aeea00',
    '#ffff8d',
    '#ffff00',
    '#ffea00',
    '#ffd600',
    '#ffe57f',
    '#ffd740',
    '#ffc400',
    '#ffab00',
    '#ffd180',
    '#ffab40',
    '#ff9100',
    '#ff6d00',
    '#ff9e80',
    '#ff6e40',
    '#ff3d00',
    '#dd2c00',
  ];

  const dummyAvatar = [DummyAvatar1, DummyAvatar2, DummyAvatar3];

  const getBgColor = () => {
    const completeName = name + (name2 ?? '');
    const count = completeName
      .trim()
      .split(' ')
      .join('')
      .split('')
      .reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return bgColors[count % bgColors.length];
  };

  const getDummyAvatar = () => {
    const array = new Uint32Array(1);
    window.crypto?.getRandomValues(array);
    return dummyAvatar[Math.floor((array[0] / (0xffffffff + 1)) * dummyAvatar.length)];
  };

  const getNameCharacter = () => {
    if (!name) {
      return '';
    } else if (name.trim() && name2.trim()) {
      return `${name.trim().charAt(0).toLocaleUpperCase()}${name2.trim().charAt(0).toLocaleUpperCase()}`;
    } else if (name.trim()) {
      const partsOfNames = name.trim().split(' ');
      if (partsOfNames.length === 1) {
        return partsOfNames[0].trim().charAt(0).toLocaleUpperCase();
      } else {
        return `${partsOfNames[0].charAt(0).toLocaleUpperCase()}${partsOfNames[1].charAt(0).toLocaleUpperCase()}`;
      }
    }
  };
  const handleFileUploadBtnClick = () => {
    if (hiddenFileInput?.current) {
      hiddenFileInput.current.click();
    }
  };
  if (isLoading) {
    return <Skeleton variant="circular" animation="wave" width={size} height={size} />;
  } else if (dummy) {
    return (
      <Avatar
        alt={alt}
        src={getDummyAvatar()}
        sx={{ width: size, height: size, backgroundColor: getBgColor(), ...font }}
      ></Avatar>
    );
  } else if (needImgTag) {
    return (
      <>
        {avatar && (
          <Box
            component="img"
            src={`${process.env.REACT_APP_CLOUD_URL}/minio/image/${avatar}`}
            sx={{ display: 'inline', marginBottom: '-4px', width: size, height: size, borderRadius: '50%' }}
          />
        )}
        {!avatar && (
          <Box
            component="div"
            sx={{
              display: 'inline',
              padding: '3px',
              width: size,
              height: size,
              backgroundColor: getBgColor(),
              color: theme.palette.white.main,
              borderRadius: '50%',
              ...font,
            }}
          >
            {getNameCharacter()}
          </Box>
        )}
      </>
    );
  } else {
    const avatarUrl = avatar ? `${process.env.REACT_APP_CLOUD_URL}/minio/image/${avatar}` : '';
    const avatarSrc = selectedAvatar ?? avatarUrl;
    return (
      <Box sx={{ position: 'relative', width: size, height: size }}>
        <Avatar
          alt={alt}
          src={avatarSrc}
          sx={{ position: 'absolute', width: size, height: size, backgroundColor: getBgColor(), ...font }}
        >
          {getNameCharacter()}
        </Avatar>
        {editable && (
          <label hidden={!editable}>
            <IconButton
              component="span"
              sx={{ position: 'absolute', bottom: '75%', left: '75%', padding: '0' }}
              onClick={handleFileUploadBtnClick}
              aria-label="edit"
            >
              <Box component="img" src={EditSVG}></Box>
            </IconButton>
          </label>
        )}
        {editable && (
          <input
            id="image-input"
            ref={hiddenFileInput}
            accept=".png, .jpg, .jpeg"
            style={{ display: 'none' }}
            type="file"
            onChange={onChange}
          />
        )}
      </Box>
    );
  }
}

export default AvatarEdit;
