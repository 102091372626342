import React, { Fragment, useEffect, useState } from 'react';
import { Box, Grid, TextField, Typography, Autocomplete, useTheme } from '@mui/material';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomInputBox from '../../../components/CustomInputBox/CustomInputBox';
import CustomButtonLoadingButton from '../../../components/CustomButtonLoadingButton/CustomButtonLoadingButton';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { generalActions } from '../../../redux/general/generalSlice';
import { clinicActions } from '../../../redux/clinic/clinicSlice';
import _ from 'lodash';
import SizeTooLargeError from '../../../components/SizeTooLargeError/SizeTooLargeError';
import { validation } from '../../../schema/validation';

interface GeneralInfoTabProps {
  clinicId?: string;
}

function GeneralInfoTab({ clinicId }: GeneralInfoTabProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [fileSizeTooLargeError, setFileSizeTooLargeError] = useState(false);
  const countryList = useAppSelector((state: RootState) => state.general.countryList);
  const stateList = useAppSelector((state: RootState) => state.general.stateList);
  const addClinicData = useAppSelector((state: RootState) => state.clinic.addClinicData);

  const [image, setImage] = useState<{ url: string; file: File | undefined }>({
    url: _.get(addClinicData, 'avatar.url', ''),
    file: _.get(addClinicData, 'avatar.file', undefined),
  });

  const componentStatus = useAppSelector((state: RootState) => state.general.componentStatus);
  const isSubmitting = _.get(componentStatus, 'isSubmitting', false) as boolean;
  const isEmailAlreadyExists = _.get(componentStatus, 'isEmailAlreadyExists', false);

  const clinicInfo = useAppSelector((state: RootState) => state.clinic.clinicInfo);
  const clinicIntermediateInfo = useAppSelector((state: RootState) => state.clinic.clinicIntermediateInfo);

  const getInitialValues = (property: string) => {
    if (clinicId) {
      const interValue = _.find(clinicIntermediateInfo, { field: property });
      return {
        value: interValue ? interValue['toValue'] : _.get(clinicInfo, property, ''),
        isIntermediate: !!interValue,
      };
    } else {
      return { value: _.get(addClinicData, property, ''), isIntermediate: false };
    }
  };

  const formik = useFormik({
    initialValues: {
      name: getInitialValues('name').value,
      email: getInitialValues('email').value,
      domain: getInitialValues('domain').value,
      phone: getInitialValues('phone').value,
      addressLine1: getInitialValues('addressLine1').value,
      addressLine2: getInitialValues('addressLine2').value,
      city: getInitialValues('city').value,
      state: getInitialValues('state').value,
      country: getInitialValues('country').value,
      zipCode: getInitialValues('zipCode').value,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string().max(30).required('Clinic name is required'),
      email: validation.email,
      domain: yup.string().max(190, 'Website name should not contain more than 190 characters').optional(),
      phone: yup
        .string()
        .matches(/^\+?\d{6,14}$/, { message: 'Invalid phone number' })
        .required('Phone number is required'),
      addressLine1: yup.string().max(150).required('Address Line 1 is required'),
      addressLine2: yup.string().max(150).optional(),
      city: yup.string().max(30).required('City is required'),
      state: yup.string().max(30).required('State/Region is required'),
      country: yup.string().required('Country is required'),
      zipCode: yup.string().max(30).matches(/^\d+$/, { message: 'Invalid zip code' }).required('Zip Code is required'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (clinicId) {
        dispatch(
          generalActions.setComponentStatus({
            isEmailAlreadyExists: false,
          }),
        );

        const changedValues = _.omitBy(values, (val, key) => _.get(formik.initialValues, key, '') === val);
        dispatch(clinicActions.updateClinicAsync({ clinicId, clinicInfo: changedValues, avatarFile: image.file }));
      } else {
        dispatch(
          generalActions.setComponentStatus({
            isEmailAlreadyExists: false,
          }),
        );
        dispatch(
          clinicActions.handleNextToAddClinicAdminTab({
            ...values,
            avatar: image,
          }),
        );
      }
    },
  });

  useEffect(() => {
    if (isEmailAlreadyExists) {
      formik.setFieldTouched('email', true);
      formik.setFieldError('email', 'Email address already exists');
    }
  }, [isEmailAlreadyExists]);

  useEffect(() => {
    dispatch(generalActions.getCountryListAsync());
  }, []);

  useEffect(() => {
    if (formik.values.country) {
      dispatch(generalActions.getStateListAsync({ country: formik.values.country }));
    }
  }, [formik.values.country]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.size <= 5 * 1024 * 1024) {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function (e) {
          const selectedFile = reader.result as string;

          setImage({ url: selectedFile, file: file });
        };
      } else {
        setFileSizeTooLargeError(true);
      }
    }
  };

  const isAnyValueHasChange = () => {
    return !_.isEqual(formik.initialValues, formik.values) || !!image.file;
  };

  return (
    <Fragment>
      <Box marginTop={2}>
        <AvatarEdit
          avatar={clinicInfo?.avatar}
          selectedAvatar={image.url}
          editable={true}
          name={_.get(formik, 'values.name', '')}
          font={theme.typography.h2}
          size="100px"
          onChange={handleFileChange}
        />
      </Box>

      <Grid container marginTop={1} rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12}>
          <CustomInputBox
            label="Clinic Name"
            required={true}
            type="text"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            backgroundColor={
              getInitialValues('name').isIntermediate ? `${theme.palette.warning.main}3f` : 'transparent'
            }
          />
        </Grid>
        <Grid item xs={4}>
          <CustomInputBox
            label="Website"
            required={false}
            type="text"
            name="domain"
            value={formik.values.domain}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.domain && Boolean(formik.errors.domain)}
            helperText={formik.touched.domain && formik.errors.domain}
            backgroundColor={
              getInitialValues('domain').isIntermediate ? `${theme.palette.warning.main}3f` : 'transparent'
            }
          />
        </Grid>
        <Grid item xs={4}>
          <CustomInputBox
            label="Email Address"
            required={true}
            type="text"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            backgroundColor={
              getInitialValues('email').isIntermediate ? `${theme.palette.warning.main}3f` : 'transparent'
            }
          />
        </Grid>
        <Grid item xs={4}>
          <CustomInputBox
            label="Phone Number"
            required={true}
            type="text"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            backgroundColor={
              getInitialValues('phone').isIntermediate ? `${theme.palette.warning.main}3f` : 'transparent'
            }
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInputBox
            label="Address Line 1"
            required={true}
            type="text"
            name="addressLine1"
            value={formik.values.addressLine1}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)}
            helperText={formik.touched.addressLine1 && formik.errors.addressLine1}
            backgroundColor={
              getInitialValues('addressLine1').isIntermediate ? `${theme.palette.warning.main}3f` : 'transparent'
            }
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInputBox
            label="Address Line 2"
            required={false}
            type="text"
            name="addressLine2"
            value={formik.values.addressLine2}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.addressLine2 && Boolean(formik.errors.addressLine2)}
            helperText={formik.touched.addressLine2 && formik.errors.addressLine2}
            backgroundColor={
              getInitialValues('addressLine2').isIntermediate ? `${theme.palette.warning.main}3f` : 'transparent'
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CustomInputBox
            label="City"
            required={true}
            type="text"
            name="city"
            value={formik.values.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
            backgroundColor={
              getInitialValues('city').isIntermediate ? `${theme.palette.warning.main}3f` : 'transparent'
            }
          />
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" flexDirection="column" gap="2px">
            <Typography variant="meta" component="p" align="left">
              State/ Province
              <Typography component="span" color="primary">
                &nbsp;*
              </Typography>
            </Typography>
            <Autocomplete
              value={formik.values.state}
              onChange={(e, value) => formik.setFieldValue('state', value)}
              onBlur={formik.handleBlur}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
              options={stateList.map((e) => e.state)}
              disabled={!formik.values.country}
              sx={{
                '& .MuiInputBase-root': {
                  alignContent: 'center',
                  height: '38px',
                  borderRadius: '10px',
                  backgroundColor: formik.values.country ? 'initial' : theme.palette.shades.disabled,
                },
              }}
            />
            {formik.touched.state && Boolean(formik.errors.state) && (
              <Typography paddingLeft={2} variant="meta" component="p" color="error">
                {formik.touched.state && formik.errors.state}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <CustomInputBox
            label="Country"
            required={true}
            type="text"
            select={true}
            name="country"
            value={formik.values.country}
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldValue('state', '');
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
            selectOptions={countryList.map((e) => ({
              key: e.country,
              value: e.country,
            }))}
            backgroundColor={
              getInitialValues('country').isIntermediate ? `${theme.palette.warning.main}3f` : 'transparent'
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CustomInputBox
            label="Zip Code"
            required={true}
            type="text"
            name="zipCode"
            value={formik.values.zipCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
            helperText={formik.touched.zipCode && formik.errors.zipCode}
            backgroundColor={
              getInitialValues('zipCode').isIntermediate ? `${theme.palette.warning.main}3f` : 'transparent'
            }
          />
        </Grid>
      </Grid>
      <Box
        display="flex"
        justifyContent="right"
        marginTop={4}
        sx={{
          paddingTop: '20px',
          borderTop: `1px solid ${theme.palette.shades.purpleBorder}`,
        }}
      >
        <CustomButtonLoadingButton
          disabled={!!clinicId && !isAnyValueHasChange()}
          loading={isSubmitting}
          text={clinicId ? 'Update' : 'Next'}
          variant="contained"
          color="primary"
          fullWidth={false}
          width="95px"
          onClick={() => {
            formik.handleSubmit();
          }}
        />
      </Box>

      <SizeTooLargeError
        open={fileSizeTooLargeError}
        maxFileSize={'5MB'}
        onClickDismiss={() => setFileSizeTooLargeError(false)}
      />
    </Fragment>
  );
}

export default GeneralInfoTab;
