import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { AddClinicianProps, clinicianActions } from './clinicianSlice';
import { AxiosResponse } from 'axios';
import { authActions } from '../auth/authSlice';
import {
  createClinician,
  deactivateClinician,
  GetAllClinicianParamType,
  getAllClinicians,
  getAllCliniciansInClinic,
  getClinicianByUserId,
  reactivateClinician,
} from '../../api/clinicianApi';
import { generalActions } from '../general/generalSlice';
import { validateEmail } from '../../api/userApi';
import { patientActions } from '../patient/patientSlice';

function* watchGetClinicianByUserId({ payload }: { payload: string }) {
  const userId = payload;

  yield put(authActions.setComponentStatus({ isSelectedClinicianLoading: true }));
  try {
    const res: AxiosResponse = yield call(getClinicianByUserId, userId);

    yield put(clinicianActions.setSelectedClinician(res.data));
    yield put(patientActions.setComponentStatus({ isTransferLoading: false }));
  } catch (err: any) {
    yield put(clinicianActions.setSelectedClinician(undefined));
  }
  yield put(authActions.setComponentStatus({ isSelectedClinicianLoading: false }));
}

function* watchGetClinicianList({ payload }: { payload: GetAllClinicianParamType }) {
  yield put(authActions.setComponentStatus({ isClinicianTableLoading: true }));
  try {
    const res: AxiosResponse = yield call(getAllClinicians, payload);
    yield put(clinicianActions.setTotalClinicianCount(res.data['total']));
    yield put(clinicianActions.setClinicianList(res.data['data']));
  } catch (err: any) {
    yield put(clinicianActions.setTotalClinicianCount(0));
    yield put(clinicianActions.setClinicianList([]));
  }
  yield put(authActions.setComponentStatus({ isClinicianTableLoading: false }));
}

function* watchCreateClinicianAsync({ payload }: { payload: AddClinicianProps }) {
  yield put(generalActions.setComponentStatus({ isSubmitting: true }));
  try {
    let isEmailAlreadyExists = false;
    if (payload.email) {
      const res: AxiosResponse = yield call(validateEmail, `${payload.email}`);
      isEmailAlreadyExists = res.data;
      yield put(generalActions.setComponentStatus({ isEmailAlreadyExists }));
    }
    if (!isEmailAlreadyExists) {
      yield call(createClinician, payload);
      yield put(clinicianActions.setAddClinicianData(payload));
      yield put(generalActions.setComponentStatus({ isAddClinicianModalOpen: false }));
      yield put(authActions.setComponentStatus({ shouldClinicianListLoad: true }));
      yield put(generalActions.setComponentStatus({ isAddClinicianSuccessModalOpen: true }));
    }
  } catch (err: any) {}
  yield put(generalActions.setComponentStatus({ isSubmitting: false }));
}

function* watchDeactivateClinician({ payload }: { payload: { userId: string; password: string } }) {
  yield put(authActions.setComponentStatus({ isClinicianDeactivating: true }));
  try {
    yield call(deactivateClinician, payload.userId, payload.password);
    yield put(authActions.setComponentStatus({ isDeactivateSuccess: true }));
  } catch (err: any) {
    if (err.response.status === 403) {
      yield put(authActions.setComponentStatus({ isPasswordIncorrect: true }));
    }
    yield put(authActions.setComponentStatus({ error: err.response.data.message }));
  }
  yield put(authActions.setComponentStatus({ isClinicianDeactivating: false }));
}

function* watchReactivateClinician({ payload }: { payload: { userId: string; password: string } }) {
  yield put(authActions.setComponentStatus({ isClinicianReactivating: true }));
  try {
    yield call(reactivateClinician, payload.userId, payload.password);
    yield put(authActions.setComponentStatus({ isReactivateSuccess: true }));
  } catch (err: any) {
    if (err.response.status === 403) {
      yield put(authActions.setComponentStatus({ isPasswordIncorrect: true }));
    }
    yield put(authActions.setComponentStatus({ error: err.response.data.message }));
  }
  yield put(authActions.setComponentStatus({ isClinicianReactivating: false }));
}

function* watchGetAllCliniciansInClinic() {
  try {
    const res: AxiosResponse = yield call(getAllCliniciansInClinic);
    yield put(clinicianActions.setCliniciansDropdownList(res.data));
  } catch (err: any) {
    yield put(clinicianActions.setCliniciansDropdownList([]));
  }
}

export function* watchClinicianSagas(): Generator<ForkEffect, void> {
  yield takeLatest(clinicianActions.getClinicianByUserIdAsync, watchGetClinicianByUserId);
  yield takeLatest(clinicianActions.getClinicianListAsync, watchGetClinicianList);
  yield takeLatest(clinicianActions.createClinicianAsync, watchCreateClinicianAsync);
  yield takeLatest(clinicianActions.deactivateClinicianAsync, watchDeactivateClinician);
  yield takeLatest(clinicianActions.reactivateClinicianAsync, watchReactivateClinician);
  yield takeLatest(clinicianActions.getAllCliniciansInClinicAsync, watchGetAllCliniciansInClinic);
}
const clinicianSagas = watchClinicianSagas;
export default clinicianSagas;
