import React, { useEffect, useState } from 'react';
import CustomModal from '../../../../../components/CustomModal/CustomModal';
import CustomTable, { ColumnProps } from '../../../../../components/CustomTable/CustomTable';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { patientActions } from '../../../../../redux/patient/patientSlice';
import * as dateFns from 'date-fns';
import { RootState } from '../../../../../redux/store';
import _ from 'lodash';
import { SortOrder } from '../../../../../utils/enums/sort-order.enum';

interface CositHistoryProps {
  isOpen: boolean;
  patientUserId: string;
}

function CositHistoryModal({ isOpen, patientUserId }: CositHistoryProps) {
  const dispatch = useAppDispatch();

  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const cositHistory = useAppSelector((state: RootState) => state.patient.cositHistory);
  const totalDataRows = useAppSelector((state: RootState) => state.patient.totalCositHistoryCount);
  const componentStatus = useAppSelector((state: RootState) => state.patient.componentStatus);
  const isCositHistoryLoading = _.get(componentStatus, 'isCositHistoryLoading', false) as boolean;

  useEffect(() => {
    if (isOpen) {
      const offset = (page - 1) * rowsPerPage;
      dispatch(patientActions.getCositHistoryAsync({ patientUserId, offset, limit: rowsPerPage }));
    }
  }, [isOpen, rowsPerPage, page]);

  const columns: ColumnProps[] = [
    {
      key: 'createdAt',
      name: 'Date of Update',
      enableSort: false,
      align: 'left',
      width: '20%',
      minWidth: 100,
      resizable: false,
    },
    { key: 'first', name: '1', enableSort: false, align: 'left', width: '16%', minWidth: 50, resizable: false },
    { key: 'second', name: '2', enableSort: false, align: 'left', width: '16%', minWidth: 50, resizable: false },
    { key: 'third', name: '3', enableSort: false, align: 'left', width: '16%', minWidth: 50, resizable: false },
    { key: 'fourth', name: '4', enableSort: false, align: 'left', width: '16%', minWidth: 50, resizable: false },
    { key: 'fifth', name: '5', enableSort: false, align: 'left', width: '16%', minWidth: 50, resizable: false },
  ];

  const getFormattedRows = () => {
    return cositHistory?.map((cosit) => {
      return {
        createdAt: dateFns.format(new Date(cosit?.createdAt ?? ''), 'MM/dd/yyyy'),
        first: cosit.goals[0],
        second: cosit.goals[1],
        third: cosit.goals[2],
        fourth: cosit.goals[3],
        fifth: cosit.goals[4],
      };
    });
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setRowsPerPage(rowsPerPage);
    setPage(1);
  };

  const handleDismiss = () => {
    dispatch(patientActions.setComponentStatus({ isCositHistoryModalOpen: false }));
  };
  return (
    <CustomModal
      modal={true}
      open={isOpen}
      title={'COSIT'}
      width="906px"
      minHeight="650px"
      height="auto"
      topCloseBtn={handleDismiss}
      padding={0}
      isHistoryModal={true}
    >
      <CustomTable
        isLoading={isCositHistoryLoading}
        totalDataRows={totalDataRows}
        enablePagination={true}
        selectedPage={page}
        enablePagePerRows={true}
        rowsPerList={[10, 20, 50, 100]}
        selectedRowsPerPage={rowsPerPage}
        columns={columns}
        rows={getFormattedRows()}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSortByChange={() => {}}
        handleSortOrderChange={() => {}}
        minBodyRowSpace={10}
        sortBy={''}
        sortOrder={SortOrder.ASC}
        tableBorderRadius={'0'}
        tableContainerComponent={'div'}
        customTableTop={<></>}
      />
    </CustomModal>
  );
}

export default CositHistoryModal;
