import React from 'react';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { Box, Grid, Stack, Theme, Typography, useTheme } from '@mui/material';
import CloseCircleBoldSVG from '../../../assets/img/close-circle-bold.svg';
import GeneralInfoTab from './GeneralInfoTab';
import { styled as materialStyled } from '@mui/material/styles';
import ConfirmTab from './ConfirmTab';
import ClinicAdminTab from './ClinicAdminTab';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';
import { generalActions } from '../../../redux/general/generalSlice';
import { clinicActions } from '../../../redux/clinic/clinicSlice';
import { AddClinicTabs } from '../../../utils/enums/add-clinic-tabs.enum';

const TabHeading = materialStyled(Typography)(({ theme, active }: { theme: Theme; active: string }) => ({
  paddingBottom: '10px',
  display: 'block',
  paddingLeft: active === 'true' ? 0 : '10px',
  borderBottom:
    active === 'true' ? `2px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.shades.purpleBorder}`,
  color: active === 'true' ? theme.palette.primary.main : theme.palette.info.main,
}));

function AddClinic() {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const componentStatus = useAppSelector((state: RootState) => state.general.componentStatus);
  const tab = _.get(componentStatus, 'selectedTab', AddClinicTabs.GENERAL_INFO) as string;
  const isAddClinicModalOpen = _.get(componentStatus, 'isAddClinicModalOpen', false) as boolean;

  const handleAddClinicModalClose = () => {
    dispatch(generalActions.clearComponentStatus());
    dispatch(clinicActions.clearAddClinicData());
  };

  return (
    <CustomModal
      modal={true}
      open={isAddClinicModalOpen}
      width="900px"
      minHeight="650px"
      height="auto"
      title={'Add New Clinic'}
      align="left"
    >
      <Stack direction="column" alignItems="left" justifyContent="center">
        <Box
          component="img"
          src={CloseCircleBoldSVG}
          onClick={handleAddClinicModalClose}
          sx={{ position: 'absolute', bottom: '75%', left: '93%', top: '6%', width: '36px', cursor: 'pointer' }}
        />
        <Grid container>
          <Grid item xs={4}>
            <TabHeading
              variant={tab === AddClinicTabs.GENERAL_INFO ? 'bodyBold' : 'bodyRegular'}
              theme={theme}
              active={`${tab === AddClinicTabs.GENERAL_INFO}`}
            >
              1. General Info
            </TabHeading>
          </Grid>
          <Grid item xs={4}>
            <TabHeading
              theme={theme}
              variant={tab === AddClinicTabs.CLINIC_ADMIN ? 'bodyBold' : 'bodyRegular'}
              active={`${tab === AddClinicTabs.CLINIC_ADMIN}`}
            >
              2. Clinic Admin
            </TabHeading>
          </Grid>
          <Grid item xs={4}>
            <TabHeading
              theme={theme}
              variant={tab === AddClinicTabs.CONFIRM ? 'bodyBold' : 'bodyRegular'}
              active={`${tab === AddClinicTabs.CONFIRM}`}
            >
              3. Confirm
            </TabHeading>
          </Grid>
        </Grid>
        {tab === AddClinicTabs.GENERAL_INFO && <GeneralInfoTab />}
        {tab === AddClinicTabs.CLINIC_ADMIN && <ClinicAdminTab />}
        {tab === AddClinicTabs.CONFIRM && <ConfirmTab />}
      </Stack>
    </CustomModal>
  );
}

export default AddClinic;
