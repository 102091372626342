import React, { MouseEventHandler, ReactNode } from 'react';
import { Box, Button, Theme, Typography, useTheme, Dialog, IconButton } from '@mui/material';
import { styled as materialStyled } from '@mui/material/styles';
import CloseCircleBoldSVG from '../../assets/img/close-circle-bold.svg';

interface CustomModalProps {
  modal?: boolean;
  open?: boolean;
  handleClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  handleButtonOnClick?: MouseEventHandler<HTMLButtonElement>;
  icon?: string;
  title?: string;
  subTitle?: string;
  children?: ReactNode;
  btnText?: string;
  btnVariant?: 'contained' | 'outlined' | 'text';
  width?: string;
  height?: string;
  minHeight?: string;
  align?: 'left' | 'center' | 'right';
  maxWidth?: string;
  topCloseBtn?: () => void;
  btnDisabled?: boolean;
  padding?: string | number;
  isHistoryModal?: boolean;
}

const CustomBox = materialStyled(Box)(
  ({ theme, width, height, minHeight }: { theme: Theme; width?: string; height?: string; minHeight?: string }) => ({
    backgroundColor: theme.palette.white.main,
    borderRadius: '20px',
    border: 'none',
    width: width ?? 'auto',
    height: height ?? 'auto',
    minHeight: minHeight ?? 'auto',
  }),
);

const CustomButton = materialStyled(Button)(({ theme }: { theme: Theme }) => ({
  borderRadius: '10px',
  height: '43px',
  gap: '10px',
  textAlign: 'center',
  textTransform: 'none',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '297px',
    width: '75vw',
  },
}));

export function CustomModal({
  modal,
  open,
  handleClose,
  handleButtonOnClick,
  icon,
  title,
  subTitle,
  children,
  btnText,
  btnVariant,
  width,
  height,
  minHeight = 'auto',
  align = 'center',
  maxWidth = 'auto',
  topCloseBtn,
  btnDisabled,
  padding = '32px 32px 32px 32px',
  isHistoryModal = false,
}: CustomModalProps) {
  const theme = useTheme();

  const renderModalContent = () => {
    return (
      <CustomBox
        theme={theme}
        width={width}
        height={height}
        minHeight={minHeight}
        maxWidth={maxWidth}
        padding={padding}
        boxShadow={1}
      >
        {icon && <Box mb={3} component="img" width="56px" display="flex" marginX="auto" src={icon}></Box>}
        {title && !topCloseBtn && (
          <Typography variant="h5" textAlign={align}>
            {title}
          </Typography>
        )}
        {title && topCloseBtn && !isHistoryModal && (
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" textAlign={align}>
              {title}
            </Typography>
            <IconButton size="small" aria-label="close" onClick={topCloseBtn}>
              <Box component="img" src={CloseCircleBoldSVG} />
            </IconButton>
          </Box>
        )}
        {title && topCloseBtn && isHistoryModal && (
          <Box display="flex" padding="32px 32px 0" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" textAlign={align}>
              {title}
            </Typography>
            <IconButton size="small" aria-label="close" onClick={topCloseBtn}>
              <Box component="img" src={CloseCircleBoldSVG} />
            </IconButton>
          </Box>
        )}
        {subTitle && (
          <Typography mt={1} variant="h6" textAlign={align}>
            {subTitle}
          </Typography>
        )}
        <Box mt={3}>
          {children}
          {!!btnText && (
            <Box mt={4} display="flex" justifyContent="center">
              <CustomButton
                variant={btnVariant ?? 'contained'}
                color="primary"
                fullWidth
                disabled={btnDisabled}
                onClick={handleButtonOnClick}
              >
                {btnText}
              </CustomButton>
            </Box>
          )}
        </Box>
      </CustomBox>
    );
  };

  if (modal) {
    return (
      <Dialog
        open={!!open}
        onClose={handleClose}
        scroll={'body'}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ '& .MuiPaper-root': { borderRadius: '20px', maxWidth: 'fit-content' } }}
      >
        {renderModalContent()}
      </Dialog>
    );
  } else {
    return renderModalContent();
  }
}

export default CustomModal;
