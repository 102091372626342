import React, { useRef, useState } from 'react';
import { Box, Typography, useTheme, LinearProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';
import { authActions } from '../../../redux/auth/authSlice';
import { userActions } from '../../../redux/user/userSlice';
import { SquareInputBox } from '../../../components/SquareInputBox/SquareInputBox';

interface EmailVerifyProps {
  email: string;
}

function EmailVerify({ email }: EmailVerifyProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [verificationCode, setVerificationCode] = useState<string[]>(Array(6).fill(''));
  const inputRefs = useRef<HTMLInputElement[]>(Array(6).fill(null));

  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const isSubmitting = _.get(componentStatus, 'isSubmitting', false) as boolean;
  const verifyStatus = _.get(componentStatus, 'verifyStatus', undefined) as string; // undefined, verifying, failed
  const error = _.get(componentStatus, 'error', '') as string;

  const handleVerificationCodeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const value = event.target.value;
    if (!isNaN(Number(value)) && index < 6) {
      if (_.compact(verificationCode).length === 6 && verifyStatus === 'failed') {
        setVerificationCode(verificationCode.fill(''));
      }

      const code = [...verificationCode];
      code[index] = event.target.value.slice(-1);
      setVerificationCode(code);

      if (value !== '' && index < 5) {
        inputRefs.current[index + 1].focus();
      }
      if (_.compact(code).length === 6) {
        dispatch(authActions.setComponentStatus({ isSubmitting: true, error: '', verifyStatus: 'verifying' }));
        dispatch(userActions.verifyEmailVerificationCodeAsync({ verificationCode: code.join('') }));
      }
    }
  };
  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Backspace' && verificationCode[index] === '' && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleResendEmailVerificationCode = () => {
    if (!isSubmitting) {
      setVerificationCode(verificationCode.fill(''));
      dispatch(authActions.setComponentStatus({ isSubmitting: true, error: '', verifyStatus: undefined }));
      dispatch(userActions.sendEmailVerificationCodeAsync({ email }));
    }
  };

  const determineColor = () => {
    if (verifyStatus === undefined) {
      return 'primary';
    } else if (verifyStatus === 'verifying') {
      return 'success';
    } else {
      return 'error';
    }
  };

  const color = determineColor();

  const keyMap: number[] = [0, 1, 2, 3, 4, 5];

  return (
    <Box>
      <Typography mb={4} variant="bodyRegular" component="p" textAlign="center" color={theme.palette.info.main}>
        To complete the verification process, please enter the verification code received at <strong>{email}</strong>
      </Typography>
      <Box display="flex" justifyContent="space-between" columnGap={1}>
        {verificationCode.map((e, index) => {
          return (
            <SquareInputBox
              height="56px"
              width="53px"
              disabled={verificationCode.filter((e) => e).length === 6 && verifyStatus === 'verifying'}
              verifystatus={verifyStatus}
              theme={theme}
              inputRef={(el) => (inputRefs.current[index] = el as HTMLInputElement)}
              autoFocus={index === 0}
              onKeyDown={(e) => handleKeyDown(index, e)}
              color={color}
              key={keyMap[index]}
              type="text"
              name={`n${index}`}
              fullWidth
              value={e}
              onChange={(event) => {
                handleVerificationCodeChange(event, index);
              }}
              variant="outlined"
            />
          );
        })}
      </Box>
      <Typography
        m={1}
        hidden={verifyStatus !== 'failed'}
        variant="bodyBold"
        component="p"
        textAlign="center"
        color="error"
      >
        {error}
      </Typography>
      {isSubmitting && (
        <Box mt={2}>
          <LinearProgress color={verifyStatus === 'verifying' ? 'success' : 'primary'} />
        </Box>
      )}
      <Typography mt={4} variant="bodyBold" component="p" textAlign="center" color={theme.palette.info.main}>
        Didn’t received the code?
        <Typography
          variant="bodyBold"
          onClick={handleResendEmailVerificationCode}
          sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
        >
          &nbsp; Resend Code
        </Typography>
      </Typography>
    </Box>
  );
}

export default EmailVerify;
