import { Fragment, useEffect, useState } from 'react';
import CustomModal from '../../../components/CustomModal/CustomModal';
import GreenCheckCircleSVG from '../../../assets/img/check-circle-bold-duotone.svg';
import ShieldOutlinedSVG from '../../../assets/img/shield-warning-bold-duotone.svg';
import { Box, Grid, IconButton, InputAdornment, Stack, Typography, useTheme } from '@mui/material';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import CustomInputBox from '../../../components/CustomInputBox/CustomInputBox';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { authActions } from '../../../redux/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';
import { clinicianActions } from '../../../redux/clinician/clinicianSlice';
import { getAgeFromDateOfBirth } from '../../../utils/time-util';

interface ClinicianReactivateProps {
  userId: string;
  open: boolean;
}

const ReactivateClinician = ({ userId, open }: ClinicianReactivateProps) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();

  const selectedClinician = useAppSelector((state: RootState) => state.clinician.selectedClinician);
  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const isPasswordIncorrect = _.get(componentStatus, 'isPasswordIncorrect', false) as boolean;
  const isClinicianReactivating = _.get(componentStatus, 'isClinicianReactivating', false) as boolean;
  const isReactivateSuccess = _.get(componentStatus, 'isReactivateSuccess', false) as boolean;
  const error = _.get(componentStatus, 'error', '') as string;

  useEffect(() => {
    formik.setFieldValue('password', '');
    if (userId) {
      dispatch(
        authActions.setComponentStatus({
          isPasswordIncorrect: false,
          isClinicianReactivating: false,
          isReactivateSuccess: false,
        }),
      );
    }
  }, [userId]);

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: yup.object({
      password: yup.string().required('Password is required'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({ password }) => {
      dispatch(
        authActions.setComponentStatus({
          isPasswordIncorrect: false,
          isClinicianReactivating: true,
          error: '',
        }),
      );
      dispatch(clinicianActions.reactivateClinicianAsync({ userId, password }));
    },
  });

  const handleSuccessDismiss = () => {
    formik.setFieldValue('password', '');
    dispatch(authActions.clearComponentStatus());
    dispatch(clinicianActions.getClinicianByUserIdAsync(userId ?? ''));
    setShowPassword(false);
  };

  return (
    <Fragment>
      <CustomModal
        modal={true}
        open={!!userId && open}
        width="374px"
        btnText={isReactivateSuccess ? 'Dismiss' : ''}
        handleButtonOnClick={handleSuccessDismiss}
        title={isReactivateSuccess ? 'Clinician Activated' : 'Activate Clinician'}
        icon={isReactivateSuccess ? GreenCheckCircleSVG : ShieldOutlinedSVG}
      >
        <Stack>
          <Typography
            hidden={!!isReactivateSuccess}
            mb={2}
            variant="bodyRegular"
            component="p"
            textAlign="center"
            color={theme.palette.info.main}
          >
            Are you sure you want to activate the selected clinician?
          </Typography>
          <Typography
            hidden={!isReactivateSuccess}
            mb={2}
            variant="bodyRegular"
            component="p"
            textAlign="center"
            color={theme.palette.info.main}
          >
            You can add patients to this clinician from the patient tab on clinic admin dashboard.
          </Typography>

          <Box display="flex" justifyContent="center" mt={4}>
            <AvatarEdit
              editable={false}
              size="72px"
              name={selectedClinician?.firstName}
              name2={selectedClinician?.lastName}
              avatar={selectedClinician?.avatar}
              font={theme.typography.h3}
            />
          </Box>

          <Typography mt={4} component="p" variant="h6" align="center">
            {`${selectedClinician?.firstName ?? ''} ${selectedClinician?.lastName}`}
          </Typography>
          <Typography mt={0.5} component="p" variant="bodyRegular" align="center">
            {selectedClinician?.email}
          </Typography>
          <Typography mt={0.5} component="p" variant="bodyRegular" align="center">
            {selectedClinician?.phone}
          </Typography>
          <Typography mt={0.5} component="p" variant="bodyRegular" align="center">
            {`${getAgeFromDateOfBirth(selectedClinician?.birthday)} years old`}
          </Typography>
          <Typography mt={0.5} component="p" variant="bodyRegular" align="center">
            {`${selectedClinician?.patientCount} Patients`}
          </Typography>

          <Box mt={2} />
          {!isReactivateSuccess && (
            <CustomInputBox
              label="Please enter your password"
              labelAlign={'center'}
              placeholder=""
              required={true}
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={(formik.touched.password && Boolean(formik.errors.password)) || isPasswordIncorrect}
              helperText={
                (formik.touched.password && formik.errors.password) || (isPasswordIncorrect && 'Incorrect password!')
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}

          {!isReactivateSuccess && (
            <Grid container marginTop={3} columnSpacing={2}>
              {error && !isPasswordIncorrect && (
                <Grid item xs={12}>
                  <Typography mb={1} variant="bodyRegular" component="p" textAlign="center" color="error">
                    {error}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <LoadingButton
                  disabled={!selectedClinician}
                  loading={isClinicianReactivating}
                  onClick={() => formik.handleSubmit()}
                  variant="contained"
                  fullWidth
                  color="primary"
                  sx={{ borderRadius: '10px', height: '43px' }}
                >
                  Activate
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </Stack>
      </CustomModal>
    </Fragment>
  );
};

export default ReactivateClinician;
