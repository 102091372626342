import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import HeadTopViewSVG from '../../../../../assets/img/head-top-view.svg';
import Ellipse13SVG from '../../../../../assets/img/ellipse-13.svg';
import Ellipse9SVG from '../../../../../assets/img/ellipse-9.svg';

const CIRCLE_RADIUS = 50;
const POINTER_RADIUS = 6;

interface HorizontalDirectionProps {
  isLoading: boolean;
  angle: number;
}

function HorizontalDirection({ isLoading, angle }: HorizontalDirectionProps) {
  const theme = useTheme();

  // transform the given angle to radian.
  const angleRadian = (Math.PI * (90 - angle)) / 180;

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography mb={2} variant="button" color={theme.palette.info.main}>
        Horizontal Direction
      </Typography>
      <Box width={190} height={178} display="flex" justifyContent="center" alignItems="center">
        <Box width={CIRCLE_RADIUS * 2} height={CIRCLE_RADIUS * 2} position="relative">
          <Box
            component="img"
            src={HeadTopViewSVG}
            position="absolute"
            margin="auto"
            left="0"
            right="0"
            top="0"
            bottom="0"
          />
          <Box
            component="img"
            src={Ellipse13SVG}
            width={CIRCLE_RADIUS * 2}
            height={CIRCLE_RADIUS * 2}
            position="absolute"
            left="0"
            right="0"
          />
          {!isLoading && (
            <Box
              component="img"
              src={Ellipse9SVG}
              width={POINTER_RADIUS * 2}
              height={POINTER_RADIUS * 2}
              position="absolute"
              left={CIRCLE_RADIUS + CIRCLE_RADIUS * Math.cos(angleRadian) - POINTER_RADIUS}
              top={CIRCLE_RADIUS - CIRCLE_RADIUS * Math.sin(angleRadian) - POINTER_RADIUS}
            />
          )}

          <Typography
            variant="meta"
            color={theme.palette.info.main}
            sx={{ position: 'absolute', left: CIRCLE_RADIUS - 15, top: CIRCLE_RADIUS - 5 }}
          >
            360°
          </Typography>

          <Typography
            variant="meta"
            color={theme.palette.info.main}
            sx={{ position: 'absolute', left: CIRCLE_RADIUS - 15, top: -20 }}
          >
            Front
          </Typography>

          <Typography
            variant="meta"
            color={theme.palette.info.main}
            sx={{ position: 'absolute', left: CIRCLE_RADIUS - 15, bottom: -20 }}
          >
            Back
          </Typography>

          <Typography
            variant="meta"
            color={theme.palette.info.main}
            sx={{ position: 'absolute', right: -40, top: CIRCLE_RADIUS - 10 }}
          >
            Right
          </Typography>

          <Typography
            variant="meta"
            color={theme.palette.info.main}
            sx={{ position: 'absolute', left: -30, top: CIRCLE_RADIUS - 10 }}
          >
            Left
          </Typography>
        </Box>
      </Box>
      <Typography mt={2} variant="meta" color={theme.palette.info.main}>
        {`${angle}°`}
      </Typography>
    </Box>
  );
}

export default HorizontalDirection;
