export function linearToDecibel(value: number) {
  const sourceRangeMin = 0;
  const sourceRangeMax = 120;
  const targetRangeMin = -90;
  const targetRangeMax = 10;

  const targetRange = targetRangeMax - targetRangeMin;
  const sourceRange = sourceRangeMax - sourceRangeMin;
  const decimalValue = ((value - sourceRangeMin) * targetRange) / sourceRange + targetRangeMin;
  return Math.round(decimalValue * 100) / 100;
}
