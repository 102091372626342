import { put, call, takeLatest, ForkEffect, debounce } from 'redux-saga/effects';
import { authActions } from './authSlice';
import { logIn, logOut, sendEmailOtp } from '../../api/authApi';
import { forgotPassword, updatePassword, validatePasswordResetToken } from '../../api/userApi';
import { AxiosResponse } from 'axios';
import * as _ from 'lodash';
import { persistor, store } from '../store';

export interface LogInPayload {
  email: string;
  password: string;
  otp: string | null;
  otpSkip: boolean | null;
}

function* watchSendEmailOtp({ payload }: { payload: { email: string; password: string } }) {
  try {
    yield put(authActions.setComponentStatus({ isSubmitting: true }));
    yield call(sendEmailOtp, payload.email, payload.password);
    yield put(authActions.setComponentStatus({ isOtpSet: true, countdownStartTime: Date.now().toString() }));
  } catch (err) {
    if (_.get(err, 'response.status') === 401 && _.get(err, 'response.data.error') === 'account_deactivated') {
      yield put(
        authActions.setComponentStatus({
          error: 'Your account has been deactivated. Please contact the TrueSilence Team.',
        }),
      );
    } else if (_.get(err, 'response.status') === 401) {
      yield put(authActions.setComponentStatus({ error: 'Email or Password Incorrect!' }));
    } else {
      yield put(
        authActions.setComponentStatus({
          error: _.get(err, 'response.data.error_description', 'Something went wrong'),
        }),
      );
    }
  }
  yield put(authActions.setComponentStatus({ isSubmitting: false }));
}

function* watchLogIn({ payload }: { payload: LogInPayload }) {
  const { email, password, otp, otpSkip } = payload;
  try {
    yield put(authActions.setComponentStatus({ isSubmitting: true }));

    const res: AxiosResponse = yield call(logIn, email, password, otp, otpSkip);

    if (res.data.access_token && res.data.refresh_token) {
      yield put(authActions.setComponentStatus({ isPageLoading: true }));
      yield put(authActions.setLogInStatus(true));
      yield put(authActions.clearComponentStatus());

      // trigger the other tabs to change isLogin status
      localStorage.setItem('isLogin', String(true));
      localStorage.removeItem('isLogin');
    } else if (res.data?.response === 'otp_sent') {
      yield put(authActions.setComponentStatus({ isOtpSet: true, countdownStartTime: Date.now().toString() }));
    }
  } catch (err) {
    if (_.get(err, 'response.data.error') === 'invalid_otp') {
      yield put(authActions.setComponentStatus({ error: 'Incorrect OTP', verifyStatus: 'failed' }));
    } else if (_.get(err, 'response.data.error') === 'invalid_otp_reset') {
      yield put(
        authActions.setComponentStatus({
          error: 'Three consecutive failed attempts, a new OTP has been sent to your email address.',
          verifyStatus: 'failed',
        }),
      );
    } else if (_.get(err, 'response.data.error') === 'expired_otp') {
      yield put(authActions.setComponentStatus({ error: 'Expired OTP', verifyStatus: 'failed' }));
    } else if (_.get(err, 'response.data.error') === 'account_deactivated') {
      yield put(
        authActions.setComponentStatus({
          error: 'Your account has been deactivated. Please contact the TrueSilence Team.',
        }),
      );
    } else if (_.get(err, 'response.data.error') === 'invalid_grant') {
      yield put(authActions.setComponentStatus({ error: 'Email or Password Incorrect!' }));
    } else {
      yield put(
        authActions.setComponentStatus({
          error: _.get(err, 'response.data.error_description', 'Something went wrong'),
        }),
      );
    }
    yield put(authActions.setLogInStatus(false));
  }
  yield put(authActions.setComponentStatus({ isSubmitting: false }));
}

function* watchLogOut() {
  try {
    yield call(logOut);
    yield put(authActions.setLogInStatus(false));
    yield put(authActions.clearComponentStatus());

    // trigger the other tabs to change isLogin status
    localStorage.setItem('isLogin', String(false));
    localStorage.removeItem('isLogin');
  } catch (err) {
    console.log(err);
  }
  store.dispatch({ type: 'CLEAR_STORE' });
  persistor.purge().then(() => {
    persistor.persist();
  });
}

function* watchSendResetPasswordLink({ payload }: { payload: any }) {
  const { email } = payload;
  try {
    yield call(forgotPassword, email);
    yield put(authActions.setComponentStatus({ isEmailSent: true }));
  } catch (err: any) {
    yield put(authActions.setComponentStatus({ error: err.response.data.message }));
  }
  yield put(authActions.setComponentStatus({ isSubmitting: false }));
}

function* watchValidatePasswordResetToken({ payload }: { payload: any }) {
  const { token } = payload;
  try {
    yield call(validatePasswordResetToken, token);
  } catch (err: any) {
    if (err.response.status === 403) {
      yield put(authActions.setComponentStatus({ isInvalidToken: true }));
    }
  }
  yield put(authActions.setComponentStatus({ isLoading: false }));
}

function* watchUpdatePassword({ payload }: { payload: any }) {
  const { token, newPassword, isNewUser } = payload;
  try {
    const res: AxiosResponse = yield call(updatePassword, token, newPassword, isNewUser);
    yield put(authActions.setComponentStatus({ role: res.data['role'] }));
  } catch (err: any) {
    if (err.response.status === 403) {
      yield put(authActions.setComponentStatus({ isInvalidToken: true }));
    }
    yield put(authActions.setComponentStatus({ error: err.response.data.message }));
  }
  yield put(authActions.setComponentStatus({ isSubmitting: false }));
}

export function* watchAuthSagas(): Generator<ForkEffect, void> {
  yield takeLatest(authActions.sendEmailOtpAsync, watchSendEmailOtp);
  yield takeLatest(authActions.logInAsync, watchLogIn);
  yield takeLatest(authActions.logOutAsync, watchLogOut);
  yield takeLatest(authActions.sendPasswordResetLinkAsync, watchSendResetPasswordLink);
  yield takeLatest(authActions.validatePasswordResetTokenAsync, watchValidatePasswordResetToken);
  yield takeLatest(authActions.updatePasswordAsync, watchUpdatePassword);
  yield debounce(
    parseInt(process.env.REACT_APP_AUTO_LOGOUT_IN ?? '30') * 60 * 1000,
    authActions.inactiveAutoLogOutAsync,
    watchLogOut,
  );
}

const authSagas = watchAuthSagas;
export default authSagas;
