import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import * as _ from 'lodash';
import { ReactNode } from 'react';
import { LogInPayload } from './authSaga';

export interface TopBarViewProps {
  text?: string;
  path?: string; // where should navigate in onclick
  sideMenuPath?: string; // which side menu should enable (path of the side menu)
  button?: {
    text: string;
    color: 'primary' | 'error';
    icon?: ReactNode;
    onClick: () => void;
  };
  backButtonOnClick?: () => void; // define custom onClick function, if not default function navigate to back
  backButton?: {
    path: string;
  };
  backNav?: boolean;
  customElement?: ReactNode; // if customElement defined, keep above filed undefined
}

export interface NotificationPaneViewProp {
  isView: boolean;
}

export interface IAuthSlice {
  isLogin: boolean;
  topBarView?: TopBarViewProps;
  notificationView: NotificationPaneViewProp;
  componentStatus: { [name: string]: undefined | string | boolean };
  lastActiveTime: number;
}

const initialState: IAuthSlice = {
  isLogin: false,
  topBarView: undefined,
  notificationView: {
    isView: false,
  },
  componentStatus: {},
  lastActiveTime: new Date().getTime(),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setComponentStatus(state, action: PayloadAction<{ [key: string]: undefined | string | boolean }>) {
      state.componentStatus = { ...state.componentStatus, ...action.payload };
    },
    clearComponentStatus(state, action: PayloadAction<string[] | undefined>) {
      if (action.payload?.length) {
        state.componentStatus = _.omit(state.componentStatus, action.payload);
      } else {
        state.componentStatus = {};
      }
    },
    setTopBarView(state, action: PayloadAction<TopBarViewProps | undefined>) {
      state.topBarView = action.payload;
    },
    setNotificationView(state, action: PayloadAction<NotificationPaneViewProp>) {
      state.notificationView = action.payload;
    },
    setLogInStatus(state, action: PayloadAction<boolean>) {
      state.isLogin = action.payload;
    },
    setLastActiveTime(state, action: PayloadAction<number>) {
      state.lastActiveTime = action.payload;
    },
    logInAsync: (state, action: PayloadAction<LogInPayload>) => {},
    logOutAsync: (_state) => {},
    inactiveAutoLogOutAsync: (_state) => {},
    sendEmailOtpAsync: (state, action: PayloadAction<{ email: string; password: string }>) => {},
    sendPasswordResetLinkAsync: (state, action: PayloadAction<{ email: string }>) => {},
    validatePasswordResetTokenAsync: (state, action: PayloadAction<{ token: string }>) => {},
    updatePasswordAsync: (
      state,
      action: PayloadAction<{ token: string; newPassword: string; isNewUser: boolean }>,
    ) => {},
  },
});
const authPersistConfig = {
  key: 'auth',
  storage: sessionStorage,
  whitelist: ['isLogin'],
};

const authActions = authSlice.actions;
const authReducer = persistReducer(authPersistConfig, authSlice.reducer);

export { authActions, authReducer };
