import React, { Fragment } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import clinicTransferIcon from '../../../assets/img/square-sort-horizontal-bold-grey.svg';
import ClinicTransferIconApprove from '../../../assets/img/square-sort-horizontal-bold-duotone.svg';
import ClinicTransferIconReject from '../../../assets/img/square-sort-horizontal-bold-duotone-red.svg';
import reactStringReplace from 'react-string-replace';

interface C2CTransferInformProps {
  notificationId: string;
  subject: string;
  description: string;
  actionDisable: boolean;
  approveBtnLoading: boolean;
  rejectBtnLoading: boolean;
  notificationType: string;
}

const getIcon = (subject: string) => {
  if (subject.includes('Approved')) {
    return ClinicTransferIconApprove;
  } else if (subject.includes('Rejected')) {
    return ClinicTransferIconReject;
  } else {
    return clinicTransferIcon;
  }
};

function C2CTransferInform(props: C2CTransferInformProps) {
  const theme = useTheme();
  return (
    <Fragment>
      <Grid item xs={2} sm={1}>
        <Box component="img" src={getIcon(props.subject)} sx={{ width: '28px', height: '28px' }} />
      </Grid>
      <Grid item mb={3} xs={10} sm={11}>
        <Typography variant="h6" fontSize="20px" mb={2} sx={{ whiteSpace: 'normal' }}>
          {props.subject}
        </Typography>
        <Typography variant="bodyRegular" component="p" sx={{ whiteSpace: 'normal', color: theme.palette.info.main }}>
          {reactStringReplace(props.description, /<b>(.*?)<\/b>/g, (match, i) => (
            <strong key={`strong-${i}`}>{match}</strong>
          ))}
        </Typography>
      </Grid>
    </Fragment>
  );
}

export default C2CTransferInform;
