import { all, call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import {
  AddEditPatientDataType,
  C2CTransferApproveParamType,
  C2CTransferRejectParamType,
  EqDataType,
  patientActions,
  RequestCositDataType,
  RequestTinnitusAvatarHistory,
} from './patientSlice';
import {
  createPatient,
  getPatientsByClinician,
  transferPatient,
  TransferPatientParamType,
  getPatient,
  updatePatient,
  getCositByPatientId,
  createCositByPatientId,
  updateCositByPatientId,
  GetAllPatientParamType,
  getAllPatients,
  getTinnitusAvatarByPatientId,
  getTinnitusAvatarHistoryByPatientId,
  getPatientSoundConfiguration,
  updatePatientSoundConfiguration,
  getTreatmentTimelineDetails,
  getCositHistory,
  GetCositHistoryParamType,
  getAllPatientFeedback,
  clinicToClinicTransferApprove,
  clinicToClinicTransferPedingDetails,
  clinicToClinicTransferReject,
} from '../../api/patientApi';
import { generalActions } from '../general/generalSlice';
import { AxiosResponse } from 'axios';
import { validateEmail } from '../../api/userApi';
import { authActions } from '../auth/authSlice';
import { notificationAction } from '../notification/notificationSlice';

function* watchCreatePatient({ payload }: { payload: AddEditPatientDataType }) {
  yield put(generalActions.setComponentStatus({ isSubmitting: true }));
  try {
    let isEmailAlreadyExists = false;
    if (payload.email) {
      const res: AxiosResponse = yield call(validateEmail, `${payload.email}`);
      isEmailAlreadyExists = res.data;
      yield put(generalActions.setComponentStatus({ isEmailAlreadyExists }));
    }
    if (!isEmailAlreadyExists) {
      const createPatientRes: AxiosResponse = yield call(createPatient, payload);
      yield put(generalActions.setComponentStatus({ isAddEditPatientModalOpen: false }));
      yield put(generalActions.setComponentStatus({ isAddPatientSuccessModalOpen: true }));
      yield put(generalActions.setComponentStatus({ newlyCreatedPatientUserId: createPatientRes.data.userId }));
      yield put(patientActions.setComponentStatus({ shouldPatientsListLoad: true }));
    }
  } catch (err: any) {
    // pass
  }
  yield put(generalActions.setComponentStatus({ isSubmitting: false }));
}

function* watchUpdatePatient({ payload }: { payload: { patientId: string; data: Partial<AddEditPatientDataType> } }) {
  yield put(generalActions.setComponentStatus({ isSubmitting: true }));
  try {
    let isEmailAlreadyExists = false;
    if (payload.data.email) {
      const res: AxiosResponse = yield call(validateEmail, `${payload.data.email}`);
      isEmailAlreadyExists = res.data;
      yield put(generalActions.setComponentStatus({ isEmailAlreadyExists }));
    }
    if (!isEmailAlreadyExists) {
      yield call(updatePatient, payload.patientId, payload.data);

      yield put(
        generalActions.setComponentStatus({
          isAddEditPatientModalOpen: false,
          isEditPatientSuccessModalOpen: true,
        }),
      );
      yield put(patientActions.setComponentStatus({ shouldPatientsListLoad: true }));
    }
  } catch (err: any) {
    // pass
  }
  yield put(generalActions.setComponentStatus({ isSubmitting: false }));
}

function* watchGetPatientsByClinician({ payload }: { payload: GetAllPatientParamType }) {
  yield put(authActions.setComponentStatus({ isPatientListLoading: true }));
  try {
    const res: AxiosResponse = yield call(getPatientsByClinician, payload);
    yield put(patientActions.setTotalPatientCount(res.data['total']));
    yield put(patientActions.setClinicianPatientList(res.data['data']));
  } catch (err: any) {
    yield put(patientActions.setTotalPatientCount(0));
    yield put(patientActions.setClinicianPatientList([]));
  }
  yield put(authActions.setComponentStatus({ isPatientListLoading: false }));
}

function* watchGetAllPatientList({ payload }: { payload: GetAllPatientParamType }) {
  yield put(patientActions.setComponentStatus({ isPatientListLoading: true }));
  try {
    const res: AxiosResponse = yield call(getAllPatients, payload);
    yield put(patientActions.setTotalPatientCount(res.data['total']));
    yield put(patientActions.setPatientList(res.data['data']));
  } catch (err: any) {
    yield put(patientActions.setTotalPatientCount(0));
    yield put(patientActions.setPatientList([]));
  }
  yield put(patientActions.setComponentStatus({ isPatientListLoading: false }));
}

function* watchTransferPatient({ payload }: { payload: TransferPatientParamType }) {
  yield put(patientActions.setComponentStatus({ isPatientTransfering: true }));
  try {
    yield call(transferPatient, payload);
    yield put(patientActions.setComponentStatus({ isTransferSuccess: true }));
    yield put(patientActions.setComponentStatus({ confirmTransferStep: false }));
    yield put(patientActions.setComponentStatus({ error: '' }));
    yield put(patientActions.setComponentStatus({ shouldPatientsListLoad: true }));
  } catch (err: any) {
    if (err.response.status === 403) {
      yield put(patientActions.setComponentStatus({ isPasswordIncorrect: true }));
    }
    yield put(patientActions.setComponentStatus({ error: err.response.data.message }));
  }
  yield put(patientActions.setComponentStatus({ isPatientTransfering: false }));
}

function* watchGetPatient({ payload }: { payload: { userId: string } }) {
  yield put(generalActions.setComponentStatus({ isPatientDetailsLoading: true }));
  try {
    const res: AxiosResponse = yield call(getPatient, payload.userId);
    yield put(patientActions.setPatientDetails(res.data));
    yield put(patientActions.setComponentStatus({ isTransferLoading: false }));
  } catch (err: any) {
    yield put(patientActions.setPatientDetails(undefined));
  }
  yield put(generalActions.setComponentStatus({ isPatientDetailsLoading: false }));
}

function* watchGetCositData({ payload }: { payload: { patientId: string } }) {
  yield put(generalActions.setComponentStatus({ isCositLoading: true }));
  try {
    const res: AxiosResponse = yield call(getCositByPatientId, payload);
    yield put(patientActions.setCositData(res.data));
    if (res.data.patientId === null) {
      yield put(generalActions.setComponentStatus({ isCositNew: true }));
    } else {
      yield put(generalActions.setComponentStatus({ isCositNew: false }));
    }
  } catch (err: any) {
    yield put(
      patientActions.setCositData({
        patientId: '',
        sleep: '',
        communication: '',
        peace: '',
        concentration: '',
        stress: '',
        goals: [],
      }),
    );
  }
  yield put(generalActions.setComponentStatus({ isCositLoading: false }));
}

function* watchCreatetCositData({ payload }: { payload: RequestCositDataType }) {
  yield put(generalActions.setComponentStatus({ isSubmitting: true }));
  try {
    yield call(createCositByPatientId, payload);
    yield put(generalActions.setComponentStatus({ isCositNew: false }));
    yield put(generalActions.setComponentStatus({ isCositSuccessModalOpen: true }));
  } catch (err: any) {}
  yield put(generalActions.setComponentStatus({ isSubmitting: false }));
}

function* watchUpdateCositData({ payload }: { payload: RequestCositDataType }) {
  yield put(generalActions.setComponentStatus({ isSubmitting: true }));
  try {
    yield call(updateCositByPatientId, payload);
    yield put(generalActions.setComponentStatus({ isCositNew: false }));
    yield put(generalActions.setComponentStatus({ isCositSuccessModalOpen: true }));
  } catch (err: any) {}
  yield put(generalActions.setComponentStatus({ isSubmitting: false }));
}

function* watchGetTinnitusAvatarData({ payload }: { payload: { patientId: string } }) {
  yield put(generalActions.setComponentStatus({ isTinnitusAvatarDataLoading: true }));
  try {
    const res: AxiosResponse = yield call(getTinnitusAvatarByPatientId, payload);
    yield put(patientActions.setTinnitusAvatarData(res.data.data[0]));
  } catch (err: any) {
    yield put(
      patientActions.setTinnitusAvatarData({
        patientId: '',
        avatarId: '',
        tinnitusType: '',
        frequency: 0,
        bandwidth: 0,
        leftVolume: 0,
        rightVolume: 0,
        horizontalDirection: 0,
        verticalPosition: 0,
        horizontalDistance: 0,
      }),
    );
    yield put(patientActions.setComponentStatus({ error: err.response.data.message }));
  }
  yield put(generalActions.setComponentStatus({ isTinnitusAvatarDataLoading: false }));
}

function* watchGetTinnitusAvatarHistory({ payload }: { payload: RequestTinnitusAvatarHistory }) {
  yield put(generalActions.setComponentStatus({ isTinnitusAvatarHistoryLoading: true }));
  try {
    const res: AxiosResponse = yield call(getTinnitusAvatarHistoryByPatientId, payload);
    yield put(patientActions.setTinnitusAvatarHistoryCount(res.data['total']));
    yield put(patientActions.setTinnitusAvatarHistory(res.data['data']));
  } catch (err: any) {
    yield put(patientActions.setTinnitusAvatarHistory([]));
    yield put(patientActions.setTinnitusAvatarHistoryCount(0));
    yield put(patientActions.setComponentStatus({ error: err.response.data.message }));
  }
  yield put(generalActions.setComponentStatus({ isTinnitusAvatarHistoryLoading: false }));
}

function* watchGetSoundConfiguration({ payload }: { payload: { userId: string } }) {
  yield put(generalActions.setComponentStatus({ isLoading: true }));
  try {
    const [patient, soundConfig]: AxiosResponse[] = yield all([
      call(getPatient, payload.userId),
      call(getPatientSoundConfiguration, payload.userId),
    ]);

    const soundConfigData = soundConfig.data;

    yield put(patientActions.setPatientDetails(patient.data));
    yield put(
      patientActions.setEqData({
        rightNoiseThreshold: soundConfigData.overallThresholdRight,
        leftNoiseThreshold: soundConfigData.overallThresholdLeft,
        rightEqThreshold: [
          soundConfigData.nbfThresholdRight_5,
          soundConfigData.nbfThresholdRight_1,
          soundConfigData.nbfThresholdRight_2,
          soundConfigData.nbfThresholdRight_4,
          soundConfigData.nbfThresholdRight_8,
          soundConfigData.nbfThresholdRight_16,
        ],
        leftEqThreshold: [
          soundConfigData.nbfThresholdLeft_5,
          soundConfigData.nbfThresholdLeft_1,
          soundConfigData.nbfThresholdLeft_2,
          soundConfigData.nbfThresholdLeft_4,
          soundConfigData.nbfThresholdLeft_8,
          soundConfigData.nbfThresholdLeft_16,
        ],
        rightEqMml: [
          soundConfigData.nbfThresholdMinMaskingRight_5,
          soundConfigData.nbfThresholdMinMaskingRight_1,
          soundConfigData.nbfThresholdMinMaskingRight_2,
          soundConfigData.nbfThresholdMinMaskingRight_4,
          soundConfigData.nbfThresholdMinMaskingRight_8,
          soundConfigData.nbfThresholdMinMaskingRight_16,
        ],
        leftEqMml: [
          soundConfigData.nbfThresholdMinMaskingLeft_5,
          soundConfigData.nbfThresholdMinMaskingLeft_1,
          soundConfigData.nbfThresholdMinMaskingLeft_2,
          soundConfigData.nbfThresholdMinMaskingLeft_4,
          soundConfigData.nbfThresholdMinMaskingLeft_8,
          soundConfigData.nbfThresholdMinMaskingLeft_16,
        ],
        rightEqThresholdStatus: [
          soundConfigData.nbfThresholdRightApplicable_5,
          soundConfigData.nbfThresholdRightApplicable_1,
          soundConfigData.nbfThresholdRightApplicable_2,
          soundConfigData.nbfThresholdRightApplicable_4,
          soundConfigData.nbfThresholdRightApplicable_8,
          soundConfigData.nbfThresholdRightApplicable_16,
        ],
        leftEqThresholdStatus: [
          soundConfigData.nbfThresholdLeftApplicable_5,
          soundConfigData.nbfThresholdLeftApplicable_1,
          soundConfigData.nbfThresholdLeftApplicable_2,
          soundConfigData.nbfThresholdLeftApplicable_4,
          soundConfigData.nbfThresholdLeftApplicable_8,
          soundConfigData.nbfThresholdLeftApplicable_16,
        ],
        rightEqMmlStatus: [
          soundConfigData.nbfThresholdMinMaskingRightApplicable_5,
          soundConfigData.nbfThresholdMinMaskingRightApplicable_1,
          soundConfigData.nbfThresholdMinMaskingRightApplicable_2,
          soundConfigData.nbfThresholdMinMaskingRightApplicable_4,
          soundConfigData.nbfThresholdMinMaskingRightApplicable_8,
          soundConfigData.nbfThresholdMinMaskingRightApplicable_16,
        ],
        leftEqMmlStatus: [
          soundConfigData.nbfThresholdMinMaskingLeftApplicable_5,
          soundConfigData.nbfThresholdMinMaskingLeftApplicable_1,
          soundConfigData.nbfThresholdMinMaskingLeftApplicable_2,
          soundConfigData.nbfThresholdMinMaskingLeftApplicable_4,
          soundConfigData.nbfThresholdMinMaskingLeftApplicable_8,
          soundConfigData.nbfThresholdMinMaskingLeftApplicable_16,
        ],
      }),
    );
  } catch (err: any) {}
  yield put(generalActions.setComponentStatus({ isLoading: false }));
}

function* watchUpdateSoundConfiguration({
  payload,
}: {
  payload: {
    isSoundConfigSet: boolean;
    userId: string;
    eqData: EqDataType;
    tanRight: number[];
    tanLeft: number[];
    isCompleted: boolean;
  };
}) {
  yield put(generalActions.setComponentStatus({ isSubmitting: true }));
  try {
    yield call(
      updatePatientSoundConfiguration,
      payload.isSoundConfigSet,
      payload.userId,
      payload.eqData,
      payload.tanRight,
      payload.tanLeft,
      payload.isCompleted,
    );
    if (payload.isCompleted) {
      yield put(generalActions.setComponentStatus({ openConfirmEqModal: false }));
      yield put(generalActions.setComponentStatus({ openEqSaveSuccessModal: true }));
    }
  } catch (err: any) {}
  yield put(generalActions.setComponentStatus({ isSubmitting: false }));
}

function* watchGetTreatmentTimelineDetails({ payload }: { payload: { patientUserId: string } }) {
  yield put(generalActions.setComponentStatus({ isTreatmentTimelineLoading: true }));
  try {
    const res: AxiosResponse = yield call(getTreatmentTimelineDetails, payload.patientUserId);
    yield put(patientActions.setTreatmentTimelineDetails(res.data));
  } catch (err) {
    yield put(patientActions.setTreatmentTimelineDetails([]));
  }
  yield put(generalActions.setComponentStatus({ isTreatmentTimelineLoading: false }));
}

function* watchGetCositHistory({ payload }: { payload: GetCositHistoryParamType }) {
  yield put(patientActions.setComponentStatus({ isCositHistoryLoading: true }));
  try {
    const res: AxiosResponse = yield call(getCositHistory, payload);
    yield put(patientActions.setCositHistory(res.data.cositHistoryData));
    yield put(patientActions.setTotalCositHistoryCount(res.data.totalHistory));
    yield put(patientActions.setComponentStatus({ isCositHistoryLoading: false }));
  } catch (err) {
    yield put(patientActions.setCositHistory([]));
  }
}

function* watchGetAllPatientFeedbackAsync({ payload }: { payload: { patientId: string } }) {
  yield put(generalActions.setComponentStatus({ isPatientFeedbackLoading: true }));
  try {
    const res: AxiosResponse = yield call(getAllPatientFeedback, payload.patientId);
    yield put(patientActions.setAllPatientFeedback(res.data));
  } catch (err) {
    yield put(
      patientActions.setAllPatientFeedback({
        dailyFeedback: [],
        tinnitusProgressFeedback: [],
        everyFiveDaysFeedback: [[]],
      }),
    );
  }
  yield put(generalActions.setComponentStatus({ isPatientFeedbackLoading: false }));
}

function* watchClinicToClinicTransferApprove({ payload }: { payload: C2CTransferApproveParamType }) {
  yield put(notificationAction.setComponentStatus({ isC2cSubmitting: true }));
  try {
    yield call(clinicToClinicTransferApprove, payload);
    yield put(
      notificationAction.setNotificationActionDisableStatus({
        notificationId: payload.notificationId,
        status: true,
      }),
    );
    yield put(notificationAction.setComponentStatus({ isConfirmStepSuccess: true }));
  } catch (err: any) {
    yield put(notificationAction.setComponentStatus({ error: err.response.data.message }));
  }
  yield put(notificationAction.setComponentStatus({ isC2cSubmitting: false }));
}

function* watchClinicToClinicTransferReject({ payload }: { payload: C2CTransferRejectParamType }) {
  yield put(notificationAction.setComponentStatus({ isC2cSubmitting: true }));
  try {
    yield call(clinicToClinicTransferReject, payload);
    yield put(
      notificationAction.setNotificationActionDisableStatus({
        notificationId: payload.notificationId,
        status: true,
      }),
    );
    yield put(notificationAction.setComponentStatus({ isConfirmStepSuccess: true }));
  } catch (err: any) {
    yield put(notificationAction.setComponentStatus({ error: err.response.data.message }));
  }
  yield put(notificationAction.setComponentStatus({ isC2cSubmitting: false }));
}

function* watchClinicToClinicTransferPending({ payload }: { payload: { notificationId: string } }) {
  yield put(notificationAction.setComponentStatus({ isC2CinfoLoding: true }));
  try {
    const res: AxiosResponse = yield call(clinicToClinicTransferPedingDetails, payload);
    yield put(patientActions.setPendingC2CTranferDetails(res.data));
    yield put(notificationAction.setComponentStatus({ isC2CinfoLoding: false }));
  } catch (err: any) {
    yield put(notificationAction.setComponentStatus({ error: err.response.data.message }));
  }
}

export function* watchPatientSagas(): Generator<ForkEffect, void> {
  yield takeLatest(patientActions.createPatientAsync, watchCreatePatient);
  yield takeLatest(patientActions.getPatientListAsync, watchGetPatientsByClinician);
  yield takeLatest(patientActions.getAllPatientListAsync, watchGetAllPatientList);
  yield takeLatest(patientActions.transferPatientAsync, watchTransferPatient);
  yield takeLatest(patientActions.getPatientAsync, watchGetPatient);
  yield takeLatest(patientActions.updatePatientAsync, watchUpdatePatient);
  yield takeLatest(patientActions.getCositDataAsync, watchGetCositData);
  yield takeLatest(patientActions.createCositDataAsync, watchCreatetCositData);
  yield takeLatest(patientActions.updateCositDataAsync, watchUpdateCositData);
  yield takeLatest(patientActions.getTinnitusAvatarDataAsync, watchGetTinnitusAvatarData);
  yield takeLatest(patientActions.getTinnitusAvatarHistoryAsync, watchGetTinnitusAvatarHistory);
  yield takeLatest(patientActions.getSoundConfigurationAsync, watchGetSoundConfiguration);
  yield takeLatest(patientActions.updateSoundConfigurationAsync, watchUpdateSoundConfiguration);
  yield takeLatest(patientActions.getTreatmentTimelineDetailsAsync, watchGetTreatmentTimelineDetails);
  yield takeLatest(patientActions.getCositHistoryAsync, watchGetCositHistory);
  yield takeLatest(patientActions.getAllPatientFeedbackAsync, watchGetAllPatientFeedbackAsync);
  yield takeLatest(patientActions.C2CTransferApproveAsync, watchClinicToClinicTransferApprove);
  yield takeLatest(patientActions.C2CTransferRejectAsync, watchClinicToClinicTransferReject);
  yield takeLatest(patientActions.C2CTransferPendingDetailsAsync, watchClinicToClinicTransferPending);
}
const patientSagas = watchPatientSagas;
export default patientSagas;
