import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import * as dateFns from 'date-fns';
import _ from 'lodash';
import { GetNotificationListParamType } from '../../api/notificationApi';
import { NotificationStatus } from '../../utils/enums/notification-status.enum';

export interface NotificationResponse {
  createdUser: string;
  createdUserAvatar?: string;
  image: string;
  description: string;
  subject: string;
  type: string;
  isActionNeeded: boolean;
  notificationId: string;
  updatedDate: Date;
  approveBtnLoading: boolean;
  rejectBtnLoading: boolean;
  actionDisable: boolean;
  patient?: {
    firstName: string;
    lastName: string;
    avatar: string;
  };
}

export interface Notification {
  notificationId: string;
  no: string;
  createdUser: string;
  createdUserAvatar: string;
  createdUserRole: string;
  respondedUser: string | undefined;
  respondedUserAvatar: string | undefined;
  ClinicImage: string;
  description: string;
  clinicName: string;
  updatedDate: string;
  status: NotificationStatus;
  updatedFieldName: string;
  clinicEmailAddress: string;
}

export interface INotificationSlice {
  isView: boolean;
  currentPage: number;
  totalPages: number;
  notifications: { [key: string]: NotificationResponse[] };
  notificationCount: number;
  notificationsLoading: boolean;
  lastNotificationViewTime: Date;
  notificationList: Notification[];
  notificationListCount: number;
  componentStatus: { [name: string]: undefined | string | boolean };
  selectedNotification: Notification;
  requestorsDropdownList: { clinicId: string; clinicBaseId: string; userId: string; name: string; avatar: string }[];
}

const initialState: INotificationSlice = {
  isView: false,
  currentPage: 0,
  totalPages: 0,
  notifications: {},
  notificationCount: 0,
  notificationsLoading: false,
  lastNotificationViewTime: dateFns.sub(new Date(), { months: 1 }),
  notificationList: [],
  componentStatus: {},
  notificationListCount: 0,
  selectedNotification: {
    no: '',
    createdUser: '',
    createdUserAvatar: '',
    respondedUser: undefined,
    respondedUserAvatar: undefined,
    ClinicImage: '',
    description: '',
    clinicName: '',
    updatedDate: '',
    status: NotificationStatus.APPROVE,
    createdUserRole: '',
    notificationId: '',
    updatedFieldName: '',
    clinicEmailAddress: '',
  },
  requestorsDropdownList: [],
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setComponentStatus(state, action: PayloadAction<{ [key: string]: undefined | string | boolean }>) {
      state.componentStatus = { ...state.componentStatus, ...action.payload };
    },
    clearComponentStatus(state, action: PayloadAction<string[] | undefined>) {
      if (action.payload?.length) {
        state.componentStatus = _.omit(state.componentStatus, action.payload);
      } else {
        state.componentStatus = {};
      }
    },
    setNotificationView(state, action: PayloadAction<boolean>) {
      state.isView = action.payload;
    },
    setNotifications(state, action: PayloadAction<Partial<INotificationSlice>>) {
      state.currentPage = action.payload.currentPage ?? 0;
      state.totalPages = action.payload.totalPages ?? 0;
      state.notifications = action.payload.notifications ?? {};
    },
    setNotificationCount(state, action: PayloadAction<number>) {
      state.notificationCount = action.payload;
    },
    setNotificationLoading(state, action: PayloadAction<boolean>) {
      state.notificationsLoading = action.payload;
    },
    setNotificationViewTime(state, action: PayloadAction<Date>) {
      state.lastNotificationViewTime = action.payload;
    },
    setNotificationApproveBtnLoading(state, action: PayloadAction<{ notificationId: string; loadingStatus: boolean }>) {
      if (state.notifications) {
        _.flatten(Object.values(state.notifications)).forEach((e) => {
          if (e.notificationId === action.payload.notificationId) {
            e.approveBtnLoading = action.payload.loadingStatus;
          }
        });
      }
      if (state.notificationList) {
        state.componentStatus = {
          ...state.componentStatus,
          notificationApproveBtnLoading: action.payload.loadingStatus,
          isNotificationModalOpen: action.payload.loadingStatus,
        };
      }
    },
    setNotificationRejectBtnLoading(state, action: PayloadAction<{ notificationId: string; loadingStatus: boolean }>) {
      if (state.notifications) {
        _.flatten(Object.values(state.notifications)).forEach((e) => {
          if (e.notificationId === action.payload.notificationId) {
            e.rejectBtnLoading = action.payload.loadingStatus;
          }
        });
      }
      if (state.notificationList) {
        state.componentStatus = {
          ...state.componentStatus,
          notificationRejecteBtnLoading: action.payload.loadingStatus,
          isNotificationModalOpen: action.payload.loadingStatus,
        };
      }
    },
    setNotificationActionDisableStatus(state, action: PayloadAction<{ notificationId: string; status: boolean }>) {
      if (state.notifications) {
        _.flatten(Object.values(state.notifications)).forEach((e) => {
          if (e.notificationId === action.payload.notificationId) {
            e.actionDisable = action.payload.status;
          }
        });
      }
    },
    loadNotificationAsync(_state, action: PayloadAction<boolean>) {},
    loadNotificationCountAsync(_state) {},
    getNotificationListAsync(_state, action: PayloadAction<GetNotificationListParamType>) {},
    setNotificationsList(state, action: PayloadAction<Notification[]>) {
      state.notificationList = action.payload;
    },
    setNotificationListCount(state, action: PayloadAction<number>) {
      state.notificationListCount = action.payload;
    },
    setSelectedNotification(state, action: PayloadAction<Notification>) {
      state.selectedNotification = action.payload;
    },
    getAllRequestorNamesAsync(_state) {},
    setRequestorNamesList(
      state,
      action: PayloadAction<{ clinicId: string; clinicBaseId: string; userId: string; name: string; avatar: string }[]>,
    ) {
      state.requestorsDropdownList = action.payload;
    },
    updateLastNotificationViewTimeAsync() {},
  },
});

const notificationAction = notificationSlice.actions;
const notificationReducer = notificationSlice.reducer;

export { notificationAction, notificationReducer };
