import { DragDropContext, Droppable, OnDragEndResponder } from '@hello-pangea/dnd';
import CustomDragItem from '../CustomDragItem/CustomDragItem';
import { Grid } from '@mui/material';
import { GoalsType } from '../../redux/patient/patientSlice';

function CustomDragList(props: { onDragEnd: OnDragEndResponder; goals: GoalsType[]; isLoading: boolean }) {
  return (
    <DragDropContext onDragEnd={props.onDragEnd}>
      <Droppable droppableId="droppable" type="customDragList">
        {(provided) => (
          <Grid {...provided.droppableProps} ref={provided.innerRef} container marginTop={4}>
            {!props.isLoading &&
              props.goals.map((goal, index) => (
                <CustomDragItem key={goal.id} name={goal.name} id={goal.id.toString()} index={index} />
              ))}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default CustomDragList;
