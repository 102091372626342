import React, { type ReactElement } from 'react';
import { Box, Typography } from '@mui/material';

export default function UnAuthorized(): ReactElement {
  return (
    <Box>
      {' '}
      <Typography variant="h1" display="flex" justifyContent="center">
        Not Authorized
      </Typography>{' '}
    </Box>
  );
}
