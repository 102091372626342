import React, { Fragment } from 'react';
import { useTheme, Grid } from '@mui/material';
import * as _ from 'lodash';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import CustomButtonLoadingButton from '../../../components/CustomButtonLoadingButton/CustomButtonLoadingButton';
import { soundActions } from '../../../redux/sound/soundSlice';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { RootState } from '../../../redux/store';
import { Theme, styled as materialStyled } from '@mui/material/styles';
interface EditAlbumArtProps {
  isOpen: boolean;
}

const GridStyled = materialStyled(Grid)(({ theme }: { theme: Theme }) => ({
  width: '517px',
  height: '494px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

function EditAlbumArt({ isOpen }: EditAlbumArtProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const componentStatus = useAppSelector((state: RootState) => state.sound.componentStatus);
  const imageSrc = _.get(componentStatus, 'albumArt', '') as string;
  const crop = _.get(componentStatus, 'crop', { x: 0, y: 0, width: 200, height: 200, unit: 'px' }) as Crop;
  const savedCrop = _.get(componentStatus, 'savedCrop', { x: 0, y: 0, width: 200, height: 200, unit: 'px' }) as Crop;
  const completedCrop = _.get(componentStatus, 'completedCrop', {
    x: 0,
    y: 0,
    width: 200,
    height: 200,
    unit: 'px',
  }) as PixelCrop;

  const handleAddModalClose = () => {
    dispatch(soundActions.setComponentStatus({ isAlbumArtEditOpen: false }));
    dispatch(soundActions.setComponentStatus({ crop: savedCrop }));
  };

  const onConfirmClick = () => {
    // Create a new image element with the cropped area
    const canvas = document.createElement('canvas');
    const image = document.createElement('img');
    image.src = imageSrc;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    if (completedCrop) {
      canvas.width = completedCrop.width;
      canvas.height = completedCrop.height;

      const ctx = canvas.getContext('2d');
      if (ctx) {
        const pixelRatio = window.devicePixelRatio;
        canvas.width = completedCrop.width * pixelRatio;
        canvas.height = completedCrop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(
          image,
          completedCrop.x * scaleX,
          completedCrop.y * scaleY,
          completedCrop.width * scaleX,
          completedCrop.height * scaleY,
          0,
          0,
          completedCrop.width,
          completedCrop.height,
        );

        const croppedImageSrc = canvas.toDataURL(); // Get base64 representation of the cropped image
        dispatch(soundActions.setAlbumArtSrc(croppedImageSrc));
        dispatch(soundActions.setComponentStatus({ savedCrop: completedCrop }));
        dispatch(soundActions.setComponentStatus({ isAlbumArtEditOpen: false }));
      }
    }
  };

  return (
    <Fragment>
      <CustomModal modal={true} open={isOpen} title="Album Art" width="600px" align="left">
        <Grid container>
          <GridStyled item xs={12}>
            <ReactCrop
              minWidth={104}
              crop={crop}
              onChange={(_, percentCrop) => dispatch(soundActions.setComponentStatus({ crop: percentCrop }))}
              onComplete={(c) => dispatch(soundActions.setComponentStatus({ completedCrop: c }))}
              aspect={1}
              keepSelection={true}
            >
              <img src={imageSrc} alt="album-art" />
            </ReactCrop>
          </GridStyled>
        </Grid>
        <Grid
          container
          marginTop={2}
          justifyContent="space-between"
          sx={{ paddingTop: '30px', borderTop: `1px solid ${theme.palette.shades.purpleBorder}` }}
        >
          <CustomButtonLoadingButton
            text="Dismiss"
            variant="outlined"
            color="primary"
            fullWidth={false}
            onClick={handleAddModalClose}
            width="118px"
          />
          <CustomButtonLoadingButton
            text="Confirm"
            variant="contained"
            color="primary"
            fullWidth={false}
            onClick={onConfirmClick}
            width="118px"
          />
        </Grid>
      </CustomModal>
    </Fragment>
  );
}

export default EditAlbumArt;
