import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import * as _ from 'lodash';
import {
  getPermissions,
  getAllAdmins,
  getAdminByUserId,
  GetAllAdminsParamType,
  createAdmin,
  updateAdmin,
  deleteAdmin,
  getDashboardInfo,
} from '../../api/adminApi';
import { validateEmail } from '../../api/userApi';
import { adminActions } from './adminSlice';
import { authActions } from '../auth/authSlice';
import { AxiosResponse } from 'axios';
import Constant from '../../constants/constant.json';
import { Roles } from '../../utils/enums/roles.enum';

function* watchGetAdminList({ payload }: { payload: GetAllAdminsParamType }) {
  const { skip, limit, sortBy, sortOrder } = payload;

  yield put(authActions.setComponentStatus({ isTableLoading: true }));
  try {
    const res: AxiosResponse = yield call(getAllAdmins, { skip, limit, sortBy, sortOrder });
    yield put(adminActions.setTotalAdminCount(res.data['total']));
    yield put(adminActions.setAdminList(res.data['data']));
  } catch (err: any) {
    yield put(adminActions.setTotalAdminCount(0));
    yield put(adminActions.setAdminList([]));
  }
  yield put(authActions.setComponentStatus({ isTableLoading: false }));
}

function* watchGetAdminByUserId({ payload }: { payload: string }) {
  const userId = payload;

  yield put(adminActions.setComponentStatus({ isSubmitting: true }));
  yield put(authActions.setComponentStatus({ isSelectedAdminLoading: true }));
  try {
    const res: AxiosResponse = yield call(getAdminByUserId, userId);
    yield put(adminActions.setSelectedAdmin(res.data));
    yield put(adminActions.setAdminData(res.data));
  } catch (err: any) {
    yield put(adminActions.setSelectedAdmin(undefined));
  }
  yield put(adminActions.setComponentStatus({ isSubmitting: false }));
  yield put(authActions.setComponentStatus({ isSelectedAdminLoading: false }));
}

function* watchCreateAdmin({ payload }: { payload: any }) {
  yield put(adminActions.setComponentStatus({ isSubmitting: true }));
  try {
    yield call(createAdmin, payload);
    yield put(adminActions.setCreateSuccess(false));
  } catch (err: any) {
    yield put(adminActions.setComponentStatus({ error: _.get(err, 'response.data.message', 'Something went wrong') }));
  }
  yield put(adminActions.setComponentStatus({ isSubmitting: false }));
}

function* watchUpdateAdmin({ payload }: { payload: any }) {
  yield put(adminActions.setComponentStatus({ isSubmitting: true }));
  try {
    yield call(updateAdmin, payload);
    yield put(adminActions.setCreateSuccess(false));
  } catch (err: any) {
    yield put(adminActions.setComponentStatus({ error: _.get(err, 'response.data.message', 'Something went wrong') }));
  }
  yield put(adminActions.setComponentStatus({ isSubmitting: false }));
}

function* watchDeleteAdmin({ payload }: { payload: any }) {
  yield put(authActions.setComponentStatus({ isAdminDeleting: true }));
  try {
    yield call(deleteAdmin, payload.userId, payload.password);
    yield put(authActions.setComponentStatus({ isDeleteSuccess: true }));
  } catch (err: any) {
    if (err.response.status === 403) {
      yield put(authActions.setComponentStatus({ isPasswordIncorrect: true }));
    }
    yield put(authActions.setComponentStatus({ error: err.response.data.message }));
  }
  yield put(authActions.setComponentStatus({ isAdminDeleting: false }));
}

function* watchNextToAddAdminPermissionTab({ payload }: { payload: any }) {
  let isEmailAlreadyExists = false;

  yield put(adminActions.setComponentStatus({ isSubmitting: true }));
  try {
    if (payload.userId === undefined) {
      const res: AxiosResponse = yield call(validateEmail, payload.email);
      isEmailAlreadyExists = res.data;
      yield put(adminActions.setComponentStatus({ isEmailAlreadyExists }));
    }
    if (!isEmailAlreadyExists) {
      const adminPermissions: AxiosResponse = yield call(getPermissions, Roles.TRUE_SILENCE_ADMIN);
      yield put(adminActions.setAdminPermissions(adminPermissions));
      yield put(adminActions.setComponentStatus({ selectedTab: Constant.AdminTabs.CONFIG_PERMISSION.slug }));
      yield put(adminActions.setAdminData(payload));
    }
  } catch (err: any) {
    // pass
  }
  yield put(adminActions.setComponentStatus({ isSubmitting: false }));
}

function* watchGetDashboardInfo() {
  yield put(adminActions.setComponentStatus({ isDashboardInfoLoading: true }));
  try {
    const res: AxiosResponse = yield call(getDashboardInfo);
    yield put(adminActions.setDashboardInfo(res.data));
    yield put(adminActions.setComponentStatus({ isDashboardInfoLoading: false }));
  } catch (err: any) {}
}

export function* watchAdminSagas(): Generator<ForkEffect, void> {
  yield takeLatest(adminActions.getAdminListAsync, watchGetAdminList);
  yield takeLatest(adminActions.getAdminByUserIdAsync, watchGetAdminByUserId);
  yield takeLatest(adminActions.createAdminAsync, watchCreateAdmin);
  yield takeLatest(adminActions.updateAdminAsync, watchUpdateAdmin);
  yield takeLatest(adminActions.deleteAdminAsync, watchDeleteAdmin);
  yield takeLatest(adminActions.nextToAddAdminPermissionTab, watchNextToAddAdminPermissionTab);
  yield takeLatest(adminActions.getDashboardInfoAsync, watchGetDashboardInfo);
}
const adminSagas = watchAdminSagas;
export default adminSagas;
