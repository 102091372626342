import { Card, Typography, useTheme, CardContent, Box, Grid, Stack, Skeleton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useAuth } from '../../../context/AuthContext';
import CliniciansTable from './CliniciansTable';
import ClinicAdminsTable from './ClinicAdminsTable';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import { Fragment, useEffect } from 'react';
import { authActions } from '../../../redux/auth/authSlice';
import { Roles } from '../../../utils/enums/roles.enum';
import { Permission } from '../../../utils/enums/permission.enum';
import { ReactComponent as SlashCircleOutlinedSVG } from '../../../assets/img/slash-circle-outline.svg';
import { ReactComponent as CheckCircleOutlinedBlueSVG } from '../../../assets/img/check-circle-bold-duotone-blue.svg';
import { SIDEBAR_OPTIONS } from '../../../utils/sidebar-schema';
import { SidebarOption } from '../../../utils/enums/sidebar-option.enum';
import { RootState } from '../../../redux/store';
import DeactivateClinic from '../DeactivateClinic/DeactivateClinic';
import _ from 'lodash';
import ReactivateClinic from '../ReactivateClinic/ReactivateClinic';
import { clinicActions } from '../../../redux/clinic/clinicSlice';
import { useParams } from 'react-router-dom';
import { ReactComponent as PenLinearSVG } from '../../../assets/img/pen-linear.svg';
import EditClinic from '../EditClinic/EditClinic';
import { generalActions } from '../../../redux/general/generalSlice';
import EditClinicSuccessModal from '../EditClinic/EditClinicSuccessModal';
import CountDisplayCard from '../../../components/CountDisplayCard/CountDisplayCard';

interface ClinicDetailProps {
  myClinicId?: string;
}

function ClinicDetail({ myClinicId }: ClinicDetailProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  let { clinicId } = useParams();
  if (!clinicId) {
    clinicId = myClinicId;
  }

  const clinicInfo = useAppSelector((state: RootState) => state.clinic.clinicInfo);
  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const deactivateModalOpen = _.get(componentStatus, 'deactivateClinicModalOpen', false) as boolean;
  const reactivateModalOpen = _.get(componentStatus, 'reactivateClinicModalOpen', false) as boolean;
  const isClinicInfoLoading = _.get(componentStatus, 'isClinicInfoLoading', false) as boolean;

  const shouldClinicDetailsLoad = _.get(
    useAppSelector((state: RootState) => state.general.componentStatus),
    'shouldClinicDetailsLoad',
    false,
  ) as boolean;

  const getButton = () => {
    let text = '';
    let icon = null;
    let color: 'error' | 'primary' = 'error';
    let onClick: () => void = () => {};

    if (myClinicId) {
      if (user.permissions.includes(Permission.CLINIC_ADMIN_EDIT_CLINIC)) {
        text = 'Edit Clinic';
        color = 'primary';
        icon = <PenLinearSVG />;
        onClick = () => {
          dispatch(generalActions.clearComponentStatus());
          dispatch(generalActions.setComponentStatus({ editClinicModalOpen: true }));
        };
        return { text, icon, color, onClick };
      }
    }

    if (
      !isClinicInfoLoading &&
      (user.role === Roles.SUPER_ADMIN ||
        user.permissions.includes(Permission.TRUE_SILENCE_ADMIN_DEACTIVATE_ACTIVATE_CLINICS))
    ) {
      if (clinicInfo?.status === 'ACTIVE') {
        text = 'Deactivate Clinic';
        icon = <SlashCircleOutlinedSVG />;
        onClick = handleDeactivateClinic;
      } else if (clinicInfo?.status === 'DEACTIVATED') {
        text = 'Reactivate Clinic';
        icon = <CheckCircleOutlinedBlueSVG />;
        color = 'primary';
        onClick = handleReactivateClinic;
      }

      return { text, icon, color, onClick };
    }
    return undefined;
  };

  useEffect(() => {
    dispatch(clinicActions.getClinicInfoAsync(clinicId ?? ''));
  }, [shouldClinicDetailsLoad]);

  useEffect(() => {
    if (myClinicId) {
      // for my clinic page
      dispatch(
        authActions.setTopBarView({
          text: clinicInfo?.name,
          button: getButton(),
        }),
      );
    } else {
      dispatch(
        authActions.setTopBarView({
          text: clinicInfo?.name,
          sideMenuPath: SIDEBAR_OPTIONS[SidebarOption.CLINICS].path,
          backButton: { path: SIDEBAR_OPTIONS[SidebarOption.CLINICS].path },
          button: getButton(),
        }),
      );
    }
  }, [clinicInfo]);

  const handleDeactivateClinic = () => {
    dispatch(authActions.setComponentStatus({ openClinicianDetailsModal: false }));
    dispatch(authActions.setComponentStatus({ openClinicAdminDetailsModal: false }));
    dispatch(authActions.setComponentStatus({ deactivateClinicModalOpen: true }));
  };

  const handleReactivateClinic = () => {
    dispatch(authActions.setComponentStatus({ openClinicianDetailsModal: false }));
    dispatch(authActions.setComponentStatus({ openClinicAdminDetailsModal: false }));
    dispatch(authActions.setComponentStatus({ reactivateClinicModalOpen: true }));
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item lg={4} xs={12}>
          <Box>
            <Card
              sx={{
                display: 'flex',
                gap: '30px',
                borderRadius: '10px',
                padding: '24px',
                minHeight: '190px',
              }}
            >
              <Stack
                display={isClinicInfoLoading ? 'flex' : 'none'}
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap="30px"
                padding="8px"
              >
                <Skeleton variant="circular" animation="wave" width={110} height={110} />
                <Stack display="flex" width="auto">
                  <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: 120 }} />
                  <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: 100, mt: 2 }} />
                  <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: 150, mt: 2 }} />
                </Stack>
              </Stack>

              <Box
                display={isClinicInfoLoading ? 'none' : 'flex'}
                justifyContent="center"
                alignItems="flex-start"
                gap="10px"
              >
                <AvatarEdit
                  avatar={clinicInfo?.avatar}
                  editable={false}
                  name={clinicInfo?.name ?? ''}
                  font={theme.typography.h2}
                  size="100px"
                />
              </Box>
              <CardContent
                sx={{
                  display: isClinicInfoLoading ? 'none' : 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '12px',
                  padding: 0,
                  overflowWrap: 'anywhere',
                }}
              >
                <Typography variant="bodyRegular" color={theme.palette.info.main} component="div">
                  {clinicInfo?.email}
                </Typography>
                <Typography variant="bodyRegular" color={theme.palette.info.main} component="div">
                  {clinicInfo?.phone}
                </Typography>
                <Typography variant="bodyRegular" color={theme.palette.info.main} component="div">
                  {[
                    clinicInfo?.addressLine1,
                    clinicInfo?.addressLine2,
                    clinicInfo?.city,
                    clinicInfo?.state,
                    clinicInfo?.country,
                    clinicInfo?.zipCode,
                  ].join(', ')}
                </Typography>
                {clinicInfo?.domain && (
                  <Typography variant="bodyRegular" component="div">
                    <a
                      href={clinicInfo?.domain}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', color: theme.palette.primary.main }}
                    >
                      {clinicInfo?.domain}
                    </a>
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item lg={2} xs={6}>
          <CountDisplayCard
            isLoading={isClinicInfoLoading}
            description={'Clinicians'}
            count={clinicInfo?.clinicianCount ?? 0}
            minHeight="190px"
          />
        </Grid>
        <Grid item lg={2} xs={6}>
          <CountDisplayCard
            isLoading={isClinicInfoLoading}
            description={'After treatment usage'}
            count={clinicInfo?.returningPatientsCount ?? 0}
            minHeight="190px"
          />
        </Grid>
        <Grid item lg={2} xs={6}>
          <CountDisplayCard
            isLoading={isClinicInfoLoading}
            description={'Treatment completed patients'}
            count={clinicInfo?.treatmentCompletedPatientsCount ?? 0}
            minHeight="190px"
          />
        </Grid>
        <Grid item lg={2} xs={6}>
          <CountDisplayCard
            isLoading={isClinicInfoLoading}
            description={'Patients under treatment'}
            count={clinicInfo?.underTreatmentPatientCount ?? 0}
            minHeight="190px"
          />
        </Grid>

        <Grid item lg={6} xs={12} marginTop={1}>
          <CliniciansTable clinicId={clinicId ?? ''} />
        </Grid>
        <Grid item lg={6} xs={12} marginTop={1}>
          <ClinicAdminsTable clinicId={clinicId ?? ''} />
        </Grid>
      </Grid>

      <DeactivateClinic clinicId={clinicInfo ? clinicInfo.clinicId : ''} open={deactivateModalOpen} />
      <ReactivateClinic clinicId={clinicInfo ? clinicInfo.clinicId : ''} open={reactivateModalOpen} />

      {myClinicId && <EditClinic clinicId={myClinicId} />}
      {myClinicId && <EditClinicSuccessModal />}
    </Fragment>
  );
}

export default ClinicDetail;
