import React, { Fragment, useEffect, useState } from 'react';
import CustomModal from '../../../components/CustomModal/CustomModal';
import TrashBinTrashBoldDuotone from '../../../assets/img/trash-bin-trash-bold-duotone.svg';
import CheckCircleBoldDuotoneRed from '../../../assets/img/check-circle-bold-duotone-red.svg';
import { Box, Grid, IconButton, InputAdornment, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import CustomInputBox from '../../../components/CustomInputBox/CustomInputBox';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { authActions } from '../../../redux/auth/authSlice';
import { adminActions } from '../../../redux/admin/adminSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';

interface AdminDeleteProps {
  userId: string;
  open: boolean;
  setDeleteUserId: (userId: string) => void;
}

const AdminDelete = ({ userId, setDeleteUserId, open }: AdminDeleteProps) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();

  const selectedAdmin = useAppSelector((state: RootState) => state.admin.selectedAdmin);

  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const shouldAdminListLoad = _.get(componentStatus, 'shouldAdminListLoad', false) as boolean;
  const isSelectedAdminLoading = _.get(componentStatus, 'isSelectedAdminLoading', true) as boolean;
  const isPasswordIncorrect = _.get(componentStatus, 'isPasswordIncorrect', false) as boolean;
  const isAdminDeleting = _.get(componentStatus, 'isAdminDeleting', false) as boolean;
  const isDeleteSuccess = _.get(componentStatus, 'isDeleteSuccess', false) as boolean;
  const error = _.get(componentStatus, 'error', '') as string;

  useEffect(() => {
    formik.setFieldValue('password', '');
    if (userId) {
      dispatch(
        authActions.setComponentStatus({ isPasswordIncorrect: false, isAdminDeleting: false, isDeleteSuccess: false }),
      );
      dispatch(adminActions.getAdminByUserIdAsync(userId));
    }
  }, [userId]);

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: yup.object({
      password: yup.string().required('Password is required'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({ password }) => {
      dispatch(authActions.setComponentStatus({ isPasswordIncorrect: false, isAdminDeleting: true, error: '' }));
      dispatch(adminActions.deleteAdminAsync({ userId, password }));
    },
  });

  const handleDismiss = () => {
    setDeleteUserId('');
    formik.setFieldValue('password', '');
    dispatch(authActions.clearComponentStatus());
    dispatch(authActions.setComponentStatus({ shouldAdminListLoad: shouldAdminListLoad })); // keep the table without reload
    setShowPassword(false);
    setTimeout(() => {
      dispatch(adminActions.setSelectedAdmin(undefined));
    }, 500);
  };

  const handleSuccessDismiss = () => {
    setDeleteUserId('');
    formik.setFieldValue('password', '');
    dispatch(authActions.clearComponentStatus());
    dispatch(authActions.setComponentStatus({ shouldAdminListLoad: !shouldAdminListLoad })); // reload the table
    setShowPassword(false);
    setTimeout(() => {
      dispatch(adminActions.setSelectedAdmin(undefined));
    }, 500);
  };

  return (
    <Fragment>
      <CustomModal
        modal={true}
        open={!!userId && open}
        width="374px"
        btnText={isDeleteSuccess ? 'Dismiss' : ''}
        handleButtonOnClick={handleSuccessDismiss}
        title={isDeleteSuccess ? 'Admin Deleted Successfully!' : 'Delete Confirmation'}
        icon={isDeleteSuccess ? CheckCircleBoldDuotoneRed : TrashBinTrashBoldDuotone}
      >
        <Stack
          display={isSelectedAdminLoading ? 'flex' : 'none'}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Skeleton variant="circular" animation="wave" width={72} height={72} />
          <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: '60%', mt: 2 }} />
          <Skeleton variant="rounded" animation="wave" width="90%" height={90} sx={{ mt: 3 }} />
        </Stack>

        <Stack display={isSelectedAdminLoading ? 'none' : 'flex'}>
          <Typography
            hidden={!!isDeleteSuccess}
            mb={2}
            variant="bodyRegular"
            component="p"
            textAlign="center"
            color={theme.palette.info.main}
          >
            Are you sure you want to delete the selected item? This action cannot be undone.
          </Typography>
          <Typography
            hidden={!isDeleteSuccess}
            mb={2}
            variant="bodyRegular"
            component="p"
            textAlign="center"
            color={theme.palette.info.main}
          >
            The selected admin has been successfully deleted from the system.
          </Typography>

          <Box display="flex" justifyContent="center">
            <AvatarEdit
              editable={false}
              size="72px"
              name={selectedAdmin?.firstName}
              name2={selectedAdmin?.lastName}
              avatar={selectedAdmin?.avatar}
              font={theme.typography.h3}
            />
          </Box>

          <Typography
            mt={2}
            component="p"
            variant="h6"
            align="center"
          >{`${selectedAdmin?.firstName} ${selectedAdmin?.lastName}`}</Typography>
          <Typography component="p" variant="meta" align="center" color={theme.palette.info.main}>
            {selectedAdmin?.email}
          </Typography>
          <Typography mb={2} component="p" variant="meta" align="center">
            True Silence Admin
          </Typography>

          <Typography component="p" variant="meta" align="center">
            Permissions
          </Typography>
          {selectedAdmin?.permissions.map((e, i) => {
            return (
              <Typography
                key={e.permissionId}
                component="p"
                variant="bodyRegular"
                align="center"
                color={theme.palette.info.main}
              >
                {e.description}
              </Typography>
            );
          })}

          <Box mt={2} />
          {!isDeleteSuccess && (
            <CustomInputBox
              label="Please enter your password"
              labelAlign={'center'}
              placeholder=""
              required={true}
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={(formik.touched.password && Boolean(formik.errors.password)) || isPasswordIncorrect}
              helperText={
                (formik.touched.password && formik.errors.password) || (isPasswordIncorrect && 'Incorrect password!')
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}

          {!isDeleteSuccess && (
            <Grid container marginTop={3} columnSpacing={2}>
              {error && !isPasswordIncorrect && (
                <Grid item xs={12}>
                  <Typography mb={1} variant="bodyRegular" component="p" textAlign="center" color="error">
                    {error}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={6}>
                <LoadingButton
                  variant="outlined"
                  onClick={handleDismiss}
                  fullWidth
                  sx={{ borderRadius: '10px', height: '43px' }}
                >
                  Dismiss
                </LoadingButton>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton
                  disabled={!selectedAdmin}
                  loading={isAdminDeleting}
                  onClick={() => formik.handleSubmit()}
                  variant="contained"
                  fullWidth
                  color="error"
                  sx={{ borderRadius: '10px', height: '43px' }}
                >
                  Delete
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </Stack>
      </CustomModal>
    </Fragment>
  );
};

export default AdminDelete;
