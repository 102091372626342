import React from 'react';
import { Typography, useTheme, Theme, Grid } from '@mui/material';
import { styled as materialStyled } from '@mui/material/styles';

const Item = materialStyled(Typography)(({ theme, active }: { theme: Theme; active: string }) => ({
  fontSize: '16px',
  textAlign: 'left',
  textTransform: 'none',
  paddingBottom: '10px',
  paddingLeft: '10px',
  borderBottom:
    active === 'true' ? `2px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.shades.purpleBorder}`,
  color: active === 'true' ? theme.palette.primary.main : theme.palette.info.main,
  fontWeight: active === 'true' ? 500 : 400,
  display: 'block',
  [theme.breakpoints.down('sm')]: {},
}));

interface CustomTabHeaderProps {
  activeTab: string;
  tabs: {
    index: number;
    name: string;
    slug: string;
  }[];
}

function CustomTabHeader({ activeTab, tabs }: CustomTabHeaderProps) {
  const theme = useTheme();
  const gridSize = Math.floor(12 / tabs.length);
  return (
    <Grid container columns={{ xs: gridSize, sm: gridSize, md: 12 }}>
      {tabs.map(({ index, name, slug }, key) => {
        return (
          <Grid item xs={12} sm={12} md={gridSize} key={name}>
            <Item theme={theme} variant="bodyRegular" active={activeTab === slug ? 'true' : 'false'}>
              {index}. {name}
            </Item>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default CustomTabHeader;
