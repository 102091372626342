import React from 'react';
import { Navigate, Outlet, type RouteObject } from 'react-router-dom';
import { Roles } from '../utils/enums/roles.enum';
import Dashboard from '../pages/Dashboard/Dashboard';
import ManageAdmins from '../pages/ManageAdmins/ManageAdmins';
import Clinics from '../pages/Clinics/Clinics';
import Clinicians from '../pages/Clinicians/Clinicians';
import Patients from '../pages/Patients/Patients';
import SoundLibrary from '../pages/SoundLibrary/SoundLibrary';
import { LayoutType } from '../utils/enums/layout-type.enum';
import Login from '../pages/Login/Login';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import PrivateRoute from '../components/PrivateRouter/PrivateRouter';
import AppLayout from '../components/Layouts/AppLayout';
import { SIDEBAR_OPTIONS } from '../utils/sidebar-schema';
import { SidebarOption } from '../utils/enums/sidebar-option.enum';
import MyProfile from '../pages/MyProfile/MyProfile';
import ClinicDetail from '../pages/Clinics/ClinicDetail/ClinicDetail';
import ManageClinicAdmins from '../pages/Clinics/ManageClinicAdmins/ManageClinicAdmins';
import ClinicianDetail from '../pages/Clinicians/ClinicianDetail/ClinicianDetail';
import PatientDetails from '../pages/Patients/PatientDetails/PatientDetails';
import Cosit from '../pages/Patients/Cosit/Cosit';
import SetEq from '../pages/Patients/SetEq/SetEq';
import Requests from '../pages/Requests/Requests';
import NotFound from '../pages/NotFound/NotFound';
import Support from '../pages/Support/Support';
import MyPatients from '../pages/Patients/MyPatients';

const routes = (isLoggedIn: boolean, role: Roles, user: { clinicId: string }): RouteObject[] => [
  {
    path: '/',
    element: (
      <PrivateRoute
        isLoggedIn={isLoggedIn}
        userRole={role}
        routerRoles={[Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN, Roles.CLINIC_ADMIN, Roles.CLINICIAN]}
      />
    ),
    children: [
      {
        path: '/',
        element: [Roles.TRUE_SILENCE_ADMIN, Roles.SUPER_ADMIN].includes(role) ? (
          <Navigate to={SIDEBAR_OPTIONS[SidebarOption.DASHBOARD].path} />
        ) : (
          <Navigate to={SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path} />
        ),
      },
      {
        path: SIDEBAR_OPTIONS[SidebarOption.DASHBOARD].path,
        element: (
          <AppLayout layout={LayoutType.DASHBOARD}>
            <Dashboard />
          </AppLayout>
        ),
      },
      {
        path: 'my-profile',
        element: (
          <AppLayout layout={LayoutType.DASHBOARD}>
            <MyProfile />
          </AppLayout>
        ),
      },
      {
        path: 'requests',
        element: (
          <AppLayout layout={LayoutType.DASHBOARD}>
            <Requests />
          </AppLayout>
        ),
      },
    ],
  },
  {
    path: SIDEBAR_OPTIONS[SidebarOption.MANAGE_ADMINS].path,
    element: (
      <PrivateRoute
        isLoggedIn={isLoggedIn}
        userRole={role}
        routerRoles={SIDEBAR_OPTIONS[SidebarOption.MANAGE_ADMINS].roles}
      />
    ),
    children: [
      {
        path: SIDEBAR_OPTIONS[SidebarOption.MANAGE_ADMINS].path,
        element: (
          <AppLayout layout={LayoutType.DASHBOARD}>
            {[Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(role) ? <ManageAdmins /> : <ManageClinicAdmins />}
          </AppLayout>
        ),
      },
    ],
  },
  {
    path: SIDEBAR_OPTIONS[SidebarOption.CLINICS].path,
    element: (
      <PrivateRoute
        isLoggedIn={isLoggedIn}
        userRole={role}
        routerRoles={SIDEBAR_OPTIONS[SidebarOption.CLINICS].roles}
      />
    ),
    children: [
      {
        path: SIDEBAR_OPTIONS[SidebarOption.CLINICS].path,
        element: (
          <AppLayout layout={LayoutType.DASHBOARD}>
            <Clinics />
          </AppLayout>
        ),
      },
      {
        path: `${SIDEBAR_OPTIONS[SidebarOption.CLINICS].path}/:clinicId`,
        element: (
          <AppLayout layout={LayoutType.DASHBOARD}>
            <ClinicDetail />
          </AppLayout>
        ),
      },
    ],
  },
  {
    path: '/my-clinic',
    element: <PrivateRoute isLoggedIn={isLoggedIn} userRole={role} routerRoles={[Roles.CLINIC_ADMIN]} />,
    children: [
      {
        path: '/my-clinic',
        element: (
          <AppLayout layout={LayoutType.DASHBOARD}>
            <ClinicDetail myClinicId={user.clinicId} />
          </AppLayout>
        ),
      },
    ],
  },
  {
    path: SIDEBAR_OPTIONS[SidebarOption.CLINICIANS].path,
    element: (
      <PrivateRoute
        isLoggedIn={isLoggedIn}
        userRole={role}
        routerRoles={SIDEBAR_OPTIONS[SidebarOption.CLINICIANS].roles}
      />
    ),
    children: [
      {
        path: SIDEBAR_OPTIONS[SidebarOption.CLINICIANS].path,
        element: (
          <AppLayout layout={LayoutType.DASHBOARD}>
            <Clinicians />
          </AppLayout>
        ),
      },
      {
        path: `${SIDEBAR_OPTIONS[SidebarOption.CLINICIANS].path}/:userId`,
        element: (
          <AppLayout layout={LayoutType.DASHBOARD}>
            <ClinicianDetail />
          </AppLayout>
        ),
      },
    ],
  },
  {
    path: SIDEBAR_OPTIONS[SidebarOption.MY_PATIENTS].path,
    element: <PrivateRoute isLoggedIn={isLoggedIn} userRole={role} routerRoles={SIDEBAR_OPTIONS[SidebarOption.MY_PATIENTS].roles} />,
    children: [
      {
        path: SIDEBAR_OPTIONS[SidebarOption.MY_PATIENTS].path,
        element: (
          <AppLayout layout={LayoutType.DASHBOARD}>
            <MyPatients/>
          </AppLayout>
        ),
      },
    ],
  },
  {
    path: SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path,
    element: (
      <PrivateRoute
        isLoggedIn={isLoggedIn}
        userRole={role}
        routerRoles={[Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN, Roles.CLINIC_ADMIN, Roles.CLINICIAN]}
      />
    ),
    children: [
      {
        index: true,
        path: SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path,
        element: (
          <AppLayout layout={LayoutType.DASHBOARD}>
            <Patients />
          </AppLayout>
        ),
      },
      {
        path: `${SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path}/:userId`,
        element: (
          <AppLayout layout={LayoutType.DASHBOARD}>
            <PatientDetails />
          </AppLayout>
        ),
      },
      {
        path: `${SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path}/:userId/cosit`,
        element: (
          <AppLayout layout={LayoutType.DASHBOARD}>
            <Cosit />
          </AppLayout>
        ),
      },
      {
        path: `${SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path}/:userId/set-eq`,
        element: (
          <AppLayout layout={LayoutType.DASHBOARD}>
            <SetEq OVERALL_CONFIG={true} />
          </AppLayout>
        ),
      },
    ],
  },
  {
    path: SIDEBAR_OPTIONS[SidebarOption.SOUND_LIBRARY].path,
    element: (
      <PrivateRoute
        isLoggedIn={isLoggedIn}
        userRole={role}
        routerRoles={SIDEBAR_OPTIONS[SidebarOption.SOUND_LIBRARY].roles}
      />
    ),
    children: [
      {
        path: SIDEBAR_OPTIONS[SidebarOption.SOUND_LIBRARY].path,
        element: (
          <AppLayout layout={LayoutType.DASHBOARD}>
            <SoundLibrary />
          </AppLayout>
        ),
      },
    ],
  },
  {
    path: '/login',
    element: !isLoggedIn ? <Outlet /> : <Navigate to="/" />,
    children: [
      {
        path: '/login',
        element: (
          <AppLayout layout={LayoutType.BLANKLAYOUT}>
            <Login />
          </AppLayout>
        ),
      },
    ],
  },
  {
    path: '/reset-password',
    element: !isLoggedIn ? <Outlet /> : <Navigate to="/" />,
    children: [
      {
        path: '/reset-password',
        element: (
          <AppLayout layout={LayoutType.BLANKLAYOUT}>
            <ResetPassword />
          </AppLayout>
        ),
      },
    ],
  },
  {
    path: '/forgot-password',
    element: !isLoggedIn ? <Outlet /> : <Navigate to="/" />,
    children: [
      {
        path: '/forgot-password',
        element: (
          <AppLayout layout={LayoutType.BLANKLAYOUT}>
            <ForgotPassword />
          </AppLayout>
        ),
      },
    ],
  },
  {
    path: '/support',
    element: (
      <PrivateRoute isLoggedIn={isLoggedIn} userRole={role} routerRoles={[Roles.CLINICIAN, Roles.CLINIC_ADMIN]} />
    ),
    children: [
      {
        index: true,
        path: '/support',
        element: (
          <AppLayout layout={LayoutType.DASHBOARD}>
            <Support />
          </AppLayout>
        ),
      },
    ],
  },
  { path: '*', element: <NotFound /> },
];

export default routes;
