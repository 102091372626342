import React, { type ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, IconButton, InputAdornment, TextField, Theme, Typography, SvgIcon, Button } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { styled as materialStyled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import GreenCheckCircleSVG from '../../assets/img/check-circle-bold-duotone.svg';
import RedCloseCircleSVG from '../../assets/img/close-circle-bold-duotone.svg';
import { theme } from '../../theme/theme';
import { authActions } from '../../redux/auth/authSlice';
import { Roles } from '../../utils/enums/roles.enum';
import { RootState } from '../../redux/store';
import * as _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as AppStoreIcon } from '../../assets/img/App_Store.svg';
import { ReactComponent as PlayStoreIcon } from '../../assets/img/Google_Play.svg';

const ContainerBox = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'row',
  width: 'auto',
  padding: '32px',
  borderRadius: '20px',
  boxShadow: `0px 4px 32px 0px ${theme.palette.shades.shadow}`,
  [theme.breakpoints.down('md')]: {
    maxWidth: '361px',
    flexDirection: 'column-reverse',
    alignItems: 'center',
  },
}));

const CustomTextField = materialStyled(TextField)(({ theme }: { theme: Theme }) => ({
  width: '310px',
  '& .MuiInputBase-root': {
    height: '38px',
    borderRadius: '10px',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '297px',
    width: '75vw',
  },
}));

const CustomButton = materialStyled(LoadingButton)(({ theme }: { theme: Theme }) => ({
  borderRadius: '10px',
  width: '310px',
  height: '43px',
  gap: '10px',
  textAlign: 'center',
  textTransform: 'none',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '297px',
    width: '75vw',
  },
}));

const VerticalLine = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor: `${theme.palette.primary.main}4D`,
  width: '1px',
  marginLeft: '10px',
  marginRight: '10px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const HorizontalLine = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor: `${theme.palette.primary.main}4D`,
  width: '90%',
  height: '1px',
  marginTop: '20px',
  marginBottom: '20px',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export default function ResetPassword(): ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [resetToken, setResetToken] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [deviceType, setDeviceType] = useState('');

  const isNewUser = Boolean(new URLSearchParams(location.search).get('newUser'));

  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const isLoading = _.get(componentStatus, 'isLoading', true) as boolean;
  const isInvalidToken = _.get(componentStatus, 'isInvalidToken', false) as string;
  const role = _.get(componentStatus, 'role', '');
  const error = _.get(componentStatus, 'error', '') as string;
  const isSubmitting = _.get(componentStatus, 'isSubmitting', false) as boolean;

  const handleGoBackToLogin = () => {
    if (role !== Roles.PATIENT) {
      navigate('/login', { replace: true });
    }
  };

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token') as string;
    if (!resetToken && token) {
      setResetToken(token);
      dispatch(authActions.validatePasswordResetTokenAsync({ token }));
    } else {
      navigate('/login', { replace: true });
    }
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('android')) {
      setDeviceType('Android');
    } else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
      setDeviceType('iOS');
    } else if (userAgent.includes('mac') || userAgent.includes('windows') || userAgent.includes('linux')) {
      setDeviceType('Desktop/Laptop');
    } else {
      setDeviceType('Unknown');
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: yup.object({
      newPassword: yup
        .string()
        .min(8, 'Password must be 8 characters long')
        .matches(/\d/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a symbol')
        .required('Password is required'),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('newPassword')], 'Please make sure your passwords match!')
        .required('Confirm password is required'),
    }),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: ({ newPassword }, { setSubmitting }) => {
      dispatch(authActions.setComponentStatus({ isSubmitting: true, isInvalidToken: false, role: '', error: '' }));
      dispatch(authActions.updatePasswordAsync({ token: resetToken, newPassword, isNewUser }));
    },
  });

  const validatePasswordRules = (value: string, rule: string) => {
    const rules: { [rule: string]: boolean } = {
      is8CharLong: value.length >= 8,
      isIncludeNumber: /\d/.test(value),
      isIncludeLowerCase: /[a-z]/.test(value),
      isIncludeUpperCase: /[A-Z]/.test(value),
      isIncludeSpecialCharacter: /[^\w]/.test(value),
    };
    return rules[rule] ? GreenCheckCircleSVG : RedCloseCircleSVG;
  };

  const getDownloadAppButton = () => {
    if (deviceType === 'Android') {
      return (
        <Button href={process.env.REACT_APP_PLAY_STORE_URL}>
          <SvgIcon sx={{ width: '220px', height: '60px' }} component={PlayStoreIcon} inheritViewBox />
        </Button>
      );
    } else if (deviceType === 'iOS') {
      return (
        <Button href={process.env.REACT_APP_APP_STORE_URL}>
          <SvgIcon sx={{ width: '220px', height: '60px' }} component={AppStoreIcon} inheritViewBox />
        </Button>
      );
    } else {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button href={process.env.REACT_APP_PLAY_STORE_URL}>
            <SvgIcon sx={{ width: '220px', height: '60px' }} component={PlayStoreIcon} inheritViewBox />
          </Button>
          <Button href={process.env.REACT_APP_APP_STORE_URL}>
            <SvgIcon sx={{ width: '220px', height: '60px' }} component={AppStoreIcon} inheritViewBox />
          </Button>
        </Box>
      );
    }
  };

  if (isLoading) {
    return <CircularProgress size={80} />;
  } else if (isInvalidToken) {
    return (
      <ContainerBox>
        <Box maxWidth="374px">
          <Box
            component="img"
            width="56px"
            display="flex"
            margin="auto"
            src={RedCloseCircleSVG}
            alt="red-close-circle-icon"
          ></Box>
          <Typography mt={3} variant="h5" textAlign="center">
            Link Expired!
          </Typography>
          <Box mt={3}>
            {error.toLowerCase().includes('expired') ? (
              <Typography variant="bodyRegular" component="p" textAlign="center">
                Your password reset link is expired or invalid.
              </Typography>
            ) : (
              <Typography variant="bodyRegular" component="p" textAlign="center">
                Link is expired as user account is already created.
              </Typography>
            )}
          </Box>
        </Box>
      </ContainerBox>
    );
  } else if (role) {
    return (
      <ContainerBox maxWidth="310px" paddingX={0}>
        <Box>
          <Box
            component="img"
            display="flex"
            margin="auto"
            src={GreenCheckCircleSVG}
            alt="green-check-circle-icon"
          ></Box>
          <Typography hidden={isNewUser} mt={3} variant="h5" textAlign="center">
            Password Updated!
          </Typography>
          <Typography hidden={!isNewUser} mt={3} variant="h5" textAlign="center">
            Password Created!
          </Typography>
          <Box mt={3}>
            <Typography hidden={isNewUser} variant="bodyRegular" component="p" textAlign="center">
              {role === Roles.PATIENT
                ? 'Please download our app on your mobile and use the newly created password to login.'
                : 'Please go back to login screen and login with your newly created password!'}
            </Typography>
            <Typography hidden={!isNewUser} variant="bodyRegular" component="p" textAlign="center">
              Please go to the login page of our app and use the newly created password to login.
            </Typography>
            <Box mt={4} display="flex" justifyContent="center">
              {role === Roles.PATIENT ? (
                getDownloadAppButton()
              ) : (
                <CustomButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleGoBackToLogin}
                  sx={{ marginBottom: '0' }}
                >
                  Go to Login Screen
                </CustomButton>
              )}
            </Box>
          </Box>
        </Box>
      </ContainerBox>
    );
  } else {
    const passwordFieldType = showPassword ? 'text' : 'password';

    return (
      <ContainerBox>
        <Box paddingRight="20px" display="flex" flexDirection="column" justifyContent="center">
          <Typography gutterBottom variant="bodyBold" textAlign="center">
            Password must contain the following
          </Typography>
          <Box>
            <Box my="10px" display="flex">
              <Box
                component="img"
                width="20px"
                src={validatePasswordRules(formik.values.newPassword, 'isIncludeLowerCase')}
                alt="check-icon"
              ></Box>
              <Typography marginLeft="10px" variant="bodyRegular" textAlign="left">
                A lowercase letter
              </Typography>
            </Box>
            <Box my="10px" display="flex">
              <Box
                component="img"
                width="20px"
                src={validatePasswordRules(formik.values.newPassword, 'isIncludeUpperCase')}
                alt="check-icon"
              ></Box>
              <Typography marginLeft="10px" variant="bodyRegular" textAlign="left">
                A uppercase letter
              </Typography>
            </Box>
            <Box my="10px" display="flex">
              <Box
                component="img"
                width="20px"
                src={validatePasswordRules(formik.values.newPassword, 'isIncludeNumber')}
                alt="check-icon"
              ></Box>
              <Typography marginLeft="10px" variant="bodyRegular" textAlign="left">
                A number
              </Typography>
            </Box>
            <Box my="10px" display="flex">
              <Box
                component="img"
                width="20px"
                src={validatePasswordRules(formik.values.newPassword, 'isIncludeSpecialCharacter')}
                alt="check-icon"
              ></Box>
              <Typography marginLeft="10px" variant="bodyRegular" textAlign="left">
                A special character
              </Typography>
            </Box>
            <Box my="10px" display="flex">
              <Box
                component="img"
                width="20px"
                src={validatePasswordRules(formik.values.newPassword, 'is8CharLong')}
                alt="check-icon"
              ></Box>
              <Typography marginLeft="10px" variant="bodyRegular" textAlign="left">
                Minimum 8 characters
              </Typography>
            </Box>
          </Box>
        </Box>

        <VerticalLine />
        <HorizontalLine />

        <Box sx={{ paddingLeft: { md: '20px' } }}>
          <Typography gutterBottom variant="h5" textAlign="center">
            Create New Password
          </Typography>
          <Box display="flex" flexDirection="column" justifyContent="space-around" alignItems="center" height="90%">
            <Box>
              <Typography hidden={!error} variant="meta" component="p" gutterBottom textAlign="center" color="error">
                {error}
              </Typography>
              <Box mb={3} display="flex" flexDirection="column">
                <Typography mb={0.5} variant="meta" component="p">
                  New Password{' '}
                  <Typography component="span" color="primary">
                    &nbsp;*
                  </Typography>
                </Typography>
                <CustomTextField
                  type={passwordFieldType}
                  name="newPassword"
                  fullWidth
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                  helperText={formik.touched.newPassword && formik.errors.newPassword}
                  variant="outlined"
                  FormHelperTextProps={{ style: { ...theme.typography.meta } }}
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography mb={0.5} variant="meta" component="p">
                  Confirm Password{' '}
                  <Typography component="span" color="primary">
                    &nbsp;*
                  </Typography>
                </Typography>
                <CustomTextField
                  name="confirmPassword"
                  fullWidth
                  type={passwordFieldType}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                  helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                  variant="outlined"
                  FormHelperTextProps={{ style: { ...theme.typography.meta } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>

            <Box my={2}>
              <CustomButton
                loading={isSubmitting}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => formik.handleSubmit()}
              >
                Update
              </CustomButton>
            </Box>
          </Box>
        </Box>
      </ContainerBox>
    );
  }
}
