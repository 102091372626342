import React, { type ReactElement, type ReactNode } from 'react';
import { Container, Theme, Typography, Box, styled as materialStyled } from '@mui/material';

interface BlankLayoutProps {
  children: ReactNode;
}

const CustomContainer = materialStyled(Container)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingBottom: '15px',
}));

const AppName = materialStyled(Typography)(({ theme }: { theme: Theme }) => ({
  textAlign: 'center',
  opacity: '20%',
  color: theme.palette.primary.main,
  paddingTop: '2rem',
}));

const FooterNote = materialStyled(Typography)(({ theme }: { theme: Theme }) => ({
  textAlign: 'center',
  color: `${theme.palette.info.main}66`,
}));

function BlankLayout({ children }: BlankLayoutProps): ReactElement {
  return (
    <CustomContainer maxWidth={false}>
      <AppName variant="h4Bold">TrueSilence</AppName>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{children}</Box>
      <FooterNote variant="subtitle1">{new Date().getFullYear()} TrueSlience. All rights reserved.</FooterNote>
    </CustomContainer>
  );
}

export default BlankLayout;
