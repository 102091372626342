import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Slider, IconButton, Typography, useTheme, Grid } from '@mui/material';
import { AudioManager } from '../../../utils/audio-manager';
import { FilterRollOff, Noise, Volume } from 'tone';
import PlayBoldSVG from '../../../assets/img/play-bold.svg';
import StopBoldSVG from '../../../assets/img/stop-bold.svg';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

interface EqSimulateProps {
  tan: number[];
  overallThreshold: number;
}

const MAX_VALUE = 120;
const MIN_VALUE = 0;

const frequencyBand = [
  { frequency: 16, lowerLimit: 11, upperLimit: 22 },
  { frequency: 31.5, lowerLimit: 22, upperLimit: 44 },
  { frequency: 63, lowerLimit: 44, upperLimit: 88 },
  { frequency: 125, lowerLimit: 88, upperLimit: 177 },
  { frequency: 250, lowerLimit: 177, upperLimit: 355 },
  { frequency: 500, lowerLimit: 355, upperLimit: 710 },
  { frequency: 1000, lowerLimit: 710, upperLimit: 1420 },
  { frequency: 2000, lowerLimit: 1420, upperLimit: 2840 },
  { frequency: 4000, lowerLimit: 2840, upperLimit: 5680 },
  { frequency: 8000, lowerLimit: 5680, upperLimit: 11360 },
  { frequency: 16000, lowerLimit: 11360, upperLimit: 22720 },
];

function EqSimulate(props: EqSimulateProps) {
  const theme = useTheme();

  const [isPlay, setIsPlay] = useState(false);
  const [volumLevel, setVolumLevel] = useState(0);

  const toneObj = useMemo(() => {
    const audioManager = new AudioManager();

    const eqFrequencyNoise: Noise[] = [];
    const eqFrequencyVolume: Volume[] = [];
    frequencyBand.forEach((band, index) => {
      const noise = audioManager.getNoise('white');

      const filter = audioManager.getFilter();
      filter.type = 'bandpass';
      filter.frequency.value = band.frequency;
      filter.Q.value = band.frequency / (band.upperLimit - band.lowerLimit);
      filter.rolloff = Number(process.env.REACT_APP_EQ_FILTER_ROLLOFF ?? -24) as FilterRollOff;

      noise.connect(filter);

      const volume = audioManager.getVolume(-12);

      filter.connect(volume);
      volume.toDestination();

      eqFrequencyNoise.push(noise);
      eqFrequencyVolume.push(volume);
    });

    return { eqFrequencyNoise, eqFrequencyVolume };
  }, []);

  useEffect(() => {
    toneObj.eqFrequencyVolume.forEach((volume, index) => {
      let tan = props.overallThreshold;
      if (index > 4) {
        tan = props.tan[index - 5];
      }

      // convert tan values into -12 ~ 12 range
      const normalizedTan = (tan / 120) * 24 - 12; // -12 ~ 12

      // normalize tan max value
      const tanMax = Math.max(...[props.overallThreshold, ...props.tan].map((e) => (e / 120) * 24 - 12));

      // move the equliizer graph within -90 ~ +10 range without changing the graph shape. (gap between datapoints not changed)
      const normalizedVolume = normalizedTan - (tanMax - -90) + (volumLevel / 120) * 100;

      volume.volume.value = normalizedVolume;
    });
  }, [volumLevel]);

  useEffect(() => {
    return () => {
      setIsPlay(false);
      setVolumLevel(0);
      toneObj.eqFrequencyNoise.forEach((noise) => {
        noise.stop();
      });
    };
  }, []);

  useEffect(() => {
    document.onkeydown = handleOnKeyDown;
    return () => {
      document.onkeydown = null;
    };
  }, [volumLevel]);

  const handlePlayOnClick = () => {
    if (isPlay) {
      setIsPlay(false);
      toneObj.eqFrequencyNoise.forEach((noise) => {
        noise.stop();
      });
    } else {
      setIsPlay(true);
      toneObj.eqFrequencyNoise.forEach((noise) => {
        noise.start();
      });
    }
  };

  const handleVolumeIncrease = () => {
    if (volumLevel < 120) {
      setVolumLevel(volumLevel + 1);
    }
  };

  const handleVolumeDecrease = () => {
    if (volumLevel > 0) {
      setVolumLevel(volumLevel - 1);
    }
  };

  const handleChange = (_event: Event, newValue: number | number[]) => {
    setVolumLevel(newValue as number);
  };

  const handleOnKeyDown = (event: KeyboardEvent) => {
    const keyPressValueMap = new Map<string, number>([
      ['ArrowUp', 1],
      ['ArrowDown', -1],
      ['+', 5],
      ['-', -5],
    ]);

    const pressKey = event.key;
    if (keyPressValueMap.has(pressKey)) {
      const value = volumLevel + Number(keyPressValueMap.get(pressKey));
      if (value > MAX_VALUE) {
        setVolumLevel(MAX_VALUE);
      } else if (value < MIN_VALUE) {
        setVolumLevel(MIN_VALUE);
      } else {
        setVolumLevel(value);
      }
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <VolumeUpIcon color="primary" />
            <Slider value={volumLevel} onChange={handleChange} sx={{ marginX: 4 }} step={1} min={0} max={120} />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              bgcolor={theme.palette.white.main}
              width={140}
              sx={{ borderWidth: '0.5px', borderStyle: 'solid', borderRadius: '10px' }}
            >
              <IconButton size="small" onClick={handleVolumeDecrease}>
                <RemoveCircleOutlineIcon color="primary" />
              </IconButton>
              <Typography>{volumLevel}</Typography>
              <IconButton size="small" onClick={handleVolumeIncrease}>
                <ControlPointIcon color="primary" />
              </IconButton>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            sx={{ borderRadius: '10px' }}
            onClick={handlePlayOnClick}
            endIcon={<Box width={24} height={24} component="img" src={isPlay ? StopBoldSVG : PlayBoldSVG} />}
          >
            {isPlay ? 'Stop Demonstrate' : 'Demonstrate'}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default EqSimulate;
