import { call, ForkEffect, put, select, takeLatest, debounce } from 'redux-saga/effects';
import { IUserSlice, SaveFeedbackPayload, userActions } from './userSlice';
import { AxiosResponse } from 'axios';
import {
  getUser,
  updateUser,
  sendEmailVerificationCode,
  verifyEmailVerificationCode,
  changePassword,
  uploadAvatar,
  validateEmail,
  saveFeedback,
} from '../../api/userApi';
import { authActions } from '../auth/authSlice';
import _ from 'lodash';
import { Roles } from '../../utils/enums/roles.enum';
import { AddClinicianProps } from '../clinician/clinicianSlice';
import { updateClinician } from '../../api/clinicianApi';
import { createNewAccessToken } from '../../api/authApi';
import { notificationAction } from '../notification/notificationSlice';

function* watchGetUser() {
  try {
    const res: AxiosResponse = yield call(getUser);
    yield put(
      userActions.setUser({
        userId: res.data['userId'],
        email: res.data['email'],
        role: res.data['role'],
        firstName: res.data['firstName'],
        lastName: res.data['lastName'],
        phone: res.data['phone'],
        avatar: res.data['avatar'],
        clinicId: res.data['clinicId'],
        clinician: res.data['clinician'],
        permissions: _.map(res.data['permissions'], 'permission'),
        clinicName: res.data['clinic']?.name,
      }),
    );
    yield put(notificationAction.setNotificationViewTime(new Date(res.data['lastNotificationViewTime'])));
  } catch (err: any) {
    console.log('setUser err', err);
  }
  yield put(authActions.setComponentStatus({ isPageLoading: false }));
}

function* watchUserUpdate({
  payload,
}: {
  payload: { data: Partial<IUserSlice | AddClinicianProps>; image: FormData | undefined };
}) {
  const { data, image } = payload;
  try {
    let isEmailAlreadyExists = false;
    if (data.email) {
      const res: AxiosResponse = yield call(validateEmail, `${data.email}`);
      isEmailAlreadyExists = res.data;
      yield put(authActions.setComponentStatus({ isEmailAlreadyExists }));
    }

    if (!isEmailAlreadyExists) {
      if (image) {
        yield call(uploadAvatar, image);
      }
      if (data.email) {
        yield call(sendEmailVerificationCode, data.email);
      }
      if (Object.values(_.omit(data, ['email'])).length > 0) {
        const role: string = yield select((state) => state.user.role);
        if (role === Roles.CLINICIAN) {
          yield call(updateClinician, _.omit(data, ['email']) as Partial<AddClinicianProps>);
        } else {
          yield call(updateUser, _.omit(data, ['email']));
        }
      }

      const componentStatus = data.email ? { emailVerifyModalOpen: true } : { successModalOpen: true };

      yield put(authActions.setComponentStatus(componentStatus));
    }
  } catch (err: any) {
    yield put(authActions.setComponentStatus({ error: err.response.data.message }));
  }
  yield put(authActions.setComponentStatus({ isSubmitting: false }));
}

function* watchSendEmailVerificationCode({ payload }: { payload: { email: string } }) {
  try {
    yield call(sendEmailVerificationCode, `${payload.email}`);
  } catch (err: any) {
    yield put(authActions.setComponentStatus({ error: err.response.data.message }));
  }
  yield put(authActions.setComponentStatus({ isSubmitting: false }));
}

function* watchVerifyEmailVerificationCode({ payload }: { payload: { verificationCode: string } }) {
  const { verificationCode } = payload;
  try {
    yield call(verifyEmailVerificationCode, verificationCode);

    // generate new access token
    yield call(createNewAccessToken);

    yield put(authActions.setComponentStatus({ emailVerifyModalOpen: false }));
    yield put(authActions.setComponentStatus({ successModalOpen: true }));
    yield put(authActions.setComponentStatus({ verifyStatus: undefined }));
  } catch (err: any) {
    if (err.response.status === 403) {
      yield put(authActions.setComponentStatus({ error: 'Incorrect Verification Code!' }));
    } else {
      yield put(authActions.setComponentStatus({ error: err.response.data.message }));
    }
    yield put(authActions.setComponentStatus({ verifyStatus: 'failed' }));
  }
  yield put(authActions.setComponentStatus({ isSubmitting: false }));
}

function* watchChangePassword({ payload }: { payload: { password: string; newPassword: string } }) {
  const { password, newPassword } = payload;
  try {
    yield call(changePassword, password, newPassword);
    yield put(authActions.setComponentStatus({ successModalOpen: true }));
  } catch (err: any) {
    if (err.response.status === 403) {
      yield put(authActions.setComponentStatus({ isCurrentPasswordIncorrect: true }));
    }
    yield put(authActions.setComponentStatus({ error: err.response.data.message }));
  }
  yield put(authActions.setComponentStatus({ isSubmitting: false }));
}

function* watchSaveFeedback({ payload }: { payload: SaveFeedbackPayload }) {
  yield put(userActions.setComponentStatus({ isFeedbackSubmitting: true }));
  try {
    yield call(saveFeedback, payload);
    yield put(userActions.setComponentStatus({ isFeedbackSubmitting: false }));
    yield put(userActions.setComponentStatus({ isSendFeedbackSuccessModalOpen: true }));
  } catch (e) {
    yield put(userActions.setComponentStatus({ isFeedbackSubmitting: false }));
  }
}

export function* watchUserSagas(): Generator<ForkEffect, void> {
  yield debounce(1, userActions.getUserAsync, watchGetUser);
  yield takeLatest(userActions.userUpdateAsync, watchUserUpdate);
  yield takeLatest(userActions.sendEmailVerificationCodeAsync, watchSendEmailVerificationCode);
  yield takeLatest(userActions.verifyEmailVerificationCodeAsync, watchVerifyEmailVerificationCode);
  yield takeLatest(userActions.changePasswordAsync, watchChangePassword);
  yield takeLatest(userActions.saveFeedback, watchSaveFeedback);
}
const userSagas = watchUserSagas;
export default userSagas;
