import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import HeadSideViewSVG from '../../../../../assets/img/head-side-view.svg';
import Ellipse1SVG from '../../../../../assets/img/ellipse-1.svg';
import Ellipse2SVG from '../../../../../assets/img/ellipse-2.svg';
import Ellipse3SVG from '../../../../../assets/img/ellipse-3.svg';
import Ellipse4SVG from '../../../../../assets/img/ellipse-4.svg';
import Ellipse5SVG from '../../../../../assets/img/ellipse-5.svg';
import Ellipse9SVG from '../../../../../assets/img/ellipse-9.svg';
import DashedLineSVG from '../../../../../assets/img/dashed-line.svg';
import VerticalLineSVG from '../../../../../assets/img/vertical-line.svg';

const CIRCLE_RADIUS = 50;
const POINTER_RADIUS = 6;

interface VerticalPositionProps {
  isLoading: boolean;
  verticalPosition: number;
}

function VerticalPosition({ isLoading, verticalPosition }: VerticalPositionProps) {
  const theme = useTheme();

  const scale = (number: number) => {
    const [inMin, inMax] = [-10, 10];
    const [outMin, outMax] = [90, -5];

    if (number > 10) return outMax;
    else if (number < -10) return outMin;

    return ((number - inMin) / (inMax - inMin)) * (outMax - outMin) + outMin;
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography mb={2} variant="button" color={theme.palette.info.main}>
        Vertical Position
      </Typography>
      <Box width={190} height={178} marginLeft={8} display="flex" justifyContent="start" alignItems="center">
        <Box width={CIRCLE_RADIUS * 2} height={CIRCLE_RADIUS * 2} position="relative">
          <Box component="img" src={DashedLineSVG} position="absolute" left={50} top={-5} />

          <Box
            component="img"
            src={HeadSideViewSVG}
            position="absolute"
            margin="auto"
            left="0"
            right="0"
            top="0"
            bottom="0"
          />
          <Box component="img" src={Ellipse1SVG} position="absolute" left={20} top={0} />
          <Box component="img" src={Ellipse2SVG} position="absolute" left={5} top={20} />
          <Box component="img" src={Ellipse3SVG} position="absolute" left={-5} top={40} />
          <Box component="img" src={Ellipse4SVG} position="absolute" left={5} top={60} />
          <Box component="img" src={Ellipse5SVG} position="absolute" left={20} top={80} />

          <Box component="img" src={DashedLineSVG} position="absolute" left={50} bottom={0} />

          <Box component="img" src={VerticalLineSVG} position="absolute" left={120} bottom={0} />

          {!isLoading && (
            <Box
              component="img"
              src={Ellipse9SVG}
              width={POINTER_RADIUS * 2}
              height={POINTER_RADIUS * 2}
              position="absolute"
              left={117}
              top={scale(verticalPosition)}
            />
          )}

          <Typography variant="meta" color={theme.palette.info.main} sx={{ position: 'absolute', left: 40, top: -25 }}>
            Top
          </Typography>

          <Typography
            variant="meta"
            color={theme.palette.info.main}
            sx={{ position: 'absolute', left: 40, bottom: -20 }}
          >
            Down
          </Typography>

          <Typography variant="meta" color={theme.palette.info.main} sx={{ position: 'absolute', left: 110, top: -25 }}>
            +10
          </Typography>

          <Typography
            variant="meta"
            color={theme.palette.info.main}
            sx={{ position: 'absolute', left: 110, bottom: -20 }}
          >
            -10
          </Typography>
        </Box>
      </Box>
      <Typography mt={2} variant="meta" color={theme.palette.info.main}>
        {verticalPosition}
      </Typography>
    </Box>
  );
}

export default VerticalPosition;
