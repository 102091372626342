import CustomModal from '../../../components/CustomModal/CustomModal';
import GreenCheckCircleSVG from '../../../assets/img/check-circle-bold-duotone.svg';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';
import { generalActions } from '../../../redux/general/generalSlice';
import { clinicianActions } from '../../../redux/clinician/clinicianSlice';
import { authActions } from '../../../redux/auth/authSlice';

function ClinicianSuccessModal() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const addClinicianData = useAppSelector((state: RootState) => state.clinician.addClinicianData);

  const componentStatus = useAppSelector((state: RootState) => state.general.componentStatus);
  const isAddClinicianSuccessModalOpen = _.get(componentStatus, 'isAddClinicianSuccessModalOpen', false) as boolean;

  const handleDismiss = () => {
    dispatch(generalActions.clearComponentStatus());
    dispatch(clinicianActions.clearAddClinicianData());
    dispatch(authActions.setComponentStatus({ shouldClinicianListLoad: false }));
  };

  return (
    <CustomModal
      modal={true}
      open={isAddClinicianSuccessModalOpen}
      title="Clinician Added"
      icon={GreenCheckCircleSVG}
      btnText="Dismiss"
      width="374px"
      handleClose={handleDismiss}
      handleButtonOnClick={handleDismiss}
    >
      <Stack direction="column" alignItems="center" justifyContent="center">
        <Box>
          <AvatarEdit
            editable={false}
            selectedAvatar={addClinicianData?.avatar?.url}
            avatar=""
            name={addClinicianData?.firstName}
            name2={addClinicianData?.lastName}
            size="72px"
            font={theme.typography.h3}
          />
        </Box>

        <Typography mt={2} variant="h6" component="p" textAlign="center">
          {`${addClinicianData?.firstName} ${addClinicianData?.lastName}`}
        </Typography>
        <Typography mt={1} variant="bodyRegular" component="p" textAlign="center" color={theme.palette.info.main}>
          {addClinicianData?.email}
        </Typography>
        <Typography mt={1} variant="bodyRegular" component="p" textAlign="center" color={theme.palette.info.main}>
          {addClinicianData?.phone}
        </Typography>
      </Stack>
    </CustomModal>
  );
}

export default ClinicianSuccessModal;
