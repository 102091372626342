import React, { type ReactElement, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { authActions } from '../../redux/auth/authSlice';
import { getGreetingMessage } from '../../utils/time-util';
import { Box, Card, Grid, Theme, Typography } from '@mui/material';
import LoadingTypography from '../../components/LoadingTypography/LoadingTypography';
import { theme } from '../../theme/theme';
import PatientsIcon from './/../../assets/img/patients-bold.svg';
import CliniciansIcon from './/../../assets/img/clinicians.svg';
import CheckCircleIcon from './/../../assets/img/check-circle-bold-grey.svg';
import ClinicsIcon from './/../../assets/img/clinics-bold-grey.svg';
import RefreshCircleIcon from './/../../assets/img/refresh-circle-bold-grey.svg';

import { styled as materialStyled } from '@mui/material/styles';
import { adminActions } from '../../redux/admin/adminSlice';
import { RootState } from '../../redux/store';
import _ from 'lodash';

const CardStyled = materialStyled(Card)(({ theme }: { theme: Theme }) => ({
  borderRadius: '10px',
  padding: '24px',
  minHeight: '90px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  position: 'relative',
  height: '100%',
}));

const BoxInfoStyled = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}));

export default function Dashboard(): ReactElement {
  const dispatch = useAppDispatch();
  const dashboardInfo = useAppSelector((state: RootState) => state.admin.dashboardInfo);
  const componentStatus = useAppSelector((state: RootState) => state.admin.componentStatus);
  const isDashboardInfoLoading = _.get(componentStatus, 'isDashboardInfoLoading', false) as boolean;

  useEffect(() => {
    dispatch(
      authActions.setTopBarView({
        text: getGreetingMessage(new Date()),
        path: '/dashboard',
        sideMenuPath: '/dashboard',
      }),
    );
    dispatch(adminActions.getDashboardInfoAsync());
  }, []);

  return (
    <Grid container rowSpacing={2} padding={2} columnSpacing={2} flexGrow={1}>
      <Grid item xs={12} md={4}>
        <CardStyled>
          <BoxInfoStyled>
            <LoadingTypography
              isLoading={isDashboardInfoLoading}
              variant="h3"
              color={theme.palette.black.main}
              sx={{ minWidth: '100px' }}
            >
              {dashboardInfo.activeClinics}
            </LoadingTypography>
            <Typography variant="bodyRegular" color={theme.palette.info.main}>
              Total no. of active clinics
            </Typography>
          </BoxInfoStyled>
          <Box width={74} component="img" src={ClinicsIcon}></Box>
        </CardStyled>
      </Grid>
      <Grid item xs={12} md={4}>
        <CardStyled>
          <BoxInfoStyled>
            <LoadingTypography
              isLoading={isDashboardInfoLoading}
              variant="h3"
              color={theme.palette.black.main}
              sx={{ minWidth: '100px' }}
            >
              {dashboardInfo.activeClinicians}
            </LoadingTypography>
            <Typography variant="bodyRegular" color={theme.palette.info.main}>
              Total no. of active clinicians
            </Typography>
          </BoxInfoStyled>
          <Box width={74} component="img" src={CliniciansIcon}></Box>
        </CardStyled>
      </Grid>
      <Grid item xs={12} md={4}>
        <CardStyled>
          <BoxInfoStyled>
            <LoadingTypography
              isLoading={isDashboardInfoLoading}
              variant="h3"
              color={theme.palette.black.main}
              sx={{ minWidth: '100px' }}
            >
              {dashboardInfo.activePatients}
            </LoadingTypography>
            <Typography variant="bodyRegular" color={theme.palette.info.main}>
              Total no. of active patients
            </Typography>
          </BoxInfoStyled>
          <Box width={74} component="img" src={PatientsIcon} sx={{ fill: 'red' }}></Box>
        </CardStyled>
      </Grid>
      <Grid item xs={12} md={4}>
        <CardStyled>
          <BoxInfoStyled>
            <LoadingTypography
              isLoading={isDashboardInfoLoading}
              variant="h3"
              color={theme.palette.black.main}
              sx={{ minWidth: '100px' }}
            >
              {dashboardInfo.treatmentCompletedPatients}
            </LoadingTypography>
            <Typography variant="bodyRegular" color={theme.palette.info.main}>
              Total no. of treatment completed
            </Typography>
          </BoxInfoStyled>
          <Box width={74} component="img" src={CheckCircleIcon}></Box>
        </CardStyled>
      </Grid>
      <Grid item xs={12} md={4}>
        <CardStyled>
          <BoxInfoStyled>
            <LoadingTypography
              isLoading={isDashboardInfoLoading}
              variant="h3"
              color={theme.palette.black.main}
              sx={{ minWidth: '100px' }}
            >
              {dashboardInfo.returningPatients}
            </LoadingTypography>
            <Typography variant="bodyRegular" color={theme.palette.info.main}>
              Total no. of returning patients
            </Typography>
          </BoxInfoStyled>
          <Box width={74} component="img" src={RefreshCircleIcon}></Box>
        </CardStyled>
      </Grid>
    </Grid>
  );
}
