export enum Permission {
  TRUE_SILENCE_ADMIN_CREATE_NEW_CLINIC = 'create_new_clinic',
  TRUE_SILENCE_ADMIN_DEACTIVATE_ACTIVATE_CLINICS = 'deactivate_activate_clinics',
  TRUE_SILENCE_ADMIN_REVIEW_CLINIC_TO_CLINIC_PATIENT_TRANSFERS = 'review_clinic_to_clinic_patient_transfers',
  TRUE_SILENCE_ADMIN_REVIEW_CLINIC_CHANGES = 'review_clinic_changes',
  TRUE_SILENCE_ADMIN_MANAGE_SOUND_LIBRARY = 'manage_sound_library',

  CLINIC_ADMIN_ADD_CLINIC_ADMIN = 'add_clinic_admin',
  CLINIC_ADMIN_REMOVE_CLINIC_ADMIN = 'remove_clinic_admin',
  CLINIC_ADMIN_CHANGE_PERMISSIONS_CLINIC_ADMIN = 'change_permissions_clinic_admin',
  CLINIC_ADMIN_ADD_CLINICIAN = 'add_clinician',
  CLINIC_ADMIN_DEACTIVATE_CLINICIAN = 'deactivate_clinician',
  CLINIC_ADMIN_ADD_PATIENT = 'add_patient',
  CLINIC_ADMIN_TRANSFER_PATIENT = 'transfer_patient',
  CLINIC_ADMIN_EDIT_PATIENT = 'edit_patient',
  CLINIC_ADMIN_EDIT_CLINIC = 'edit_clinic',
}
