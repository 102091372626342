import React, { useContext, createContext, useEffect, useMemo } from 'react';
import { RootState } from '../redux/store';
import { userActions } from '../redux/user/userSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { authActions } from '../redux/auth/authSlice';

const AuthContext = createContext<{ isLogin: boolean; user: any }>({
  isLogin: false,
  user: undefined,
});

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: any) {
  const auth = useAppSelector((state: RootState) => state.auth);
  const user = useAppSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (auth.isLogin) {
      dispatch(authActions.setComponentStatus({ isPageLoading: true }));
    }
  }, [auth.isLogin]);

  useEffect(() => {
    if (auth.isLogin) {
      dispatch(userActions.getUserAsync());
    }
  }, [auth.isLogin]);

  const authState = useMemo(() => ({ isLogin: auth.isLogin, user: user }), [auth.isLogin, user]);

  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>;
}
