import React, { Fragment, useEffect } from 'react';
import { Box, Card, CardContent, Skeleton, Stack, Typography, Fade } from '@mui/material';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import { theme } from '../../../theme/theme';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import _ from 'lodash';
import PatientsTable from './PatientTable';
import PatientsBold from '../../../assets/img/patients-bold.svg';
import CliniciansIcon from '../../../assets/img/clinicians.svg';
import { ReactComponent as SlashCircleOutlinedSVG } from '../../../assets/img/slash-circle-outline.svg';
import { ReactComponent as CheckCircleBlueSVG } from '../../../assets/img/check-circle-bold-duotone-blue.svg';
import { SIDEBAR_OPTIONS } from '../../../utils/sidebar-schema';
import { useParams } from 'react-router-dom';
import { TopBarViewProps, authActions } from '../../../redux/auth/authSlice';
import { SidebarOption } from '../../../utils/enums/sidebar-option.enum';
import { Roles } from '../../../utils/enums/roles.enum';
import { Permission } from '../../../utils/enums/permission.enum';
import { useAuth } from '../../../context/AuthContext';
import { clinicianActions } from '../../../redux/clinician/clinicianSlice';
import DeactivateClinician from '../DeactivateClinician/DeactivateClinician';
import ReactivateClinician from '../ReactivateClinician/ReactivateClinician';
import LoadingTypography from '../../../components/LoadingTypography/LoadingTypography';
import InfoIconSVG from '../../../assets/img/infoIcon.svg';
import { IdDescriptions } from '../../../utils/enums/ids.enum';
import { LightTooltip } from '../../../components/LightTooltip/LightTooltip';

function ClinicianDetail() {
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const { userId } = useParams();

  const selectedClinician = useAppSelector((state: RootState) => state.clinician.selectedClinician);
  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const deactivateModalOpen = _.get(componentStatus, 'deactivateClinicianModalOpen', false) as boolean;
  const reactivateModalOpen = _.get(componentStatus, 'reactivateClinicianModalOpen', false) as boolean;
  const isSelectedClinicianLoading = _.get(componentStatus, 'isSelectedClinicianLoading', false) as boolean;

  const isSuperOrTruesilenceAdmin = (role: Roles) => {
    return [Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(role);
  };

  useEffect(() => {
    dispatch(clinicianActions.getClinicianByUserIdAsync(userId ?? ''));
  }, [userId]);

  useEffect(() => {
    let topBarView: TopBarViewProps = {
      text: isSuperOrTruesilenceAdmin(user.role)
        ? selectedClinician?.globalId ?? ''
        : `${selectedClinician?.firstName ?? ''} ${selectedClinician?.lastName ?? ''}`,
      sideMenuPath: SIDEBAR_OPTIONS[SidebarOption.CLINICIANS].path,
      backButton: { path: SIDEBAR_OPTIONS[SidebarOption.CLINICIANS].path },
      backNav: Boolean(userId),
    };

    if (
      !isSelectedClinicianLoading &&
      user.role === Roles.CLINIC_ADMIN &&
      user.permissions.includes(Permission.CLINIC_ADMIN_DEACTIVATE_CLINICIAN)
    ) {
      if (selectedClinician?.status === 'ACTIVE') {
        topBarView.button = {
          text: 'Deactivate Clinician',
          icon: <SlashCircleOutlinedSVG />,
          color: 'error',
          onClick: () => {
            handleDeactivateClinician();
          },
        };
      }
      if (selectedClinician?.status === 'DELETED') {
        topBarView.button = {
          text: 'Reactivate Clinician',
          icon: <CheckCircleBlueSVG />,
          color: 'primary',
          onClick: () => {
            handleReactivateClinician();
          },
        };
      }
    }

    dispatch(authActions.setTopBarView(topBarView));
  }, [selectedClinician]);

  const handleDeactivateClinician = () => {
    dispatch(authActions.setComponentStatus({ deactivateClinicianModalOpen: true }));
  };

  const handleReactivateClinician = () => {
    dispatch(authActions.setComponentStatus({ reactivateClinicianModalOpen: true }));
  };

  const getOrderedClinicianInfo = () => {
    if ([Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role)) {
      return [
        { name: 'TSTID', value: _.get(selectedClinician, 'globalId', '') },
        {
          name: 'Clinician Name',
          value: [
            _.get(selectedClinician, 'firstName', ''),
            _.get(selectedClinician, 'middleName', ''),
            _.get(selectedClinician, 'lastName', ''),
          ]
            .filter(Boolean)
            .join(' '),
        },
        { name: 'City', value: _.get(selectedClinician, 'city', '') },
        { name: 'State/ Region', value: _.get(selectedClinician, 'state', '') },
        { name: 'Zip Code', value: _.get(selectedClinician, 'zipCode', '') },
        { name: 'Country', value: _.get(selectedClinician, 'country', '') },
        {
          name: 'Status',
          value: selectedClinician?.status?.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase()) ?? '',
        },
        { name: 'Clinic', value: selectedClinician?.clinicName ?? '' },
        { name: 'Joined Date', value: selectedClinician?.createAt?.split('T')[0] ?? '' },
      ];
    } else if ([Roles.CLINIC_ADMIN].includes(user.role)) {
      return [
        { name: 'TSTID', value: _.get(selectedClinician, 'globalId', '') },
        { name: 'TID', value: _.get(selectedClinician, 'clinicBaseId', '') },
        { name: 'First Name', value: _.get(selectedClinician, 'firstName', '') },
        { name: 'Last Name', value: _.get(selectedClinician, 'lastName', '') },
        { name: 'Middle Name', value: _.get(selectedClinician, 'surname', '') },
        { name: 'Email Address', value: _.get(selectedClinician, 'email', '') },
        { name: 'Phone Number', value: _.get(selectedClinician, 'phone', '') },
        { name: 'Birth Date', value: selectedClinician?.birthday?.split('T')[0] ?? '' },
        {
          name: 'Address',
          value: _.trimEnd(
            [selectedClinician?.addressLine1 ?? '', selectedClinician?.addressLine2 ?? ''].join(', ').trim(),
            ',',
          ),
        },
        { name: 'City', value: _.get(selectedClinician, 'city', '') },
        { name: 'State/ Region', value: _.get(selectedClinician, 'state', '') },
        { name: 'Zip Code', value: _.get(selectedClinician, 'zipCode', '') },
        { name: 'Country', value: _.get(selectedClinician, 'country', '') },
        { name: 'Joined Date', value: selectedClinician?.createAt?.split('T')[0] ?? '' },
      ];
    } else {
      return [];
    }
  };

  const selectedClinicianLoadingDisplayFlex = isSelectedClinicianLoading ? 'flex' : 'none';

  const idDescriptionsMap = {
    TSTID: IdDescriptions.TSTID,
    TID: IdDescriptions.TID,
  };

  return (
    <Fragment>
      <Box display={'flex'} flexDirection={'row'} gap={'20px'}>
        <Box flex={1} flexDirection={'column'}>
          <Card
            sx={{
              display: 'flex',
              gap: '30px',
              borderRadius: '10px',
              padding: '24px',
              minHeight: user.role === Roles.CLINIC_ADMIN ? '290px' : '190px',
            }}
          >
            <Box justifyContent="center" alignItems="flex-start" flex={1} gap="10px">
              <AvatarEdit
                isLoading={isSelectedClinicianLoading}
                avatar={selectedClinician?.avatar}
                dummy={[Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role)}
                editable={false}
                name={selectedClinician?.firstName}
                name2={selectedClinician?.lastName}
                font={theme.typography.h2}
                size="100px"
              />
            </Box>

            <Box
              sx={
                [Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role)
                  ? {
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignContent: 'flex-start',
                      borderRadius: '20px',
                      columnGap: '16px',
                      rowGap: '25px',
                    }
                  : { display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', borderRadius: '20px' }
              }
            >
              {getOrderedClinicianInfo().map(({ name, value }) => {
                return (
                  <Box
                    id={`clinician-${name}-id`}
                    key={`c-${name.replace(/ /g, '-')}`}
                    width={[Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role) ? 'auto' : '50%'}
                    padding={1}
                  >
                    <Box display="flex" alignItems="center">
                      <Typography variant="bodyRegular" color={theme.palette.shades.purpleBorder} component="div">
                        {name}
                      </Typography>
                      {(name === 'TSTID' || name === 'TID') && [Roles.CLINIC_ADMIN].includes(user.role) && (
                        <LightTooltip
                          arrow
                          placement="right"
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title={idDescriptionsMap[name] || ''}
                          children={<Box sx={{ cursor: 'pointer' }} component="img" src={InfoIconSVG} ml={0.8}></Box>}
                        />
                      )}
                    </Box>
                    <LoadingTypography
                      isLoading={isSelectedClinicianLoading}
                      variant="bodyRegular"
                      color={theme.palette.info.main}
                      component="p"
                      sx={{ wordWrap: 'break-word' }}
                    >
                      {value ?? ''}
                    </LoadingTypography>
                  </Box>
                );
              })}
            </Box>
          </Card>

          <Box display={'flex'} gap={'20px'}>
            <Box flex={1} mt={2}>
              <Card sx={{ borderRadius: '10px', padding: '24px' }}>
                <Stack
                  display={selectedClinicianLoadingDisplayFlex}
                  direction="row"
                  alignItems="center"
                  gap="30px"
                  padding="0px"
                >
                  <Stack>
                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '4rem', width: 60 }} />
                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: 100 }} />
                  </Stack>
                </Stack>
                <CardContent sx={{ display: isSelectedClinicianLoading ? 'none' : 'block', padding: 0 }}>
                  <Box display={'flex'} justifyContent="space-between" alignItems="start">
                    <Box>
                      <Typography gutterBottom variant="h3" component="div" color={theme.palette.black.main}>
                        {selectedClinician?.underTreatmentCount ?? ''}
                      </Typography>
                      <Typography variant="bodyRegular" color={theme.palette.info.main}>
                        Patients under <br /> treatment
                      </Typography>
                    </Box>
                    <Box component="img" src={PatientsBold}></Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box flex={1} mt={2}>
              <Card sx={{ borderRadius: '10px', padding: '24px' }}>
                <Stack
                  display={selectedClinicianLoadingDisplayFlex}
                  direction="row"
                  alignItems="center"
                  gap="30px"
                  padding="0px"
                >
                  <Stack>
                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '4rem', width: 60 }} />
                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: 100 }} />
                  </Stack>
                </Stack>
                <CardContent sx={{ display: isSelectedClinicianLoading ? 'none' : 'block', padding: 0 }}>
                  <Box display={'flex'} justifyContent="space-between" alignItems="start">
                    <Box>
                      <Typography gutterBottom variant="h3" component="div" color={theme.palette.black.main}>
                        {selectedClinician?.completedCount ?? ''}
                      </Typography>
                      <Typography variant="bodyRegular" color={theme.palette.info.main}>
                        Total Patients <br /> treated
                      </Typography>
                    </Box>
                    <Box component="img" src={CliniciansIcon}></Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>

        <Box flex={1}>
          <PatientsTable
            clinicianUserId={selectedClinician?.userId ?? ''}
            clinicianId={selectedClinician?.clinicianId ?? ''}
          />
        </Box>
      </Box>

      <DeactivateClinician userId={selectedClinician?.userId ?? ''} open={deactivateModalOpen} />
      <ReactivateClinician userId={selectedClinician?.userId ?? ''} open={reactivateModalOpen} />
    </Fragment>
  );
}

export default ClinicianDetail;
