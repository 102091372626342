import { createTheme, Shadows } from '@mui/material';
import React from 'react';

declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary'];
    white: Palette['primary'];
    shades: {
      placeholderText: string;
      purpleBorder: string;
      errorBorder: string;
      blackBorder: string;
      shadow: string;
      lightError: string;
      disabled: string;
    };
  }
  interface PaletteOptions {
    black?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
    shades?: {
      placeholderText?: string;
      purpleBorder?: string;
      errorBorder?: string;
      blackBorder?: string;
      shadow?: string;
      lightError?: string;
      disabled?: string;
    };
  }

  interface TypographyVariants {
    bodyRegular: React.CSSProperties;
    bodyBold: React.CSSProperties;
    meta: React.CSSProperties;
    h4Bold: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    bodyRegular?: React.CSSProperties;
    bodyBold?: React.CSSProperties;
    meta?: React.CSSProperties;
    h4Bold?: React.CSSProperties;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    black: true;
    white: true;
    shades: true;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bodyRegular: true;
    bodyBold: true;
    meta: true;
    h4Bold: true;
    subtitle1: true;
    subtitle2: false;
    body1: false;
    body2: false;
    caption: false;
    overline: false;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            backgroundClip: 'text',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#3800AE',
    },
    secondary: {
      main: '#F3EFF6',
    },
    error: {
      main: '#C37070',
    },
    warning: {
      main: '#DECF44',
    },
    info: {
      main: '#595959',
    },
    success: {
      main: '#A2CBBF',
    },
    black: {
      main: '#000000',
    },
    white: {
      main: '#FFFFFF',
    },
    shades: {
      placeholderText: '#0000001A',
      purpleBorder: '#3800AE4D',
      errorBorder: '#C3707080',
      blackBorder: '#0000004D',
      shadow: '#00000012',
      lightError: '#FAF4F4',
      disabled: '#7c7b7b1f',
    },
  },
  typography: {
    fontFamily: 'Satoshi',
    h1: {
      fontFamily: 'Satoshi',
      fontWeight: 500,
      fontSize: '64px',
      letterSpacing: 0,
      lineHeight: 'normal',
    },
    h2: {
      fontFamily: 'Satoshi',
      fontWeight: 500,
      fontSize: '48px',
      letterSpacing: 0,
      lineHeight: 'normal',
    },
    h3: {
      fontFamily: 'Satoshi',
      fontWeight: 500,
      fontSize: '40px',
      letterSpacing: 0,
      lineHeight: 'normal',
    },
    h4Bold: {
      fontFamily: 'Satoshi',
      fontWeight: 700,
      fontSize: '36px',
      letterSpacing: 0,
      lineHeight: 'normal',
    },
    h4: {
      fontFamily: 'Satoshi',
      fontWeight: 500,
      fontSize: '36px',
      letterSpacing: 0,
      lineHeight: 'normal',
    },
    h5: {
      fontFamily: 'Satoshi',
      fontWeight: 500,
      fontSize: '24px',
      letterSpacing: 0,
      lineHeight: 'normal',
    },
    h6: {
      fontFamily: 'Satoshi',
      fontWeight: 400,
      fontSize: '20px',
      letterSpacing: 0,
      lineHeight: 'normal',
    },
    bodyRegular: {
      fontFamily: 'Satoshi',
      fontWeight: 400,
      fontSize: '16px',
      letterSpacing: 0,
      lineHeight: 'normal',
    },
    bodyBold: {
      fontFamily: 'Satoshi',
      fontWeight: 500,
      fontSize: '16px',
      letterSpacing: 0,
      lineHeight: 'normal',
    },
    meta: {
      fontFamily: 'Satoshi',
      fontWeight: 400,
      fontSize: '14px',
      letterSpacing: 0,
      lineHeight: 'normal',
    },
    button: {
      fontFamily: 'Satoshi',
      fontWeight: 700,
      fontSize: '14px',
      letterSpacing: 0,
      lineHeight: 'normal',
      textTransform: 'none',
    },
    subtitle1: {
      fontFamily: 'Satoshi',
      fontWeight: 400,
      fontSize: '12px',
      letterSpacing: 0,
      lineHeight: 'normal',
    },
    subtitle2: undefined,
    body1: undefined,
    body2: undefined,
    caption: undefined,
    overline: undefined,
  },
  shadows: [
    ...createTheme({}).shadows.map((shadow, i) => (i === 1 ? '0px 4px 32px 0px #00000012' : shadow)),
  ] as Shadows,
});
