import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { Box, Typography } from '@mui/material';
import { theme } from '../../theme/theme';
import { Draggable } from '@hello-pangea/dnd';

function CustomDragItem(props: { name: string; index: number; id: string }) {
  return (
    <Draggable draggableId={props.id} index={props.index}>
      {(provided, snapshot) => (
        <Box
          display="flex"
          flexDirection="row"
          gap="16px"
          sx={{
            borderBottom: '0.5px solid ' + theme.palette.shades.purpleBorder,
            width: '100%',
            padding: '12px 0',
            backgroundColor: snapshot.isDragging ? theme.palette.secondary.main : theme.palette.white.main,
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <MenuOutlinedIcon sx={{ color: theme.palette.primary.main }} />
          <Typography variant="bodyRegular" sx={{ color: theme.palette.black.main }}>
            {props.name}
          </Typography>
        </Box>
      )}
    </Draggable>
  );
}

export default CustomDragItem;
