import React, { Fragment, useEffect, useState } from 'react';
import CustomModal from '../../../components/CustomModal/CustomModal';
import CheckCircleBoldDuotoneRed from '../../../assets/img/check-circle-bold-duotone-red.svg';
import SlashCircleOutlinedSVG from '../../../assets/img/slash-circle-outline.svg';
import { Box, Grid, IconButton, InputAdornment, Stack, Typography, useTheme } from '@mui/material';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import CustomInputBox from '../../../components/CustomInputBox/CustomInputBox';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { authActions } from '../../../redux/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';
import { clinicActions } from '../../../redux/clinic/clinicSlice';

interface ClinicDeactivateProps {
  clinicId: string;
  open: boolean;
}

const DeactivateClinic = ({ clinicId, open }: ClinicDeactivateProps) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();

  const clinicInfo = useAppSelector((state: RootState) => state.clinic.clinicInfo);
  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const isPasswordIncorrect = _.get(componentStatus, 'isPasswordIncorrect', false) as boolean;
  const isClinicDeactivating = _.get(componentStatus, 'isClinicDeactivating', false) as boolean;
  const isDeactivateSuccess = _.get(componentStatus, 'isDeactivateSuccess', false) as boolean;
  const error = _.get(componentStatus, 'error', '') as string;

  useEffect(() => {
    formik.setFieldValue('password', '');
    if (clinicId) {
      dispatch(
        authActions.setComponentStatus({
          isPasswordIncorrect: false,
          isClinicDeactivating: false,
          isDeactivateSuccess: false,
        }),
      );
    }
  }, [clinicId]);

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: yup.object({
      password: yup.string().required('Password is required'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({ password }) => {
      dispatch(
        authActions.setComponentStatus({
          isPasswordIncorrect: false,
          isClinicDeactivating: true,
          error: '',
        }),
      );
      dispatch(clinicActions.deactivateClinicAsync({ clinicId, password }));
    },
  });

  const handleDismiss = () => {
    formik.setFieldValue('password', '');
    dispatch(authActions.setComponentStatus({ deactivateClinicModalOpen: false }));
    setShowPassword(false);
  };

  const handleSuccessDismiss = () => {
    formik.setFieldValue('password', '');
    dispatch(authActions.clearComponentStatus());
    dispatch(clinicActions.getClinicInfoAsync(clinicId ?? ''));
    setShowPassword(false);
  };

  return (
    <Fragment>
      <CustomModal
        modal={true}
        open={!!clinicId && open}
        width="374px"
        btnText={isDeactivateSuccess ? 'Dismiss' : ''}
        handleButtonOnClick={handleSuccessDismiss}
        title={isDeactivateSuccess ? 'Clinic Deactivated Successfully!' : 'Deactivation Confirmation'}
        icon={isDeactivateSuccess ? CheckCircleBoldDuotoneRed : SlashCircleOutlinedSVG}
      >
        <Stack>
          <Typography
            hidden={!!isDeactivateSuccess}
            mb={2}
            variant="bodyRegular"
            component="p"
            textAlign="center"
            color={theme.palette.info.main}
          >
            Are you sure you want to deactivate the selected clinic?
          </Typography>
          <Typography
            hidden={!isDeactivateSuccess}
            mb={2}
            variant="bodyRegular"
            component="p"
            textAlign="center"
            color={theme.palette.info.main}
          >
            The selected clinic has been successfully deactivated.
          </Typography>

          <Box display="flex" justifyContent="center">
            <AvatarEdit
              editable={false}
              size="72px"
              name={clinicInfo?.name ?? ''}
              avatar={clinicInfo?.avatar}
              font={theme.typography.h3}
            />
          </Box>

          <Typography mt={2} component="p" variant="h6" align="center">
            {clinicInfo?.name}
          </Typography>

          <Box mt={2} />
          {!isDeactivateSuccess && (
            <CustomInputBox
              label="Please enter your password"
              labelAlign={'center'}
              placeholder=""
              required={true}
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={(formik.touched.password && Boolean(formik.errors.password)) || isPasswordIncorrect}
              helperText={
                (formik.touched.password && formik.errors.password) || (isPasswordIncorrect && 'Incorrect password!')
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}

          {!isDeactivateSuccess && (
            <Grid container marginTop={3} columnSpacing={2}>
              {error && !isPasswordIncorrect && (
                <Grid item xs={12}>
                  <Typography mb={1} variant="bodyRegular" component="p" textAlign="center" color="error">
                    {error}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={6}>
                <LoadingButton
                  variant="outlined"
                  onClick={handleDismiss}
                  fullWidth
                  sx={{ borderRadius: '10px', height: '43px' }}
                >
                  Dismiss
                </LoadingButton>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton
                  disabled={!clinicInfo}
                  loading={isClinicDeactivating}
                  onClick={() => formik.handleSubmit()}
                  variant="contained"
                  fullWidth
                  color="error"
                  sx={{ borderRadius: '10px', height: '43px' }}
                >
                  Confirm
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </Stack>
      </CustomModal>
    </Fragment>
  );
};

export default DeactivateClinic;
