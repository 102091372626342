import { call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects';
import { AddClinicProps, clinicActions, ClinicInfo } from './clinicSlice';
import { AxiosResponse } from 'axios';
import {
  getAllClinics,
  GetAllClinicParamType,
  getClinicAdminByUserId,
  validateEmailOrDomain,
  deactivateClinic,
  reactivateClinic,
  getClinicInfo,
  GetAllClinicAdminParamType,
  getAllClinicAdmins,
  createClinic,
  getClinicAdminPermissions,
  createClinicAdmin,
  updateClinicAdmin,
  getClinicIntermediateInfo,
  updateClinic,
  approveIntermediateClinicInfo,
  rejectIntermediateClinicInfo,
  deleteClinicAdmin,
  getAllClinicNames,
} from '../../api/clinicApi';
import { validateEmail } from '../../api/userApi';
import { authActions } from '../auth/authSlice';
import { generalActions } from '../general/generalSlice';
import { uploadImage } from '../../api/minioApi';
import Constant from '../../constants/constant.json';
import { Roles } from '../../utils/enums/roles.enum';
import { notificationAction } from '../notification/notificationSlice';
import * as _ from 'lodash';
import { AddClinicTabs } from '../../utils/enums/add-clinic-tabs.enum';

function* watchGetClinicList({ payload }: { payload: GetAllClinicParamType }) {
  yield put(authActions.setComponentStatus({ isTableLoading: true }));
  try {
    const res: AxiosResponse = yield call(getAllClinics, payload);
    yield put(clinicActions.setTotalClinicCount(res.data['totalClinics']));
    yield put(clinicActions.setClinicList(res.data['data']));
  } catch (err: any) {
    yield put(clinicActions.setTotalClinicCount(0));
    yield put(clinicActions.setClinicList([]));
  }
  yield put(authActions.setComponentStatus({ isTableLoading: false }));
}

function* watchGetClinicInfo({ payload }: { payload: string }) {
  const clinicId = payload;

  yield put(authActions.setComponentStatus({ isClinicInfoLoading: true }));
  try {
    const res: AxiosResponse = yield call(getClinicInfo, clinicId);

    yield put(clinicActions.setClinicInfo(res.data));
  } catch (err: any) {
    yield put(clinicActions.setClinicInfo());
  }
  yield put(authActions.setComponentStatus({ isClinicInfoLoading: false }));
}

function* watchGetClinicAdminByUserId({ payload }: { payload: string }) {
  const userId = payload;

  yield put(authActions.setComponentStatus({ isSelectedClinicAdminLoading: true }));
  yield put(clinicActions.setComponentStatus({ isSubmitting: true }));
  try {
    const res: AxiosResponse = yield call(getClinicAdminByUserId, userId);
    yield put(clinicActions.setSelectedClinicAdmin(res.data));
    yield put(clinicActions.setAdminData(res.data));
    const adminPermissions: AxiosResponse = yield call(getClinicAdminPermissions, Roles.CLINIC_ADMIN);
    yield put(clinicActions.setAdminPermissions(adminPermissions));
  } catch (err: any) {
    yield put(clinicActions.setSelectedClinicAdmin(undefined));
  }
  yield put(authActions.setComponentStatus({ isSelectedClinicAdminLoading: false }));
  yield put(clinicActions.setComponentStatus({ isSubmitting: false }));
}

function* watchDeactivateClinic({ payload }: { payload: { clinicId: string; password: string } }) {
  yield put(authActions.setComponentStatus({ isClinicDeactivating: true }));
  try {
    yield call(deactivateClinic, payload.clinicId, payload.password);
    yield put(authActions.setComponentStatus({ isDeactivateSuccess: true }));
  } catch (err: any) {
    if (err.response.status === 403) {
      yield put(authActions.setComponentStatus({ isPasswordIncorrect: true }));
    }
    yield put(authActions.setComponentStatus({ error: err.response.data?.message }));
  }
  yield put(authActions.setComponentStatus({ isClinicDeactivating: false }));
}

function* watchReactivateClinic({ payload }: { payload: { clinicId: string; password: string } }) {
  yield put(authActions.setComponentStatus({ isClinicReactivating: true }));
  try {
    yield call(reactivateClinic, payload.clinicId, payload.password);
    yield put(authActions.setComponentStatus({ isReactivateSuccess: true }));
  } catch (err: any) {
    if (err.response.status === 403) {
      yield put(authActions.setComponentStatus({ isPasswordIncorrect: true }));
    }
    yield put(authActions.setComponentStatus({ error: err.response.data?.message }));
  }
  yield put(authActions.setComponentStatus({ isClinicReactivating: false }));
}

function* watchHandleNextToAddClinicAdminTab({ payload }: { payload: AddClinicProps }) {
  yield put(generalActions.setComponentStatus({ isSubmitting: true }));
  try {
    const res: AxiosResponse = yield call(validateEmailOrDomain, payload.email ?? '');
    const isEmailAlreadyExists = res.data.email;

    yield put(generalActions.setComponentStatus({ isEmailAlreadyExists }));

    if (!isEmailAlreadyExists) {
      yield put(clinicActions.setAddClinicData(payload));
      yield put(generalActions.setComponentStatus({ selectedTab: AddClinicTabs.CLINIC_ADMIN }));
    }
  } catch (err: any) {
    // pass
  }
  yield put(generalActions.setComponentStatus({ isSubmitting: false }));
}

function* watchHandleNextToConfirmAddClinicTab({ payload }: { payload: Partial<AddClinicProps> }) {
  if (!payload.clinicAdmin) return;

  yield put(generalActions.setComponentStatus({ isSubmitting: true }));
  try {
    const res: AxiosResponse = yield call(validateEmail, payload.clinicAdmin.email);
    const isEmailAlreadyExists = res.data;
    yield put(generalActions.setComponentStatus({ isEmailAlreadyExists }));

    if (!isEmailAlreadyExists) {
      yield put(clinicActions.setAddClinicData({ clinicAdmin: payload.clinicAdmin }));
      yield put(generalActions.setComponentStatus({ selectedTab: 'confirm' }));
    }
  } catch (err: any) {
    // pass
  }
  yield put(generalActions.setComponentStatus({ isSubmitting: false }));
}

function* watchCreateClinicAsync({ payload }: { payload: AddClinicProps }) {
  yield put(generalActions.setComponentStatus({ isSubmitting: true }));
  try {
    let clinicAvatar = '';
    if (payload.avatar?.file) {
      const res: AxiosResponse = yield call(uploadImage, payload.avatar.file);
      clinicAvatar = res.data['fileName'];
    }
    yield call(createClinic, payload, clinicAvatar);
    yield put(generalActions.setComponentStatus({ isAddClinicModalOpen: false }));
    yield put(generalActions.setComponentStatus({ isAddClinicSuccessModalOpen: true }));
  } catch (err: any) {
    // pass
  }
  yield put(generalActions.setComponentStatus({ isSubmitting: false }));
}

function* watchGetClinicAdminList({ payload }: { payload: GetAllClinicAdminParamType }) {
  yield put(authActions.setComponentStatus({ isClinicAdminTableLoading: true }));
  try {
    const res: AxiosResponse = yield call(getAllClinicAdmins, payload);
    yield put(clinicActions.setTotalClinicAdminCount(res.data['totalAdmins']));
    yield put(clinicActions.setClinicAdminList(res.data['data']));
  } catch (err: any) {
    yield put(clinicActions.setTotalClinicAdminCount(0));
    yield put(clinicActions.setClinicAdminList([]));
  }
  yield put(authActions.setComponentStatus({ isClinicAdminTableLoading: false }));
}

function* watchCreateClinicAdmin({ payload }: { payload: any }) {
  yield put(clinicActions.setComponentStatus({ isSubmitting: true }));
  try {
    yield call(createClinicAdmin, payload);
  } catch (err: any) {
    if ([403, 404].includes(err.respponse.status)) {
      yield put(authActions.setComponentStatus({ error: err.response.data?.message }));
    }
  }
  yield put(authActions.setComponentStatus({ shouldAdminListLoad: true }));
  yield put(clinicActions.setCreateSuccess());
  yield put(clinicActions.setComponentStatus({ addSuccessModalOpen: true }));
}

function* watchUpdateAdmin({ payload }: { payload: any }) {
  yield put(clinicActions.setComponentStatus({ isSubmitting: true }));
  try {
    yield call(updateClinicAdmin, payload);
    yield put(authActions.setComponentStatus({ shouldAdminListLoad: true }));
    yield put(clinicActions.setCreateSuccess());
    yield put(clinicActions.setComponentStatus({ editSuccessModalOpen: true }));
  } catch (err) {
    yield put(
      clinicActions.setComponentStatus({
        error: _.get(err, 'response.data.message', 'Something went wrong'),
      }),
    );
  }
  yield put(clinicActions.setComponentStatus({ isSubmitting: false }));
}

function* watchNextToAddClinicAdminPermissionTab({ payload }: { payload: any }) {
  let isEmailAlreadyExists = false;

  yield put(clinicActions.setComponentStatus({ isSubmitting: true }));
  try {
    if (payload.userId === undefined) {
      const res: AxiosResponse = yield call(validateEmail, payload.email);
      isEmailAlreadyExists = res.data;
      yield put(clinicActions.setComponentStatus({ isEmailAlreadyExists }));
    }
    if (!isEmailAlreadyExists) {
      const adminPermissions: AxiosResponse = yield call(getClinicAdminPermissions, Roles.CLINIC_ADMIN);
      yield put(clinicActions.setAdminPermissions(adminPermissions));
      yield put(clinicActions.setComponentStatus({ selectedTab: Constant.AdminTabs.CONFIG_PERMISSION.slug }));
      yield put(clinicActions.setAdminData(payload));
    }
  } catch (err: any) {
    // pass
  }
  yield put(clinicActions.setComponentStatus({ isSubmitting: false }));
}
function* watchGetClinicIntermediateInfo({ payload }: { payload: { clinicId: string } }) {
  yield put(generalActions.setComponentStatus({ isLoading: true }));
  try {
    const res: AxiosResponse = yield call(getClinicIntermediateInfo, payload.clinicId);
    yield put(clinicActions.setClinicIntermediateInfo(res.data));
  } catch (err: any) {
    // pass
  }
  yield put(generalActions.setComponentStatus({ isLoading: false }));
}

function* watchUpdateClinicAsync({
  payload,
}: {
  payload: { clinicId: string; clinicInfo: Partial<ClinicInfo>; avatarFile?: File };
}) {
  yield put(generalActions.setComponentStatus({ isSubmitting: true }));
  try {
    let isEmailAlreadyExists = false;
    if (payload.clinicInfo.email) {
      const res: AxiosResponse = yield call(validateEmailOrDomain, payload.clinicInfo.email ?? '');
      isEmailAlreadyExists = res.data.email;

      yield put(generalActions.setComponentStatus({ isEmailAlreadyExists }));
    }

    if (!isEmailAlreadyExists) {
      const clinicInfo: ClinicInfo = yield select((state) => state.clinic.clinicInfo);
      let clinicAvatar = clinicInfo.avatar;
      if (payload.avatarFile) {
        const res: AxiosResponse = yield call(uploadImage, payload.avatarFile);
        clinicAvatar = res.data['fileName'];
      }
      yield call(updateClinic, payload.clinicId, { ...payload.clinicInfo, avatar: clinicAvatar });
      yield put(generalActions.clearComponentStatus());
      yield put(generalActions.setComponentStatus({ isEditClinicSuccessModalOpen: true }));

      yield put(clinicActions.setClinicInfo({ ...clinicInfo, ...payload.clinicInfo, avatar: clinicAvatar }));

      if (Object.keys(payload.clinicInfo).length) {
        yield put(generalActions.setComponentStatus({ isClinicPropertyChanged: true }));
      }
    }
  } catch (err: any) {
    // pass
  }
  yield put(generalActions.setComponentStatus({ isSubmitting: false }));
}

function* watchApproveClinicAttribute({ payload }: { payload: { notificationId: string } }) {
  yield put(
    notificationAction.setNotificationApproveBtnLoading({
      notificationId: payload.notificationId,
      loadingStatus: true,
    }),
  );
  try {
    yield call(approveIntermediateClinicInfo, payload.notificationId);
    yield put(
      notificationAction.setNotificationActionDisableStatus({
        notificationId: payload.notificationId,
        status: true,
      }),
    );
  } catch (err: any) {
    // pass
  }
  yield put(
    notificationAction.setNotificationApproveBtnLoading({
      notificationId: payload.notificationId,
      loadingStatus: false,
    }),
  );
}

function* watchRejectClinicAttribute({ payload }: { payload: { notificationId: string } }) {
  yield put(
    notificationAction.setNotificationRejectBtnLoading({
      notificationId: payload.notificationId,
      loadingStatus: true,
    }),
  );
  try {
    yield call(rejectIntermediateClinicInfo, payload.notificationId);
    yield put(
      notificationAction.setNotificationActionDisableStatus({
        notificationId: payload.notificationId,
        status: true,
      }),
    );
  } catch (err: any) {
    // pass
  }
  yield put(
    notificationAction.setNotificationRejectBtnLoading({
      notificationId: payload.notificationId,
      loadingStatus: false,
    }),
  );
}

function* watchDeleteClinicAdmin({ payload }: { payload: any }) {
  yield put(authActions.setComponentStatus({ isClinicAdminDeleting: true }));
  try {
    yield call(deleteClinicAdmin, payload.userId, payload.password);
    yield put(authActions.setComponentStatus({ isDeleteSuccess: true }));
  } catch (err: any) {
    if ([403, 401].includes(err.response.status)) {
      yield put(authActions.setComponentStatus({ isPasswordIncorrect: true }));
    }
    yield put(authActions.setComponentStatus({ error: err.response.data?.message }));
  }
  yield put(authActions.setComponentStatus({ isClinicAdminDeleting: false }));
  yield put(authActions.setComponentStatus({ shouldAdminListLoad: true }));
}

function* watchGetAllClinicNames({ payload }: { payload: { status: string | undefined } }) {
  try {
    const res: AxiosResponse = yield call(getAllClinicNames, payload.status);
    yield put(clinicActions.setClinicDropdownList(res.data));
  } catch (err: any) {
    yield put(clinicActions.setClinicDropdownList([]));
  }
}

export function* watchClinicSagas(): Generator<ForkEffect, void> {
  yield takeLatest(clinicActions.getClinicListAsync, watchGetClinicList);
  yield takeLatest(clinicActions.getClinicAdminByUserIdAsync, watchGetClinicAdminByUserId);
  yield takeLatest(clinicActions.deactivateClinicAsync, watchDeactivateClinic);
  yield takeLatest(clinicActions.reactivateClinicAsync, watchReactivateClinic);
  yield takeLatest(clinicActions.getClinicInfoAsync, watchGetClinicInfo);
  yield takeLatest(clinicActions.handleNextToAddClinicAdminTab, watchHandleNextToAddClinicAdminTab);
  yield takeLatest(clinicActions.handleNextToConfirmAddClinicTab, watchHandleNextToConfirmAddClinicTab);
  yield takeLatest(clinicActions.getClinicAdminListAsync, watchGetClinicAdminList);
  yield takeLatest(clinicActions.createClinicAsync, watchCreateClinicAsync);
  yield takeLatest(clinicActions.createClinicAdminAsync, watchCreateClinicAdmin);
  yield takeLatest(clinicActions.updateClinicAdminAsync, watchUpdateAdmin);
  yield takeLatest(clinicActions.nextToAddClinicAdminPermissionTab, watchNextToAddClinicAdminPermissionTab);
  yield takeLatest(clinicActions.getClinicIntermediateInfoAsync, watchGetClinicIntermediateInfo);
  yield takeLatest(clinicActions.updateClinicAsync, watchUpdateClinicAsync);
  yield takeLatest(clinicActions.approveClinicAttributeAsync, watchApproveClinicAttribute);
  yield takeLatest(clinicActions.rejectClinicAttributeAsync, watchRejectClinicAttribute);
  yield takeLatest(clinicActions.deleteCliniAdminAsync, watchDeleteClinicAdmin);
  yield takeLatest(clinicActions.getAllClinicNamesAsync, watchGetAllClinicNames);
}
const clinicSagas = watchClinicSagas;
export default clinicSagas;
