import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Divider, IconButton, Typography, useTheme } from '@mui/material';
import SolarPauseBoldSVG from '../../../assets/img/solar_pause-bold.svg';
import PlayBoldSVG from '../../../assets/img/play-bold.svg';
import CustomButtonLoadingButton from '../../../components/CustomButtonLoadingButton/CustomButtonLoadingButton';
import EQChart from '../../../components/EQChart/EQChart';
import * as _ from 'lodash';
import NoisePlayer from '../../../components/NoisePlayer/NoisePlayer';
import MiniEqChart from '../../../components/MiniEqChart/MiniEqChart';
import ConfirmEq from './ConfirmEq';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { generalActions } from '../../../redux/general/generalSlice';
import { authActions } from '../../../redux/auth/authSlice';
import { SIDEBAR_OPTIONS } from '../../../utils/sidebar-schema';
import { SidebarOption } from '../../../utils/enums/sidebar-option.enum';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { patientActions } from '../../../redux/patient/patientSlice';
import CircularProgress from '@mui/material/CircularProgress';
import CancelEqSetting from './CancelEqSetting';
import SuccessModal from './SuccessModal';
import { calculateTan } from '../../../utils/tan-utils';
import { EQDataType } from '../../../utils/enums/eq-data-type.enum';

interface SetEqProps {
  patientUserId?: string;
  OVERALL_CONFIG?: boolean;
}

function SetEq({ patientUserId, OVERALL_CONFIG = false }: SetEqProps) {
  const theme = useTheme();
  let { userId } = useParams();
  const dispatch = useAppDispatch();
  const [currentTab, setCurrentTab] = useState<
    | 'noise_threshold'
    | 'right_eq_threshold'
    | 'left_eq_threshold'
    | 'right_eq_mml'
    | 'left_eq_mml'
    | 'overall_noise_threshold'
    | 'overall_eq_threshold'
    | 'overall_eq_mml'
  >(OVERALL_CONFIG ? 'overall_noise_threshold' : 'noise_threshold');
  const [isEqPlay, setIsEqPlay] = useState(false);
  const [isPlayRightNoise, setIsPlayRightNoise] = useState(false);
  const [isPlayLeftNoise, setIsPlayLeftNoise] = useState(false);
  const [eqData, setEqData] = useState({
    rightNoiseThreshold: 50,
    leftNoiseThreshold: 50,
    rightEqThreshold: [0, 0, 0, 0, 0, 0],
    leftEqThreshold: [0, 0, 0, 0, 0, 0],
    rightEqMml: [0, 0, 0, 0, 0, 0],
    leftEqMml: [0, 0, 0, 0, 0, 0],
    rightEqThresholdStatus: [true, true, true, true, true, true],
    leftEqThresholdStatus: [true, true, true, true, true, true],
    rightEqMmlStatus: [true, true, true, true, true, true],
    leftEqMmlStatus: [true, true, true, true, true, true],
  });

  const patientsDetails = useAppSelector((state: RootState) => state.patient.patientsDetails);
  const initialEqValues = useAppSelector((state: RootState) => state.patient.eqData);

  const componentStatus = useAppSelector((state: RootState) => state.general.componentStatus);
  const isLoading = _.get(componentStatus, 'isLoading', false) as boolean;
  const isSubmitting = _.get(componentStatus, 'isSubmitting', false) as boolean;

  useEffect(() => {
    if (patientsDetails) {
      dispatch(
        authActions.setTopBarView({
          text: `${patientsDetails.firstName} ${patientsDetails.lastName}`,
          path: SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path,
          sideMenuPath: SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path,
          backButton: { path: `${SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path}/${userId}` },
          backButtonOnClick: () => {
            dispatch(generalActions.setComponentStatus({ openCancelEqSettingModal: true }));
          },
          customElement: (
            <Box display="flex" alignItems="center" justifyContent="start">
              <ArrowForwardIosIcon fontSize="small" color="primary" />
              <Typography marginLeft={1} component="h5" variant="h5" color={theme.palette.primary.main}>
                Set EQ
              </Typography>
            </Box>
          ),
        }),
      );
    }
  }, [patientsDetails]);

  useEffect(() => {
    if (patientUserId) {
      userId = patientUserId;
    }
    if (userId) {
      dispatch(patientActions.getSoundConfigurationAsync({ userId }));
    }
  }, []);

  useEffect(() => {
    setEqData(initialEqValues);
  }, [initialEqValues]);

  const handlePlayOnClick = () => {
    setIsEqPlay(!isEqPlay);
  };

  const handleBackOnClick = () => {
    if (OVERALL_CONFIG) {
      setIsPlayRightNoise(false);
      setIsPlayLeftNoise(false);
      setIsEqPlay(false);

      if (currentTab === 'overall_eq_threshold') {
        setCurrentTab('overall_noise_threshold');
      } else if (currentTab === 'overall_eq_mml') {
        setCurrentTab('overall_eq_threshold');
      }
    } else {
      setIsPlayRightNoise(false);
      setIsPlayLeftNoise(false);
      setIsEqPlay(false);

      if (currentTab === 'right_eq_threshold') {
        setCurrentTab('noise_threshold');
      } else if (currentTab === 'left_eq_threshold') {
        setCurrentTab('right_eq_threshold');
      } else if (currentTab === 'right_eq_mml') {
        setCurrentTab('left_eq_threshold');
      } else if (currentTab === 'left_eq_mml') {
        setCurrentTab('right_eq_mml');
      }
    }
  };

  const handleNextOnClick = () => {
    setIsPlayRightNoise(false);
    setIsPlayLeftNoise(false);
    setIsEqPlay(false);

    const rightEqTan = eqData.rightEqThreshold.map((rt, index) => calculateTan(rt, eqData.rightEqMml[index], eqData.rightNoiseThreshold));
    const leftEqTan = eqData.leftEqThreshold.map((lt, index) => calculateTan(lt, eqData.leftEqMml[index], eqData.leftNoiseThreshold));
    if (patientsDetails?.isSoundConfigSet !== undefined) {
      dispatch(
        patientActions.updateSoundConfigurationAsync({
          isSoundConfigSet: patientsDetails.isSoundConfigSet,
          userId: userId ?? '',
          eqData,
          tanRight: rightEqTan,
          tanLeft: leftEqTan,
          isCompleted: false,
        }),
      );
    }
    if (!isSubmitting) {
      switch (currentTab) {
        case 'overall_noise_threshold':
          const temp = _.cloneDeep(eqData);
          const overallThreshold = eqData.leftNoiseThreshold - 10;
          const newThreshold = eqData.leftEqThreshold.map(value => value < overallThreshold ? overallThreshold : value);
          temp.leftEqThreshold = newThreshold ;
          setEqData(temp)
          setCurrentTab(OVERALL_CONFIG ? 'overall_eq_threshold' : 'right_eq_threshold');
          break;
        case 'overall_eq_threshold':
          const currentData = _.cloneDeep(eqData);
          const newEqMmlThreshold = eqData.leftEqMml.map((value,index) => value < eqData.leftEqThreshold[index] ? eqData.leftEqThreshold[index] : value);
          currentData.leftEqMml = newEqMmlThreshold ; 
          setEqData(currentData)
          setCurrentTab(OVERALL_CONFIG ? 'overall_eq_mml' : 'left_eq_threshold');
          break;
        case 'overall_eq_mml':
        case 'left_eq_mml':
          dispatch(generalActions.setComponentStatus({ openConfirmEqModal: true }));
          break;
        case 'noise_threshold':
          setCurrentTab('right_eq_threshold');
          break;
        case 'right_eq_threshold':
          setCurrentTab('left_eq_threshold');
          break;
        case 'left_eq_threshold':
          setCurrentTab('right_eq_mml');
          break;
        case 'right_eq_mml':
          setCurrentTab('left_eq_mml');
          break;
        default:
          break;
      }
    }
  };

  const handleEqValueChangeCallback = (values: number[]) => {
    const temp = _.cloneDeep(eqData);
    if (currentTab === 'right_eq_threshold') {
      temp.rightEqThreshold = values;
    } else if (currentTab === 'left_eq_threshold') {
      temp.leftEqThreshold = values;
    } else if (currentTab === 'right_eq_mml') {
      temp.rightEqMml = values;
    } else if (currentTab === 'left_eq_mml') {
      temp.leftEqMml = values;
    } else if (currentTab === 'overall_eq_threshold') {
      temp.rightEqThreshold = values;
      temp.leftEqThreshold = values;
    } else if (currentTab === 'overall_eq_mml') {
      temp.rightEqMml = values;
      temp.leftEqMml = values;
    }
    setEqData(temp);
  };

  const handleEqValidStatusChangeCallback = (status: boolean[]) => {
    const temp = _.cloneDeep(eqData);
    if (currentTab === 'right_eq_threshold') {
      temp.rightEqThresholdStatus = status;
    } else if (currentTab === 'left_eq_threshold') {
      temp.leftEqThresholdStatus = status;
    } else if (currentTab === 'right_eq_mml') {
      temp.rightEqMmlStatus = status;
    } else if (currentTab === 'left_eq_mml') {
      temp.leftEqMmlStatus = status;
    } else if (currentTab === 'overall_eq_threshold') {
      temp.rightEqThresholdStatus = status;
      temp.leftEqThresholdStatus = status;
    } else if (currentTab === 'overall_eq_mml') {
      temp.rightEqMmlStatus = status;
      temp.leftEqMmlStatus = status;
    }
    setEqData(temp);
  };

  const handleNoiseThresholdChangeCallback = (value: number, side: 'right' | 'left') => {
    const temp = _.cloneDeep(eqData);
    if (OVERALL_CONFIG) {
      temp.rightNoiseThreshold = value;
      temp.leftNoiseThreshold = value;
    } else if (side === 'right') {
      temp.rightNoiseThreshold = value;
    } else if (side === 'left') {
      temp.leftNoiseThreshold = value;
    }
    setEqData(temp);
  };

  return (
    <Box display="flex">
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading || isSubmitting}>
        <CircularProgress color="primary" size={50} />
      </Backdrop>
      <Box
        sx={{
          backgroundColor: theme.palette.white.main,
          borderRadius: '20px',
          padding: '32px',
          boxShadow: '1',
          width: '890px',
        }}
      >
        <Box marginBottom={2} display="flex" justifyContent="space-between">
          {currentTab === 'noise_threshold' && (
            <Typography component="h5" variant="h5">
              1. Find overall white noise threshold
            </Typography>
          )}
          {currentTab === 'right_eq_threshold' && (
            <Typography component="h5" variant="h5">
              2.1 Narrow Band Frequency Threshold - Right Ear
            </Typography>
          )}
          {currentTab === 'left_eq_threshold' && (
            <Typography component="h5" variant="h5">
              2.2 Narrow Band Frequency Threshold - Left Ear
            </Typography>
          )}
          {currentTab === 'right_eq_mml' && (
            <Typography component="h5" variant="h5">
              3.1 Narrow Band Frequency Minimum Masking Level - Right Ear
            </Typography>
          )}
          {currentTab === 'left_eq_mml' && (
            <Typography component="h5" variant="h5">
              3.2 Narrow Band Frequency Minimum Masking Level - Left Ear
            </Typography>
          )}

          {currentTab === 'overall_noise_threshold' && (
            <Typography component="h5" variant="h5">
              1. Find overall white noise threshold
            </Typography>
          )}
          {currentTab === 'overall_eq_threshold' && (
            <Typography component="h5" variant="h5">
              2. Narrow Band Frequency Threshold
            </Typography>
          )}
          {currentTab === 'overall_eq_mml' && (
            <Typography component="h5" variant="h5">
              3. Narrow Band Frequency Minimum Masking Level
            </Typography>
          )}

          {currentTab !== 'noise_threshold' && currentTab !== 'overall_noise_threshold' && (
            <IconButton id="play-pause-btn-top" size="small" onClick={handlePlayOnClick}>
              <Box width={24} height={24} component="img" src={isEqPlay ? SolarPauseBoldSVG : PlayBoldSVG} />
            </IconButton>
          )}
        </Box>

        {currentTab === 'noise_threshold' && (
          <Box height={'40vh'}>
            <Box marginTop={4}>
              <NoisePlayer
                label="Right"
                pan="right"
                isPlay={isPlayRightNoise}
                value={eqData.rightNoiseThreshold}
                handleNoiseThresholdChange={(value) => handleNoiseThresholdChangeCallback(value, 'right')}
                handlePlay={() => {
                  setIsPlayLeftNoise(false);
                  setIsPlayRightNoise(!isPlayRightNoise);
                }}
              />
            </Box>
            <Box marginTop={4}>
              <NoisePlayer
                label="Left"
                pan="left"
                isPlay={isPlayLeftNoise}
                value={eqData.leftNoiseThreshold}
                handleNoiseThresholdChange={(value) => handleNoiseThresholdChangeCallback(value, 'left')}
                handlePlay={() => {
                  setIsPlayRightNoise(false);
                  setIsPlayLeftNoise(!isPlayLeftNoise);
                }}
              />
            </Box>
          </Box>
        )}

        {currentTab === 'overall_noise_threshold' && (
          <Box height={'40vh'}>
            <Box marginTop={4} paddingTop={4}>
              <NoisePlayer
                label=""
                pan="both"
                isPlay={isPlayLeftNoise}
                value={eqData.leftNoiseThreshold}
                handleNoiseThresholdChange={(value) => handleNoiseThresholdChangeCallback(value, 'left')}
                handlePlay={() => {
                  setIsPlayRightNoise(false);
                  setIsPlayLeftNoise(!isPlayLeftNoise);
                }}
              />
            </Box>
          </Box>
        )}

        {currentTab === 'right_eq_threshold' && (
          <EQChart
            isPlay={currentTab === 'right_eq_threshold' && isEqPlay}
            pan="right"
            width={768}
            height={'40vh'}
            data={eqData.rightEqThreshold}
            validStatus={eqData.rightEqThresholdStatus}
            lineColorPrimary={theme.palette.error.main}
            lineColorSecondary={theme.palette.shades.errorBorder}
            backgroundColor={theme.palette.shades.lightError}
            handleEqValueChange={handleEqValueChangeCallback}
            handleEqValidStatusChange={handleEqValidStatusChangeCallback}
            handleIsEqPlayChange={(isPlay) => setIsEqPlay(isPlay)}
          />
        )}
        {currentTab === 'left_eq_threshold' && (
          <EQChart
            isPlay={currentTab === 'left_eq_threshold' && isEqPlay}
            pan="left"
            width={768}
            height={'40vh'}
            data={eqData.leftEqThreshold}
            validStatus={eqData.leftEqThresholdStatus}
            lineColorPrimary={theme.palette.primary.main}
            lineColorSecondary={theme.palette.shades.purpleBorder}
            backgroundColor={theme.palette.secondary.main}
            handleEqValueChange={handleEqValueChangeCallback}
            handleEqValidStatusChange={handleEqValidStatusChangeCallback}
            handleIsEqPlayChange={(isPlay) => setIsEqPlay(isPlay)}
          />
        )}
        {currentTab === 'right_eq_mml' && (
          <EQChart
            isPlay={currentTab === 'right_eq_mml' && isEqPlay}
            pan="right"
            width={768}
            height={'40vh'}
            data={eqData.rightEqMml}
            validStatus={eqData.rightEqMmlStatus}
            lineColorPrimary={theme.palette.error.main}
            lineColorSecondary={theme.palette.shades.errorBorder}
            backgroundColor={theme.palette.shades.lightError}
            handleEqValueChange={handleEqValueChangeCallback}
            handleEqValidStatusChange={handleEqValidStatusChangeCallback}
            handleIsEqPlayChange={(isPlay) => setIsEqPlay(isPlay)}
          />
        )}
        {currentTab === 'left_eq_mml' && (
          <EQChart
            isPlay={currentTab === 'left_eq_mml' && isEqPlay}
            pan="left"
            width={768}
            height={'40vh'}
            data={eqData.leftEqMml}
            validStatus={eqData.leftEqMmlStatus}
            lineColorPrimary={theme.palette.primary.main}
            lineColorSecondary={theme.palette.shades.purpleBorder}
            backgroundColor={theme.palette.secondary.main}
            handleEqValueChange={handleEqValueChangeCallback}
            handleEqValidStatusChange={handleEqValidStatusChangeCallback}
            handleIsEqPlayChange={(isPlay) => setIsEqPlay(isPlay)}
          />
        )}
        {currentTab === 'overall_eq_threshold' && (
          <EQChart
            isPlay={currentTab === 'overall_eq_threshold' && isEqPlay}
            pan="both"
            width={768}
            height={'40vh'}
            data={eqData.leftEqThreshold}
            validStatus={eqData.leftEqThresholdStatus}
            lineColorPrimary={theme.palette.primary.main}
            lineColorSecondary={theme.palette.shades.purpleBorder}
            backgroundColor={theme.palette.secondary.main}
            handleEqValueChange={handleEqValueChangeCallback}
            handleEqValidStatusChange={handleEqValidStatusChangeCallback}
            handleIsEqPlayChange={(isPlay) => setIsEqPlay(isPlay)}
            noiseThreshold={eqData.leftNoiseThreshold}
          />
        )}
        {currentTab === 'overall_eq_mml' && (
          <EQChart
            isPlay={currentTab === 'overall_eq_mml' && isEqPlay}
            pan="both"
            width={768}
            height={'40vh'}
            data={eqData.leftEqMml}
            validStatus={eqData.leftEqMmlStatus}
            lineColorPrimary={theme.palette.primary.main}
            lineColorSecondary={theme.palette.shades.purpleBorder}
            backgroundColor={theme.palette.secondary.main}
            handleEqValueChange={handleEqValueChangeCallback}
            handleEqValidStatusChange={handleEqValidStatusChangeCallback}
            handleIsEqPlayChange={(isPlay) => setIsEqPlay(isPlay)}
            noiseThreshold={eqData.leftNoiseThreshold}
            eqThresholdValues={eqData.leftEqThreshold}
            dataType={EQDataType.OVERALL_EQ_MML}
          />
        )}

        <Box
          display="flex"
          justifyContent={
            ['noise_threshold', 'overall_noise_threshold'].includes(currentTab) ? 'flex-end' : 'space-between'
          }
          marginTop={8}
          sx={{
            paddingTop: '20px',
            borderTop: `1px solid ${theme.palette.shades.purpleBorder}`,
          }}
        >
          {currentTab !== 'noise_threshold' && currentTab !== 'overall_noise_threshold' && (
            <CustomButtonLoadingButton
              disabled={false}
              loading={false}
              text={'Back'}
              variant="outlined"
              color="primary"
              fullWidth={false}
              width="95px"
              onClick={handleBackOnClick}
            />
          )}
          <CustomButtonLoadingButton
            disabled={false}
            loading={false}
            text={['left_eq_mml', 'overall_eq_mml'].includes(currentTab) ? 'Save' : 'Next'}
            variant="contained"
            color="primary"
            fullWidth={false}
            width="95px"
            onClick={handleNextOnClick}
          />
        </Box>
      </Box>

      {currentTab !== 'noise_threshold' && currentTab !== 'overall_noise_threshold' && (
        <Box
          marginLeft={2}
          height="fit-content"
          sx={{
            backgroundColor: theme.palette.white.main,
            borderRadius: '20px',
            padding: '28px',
            boxShadow: '1',
            width: 266,
          }}
        >
          {['right_eq_threshold', 'left_eq_threshold', 'right_eq_mml', 'left_eq_mml'].includes(currentTab) && (
            <Box>
              <Typography gutterBottom component="p" variant="bodyBold">
                Overall Threshold
              </Typography>
              <Box marginTop={1} display="flex" justifyContent="space-between">
                <Typography component="p" variant="bodyRegular">
                  Right
                </Typography>
                <Typography component="p" variant="bodyRegular">
                  {eqData.rightNoiseThreshold}
                </Typography>
              </Box>
              <Box marginTop={1} display="flex" justifyContent="space-between">
                <Typography component="p" variant="bodyRegular">
                  Left
                </Typography>
                <Typography component="p" variant="bodyRegular">
                  {eqData.leftNoiseThreshold}
                </Typography>
              </Box>
            </Box>
          )}

          {['left_eq_threshold', 'right_eq_mml', 'left_eq_mml'].includes(currentTab) && (
            <Divider sx={{ marginY: 2, backgroundColor: theme.palette.shades.purpleBorder }} />
          )}

          {['left_eq_threshold', 'right_eq_mml', 'left_eq_mml'].includes(currentTab) && (
            <Typography component="p" variant="bodyBold">
              Threshold
            </Typography>
          )}

          {['left_eq_threshold', 'right_eq_mml', 'left_eq_mml'].includes(currentTab) && (
            <Box marginTop={2}>
              <MiniEqChart
                data={eqData.rightEqThreshold}
                label="Right"
                lineColorPrimary={theme.palette.error.main}
                lineColorSecondary={theme.palette.shades.errorBorder}
                backgroundColor={theme.palette.shades.lightError}
              />
            </Box>
          )}

          {['right_eq_mml', 'left_eq_mml'].includes(currentTab) && (
            <Box marginTop={2}>
              <MiniEqChart
                data={eqData.leftEqThreshold}
                label="Left"
                lineColorPrimary={theme.palette.primary.main}
                lineColorSecondary={theme.palette.shades.purpleBorder}
                backgroundColor={theme.palette.secondary.main}
              />
            </Box>
          )}

          {currentTab === 'left_eq_mml' && (
            <Divider sx={{ marginY: 2, backgroundColor: theme.palette.shades.purpleBorder }} />
          )}

          {currentTab === 'left_eq_mml' && (
            <Typography component="p" variant="bodyBold">
              Minimum Masking Level
            </Typography>
          )}
          {currentTab === 'left_eq_mml' && (
            <Box marginTop={2}>
              <MiniEqChart
                data={eqData.rightEqMml}
                label="Right"
                lineColorPrimary={theme.palette.error.main}
                lineColorSecondary={theme.palette.shades.errorBorder}
                backgroundColor={theme.palette.shades.lightError}
              />
            </Box>
          )}

          {['overall_eq_threshold', 'overall_eq_mml'].includes(currentTab) && (
            <Box display="flex" justifyContent="space-between">
              <Typography gutterBottom component="p" variant="bodyBold">
                Overall Threshold
              </Typography>
              <Typography component="p" variant="bodyRegular">
                {eqData.leftNoiseThreshold}
              </Typography>
            </Box>
          )}
          {currentTab === 'overall_eq_mml' && (
            <>
              <Divider sx={{ marginY: 2, backgroundColor: theme.palette.shades.purpleBorder }} />
              <Typography component="p" variant="bodyBold">
                Narrow Band Frequency Threshold
              </Typography>
              <MiniEqChart
                data={eqData.leftEqThreshold}
                label=""
                lineColorPrimary={theme.palette.primary.main}
                lineColorSecondary={theme.palette.shades.purpleBorder}
                backgroundColor={theme.palette.secondary.main}
              />
            </>
          )}
        </Box>
      )}

      <ConfirmEq
        {...eqData}
        patientUserId={userId ?? ''}
        isSoundConfigSet={!!patientsDetails?.isSoundConfigSet}
        overallEQ={OVERALL_CONFIG}
      />

      <CancelEqSetting patientUserId={userId ?? ''} />

      <SuccessModal patientUserId={userId ?? ''} />
    </Box>
  );
}

export default SetEq;
