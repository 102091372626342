export enum SidebarOption {
  DASHBOARD = 'Dashboard',
  MANAGE_ADMINS = 'Manage Admins',
  CLINICS = 'Clinics',
  CLINICIANS = 'Clinicians',
  PATIENTS = 'Patients',
  SOUND_LIBRARY = 'Sound Library',
  LEARNING_LIBRARY = 'Learning Library',
  MY_PATIENTS = 'My Patients',
  CLINIC_PATIENTS = 'Clinic Patients',
}
