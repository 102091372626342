import React, { Fragment, useEffect } from 'react';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { Autocomplete, Box, Grid, Stack, TextField, Typography, useTheme } from '@mui/material';
import CloseCircleBoldSVG from '../../../assets/img/close-circle-bold.svg';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';
import { generalActions } from '../../../redux/general/generalSlice';
import CustomButtonLoadingButton from '../../../components/CustomButtonLoadingButton/CustomButtonLoadingButton';
import CustomInputBox from '../../../components/CustomInputBox/CustomInputBox';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { clinicianActions } from '../../../redux/clinician/clinicianSlice';
import * as dateFns from 'date-fns';
import CustomDatepicker from '../../../components/CustomeDatepicker/CustomeDatepicker';
import { validation } from '../../../schema/validation';
import { subYears } from 'date-fns';

function AddClinician() {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const componentStatus = useAppSelector((state: RootState) => state.general.componentStatus);
  const isAddClinicianModalOpen = _.get(componentStatus, 'isAddClinicianModalOpen', false) as boolean;
  const countryList = useAppSelector((state: RootState) => state.general.countryList);
  const stateList = useAppSelector((state: RootState) => state.general.stateList);
  const clinicId = useAppSelector((state: RootState) => state.user.clinicId);
  const isSubmitting = _.get(componentStatus, 'isSubmitting', false) as boolean;
  const isEmailAlreadyExists = _.get(componentStatus, 'isEmailAlreadyExists', false);

  const handleAddClinicianModalClose = () => {
    dispatch(generalActions.clearComponentStatus());
    dispatch(clinicianActions.clearAddClinicianData());
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      surname: '',
      birthday: null,
      phone: '',
      email: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      firstName: validation.firstName,
      lastName: validation.lastName,
      surname: validation.surname,
      birthday: yup.date().max(new Date(), 'Birthday must be less than today').optional().nullable(),
      email: validation.email,
      phone: yup.string().matches(/^\+?\d{6,14}$/, { message: 'Invalid phone number' }),
      addressLine1: yup.string().max(150, 'Address line 1 should not contain more than 150 characters').optional(),
      addressLine2: yup.string().max(150, 'Address line 2 should not contain more than 150 characters').optional(),
      city: yup.string().max(30, 'City should not contain more than 30 characters'),
      state: yup.string(),
      country: yup.string(),
      zipCode: yup.string().max(30).matches(/^\d+$/, { message: 'Invalid zip code' }),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      dispatch(generalActions.setComponentStatus({ isEmailAlreadyExists: false }));

      if (values.country) {
        const countryId = _.find(countryList, { country: values.country })?.countryId;
        _.set(values, 'countryId', countryId);
      }
      if (values.state) {
        const stateId = _.find(stateList, { state: values.state })?.stateId;
        _.set(values, 'stateId', stateId);
      }
      dispatch(
        clinicianActions.createClinicianAsync({ ..._.omit(values, ['state', 'country']), clinicId: clinicId ?? '' }),
      );
    },
  });

  useEffect(() => {
    if (isEmailAlreadyExists) {
      formik.setFieldTouched('email', true);
      formik.setFieldError('email', 'Email address already exists');
    }
  }, [isEmailAlreadyExists]);

  useEffect(() => {
    if (isAddClinicianModalOpen) {
      dispatch(generalActions.getCountryListAsync());
    } else {
      formik.resetForm();
    }
  }, [isAddClinicianModalOpen]);

  useEffect(() => {
    if (formik.values.country) {
      dispatch(generalActions.getStateListAsync({ country: formik.values.country }));
      formik.setFieldValue('state', '');
    }
  }, [formik.values.country]);

  return (
    <CustomModal
      modal={true}
      open={isAddClinicianModalOpen}
      width="900px"
      minHeight="650px"
      height="auto"
      title={'Add New Clinician'}
      align="left"
    >
      <Stack direction="column" alignItems="left" justifyContent="center">
        <Box
          component="img"
          src={CloseCircleBoldSVG}
          onClick={handleAddClinicianModalClose}
          sx={{ position: 'absolute', bottom: '75%', left: '93%', top: '6%', width: '36px', cursor: 'pointer' }}
        />
      </Stack>
      <Fragment>
        <Box marginTop={2}>
          <AvatarEdit
            avatar=""
            editable={false}
            name={formik.values.firstName}
            name2={formik.values.lastName}
            font={theme.typography.h2}
            size="100px"
          />
        </Box>

        <Grid container marginTop={1} rowSpacing={2} columnSpacing={1}>
          <Grid item xs={6}>
            <CustomInputBox
              label="First Name"
              required={true}
              type="text"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomInputBox
              label="Last Name"
              required={true}
              type="text"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomInputBox
              label="Middle Name"
              required={false}
              type="text"
              name="surname"
              value={formik.values.surname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.surname && Boolean(formik.errors.surname)}
              helperText={formik.touched.surname && formik.errors.surname}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomDatepicker
              label="Birth Day"
              required={false}
              name="birthday"
              value={formik.values.birthday ? formik.values.birthday : null}
              error={formik.touched.birthday && Boolean(formik.errors.birthday)}
              helperText={formik.touched.birthday && formik.errors.birthday}
              onBlur={formik.handleBlur}
              maxDate={subYears(new Date(), 18)}
              onChange={(value) => {
                formik.setFieldValue('birthday', value ? dateFns.format(new Date(value), 'yyyy-MM-dd') : null);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomInputBox
              label="Phone Number"
              required={false}
              type="text"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomInputBox
              label="Email Address"
              required={true}
              type="text"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>

          <Grid item xs={6}>
            <CustomInputBox
              label="Address Line 1"
              required={false}
              type="text"
              name="addressLine1"
              value={formik.values.addressLine1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)}
              helperText={formik.touched.addressLine1 && formik.errors.addressLine1}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomInputBox
              label="Address Line 2"
              required={false}
              type="text"
              name="addressLine2"
              value={formik.values.addressLine2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.addressLine2 && Boolean(formik.errors.addressLine2)}
              helperText={formik.touched.addressLine2 && formik.errors.addressLine2}
            />
          </Grid>

          <Grid item xs={3}>
            <CustomInputBox
              label="City"
              required={false}
              type="text"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" flexDirection="column" gap="2px">
              <Typography variant="meta" component="p" align="left">
                State/ Province
              </Typography>
              <Autocomplete
                value={formik.values.state}
                onChange={(e, value) => formik.setFieldValue('state', value)}
                onBlur={formik.handleBlur}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
                options={stateList.map((e) => e.state)}
                disabled={!formik.values.country}
                sx={{
                  '& .MuiInputBase-root': {
                    alignContent: 'center',
                    height: '38px',
                    borderRadius: '10px',
                    backgroundColor: formik.values.country ? 'initial' : theme.palette.shades.disabled,
                  },
                }}
              />
              {formik.touched.state && Boolean(formik.errors.state) && (
                <Typography paddingLeft={2} variant="meta" component="p" color="error">
                  {formik.touched.state && formik.errors.state}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <CustomInputBox
              label="Country"
              required={false}
              type="text"
              select={true}
              name="country"
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={formik.touched.country && formik.errors.country}
              selectOptions={countryList.map((e) => ({
                key: e.country,
                value: e.country,
              }))}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomInputBox
              label="Zip Code"
              required={false}
              type="text"
              name="zipCode"
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
              helperText={formik.touched.zipCode && formik.errors.zipCode}
            />
          </Grid>
        </Grid>
        <Box
          display="flex"
          justifyContent="right"
          marginTop={4}
          sx={{
            paddingTop: '20px',
            borderTop: `1px solid ${theme.palette.shades.purpleBorder}`,
          }}
        >
          <CustomButtonLoadingButton
            loading={isSubmitting}
            text="Add Clinician"
            variant="contained"
            color="primary"
            fullWidth={false}
            width="150px"
            onClick={() => formik.handleSubmit()}
          />
        </Box>
      </Fragment>
    </CustomModal>
  );
}

export default AddClinician;
