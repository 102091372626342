import React from 'react';
import CustomModal from '../../../components/CustomModal/CustomModal';
import RejectIcon from '../../../assets/img/close-circle-bold-duotone.svg';
import AcceptIcon from '../../../assets/img/check-circle-bold-duotone.svg';
import PendingIcon from '../../../assets/img/solar_bell-bing-bold-duotone.svg';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { theme } from '../../../theme/theme';
import { LoadingButton } from '@mui/lab';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { NotificationStatus } from '../../../utils/enums/notification-status.enum';
import { notificationAction } from '../../../redux/notification/notificationSlice';
import { clinicActions } from '../../../redux/clinic/clinicSlice';
import _ from 'lodash';

interface NotificationViewProps {
  open: boolean;
}

function NotificationView(props: NotificationViewProps) {
  const dispatch = useAppDispatch();
  const notification = useAppSelector((state: RootState) => state.notification.selectedNotification);
  const componentStatus = useAppSelector((state: RootState) => state.notification.componentStatus);
  const approveBtnLoading = _.get(componentStatus, 'notificationApproveBtnLoading', false) as boolean;
  const rejectBtnLoading = _.get(componentStatus, 'notificationRejectBtnLoading', false) as boolean;

  const handleDismiss = () => {
    dispatch(notificationAction.setComponentStatus({ isNotificationModalOpen: false }));
  };

  const handleApprove = () => {
    dispatch(clinicActions.approveClinicAttributeAsync({ notificationId: notification.notificationId }));
  };

  const handleReject = () => {
    dispatch(clinicActions.rejectClinicAttributeAsync({ notificationId: notification.notificationId }));
  };

  const getNotificationIcon = (status: NotificationStatus) => {
    if (status === NotificationStatus.REJECT) {
      return RejectIcon;
    } else if (status === NotificationStatus.APPROVE) {
      return AcceptIcon;
    } else {
      return PendingIcon;
    }
  };

  return (
    <CustomModal modal={true} open={props.open} width="374px" icon={getNotificationIcon(notification.status)}>
      <Stack direction="column" alignItems="center">
        <Grid container justifyContent="center">
          <Box marginBottom={4}>
            <Typography component="p" variant="h6" align="center">
              {_.upperFirst(`${notification.createdUser} ${notification.description}`)}
            </Typography>
            {(notification.status === NotificationStatus.REJECT ||
              notification.status === NotificationStatus.APPROVE) && (
              <Typography component="p" variant="h5" align="center">
                {notification.status === NotificationStatus.REJECT ? 'Rejected' : 'Approved'}
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid container alignItems={'center'} flexDirection={'column'}>
          <AvatarEdit
            editable={false}
            avatar={notification.ClinicImage}
            size="72px"
            alt="notification image"
            font={theme.typography.subtitle1}
          />
          <Typography mt={0.5} component="p" variant="h6" align="center">
            {notification.clinicName}
          </Typography>
          <Typography mt={0.5} component="p" variant="bodyRegular" align="center" color={theme.palette.info.main}>
            {notification.clinicEmailAddress}
          </Typography>
        </Grid>

        <Grid item mb={2} mt={4}>
          <Typography mt={0.5} mb={1} component="p" variant="meta" align="center">
            Requestor Name
          </Typography>
          <Box display="flex" gap={1}>
            <AvatarEdit
              editable={false}
              avatar={notification.createdUserAvatar}
              size="24px"
              font={theme.typography.subtitle1}
            />
            <Typography
              variant="bodyBold"
              color={theme.palette.primary.main}
            >{`${notification.createdUser} (${notification.createdUserRole})`}</Typography>
          </Box>
        </Grid>

        {notification.status === NotificationStatus.PENDING ? (
          <Grid container justifyContent={'space-between'} mt={2}>
            <LoadingButton
              variant="outlined"
              sx={{ borderRadius: '10px', height: '43px', width: '90px' }}
              onClick={handleDismiss}
            >
              Dismiss
            </LoadingButton>
            <LoadingButton
              variant="contained"
              sx={{ borderRadius: '10px', height: '43px', width: '90px' }}
              onClick={handleReject}
              color="error"
              disabled={approveBtnLoading}
              loading={rejectBtnLoading}
            >
              Reject
            </LoadingButton>
            <LoadingButton
              variant="contained"
              sx={{ borderRadius: '10px', height: '43px', width: '90px' }}
              onClick={handleApprove}
              disabled={rejectBtnLoading}
              loading={approveBtnLoading}
            >
              Approve
            </LoadingButton>
          </Grid>
        ) : (
          <Grid container justifyContent="center">
            <Grid item mb={4}>
              <Typography mt={0.5} mb={1} component="p" variant="meta" align="center">
                {`${notification.status === NotificationStatus.APPROVE ? 'Approved' : 'Rejected'} By`}
              </Typography>
              <Box display="flex" gap={1}>
                <AvatarEdit
                  editable={false}
                  avatar={notification.respondedUserAvatar}
                  size="24px"
                  font={theme.typography.subtitle1}
                />
                <Typography variant="bodyBold" color={theme.palette.primary.main}>
                  {notification.respondedUser}
                </Typography>
              </Box>
            </Grid>

            <LoadingButton
              variant="contained"
              sx={{ borderRadius: '10px', height: '43px' }}
              onClick={handleDismiss}
              fullWidth
            >
              Dismiss
            </LoadingButton>
          </Grid>
        )}
      </Stack>
    </CustomModal>
  );
}

export default NotificationView;
