import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { authActions } from '../../redux/auth/authSlice';
import { SIDEBAR_OPTIONS } from '../../utils/sidebar-schema';
import { SidebarOption } from '../../utils/enums/sidebar-option.enum';
import CustomTable, { ColumnProps } from '../../components/CustomTable/CustomTable';
import { Box, Button, Grid, IconButton, InputAdornment, Stack, TextField, Theme, useTheme } from '@mui/material';
import MagniferOutlineSVG from '../../assets/img/magnifer-outline.svg';
import { Roles } from '../../utils/enums/roles.enum';
import { useAuth } from '../../context/AuthContext';
import { RootState } from '../../redux/store';
import _ from 'lodash';
import * as dateFns from 'date-fns';
import { Permission } from '../../utils/enums/permission.enum';
import { Sound, soundActions } from '../../redux/sound/soundSlice';
import TrashBinBoldSVG from '../../assets/img/trash-bin-2-bold.svg';
import { styled as materialStyled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SoundPlayer from '../../components/SoundPlayer/SoundPlayer';
import AddSound from './Add/AddSound';
import DeleteSound from './Delete/DeleteSound';
import GalleryBoldSVG from '../../assets/img/gallery-minimalistic-bold.svg';
import ViewAlbumArt from './ViewAlbumArt/ViewAlbumArt';
import { SortOrder } from '../../utils/enums/sort-order.enum';
import { SoundType } from '../../utils/enums/sound-type.enum';
import { TimeConversionVariant } from '../../utils/enums/time-conversion-variant.enum';

export const CustomButton = materialStyled(Button)(({ theme, isselected }: { theme: Theme; isselected: string }) => ({
  ...theme.typography.bodyBold,
  gap: '10px',
  width: '100%',
  height: '46px',
  margin: '4px',
  justifyContent: 'center',
  padding: '12px',
  borderRadius: '15px',
  textTransform: 'none',
  backgroundColor: isselected === 'true' ? theme.palette.secondary.main : 'inherit',
  color: isselected === 'true' ? theme.palette.primary.main : theme.palette.info.main,
}));

export const getFormattedTimeInMinutes = (duration: number, variant = TimeConversionVariant.NORMAL) => {
  const currentTime = dateFns.intervalToDuration({ start: 0, end: duration });
  const minuteSymbol = variant === TimeConversionVariant.NORMAL ? 'mins' : 'Mins';
  return `${String(currentTime.minutes).padStart(2, '0')}:${String(currentTime.seconds).padStart(2, '0')} ${String(
    minuteSymbol,
  ).padStart(2, '0')}`;
};

function SoundLibrary() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { user } = useAuth();
  const searchBtn = useRef<HTMLButtonElement>(null);

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortBy, setSortBy] = useState<string>('title');
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.DESC);
  const [soundType, setSoundType] = useState<SoundType>(SoundType.RELIEF);
  const [searchText, setSearchText] = useState<string>('');

  const totalSoundCount = useAppSelector((state: RootState) => state.sound.totalSoundCount);
  const soundList = useAppSelector((state: RootState) => state.sound.soundList);
  const componentStatusSound = useAppSelector((state: RootState) => state.sound.componentStatus);
  const isAddSoundOpen = _.get(componentStatusSound, 'isAddSoundPopupOpen', false) as boolean;
  const componentStatus = useAppSelector((state: RootState) => state.sound.componentStatus);
  const isSoundListListLoading = _.get(componentStatus, 'isSoundListListLoading', false) as boolean;
  const isSoundDeleteModalOpen = _.get(componentStatus, 'isSoundDeleteModalOpen', false) as boolean;
  const isSoundListRefreshed = _.get(componentStatus, 'isSoundListRefreshed', false) as boolean;
  const isViewAlbumArtModelOpen = _.get(componentStatus, 'isViewAlbumArtModelOpen', false) as boolean;

  useEffect(() => {
    dispatch(
      authActions.setTopBarView({
        text: SIDEBAR_OPTIONS[SidebarOption.SOUND_LIBRARY].name,
        path: SIDEBAR_OPTIONS[SidebarOption.SOUND_LIBRARY].path,
        sideMenuPath: SIDEBAR_OPTIONS[SidebarOption.SOUND_LIBRARY].path,
        button:
          user.role === Roles.SUPER_ADMIN ||
          (user.role === Roles.TRUE_SILENCE_ADMIN &&
            user.permissions.includes(Permission.TRUE_SILENCE_ADMIN_MANAGE_SOUND_LIBRARY))
            ? {
                text: 'Add New Sound',
                icon: <AddCircleOutlineIcon fontSize="small" />,
                color: 'primary',
                onClick: () => {
                  dispatch(soundActions.clearComponentStatus());
                  dispatch(soundActions.setComponentStatus({ isAddSoundPopupOpen: true }));
                },
              }
            : undefined,
      }),
    );
  }, [user]);

  const enterKeyDownHandler = (event: { key: string; preventDefault: () => void }) => {
    if (event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (searchBtn.current) {
          searchBtn.current.click();
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', enterKeyDownHandler);
    return () => {
      document.removeEventListener('keydown', enterKeyDownHandler);
    };
  }, []);

  useEffect(() => {
    const offset = (page - 1) * rowsPerPage;
    dispatch(soundActions.getSoundListAsync({ offset, limit: rowsPerPage, sortBy, sortOrder, searchText, soundType }));
  }, [page, rowsPerPage, sortBy, sortOrder, soundType]);

  useEffect(() => {
    if (isSoundListRefreshed) {
      if (page === 1) {
        dispatch(
          soundActions.getSoundListAsync({ offset: 0, limit: rowsPerPage, sortBy, sortOrder, searchText, soundType }),
        );
      } else {
        setPage(1);
      }
    }
  }, [isSoundListRefreshed]);

  const handleSoundTypeChange = (type: SoundType) => {
    setSoundType(type);

    const offset = (page - 1) * rowsPerPage;
    dispatch(
      soundActions.getSoundListAsync({ offset, limit: rowsPerPage, sortBy, sortOrder, searchText, soundType: type }),
    );
  };

  const handleSearchOnClick = () => {
    const offset = (page - 1) * rowsPerPage;
    dispatch(soundActions.getSoundListAsync({ offset, limit: rowsPerPage, sortBy, sortOrder, searchText, soundType }));
  };

  const getColumns = (): ColumnProps[] => {
    return [
      { key: 'title', name: 'Title', enableSort: true, align: 'left', width: 'auto', minWidth: 160, resizable: true },
      {
        key: 'duration',
        name: 'Duration',
        enableSort: true,
        align: 'left',
        width: 'auto',
        minWidth: 80,
        resizable: true,
      },
      {
        key: 'createdAt',
        name: 'Upload Date',
        enableSort: true,
        align: 'left',
        width: 'auto',
        minWidth: 150,
        resizable: true,
      },
      { key: 'action', name: '', enableSort: false, align: 'right', width: '25%', minWidth: 100, resizable: false },
    ];
  };

  const handleDeleteSound = (soundId: string) => {
    dispatch(soundActions.clearComponentStatus());
    dispatch(soundActions.setComponentStatus({ isSoundDeleteModalOpen: true, selectedSoundId: soundId }));
    dispatch(soundActions.getDeleteSoundAsync(soundId));
  };

  const handleViewAlbumArt = ({ title, duration, albumArt }: Sound) => {
    dispatch(soundActions.clearComponentStatus());
    dispatch(
      soundActions.setComponentStatus({
        isViewAlbumArtModelOpen: true,
        selectedSoundTilte: title,
        selectedSoundDuration: getFormattedTimeInMinutes(duration),
        selectedSoundAlbumArt: albumArt,
      }),
    );
  };

  const getFormattedClinicianList = (sound: Sound[]) => {
    return sound.map((sound, index) => {
      return {
        title: sound.title,
        duration: sound.duration !== undefined ? getFormattedTimeInMinutes(sound.duration) : '',
        createdAt: sound.createdAt ? dateFns.format(new Date(sound.createdAt), 'MM/dd/yyyy') : '',
        action: (
          <Stack key={sound.soundId} direction="row" justifyContent="center" spacing={1}>
            <SoundPlayer soundId={sound.soundId ?? ''} src={sound.name ?? ''} sound={sound} />
            <IconButton
              id={`view-album-art-btn-row-${index}`}
              size="small"
              onClick={() => {
                handleViewAlbumArt(sound);
              }}
            >
              <Box component="img" src={GalleryBoldSVG} />
            </IconButton>
            {(user.role === Roles.SUPER_ADMIN ||
              (user.role === Roles.TRUE_SILENCE_ADMIN &&
                user.permissions.includes(Permission.TRUE_SILENCE_ADMIN_MANAGE_SOUND_LIBRARY))) && (
              <IconButton
                id={`delete-btn-row-${index}`}
                size="small"
                onClick={() => {
                  handleDeleteSound(sound.soundId as string);
                }}
              >
                <Box component="img" src={TrashBinBoldSVG} />
              </IconButton>
            )}
          </Stack>
        ),
      };
    });
  };

  return (
    <Fragment>
      <CustomTable
        isLoading={isSoundListListLoading}
        totalDataRows={totalSoundCount}
        enablePagination={true}
        selectedPage={page}
        enablePagePerRows={true}
        rowsPerList={[10, 20, 50, 100]}
        selectedRowsPerPage={rowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        columns={getColumns()}
        rows={getFormattedClinicianList(soundList)}
        handleChangePage={(newPage) => setPage(newPage)}
        handleChangeRowsPerPage={(rowsPerPage) => {
          setRowsPerPage(rowsPerPage);
          setPage(1);
        }}
        handleSortByChange={setSortBy}
        handleSortOrderChange={setSortOrder}
        minBodyRowSpace={10}
        customTableTop={
          <Box>
            <Box
              marginBottom={2}
              padding={1}
              sx={{
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderColor: theme.palette.shades.purpleBorder,
              }}
            >
              <Box display="flex" width="50%">
                <CustomButton
                  theme={theme}
                  isselected={`${soundType === SoundType.RELIEF}`}
                  onClick={() => {
                    handleSoundTypeChange(SoundType.RELIEF);
                  }}
                >
                  Relief Audio
                </CustomButton>
                <CustomButton
                  theme={theme}
                  isselected={`${soundType === SoundType.RELAX}`}
                  onClick={() => {
                    handleSoundTypeChange(SoundType.RELAX);
                  }}
                >
                  Relax Audio
                </CustomButton>
                <CustomButton
                  theme={theme}
                  isselected={`${soundType === SoundType.RETRAIN}`}
                  onClick={() => {
                    handleSoundTypeChange(SoundType.RETRAIN);
                  }}
                >
                  Retrain Audio
                </CustomButton>
              </Box>
            </Box>
            <form>
              <Grid container marginBottom={2} paddingX={2} rowSpacing={1.5} columnSpacing={1}>
                <Grid item xs={11}>
                  <TextField
                    id="search-input"
                    fullWidth
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                    placeholder="Search by Title"
                    sx={{ '& .MuiInputBase-root': { height: '38px', borderRadius: '10px' } }}
                    inputProps={{ maxLength: 200 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Box component="img" src={MagniferOutlineSVG} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button
                    ref={searchBtn}
                    id="search-btn"
                    fullWidth
                    variant="contained"
                    sx={{ borderRadius: '10px', height: '38px' }}
                    onClick={handleSearchOnClick}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        }
      />

      <AddSound isAddOpen={isAddSoundOpen} />
      <DeleteSound open={isSoundDeleteModalOpen} />
      <ViewAlbumArt isOpen={isViewAlbumArtModelOpen} />
    </Fragment>
  );
}

export default SoundLibrary;
