import React from 'react';
import CustomModal from '../../../components/CustomModal/CustomModal';
import GreenCheckCircleSVG from '../../../assets/img/check-circle-bold-duotone.svg';
import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import ConfirmEqChart from '../../../components/ConfirmEqChart/ConfirmEqChart';
import CustomButtonLoadingButton from '../../../components/CustomButtonLoadingButton/CustomButtonLoadingButton';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';
import { generalActions } from '../../../redux/general/generalSlice';
import { patientActions } from '../../../redux/patient/patientSlice';
import { calculateTan } from '../../../utils/tan-utils';
import EqSimulate from './EqSimulate';

interface ConfirmEqProps {
  isSoundConfigSet: boolean;
  patientUserId: string;
  rightNoiseThreshold: number;
  leftNoiseThreshold: number;
  rightEqThreshold: number[];
  leftEqThreshold: number[];
  rightEqMml: number[];
  leftEqMml: number[];
  rightEqThresholdStatus: boolean[];
  leftEqThresholdStatus: boolean[];
  rightEqMmlStatus: boolean[];
  leftEqMmlStatus: boolean[];
  overallEQ?: boolean;
}

function ConfirmEq(props: ConfirmEqProps) {
  const overallEQ = props.overallEQ ?? false;

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const rightEqTan = props.rightEqThreshold.map((rt, index) => calculateTan(rt, props.rightEqMml[index], props.rightNoiseThreshold));
  const leftEqTan = props.leftEqThreshold.map((lt, index) => calculateTan(lt, props.leftEqMml[index], props.leftNoiseThreshold));

  const componentStatus = useAppSelector((state: RootState) => state.general.componentStatus);
  const openConfirmEqModal = _.get(componentStatus, 'openConfirmEqModal', false) as boolean;
  const isSubmitting = _.get(componentStatus, 'isSubmitting', false) as boolean;

  const handleBackOnClick = () => {
    dispatch(generalActions.setComponentStatus({ openConfirmEqModal: false }));
  };

  const handleSaveOnClick = () => {
    const {
      rightNoiseThreshold,
      leftNoiseThreshold,
      rightEqThreshold,
      leftEqThreshold,
      rightEqMml,
      leftEqMml,
      rightEqThresholdStatus,
      leftEqThresholdStatus,
      rightEqMmlStatus,
      leftEqMmlStatus,
    } = props;
    dispatch(
      patientActions.updateSoundConfigurationAsync({
        isSoundConfigSet: true,
        userId: props.patientUserId,
        eqData: {
          rightNoiseThreshold,
          leftNoiseThreshold,
          rightEqThreshold,
          leftEqThreshold,
          rightEqMml,
          leftEqMml,
          rightEqThresholdStatus,
          leftEqThresholdStatus,
          rightEqMmlStatus,
          leftEqMmlStatus,
        },
        tanRight: rightEqTan,
        tanLeft: leftEqTan,
        isCompleted: true,
      }),
    );
  };

  return (
    <CustomModal
      modal={true}
      open={openConfirmEqModal}
      title="Confirm EQ Settings"
      icon={GreenCheckCircleSVG}
      width="85vw"
    >
      <Box display="flex">
        <Box
          width="16%"
          paddingRight={2}
          sx={{ borderRightStyle: 'solid', borderWidth: '1px', borderColor: theme.palette.shades.purpleBorder }}
        >
          {overallEQ ? (
            <Box display="flex" justifyContent="space-between">
              <Typography gutterBottom component="p" variant="bodyBold">
                Overall Threshold
              </Typography>
              <Typography component="p" variant="bodyRegular">
                {props.leftNoiseThreshold}
              </Typography>
            </Box>
          ) : (
            <>
              <Typography gutterBottom component="p" variant="bodyBold">
                Overall Threshold
              </Typography>
              <Box marginTop={1} display="flex" justifyContent="space-between">
                <Typography component="p" variant="bodyRegular">
                  Right
                </Typography>
                <Typography component="p" variant="bodyRegular">
                  {props.rightNoiseThreshold}
                </Typography>
              </Box>
              <Box marginTop={1} display="flex" justifyContent="space-between">
                <Typography component="p" variant="bodyRegular">
                  Left
                </Typography>
                <Typography component="p" variant="bodyRegular">
                  {props.leftNoiseThreshold}
                </Typography>
              </Box>
            </>
          )}
        </Box>

        <Box
          width="26%"
          marginLeft={2}
          paddingRight={4}
          sx={{ borderRightStyle: 'solid', borderWidth: '1px', borderColor: theme.palette.shades.purpleBorder }}
        >
          <Typography marginBottom={2} component="p" variant="bodyBold">
            {overallEQ ? 'Narrow Band Frequency Threshold' : 'Threshold'}
          </Typography>

          {!overallEQ && (
            <>
              <ConfirmEqChart
                data={props.rightEqThreshold}
                label="Right"
                lineColorPrimary={theme.palette.error.main}
                lineColorSecondary={theme.palette.shades.errorBorder}
                backgroundColor={theme.palette.shades.lightError}
                minValue={0}
                maxValue={120}
                step={30}
              />
              <Divider sx={{ marginTop: 4, marginBottom: 2, backgroundColor: theme.palette.shades.purpleBorder }} />
            </>
          )}
          <ConfirmEqChart
            data={props.leftEqThreshold}
            label={overallEQ ? '' : 'Left'}
            lineColorPrimary={theme.palette.primary.main}
            lineColorSecondary={theme.palette.shades.purpleBorder}
            backgroundColor={theme.palette.secondary.main}
            minValue={0}
            maxValue={120}
            step={30}
          />
        </Box>

        <Box
          width="26%"
          marginLeft={2}
          paddingRight={4}
          sx={{ borderRightStyle: 'solid', borderWidth: '1px', borderColor: theme.palette.shades.purpleBorder }}
        >
          <Typography marginBottom={2} component="p" variant="bodyBold">
            {overallEQ ? 'Narrow Band Frequency Minimum Masking Level' : 'Masking Level'}
          </Typography>

          {!overallEQ && (
            <>
              <ConfirmEqChart
                data={props.rightEqMml}
                label="Right"
                lineColorPrimary={theme.palette.error.main}
                lineColorSecondary={theme.palette.shades.errorBorder}
                backgroundColor={theme.palette.shades.lightError}
                minValue={0}
                maxValue={120}
                step={30}
              />
              <Divider sx={{ marginTop: 4, marginBottom: 2, backgroundColor: theme.palette.shades.purpleBorder }} />
            </>
          )}
          <ConfirmEqChart
            data={props.leftEqMml}
            label={overallEQ ? '' : 'Left'}
            lineColorPrimary={theme.palette.primary.main}
            lineColorSecondary={theme.palette.shades.purpleBorder}
            backgroundColor={theme.palette.secondary.main}
            minValue={0}
            maxValue={120}
            step={30}
          />
        </Box>

        <Box
          width="26%"
          marginLeft={2}
          paddingRight={4}
          sx={{ borderRightStyle: 'transparent', borderWidth: '1px', borderColor: theme.palette.shades.purpleBorder }}
        >
          <Typography marginBottom={2} component="p" variant="bodyBold">
            Threshold Adjusted Noise
          </Typography>
          {!overallEQ && (
            <>
              <ConfirmEqChart
                data={rightEqTan}
                label="Right"
                lineColorPrimary={theme.palette.error.main}
                lineColorSecondary={theme.palette.shades.errorBorder}
                backgroundColor={theme.palette.shades.lightError}
                minValue={0}
                maxValue={120}
                step={30}
              />
              <Divider sx={{ marginTop: 4, marginBottom: 2, backgroundColor: theme.palette.shades.purpleBorder }} />
            </>
          )}
          <ConfirmEqChart
            data={leftEqTan}
            label={overallEQ ? '' : 'Left'}
            lineColorPrimary={theme.palette.primary.main}
            lineColorSecondary={theme.palette.shades.purpleBorder}
            backgroundColor={theme.palette.secondary.main}
            minValue={0}
            maxValue={120}
            step={30}
          />
        </Box>
      </Box>
      <Grid container spacing={1} marginTop={2}>
        <Grid item xs={1}>
          <CustomButtonLoadingButton
            disabled={isSubmitting}
            loading={false}
            text={'Back'}
            variant="outlined"
            color="primary"
            fullWidth={false}
            width="95px"
            onClick={handleBackOnClick}
          />
        </Grid>
        <Grid item xs={10} sx={{ marginTop: 1 }}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <EqSimulate tan={leftEqTan} overallThreshold={props.leftNoiseThreshold} />
          </Box>
        </Grid>
        <Grid item xs={1} sx={{display: 'flex', justifyContent: 'end'}}>
          <CustomButtonLoadingButton
            disabled={false}
            loading={isSubmitting}
            text={'Save'}
            variant="contained"
            color="primary"
            fullWidth={false}
            width="95px"
            onClick={handleSaveOnClick}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
}

export default ConfirmEq;
