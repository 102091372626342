import React from 'react';
import CustomModal from '../CustomModal/CustomModal';
import RedCloseCircleSVG from '../../assets/img/close-circle-bold-duotone.svg';
import { Typography } from '@mui/material';

interface SizeTooLargeErrorProps {
  open: boolean;
  maxFileSize: string;
  onClickDismiss: () => void;
}

function SizeTooLargeError(props: SizeTooLargeErrorProps) {
  return (
    <CustomModal
      modal={true}
      open={props.open}
      icon={RedCloseCircleSVG}
      title={'File Size Too Large!'}
      maxWidth={'374px'}
      btnText={'Dismiss'}
      handleButtonOnClick={() => props.onClickDismiss()}
    >
      <Typography variant="bodyRegular" component="p" textAlign="center" color="error">
        Maximum file size should be {props.maxFileSize}. Please upload a file less than {props.maxFileSize}.
      </Typography>
    </CustomModal>
  );
}

export default SizeTooLargeError;
