import React, { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react';
import CustomTable, { ColumnProps } from '../../components/CustomTable/CustomTable';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import * as _ from 'lodash';
import {
  Box,
  Button,
  Fade,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import AvatarEdit from '../../components/AvatarEdit/AvatarEdit';
import EyeBoldSVG from '../../assets/img/eye-bold.svg';
import GreenCheckCircleSVG from '../../assets/img/check-circle-bold-duotone.svg';
import SlashCircleBoldSVG from '../../assets/img/slash-circle-bold.svg';
import MagniferOutlineSVG from '../../assets/img/magnifer-outline.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { clinicActions, ClinicProps } from '../../redux/clinic/clinicSlice';
import { Roles } from '../../utils/enums/roles.enum';
import { authActions } from '../../redux/auth/authSlice';
import { SIDEBAR_OPTIONS } from '../../utils/sidebar-schema';
import { SidebarOption } from '../../utils/enums/sidebar-option.enum';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Permission } from '../../utils/enums/permission.enum';
import AddClinic from './AddClinic/AddClinic';
import { generalActions } from '../../redux/general/generalSlice';
import SuccessModal from './AddClinic/SuccessModal';
import { SortOrder } from '../../utils/enums/sort-order.enum';

const LightTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} children={children} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    ...theme.typography.bodyBold,
  },
}));

function Clinics() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page');
  const sortByParam = searchParams.get('sort-by');
  const rowsPerPageParam = searchParams.get('rows-per-page');
  const sortOrderParam = searchParams.get('sort-order');
  const searchParam = searchParams.get('search');

  const [page, setPage] = useState<number>(pageParam ? parseInt(pageParam) : 1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageParam ? parseInt(rowsPerPageParam) : 10);
  const [sortBy, setSortBy] = useState<string>(sortByParam ?? 'clinicId');
  const [sortOrder, setSortOrder] = useState<SortOrder>((sortOrderParam as SortOrder) ?? SortOrder.DESC);
  const [searchText, setSearchText] = useState<string>(searchParam ?? '');

  const searchBtn = useRef<HTMLButtonElement>(null);

  const totalClinicCount = useAppSelector((state: RootState) => state.clinic.totalClinicCount);
  const clinicList = useAppSelector((state: RootState) => state.clinic.clinicList);

  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const isTableLoading = _.get(componentStatus, 'isTableLoading', false) as boolean;
  const shouldAdminListLoad = _.get(
    useAppSelector((state: RootState) => state.general.componentStatus),
    'shouldClinicListLoad',
    false,
  ) as boolean;

  const columns: ColumnProps[] = [
    { key: 'clinicId', name: 'TSCID', enableSort: true, align: 'left', width: '8%', minWidth: 90, resizable: true },
    { key: 'name', name: 'Name', enableSort: true, align: 'left', width: 'auto', minWidth: 160, resizable: true },
    { key: 'email', name: 'Email', enableSort: false, align: 'left', width: 'auto', minWidth: 140, resizable: true },
    { key: 'phone', name: 'Phone', enableSort: false, align: 'left', width: 'auto', minWidth: 80, resizable: true },
    { key: 'address', name: 'Address', enableSort: false, align: 'left', width: 'auto', minWidth: 80, resizable: true },
    { key: 'action', name: '', enableSort: false, align: 'right', width: '10%', minWidth: 100, resizable: false },
  ];

  const handleAddClinicModalOpen = () => {
    dispatch(generalActions.clearComponentStatus());
    dispatch(generalActions.setComponentStatus({ isAddClinicModalOpen: true }));
  };

  useEffect(() => {
    dispatch(
      authActions.setTopBarView({
        text: SIDEBAR_OPTIONS[SidebarOption.CLINICS].name,
        path: SIDEBAR_OPTIONS[SidebarOption.CLINICS].path,
        sideMenuPath: SIDEBAR_OPTIONS[SidebarOption.CLINICS].path,
        button:
          user.role === Roles.SUPER_ADMIN || user.permissions.includes(Permission.TRUE_SILENCE_ADMIN_CREATE_NEW_CLINIC)
            ? {
                text: 'Add New Clinic',
                icon: <AddCircleOutlineIcon fontSize="small" />,
                color: 'primary',
                onClick: handleAddClinicModalOpen,
              }
            : undefined,
      }),
    );
  }, [user]);

  const enterKeyDownHandler = (event: { key: string; preventDefault: () => void }) => {
    if (event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (searchBtn.current) {
          searchBtn.current.click();
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', enterKeyDownHandler);
    return () => {
      document.removeEventListener('keydown', enterKeyDownHandler);
    };
  }, []);

  useEffect(() => {
    setSearchParams({
      page: page.toString(),
      'rows-per-page': rowsPerPage.toString(),
      'sort-by': sortBy,
      'sort-order': sortOrder,
      search: searchText,
    });
    const offset = (page - 1) * rowsPerPage;
    dispatch(clinicActions.getClinicListAsync({ offset, limit: rowsPerPage, sortBy, sortOrder, searchText }));
  }, [page, rowsPerPage, sortBy, sortOrder, shouldAdminListLoad]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setRowsPerPage(rowsPerPage);
    setPage(1);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const str = event.target.value;
    setSearchText(str);
  };

  const handleSearchButton = () => {
    setSearchParams({
      page: '1',
      'rows-per-page': rowsPerPage.toString(),
      'sort-by': sortBy,
      'sort-order': sortOrder,
      search: searchText,
    });
    dispatch(clinicActions.getClinicListAsync({ offset: 0, limit: rowsPerPage, sortBy, sortOrder, searchText }));
  };

  const handleViewClinic = (clinic: ClinicProps) => {
    navigate(`/clinics/${clinic.clinicId}`, { state: clinic });
  };

  const getFormattedClinicList = (clinicList: ClinicProps[]) => {
    return clinicList.map((clinic) => {
      const address = _.trim(`${clinic.addressLine1 ?? ''}, ${clinic.addressLine2 ?? ''}`, ',');

      return {
        clinicId: clinic.clinicId,
        name: (
          <Box display="flex" gap={1}>
            <AvatarEdit
              editable={false}
              avatar={clinic.avatar}
              name={clinic.name}
              size="24px"
              font={theme.typography.subtitle1}
            />
            <Typography variant="bodyRegular">{clinic.name}</Typography>
          </Box>
        ),
        email: clinic.email,
        phone: clinic.phone,
        address: (
          <LightTooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={address}
            children={
              <Typography
                sx={{ cursor: 'pointer' }}
                variant="bodyRegular"
                component="p"
                color={theme.palette.info.main}
              >
                {_.truncate(address, { length: 24, omission: ' ...' })}
              </Typography>
            }
          />
        ),
        action: (
          <Stack direction="row" justifyContent="center" spacing={1}>
            <IconButton data-testid="view-clinics" onClick={() => handleViewClinic(clinic)} size="small">
              <Box component="img" src={EyeBoldSVG}></Box>
            </IconButton>
            <Box
              width="20px"
              component="img"
              src={clinic.status === 'ACTIVE' ? GreenCheckCircleSVG : SlashCircleBoldSVG}
            />
          </Stack>
        ),
      };
    });
  };

  return (
    <Fragment>
      <CustomTable
        isLoading={isTableLoading}
        totalDataRows={totalClinicCount}
        enablePagination={true}
        selectedPage={page}
        enablePagePerRows={true}
        rowsPerList={[10, 20, 50, 100]}
        selectedRowsPerPage={rowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        columns={columns}
        rows={getFormattedClinicList(clinicList)}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSortByChange={setSortBy}
        handleSortOrderChange={setSortOrder}
        bottomResultsCount={totalClinicCount}
        minBodyRowSpace={10}
        customTableTop={
          <Box mx={2} my={2} display="flex" columnGap={1}>
            <TextField
              fullWidth
              value={searchText}
              onChange={handleSearchChange}
              placeholder="Search by Name, Address Line 1, Address Line 2, Email, TSCID, Phone"
              sx={{ '& .MuiInputBase-root': { height: '38px', borderRadius: '10px' } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box component="img" src={MagniferOutlineSVG} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              ref={searchBtn}
              fullWidth
              variant="contained"
              onClick={handleSearchButton}
              sx={{ borderRadius: '10px', width: '109px' }}
            >
              Search
            </Button>
          </Box>
        }
      />

      {/*Add clinic modal*/}
      <AddClinic />

      {/*Add clinic success*/}
      <SuccessModal />
    </Fragment>
  );
}

export default Clinics;
