import { AxiosResponse } from 'axios';
import { backendApiInstance } from './backendApiInstance';

export async function getCountries(): Promise<AxiosResponse> {
  return backendApiInstance.get('general/country');
}

export async function getStates(country: string): Promise<AxiosResponse> {
  return backendApiInstance.get('general/state', { params: { country } });
}
