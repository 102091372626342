import { ChangeEvent, FocusEvent } from 'react';
import {
  Box,
  FilledInputProps,
  FormHelperTextProps,
  OutlinedInputProps,
  Typography,
  useTheme,
  TextField,
  MenuItem,
} from '@mui/material';

interface CustomInputBoxProps {
  label: string;
  required: boolean;
  name: string;
  placeholder?: string;
  type: string;
  value: string | undefined;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  error: boolean | undefined;
  helperText: string | boolean | undefined;
  FormHelperTextProps?: Partial<FormHelperTextProps>;
  InputProps?: Partial<FilledInputProps> | Partial<OutlinedInputProps>;
  labelAlign?: 'left' | 'center';
  disabled?: boolean;
  select?: boolean;
  selectOptions?: { key: string; value: string }[];
  backgroundColor?: string;
}

function CustomInputBox({
  label,
  required,
  name,
  placeholder,
  type,
  value,
  onChange,
  onBlur,
  error,
  helperText,
  FormHelperTextProps,
  InputProps,
  labelAlign = 'left',
  disabled = false,
  select = false,
  selectOptions = [],
  backgroundColor = 'transparent',
}: CustomInputBoxProps) {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" gap="2px">
      <Typography variant="meta" component="p" align={labelAlign}>
        {label}
        <Typography hidden={!required} component="span" color="primary">
          &nbsp;*
        </Typography>
      </Typography>
      <TextField
        select={select}
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        fullWidth
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        helperText={helperText}
        variant="outlined"
        FormHelperTextProps={{ style: { ...theme.typography.meta }, ...FormHelperTextProps }}
        InputProps={InputProps}
        sx={{ '& .MuiInputBase-root': { height: '38px', borderRadius: '10px', backgroundColor: backgroundColor } }}
      >
        {selectOptions.map((option, index) => (
          <MenuItem key={option.key} value={option.key}>
            {option.value}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}

export default CustomInputBox;
