import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useAppDispatch } from '../../redux/hooks';
import { authActions } from '../../redux/auth/authSlice';
import { useAuth } from '../../context/AuthContext';
import { Roles } from '../../utils/enums/roles.enum';
import SendFeedback from './SendFeedback/SendFeedback';

function Support() {
  const dispatch = useAppDispatch();
  const { user } = useAuth();

  useEffect(() => {
    dispatch(authActions.setTopBarView({ text: 'Support', path: '/support', sideMenuPath: '/support' }));
  }, []);

  return (
    <Grid container>
      {[Roles.CLINIC_ADMIN, Roles.CLINICIAN].includes(user.role) && (
        <Grid item xs={6}>
          <SendFeedback />
        </Grid>
      )}
    </Grid>
  );
}

export default Support;
