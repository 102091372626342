import { AxiosResponse } from 'axios';
import { backendApiInstance } from './backendApiInstance';
import { SortOrder } from '../utils/enums/sort-order.enum';

export interface GetNotificationListParamType {
  offset: number;
  limit: number;
  sortOrder: SortOrder;
  clinicId?: string;
  requestor?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
  requestorId?: string;
}

export async function getNotifications(page: number, recordCount: number): Promise<AxiosResponse> {
  return backendApiInstance.get('notifications', { params: { offset: page, limit: recordCount } });
}

export async function getNotificationCount(lastReadTime: Date): Promise<AxiosResponse<number>> {
  return backendApiInstance.get('notifications/count', { params: { lastReadTime } });
}

export async function getNotificationsList(params: GetNotificationListParamType): Promise<AxiosResponse> {
  return backendApiInstance.get('notifications/all', { params });
}

export async function getRequestorNamesList(): Promise<AxiosResponse> {
  return backendApiInstance.get('notifications/requestors-list');
}

export async function updateLastNotificationViewTime(): Promise<AxiosResponse> {
  return backendApiInstance.post('notifications/update-last-notification-view-time');
}
