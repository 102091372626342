import { AxiosResponse } from 'axios';
import { backendApiInstance } from './backendApiInstance';

export async function uploadImage(image: File): Promise<AxiosResponse> {
  const bodyFormData = new FormData();
  bodyFormData.append('image', image);
  return backendApiInstance.post('minio/image', bodyFormData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}
