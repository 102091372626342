import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Grid,
  IconButton,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
  useTheme,
} from '@mui/material';
import { Theme, styled as materialStyled } from '@mui/material/styles';
import CloseCircleBoldSVG from '../../assets/img/close-circle-bold.svg';
import BarChart, { ChartData } from './BarChart';
import CustomModal from '../CustomModal/CustomModal';
import InfoCircleBoldPNG from '../../assets/img/info-circle-bold.png';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import * as _ from 'lodash';
import { patientActions } from '../../redux/patient/patientSlice';

const colors = ['#FFB6BA', '#BF8AC0', '#F7E5DB', '#BADFD7', '#89C6D1'];
const names = ['Sleep', 'Communication/Hearing', 'Peace and Quiet', 'Concentration', 'Stress/Mood'];

const TypographyStyled = materialStyled(Typography)(({ theme }: { theme: Theme }) => ({
  ...theme.typography.bodyRegular,
  color: theme.palette.info.main,
}));

const StyledTooltip = materialStyled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const GridStyled = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  width: '100%',
  overflow: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: `${theme.palette.info.main} ${theme.palette.shades.placeholderText}`,
}));

const getLablesForBarChart = () => {
  const labels = [];
  for (let i = 5; i <= 90; i = i + 5) {
    labels.push(i.toString());
  }
  return labels;
};

function GoalImprovementFeedback() {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const [chartData, setChartData] = useState<ChartData[]>([{ data: [], color: colors[0], label: names[0] }]);
  let feedbackData = useAppSelector((state: RootState) => state.patient.patientFeedback.everyFiveDaysFeedback);
  const componentStatus = useAppSelector((state: RootState) => state.patient.componentStatus);
  const currentChart = _.get(componentStatus, 'currentGoalChart', null) as number | null;

  if (!feedbackData.length) {
    feedbackData = [[]];
  }

  const data = feedbackData.map((data, index) => ({
    data: data,
    color: colors[index],
    label: names[index],
  }));

  useEffect(() => {
    if (currentChart === null) {
      setChartData(data);
    } else if (data[currentChart]) {
      setChartData([data[currentChart]]);
    } else {
      setChartData([{ data: [], color: colors[currentChart], label: names[currentChart] }]);
    }
  }, [currentChart]);

  const handleTableSwitch = (index: number) => {
    if (currentChart === index) {
      dispatch(patientActions.setComponentStatus({ currentGoalChart: null }));
    } else {
      dispatch(patientActions.setComponentStatus({ currentGoalChart: index }));
    }
  };

  const legends = (
    <Grid container pt={2} pb={2} spacing={2}>
      {names.map((value, index) => (
        <Grid item key={value} display="flex" alignItems="center">
          <div style={{ height: 10, width: 10, borderRadius: '100%', backgroundColor: colors[index] }} />
          <Typography
            ml={1}
            sx={{ cursor: 'pointer', color: theme.palette.info.main }}
            onClick={() => handleTableSwitch(index)}
            variant={currentChart === index ? 'bodyBold' : 'bodyRegular'}
          >
            {value}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );

  const handleViewFullGraph = () => {
    setOpen(true);
  };

  const handleDismiss = () => {
    setOpen(false);
  };

  return (
    <>
      <Card sx={{ borderRadius: '10px', padding: '24px', minHeight: '209px' }}>
        <Grid container>
          <Grid mb={3} item width="100%">
            <Grid container alignItems={'center'}>
              <Grid item xs={8}>
                <Typography variant="h5" textAlign="left">
                  Improvements on Goals
                </Typography>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="end">
                <Typography
                  variant="button"
                  color={theme.palette.primary.main}
                  onClick={handleViewFullGraph}
                  sx={{ cursor: 'pointer' }}
                >
                  View Full Graph
                </Typography>
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <TypographyStyled variant="bodyRegular">
                Based on feedback questions posed every fifth treatment day, inquiring about progress toward goal
                improvement.
              </TypographyStyled>

              <StyledTooltip
                title={
                  <div>
                    <div>
                      <p>Questions asked from patients:</p>
                      <ul style={{ listStyleType: 'none', padding: 0 }}>
                        <li>o How well have you slept?</li>
                        <li>o How well have you been able to hear and interact with others?</li>
                        <li>o How comfortable have you been in quiet environments?</li>
                        <li>o How well have you been able to maintain focus?</li>
                        <li>o How well have you been able to keep your stress level under control?</li>
                      </ul>
                    </div>
                    <div>
                      <p>Bar values are based on the following answers:</p>
                      <ul style={{ listStyleType: 'none', padding: 0 }}>
                        <li>1 - Worse</li>
                        <li>2 - No different</li>
                        <li>3 - Slightly better</li>
                        <li>4 - Better</li>
                        <li>5 - Much better</li>
                      </ul>
                    </div>
                  </div>
                }
                placement="left-start"
              >
                <IconButton size="small" aria-label="tooltip">
                  <Box component="img" src={InfoCircleBoldPNG} />
                </IconButton>
              </StyledTooltip>
            </Box>
          </Grid>
        </Grid>

        <GridStyled pt={2} pb={2} sx={{ borderTop: `0.5px solid ${theme.palette.shades.purpleBorder}` }}>
          <BarChart
            data={chartData}
            stepSizeX={5}
            height={'244px'}
            barThickness={24.94}
            responsive={data[0].data.length < 90}
            stacked={true}
            labels={getLablesForBarChart()}
            maxY={currentChart !== null ? 5 : 25}
            skipLabelX={false}
            stepSizeY={currentChart !== null ? 1 : 5}
            defaultbarCount={18}
          />
        </GridStyled>

        {legends}
      </Card>

      <CustomModal modal={true} open={open} align="left" width="90vw" padding={3}>
        <Box display="flex" justifyContent="space-between" pl={2}>
          <Typography variant="h4" textAlign="left">
            Improvements on Goals
          </Typography>
          <IconButton size="small" aria-label="close" onClick={handleDismiss}>
            <Box component="img" src={CloseCircleBoldSVG} />
          </IconButton>
        </Box>

        <TypographyStyled variant="bodyRegular" pl={2}>
          Based on feedback questions posed every fifth treatment day, inquiring about progress toward goal improvement.
        </TypographyStyled>

        <GridStyled mt={2}>
          <BarChart
            data={chartData}
            stepSizeX={10}
            height={'400px'}
            barThickness={84}
            responsive={false}
            stacked={true}
            labels={getLablesForBarChart()}
            maxY={currentChart !== null ? 5 : 25}
            skipLabelX={false}
            stepSizeY={currentChart !== null ? 1 : 5}
            defaultbarCount={18}
            xAxisTitle={'Days'}
          />
          {legends}
        </GridStyled>
      </CustomModal>
    </>
  );
}

export default GoalImprovementFeedback;
