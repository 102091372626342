import React, { createRef, ElementType, ReactNode, useEffect, useRef, useState } from 'react';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  Stack,
  Skeleton,
  useTheme,
  Theme,
  Grid,
} from '@mui/material';
import SortVerticalOutlineSVG from '../../assets/img/sort-vertical-outline.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled as materialStyled } from '@mui/material/styles';
import * as _ from 'lodash';
import { SortOrder } from '../../utils/enums/sort-order.enum';
import { v4 as uuidv4 } from 'uuid';

export interface ColumnProps {
  key: string;
  name: string;
  customComponent?: ReactNode;
  enableSort: boolean;
  align: 'right' | 'left' | 'center' | 'inherit' | 'justify';
  width: string;
  minWidth: number;
  resizable: boolean;
  headerAlign?: 'right' | 'left' | 'center' | 'inherit' | 'justify';
}

export interface CustomTableProps {
  customTableTop?: ReactNode;
  customTableBottom?: ReactNode;
  isLoading: boolean;
  totalDataRows: number;
  enablePagination: boolean;
  selectedPage: number;
  enablePagePerRows: boolean;
  rowsPerList: number[];
  selectedRowsPerPage: number;
  sortBy: string;
  sortOrder: SortOrder;
  columns: ColumnProps[];
  rows: { [key: string]: string | ReactNode }[];
  handleChangePage: (page: number) => void;
  handleChangeRowsPerPage: (rowsPerPage: number) => void;
  handleSortByChange: (sortBy: string) => void;
  handleSortOrderChange: (sortOrder: SortOrder) => void;
  minBodyRowSpace?: number;
  bottomResultsCount?: number;
  tableBorderRadius?: string;
  tableContainerComponent?: ElementType;
  rowOnDoubleClick?: (() => void)[];
}

const ColResizableButton = materialStyled('div')(({ theme, resizable }: { theme: Theme; resizable: boolean }) => ({
  height: '30px',
  width: '1px',
  top: '20%',
  right: '0',
  borderWidth: '2px',
  borderColor: resizable ? `${theme.palette.primary.main}1D` : 'transparent',
  borderStyle: 'dotted',
  borderRadius: '30px',
  cursor: resizable ? 'col-resize' : 'none',
  position: 'absolute',
}));

function CustomTable({
  customTableTop,
  customTableBottom,
  isLoading,
  totalDataRows,
  enablePagination,
  selectedPage,
  enablePagePerRows,
  rowsPerList,
  selectedRowsPerPage,
  columns,
  rows,
  sortBy,
  sortOrder,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSortByChange,
  handleSortOrderChange,
  minBodyRowSpace = 0,
  bottomResultsCount,
  tableBorderRadius = '20px',
  tableContainerComponent = Paper,
  rowOnDoubleClick,
}: CustomTableProps) {
  const theme = useTheme();
  const [anchorElRowsPerPage, setAnchorElRowsPerPage] = React.useState<null | HTMLElement>(null);

  const handleSortChange = (currentSortKey: string, sortKey: string, currentSortOrder: string) => {
    if (currentSortKey !== sortKey) {
      handleSortByChange(sortKey);
      handleSortOrderChange(SortOrder.ASC);
    } else if (currentSortOrder === SortOrder.ASC) {
      handleSortOrderChange(SortOrder.DESC);
    } else {
      handleSortOrderChange(SortOrder.ASC);
    }
  };

  const [columnRefs, setColumnRefs] = useState(columns.map(() => createRef<any>()));
  const isResizing = useRef(-1);

  useEffect(() => {
    document.onmousemove = handleOnMouseMove;
    document.onmouseup = handleOnMouseUp;
    return () => {
      document.onmousemove = null;
      document.onmouseup = null;
    };
  }, []);

  const adjustWidthColumn = (index: number, width: number) => {
    const minWidth = columns[index].minWidth;
    const newWidth = width < minWidth ? minWidth : width;
    const newColumns = { ...columnRefs };
    newColumns[index].current.parentElement.style.width = newWidth + 'px';
    setColumnRefs(newColumns);
  };

  const setCursorDocument = (isResizing: boolean) => {
    document.body.style.cursor = isResizing ? 'col-resize' : 'auto';
  };

  const handleOnMouseMove = (e: any) => {
    if (
      isResizing.current >= 0 &&
      columnRefs[isResizing.current].current &&
      columnRefs[isResizing.current].current.parentElement
    ) {
      const newWidth = e.clientX - columnRefs[isResizing.current].current.parentElement.getBoundingClientRect().left;
      adjustWidthColumn(isResizing.current, newWidth);
    }
  };

  const handleOnMouseUp = () => {
    isResizing.current = -1;
    setCursorDocument(false);
  };

  const onClickResizeColumn = (index: number) => {
    isResizing.current = index;
    setCursorDocument(true);
  };

  const emptyRowSpaceCount = (minRowsLength: number, rowLength: number) => {
    if (minRowsLength - rowLength > 0) return minRowsLength - rowLength;
    else return 0;
  };

  return (
    <TableContainer sx={{ borderRadius: tableBorderRadius }} component={tableContainerComponent}>
      {customTableTop}
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow sx={{ height: customTableTop ? '42px' : '52px' }}>
            {columns.map((e, i) => {
              return (
                <TableCell
                  id={`${e.key}-column-id`}
                  key={uuidv4()}
                  sx={{
                    ...(customTableTop ? { borderTopStyle: 'solid', borderTopWidth: '1px' } : {}),
                    borderColor: theme.palette.shades.purpleBorder,
                    position: 'relative',
                  }}
                  width={e.width}
                  align={e.headerAlign}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }} justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                      {e.customComponent}
                      {!e.customComponent && !!e.name && (
                        <Typography variant="meta" sx={{ color: theme.palette.shades.purpleBorder }}>
                          {e.name}
                        </Typography>
                      )}
                      {e.enableSort && (
                        <IconButton
                          role={`sort-btn-${e.key}`}
                          size="small"
                          onClick={() => handleSortChange(sortBy, e.key, sortOrder)}
                        >
                          <Box
                            sx={{
                              width: sortBy === e.key ? '20px' : '16px',
                              height: sortBy === e.key ? '20px' : '16px',
                              transform: sortBy === e.key && sortOrder === SortOrder.ASC ? 'scaleX(-1)' : 'scaleX(1)',
                            }}
                            component="img"
                            src={SortVerticalOutlineSVG}
                          />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                  <ColResizableButton
                    role={`col-resize-btn-${e.key}`}
                    theme={theme}
                    resizable={e.resizable}
                    onMouseDown={() => onClickResizeColumn(i)}
                    ref={columnRefs[i]}
                  />
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        {!isLoading && (
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow
                key={rowIndex + 1}
                hover
                sx={{ height: '48px', cursor: rowOnDoubleClick ? 'pointer' : 'default' }}
                {...(rowOnDoubleClick && { onDoubleClick: () => rowOnDoubleClick[rowIndex]() })}
              >
                {columns.map((column, columnIndex) => {
                  return (
                    <TableCell key={columnIndex + 1} align={column.align} component="th" scope="row" sx={{ border: 0 }}>
                      {row[column.key] instanceof String ? (
                        row[column.key]
                      ) : (
                        <Typography variant="bodyRegular">{row[column.key]}</Typography>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}

            {Array.from(Array(emptyRowSpaceCount(minBodyRowSpace, rows.length))).map((row, rowIndex) => (
              <TableRow key={uuidv4()} sx={{ height: '48px' }} />
            ))}
          </TableBody>
        )}

        {isLoading && (
          <TableBody>
            {Array.from(Array(selectedRowsPerPage)).map((row, rowIndex) => (
              <TableRow key={uuidv4()} hover sx={{ height: '48px' }}>
                {columns.map((column, columnIndex) => {
                  return (
                    <TableCell key={uuidv4()} align={column.align} component="th" scope="row" sx={{ border: 0 }}>
                      <Skeleton role="loading-skeleton" animation="wave" />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        )}
        <TableFooter sx={{ borderTop: `1px solid ${theme.palette.shades.purpleBorder}`, height: '48px' }}>
          <TableRow>
            <TableCell colSpan={columns.length} sx={{ borderBottomWidth: 0 }}>
              <Grid container>
                <Grid item xs={_.isUndefined(bottomResultsCount) ? 6 : 4} display="flex" justifyContent="flex-start">
                  {enablePagination && (
                    <Pagination
                      size="small"
                      page={selectedPage}
                      count={totalDataRows && selectedRowsPerPage ? Math.ceil(totalDataRows / selectedRowsPerPage) : 1}
                      hidePrevButton
                      hideNextButton
                      siblingCount={1}
                      boundaryCount={1}
                      onChange={(event, page) => handleChangePage(page)}
                    />
                  )}
                </Grid>

                {!_.isUndefined(bottomResultsCount) && (
                  <Grid item xs={4} display="flex" justifyContent="center">
                    <Typography variant="button" color={theme.palette.shades.blackBorder}>
                      {`${bottomResultsCount} Results`}
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={_.isUndefined(bottomResultsCount) ? 6 : 4} display="flex" justifyContent="flex-end">
                  {enablePagePerRows && (
                    <Box>
                      <Stack direction="row" alignItems="center" justifyContent="flex-end">
                        <Typography variant="button">{selectedRowsPerPage}</Typography>
                        <IconButton
                          id="row-per-page-expand-btn"
                          onClick={(event) => setAnchorElRowsPerPage(event.currentTarget)}
                          sx={{ p: 0 }}
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                        <Typography variant="meta" color={theme.palette.shades.blackBorder}>
                          rows per page
                        </Typography>
                      </Stack>

                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorElRowsPerPage}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        keepMounted={false}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={Boolean(anchorElRowsPerPage)}
                        onClose={() => setAnchorElRowsPerPage(null)}
                      >
                        {rowsPerList.map((e, i) => {
                          return (
                            <MenuItem
                              id={`record-per-row-${e}`}
                              selected={e === selectedRowsPerPage}
                              key={uuidv4()}
                              onClick={(event) => {
                                handleChangeRowsPerPage(e);
                                setAnchorElRowsPerPage(null);
                              }}
                            >
                              <Typography variant="meta" color="info" textAlign="center">
                                {e}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">{customTableBottom}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default CustomTable;
