import React, { type ReactElement } from 'react';
import { TextField, Typography, Box, Theme, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled as materialStyled } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { authActions } from '../../redux/auth/authSlice';
import LoadingButton from '@mui/lab/LoadingButton';
import SendBoldSVG from '../../assets/img/send-bold.svg';
import GreenCheckCircleSVG from '../../assets/img/check-circle-bold-duotone.svg';
import { RootState } from '../../redux/store';
import * as _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { validation } from '../../schema/validation';

const CustomTextField = materialStyled(TextField)(({ theme }: { theme: Theme }) => ({
  width: '310px',
  '& .MuiInputBase-root': {
    height: '38px',
    borderRadius: '10px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '297px',
  },
}));

const ContainerBox = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor: 'white',
  width: '374px',
  // height:'388px',
  borderRadius: '20px',
  boxShadow: `0px 4px 32px 0px ${theme.palette.shades.shadow}`,
  [theme.breakpoints.down('sm')]: {
    width: '361px',
  },
}));

const CustomButton = materialStyled(LoadingButton)(({ theme }: { theme: Theme }) => ({
  borderRadius: '10px',
  marginBottom: '40px',
  width: '310px',
  height: '43px',
  padding: '12px 32px',
  gap: '10px',
  textAlign: 'center',
  textTransform: 'none',
  [theme.breakpoints.down('sm')]: {
    width: '297px',
  },
}));

export default function ForgotPassword(): ReactElement {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const isEmailSent = _.get(componentStatus, 'isEmailSent', false) as boolean;
  const error = _.get(componentStatus, 'error', '') as string;
  const isSubmitting = _.get(componentStatus, 'isSubmitting', false) as boolean;

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object({
      email: validation.email,
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({ email }, { setSubmitting }) => {
      dispatch(authActions.setComponentStatus({ isSubmitting: true, isEmailSent: false, error: '' }));
      dispatch(authActions.sendPasswordResetLinkAsync({ email }));
    },
  });

  if (!isEmailSent) {
    return (
      <ContainerBox>
        <Typography mt={3} variant="h5" gutterBottom textAlign="center">
          Forgot Password
        </Typography>
        <Box display="flex" flexDirection="column" justifyContent="space-around" alignItems="center" height="90%">
          <Typography hidden={!error} variant="meta" component="p" gutterBottom textAlign="center" color="error">
            {error}
          </Typography>
          <Box my={2} display="flex" flexDirection="column">
            <Typography mb={0.5} variant="meta">
              Email{' '}
              <Typography component="span" color="primary">
                &nbsp;*
              </Typography>
            </Typography>
            <CustomTextField
              type="email"
              name="email"
              placeholder="username@mail.com"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              FormHelperTextProps={{ style: { ...theme.typography.meta } }}
              variant="outlined"
            />
          </Box>

          <Box mt={1} columnGap={5} display="flex" flexDirection="column" justifyContent="space-around">
            <CustomButton
              loading={isSubmitting}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => formik.handleSubmit()}
              endIcon={<Box component="img" src={SendBoldSVG} alt="send-bold-icon"></Box>}
            >
              Send Reset Link
            </CustomButton>

            <Box mb={5} display="flex" justifyContent="center">
              <Link style={{ textDecoration: 'none' }} to={'/login'}>
                Remember Your Password?
              </Link>
            </Box>
          </Box>
        </Box>
      </ContainerBox>
    );
  } else {
    return (
      <ContainerBox>
        <Box
          component="img"
          display="flex"
          margin="auto"
          paddingTop={4}
          src={GreenCheckCircleSVG}
          alt="green-check-circle-icon"
        ></Box>
        <Typography mt={3} variant="h5" textAlign="center">
          Password Reset Link Sent!
        </Typography>
        <Box m={2}>
          <Typography variant="bodyRegular" component="p" textAlign="center">
            Password reset link has been sent to
          </Typography>
          <Typography variant="button" component="p" textAlign="center">
            {formik.values.email}.
          </Typography>
          <Typography my={2} variant="bodyRegular" component="p" textAlign="center">
            Please check your inbox and click on the <br /> link to reset <br /> your password.
          </Typography>
          <Box
            onClick={() => dispatch(authActions.setComponentStatus({ isEmailSent: false }))}
            mb={6}
            display="flex"
            justifyContent="center"
          >
            <Link style={{ textDecoration: 'none' }} to={'/forgot-password'}>
              <Typography variant="button" textAlign="center" color={theme.palette.primary.main}>
                Not your email address?
              </Typography>
            </Link>
          </Box>
        </Box>
      </ContainerBox>
    );
  }
}
