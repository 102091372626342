import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useAuth } from '../../../../../context/AuthContext';
import { Roles } from '../../../../../utils/enums/roles.enum';
import { RootState } from '../../../../../redux/store';
import CustomModal from '../../../../../components/CustomModal/CustomModal';
import { patientActions } from '../../../../../redux/patient/patientSlice';
import CustomTable, { ColumnProps } from '../../../../../components/CustomTable/CustomTable';
import * as dateFns from 'date-fns';
import { SortOrder } from '../../../../../utils/enums/sort-order.enum';

interface TinnitusAvatarHistoryModalProps {
  patientUserId: string;
}

function TinnitusAvatarHistoryModal({ patientUserId }: TinnitusAvatarHistoryModalProps) {
  const dispatch = useAppDispatch();
  const { user } = useAuth();

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const componentStatus = useAppSelector((state: RootState) => state.patient.componentStatus);
  const isViewTinnitusAvatarHistoryOpen = _.get(componentStatus, 'isViewTinnitusAvatarHistoryOpen', false) as boolean;
  const isTinnitusAvatarHistoryLoading = _.get(componentStatus, 'isTinnitusAvatarHistoryLoading', false) as boolean;
  const historyList = useAppSelector((state: RootState) => state.patient.avatarHistoryList);
  const total = useAppSelector((state: RootState) => state.patient.totalAvatarHistoryCount);

  const columns: ColumnProps[] = [
    {
      key: 'createdAt',
      name: 'Date of Update',
      enableSort: false,
      align: 'left',
      width: '15%',
      minWidth: 100,
      resizable: false,
      headerAlign: 'left',
    },
    {
      key: 'tinnitusType',
      name: 'Sound',
      enableSort: false,
      align: 'left',
      width: '5%',
      minWidth: 50,
      resizable: false,
      headerAlign: 'left',
    },
    {
      key: 'frequency',
      name: 'Frequency',
      enableSort: false,
      align: 'right',
      width: '5%',
      minWidth: 50,
      resizable: false,
      headerAlign: 'right',
    },
    {
      key: 'bandwidth',
      name: 'Bandwidth',
      enableSort: false,
      align: 'right',
      width: '5%',
      minWidth: 50,
      resizable: false,
      headerAlign: 'right',
    },
    {
      key: 'rightVolume',
      name: 'Right Volume',
      enableSort: false,
      align: 'right',
      width: '5%',
      minWidth: 50,
      resizable: false,
      headerAlign: 'right',
    },
    {
      key: 'leftVolume',
      name: 'Left Volume',
      enableSort: false,
      align: 'right',
      width: '5%',
      minWidth: 50,
      resizable: false,
      headerAlign: 'right',
    },
    {
      key: 'horizontalDirection',
      name: 'Horizontal Direction',
      enableSort: false,
      align: 'right',
      width: '5%',
      minWidth: 50,
      resizable: false,
      headerAlign: 'right',
    },
    {
      key: 'verticalPosition',
      name: 'Vertical Position',
      enableSort: false,
      align: 'right',
      width: '5%',
      minWidth: 50,
      resizable: false,
      headerAlign: 'right',
    },
  ];

  useEffect(() => {
    if (patientUserId && [Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN, Roles.CLINICIAN].includes(user.role)) {
      const offset = (page - 1) * rowsPerPage;
      dispatch(patientActions.getTinnitusAvatarHistoryAsync({ patientId: patientUserId, offset, limit: rowsPerPage }));
    }
  }, [page, rowsPerPage, patientUserId]);

  const handleDismiss = () => {
    dispatch(patientActions.setComponentStatus({ isViewTinnitusAvatarHistoryOpen: false }));
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setRowsPerPage(rowsPerPage);
    setPage(1);
  };

  const getFormattedRows = () => {
    return historyList?.map((value) => {
      return {
        createdAt: dateFns.format(new Date(value?.createdAt ?? ''), 'MM-dd-yyyy'),
        tinnitusType: value.tinnitusType,
        frequency: `${value.frequency}Hz`,
        bandwidth: `+/- ${value.bandwidth}Hz`,
        rightVolume: value.rightVolume,
        leftVolume: value.leftVolume,
        horizontalDirection: `${value.horizontalDirection}°`,
        verticalPosition: value.verticalPosition,
      };
    });
  };

  return (
    <CustomModal
      modal={true}
      open={isViewTinnitusAvatarHistoryOpen}
      title={'Tinnitus Avatar'}
      align="left"
      width="906px"
      padding={0}
      isHistoryModal={true}
      topCloseBtn={handleDismiss}
    >
      <CustomTable
        isLoading={isTinnitusAvatarHistoryLoading}
        totalDataRows={total}
        enablePagination={true}
        selectedPage={page}
        enablePagePerRows={true}
        rowsPerList={[10, 20, 50, 100]}
        selectedRowsPerPage={rowsPerPage}
        columns={columns}
        rows={getFormattedRows()}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSortByChange={() => {}}
        handleSortOrderChange={() => {}}
        minBodyRowSpace={10}
        sortBy={''}
        sortOrder={SortOrder.ASC}
        tableBorderRadius={'0'}
        tableContainerComponent={'div'}
        customTableTop={<></>}
      />
    </CustomModal>
  );
}

export default TinnitusAvatarHistoryModal;
