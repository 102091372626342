import { Fragment, useState, useEffect } from 'react';
import { Typography, useTheme, Box, Stack, IconButton } from '@mui/material';
import CustomTable, { ColumnProps } from '../../../components/CustomTable/CustomTable';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import EyeBoldSVG from '../../../assets/img/eye-bold.svg';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import _ from 'lodash';
import { PatientType, patientActions } from '../../../redux/patient/patientSlice';
import { Link, useNavigate } from 'react-router-dom';
import { Roles } from '../../../utils/enums/roles.enum';
import { useAuth } from '../../../context/AuthContext';
import { SIDEBAR_OPTIONS } from '../../../utils/sidebar-schema';
import { SidebarOption } from '../../../utils/enums/sidebar-option.enum';
import { SortOrder } from '../../../utils/enums/sort-order.enum';

interface PatientTableProps {
  clinicianId: string;
  clinicianUserId: string;
}

function PatientsTable({ clinicianId, clinicianUserId }: PatientTableProps) {
  const { user } = useAuth();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [sortBy, setSortBy] = useState<string>('createdAt');
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.ASC);

  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const totalPatientCount = useAppSelector((state: RootState) => state.patient.totalPatientCount);
  const patientList = useAppSelector((state: RootState) => state.patient.clinicianPatientList);
  const isPatientListLoading = _.get(componentStatus, 'isPatientListLoading', false) as boolean;

  useEffect(() => {
    if (clinicianUserId) {
      const offset = (page - 1) * rowsPerPage;
      dispatch(
        patientActions.getPatientListAsync({
          offset,
          limit: rowsPerPage,
          sortBy,
          sortOrder,
          clinicianId: clinicianUserId,
        }),
      );
    }
  }, [page, rowsPerPage, sortBy, sortOrder, clinicianUserId]);

  const patientColumns: ColumnProps[] = [
    {
      key: 'name',
      name: 'Name',
      enableSort: false,
      align: 'left',
      width: 'auto',
      minWidth: 160,
      resizable: true,
    },
    {
      key: 'action',
      name: '',
      enableSort: false,
      align: 'right',
      width: '10%',
      minWidth: 100,
      resizable: false,
    },
  ];

  const adminViewPatientColumns: ColumnProps[] = [
    {
      key: 'globalId',
      name: 'TSPID',
      enableSort: false,
      align: 'left',
      width: 'auto',
      minWidth: 160,
      resizable: true,
    },
    {
      key: 'action',
      name: '',
      enableSort: false,
      align: 'right',
      width: '10%',
      minWidth: 100,
      resizable: false,
    },
  ];

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setRowsPerPage(rowsPerPage);
    setPage(1);
  };

  const handleViewPatient = (userId: string) => {
    dispatch(patientActions.setPatientList([]));
    navigate(`/patients/${userId}`);
  };

  const getFormattedPatientsList = (patientList: PatientType[]) => {
    return patientList.map((patient) => {
      const name = `${patient.firstName} ${patient.lastName}`;
      return {
        globalId: patient.globalId,
        name:
          user.role === Roles.CLINIC_ADMIN ? (
            <Box display="flex" gap={1}>
              <AvatarEdit
                editable={false}
                avatar={patient.avatar}
                name={patient.firstName}
                name2={patient.lastName}
                size="24px"
                font={theme.typography.subtitle1}
              />
              <Typography variant="bodyRegular">{name}</Typography>
            </Box>
          ) : (
            <Typography variant="bodyRegular">{name}</Typography>
          ),
        action: (
          <Stack direction="row" spacing={1} justifyContent="center">
            <IconButton size="small" onClick={() => handleViewPatient(patient.userId)}>
              <Box component="img" src={EyeBoldSVG}></Box>
            </IconButton>
          </Stack>
        ),
      };
    });
  };

  const getColumns = () => {
    return [Roles.TRUE_SILENCE_ADMIN, Roles.SUPER_ADMIN].includes(user.role) ? adminViewPatientColumns : patientColumns;
  };

  return (
    <Fragment>
      <CustomTable
        isLoading={isPatientListLoading}
        totalDataRows={totalPatientCount}
        enablePagination={false}
        selectedPage={page}
        enablePagePerRows={false}
        rowsPerList={[10, 20, 50, 100]}
        selectedRowsPerPage={rowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        columns={getColumns()}
        rows={getFormattedPatientsList(patientList)}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSortByChange={setSortBy}
        handleSortOrderChange={setSortOrder}
        minBodyRowSpace={5}
        customTableTop={
          <Box sx={{ padding: '24px' }} display="flex" justifyContent="left" alignItems="center">
            <Typography variant="h5" component="div" color={theme.palette.black.main}>
              Recent Patients
            </Typography>
          </Box>
        }
        customTableBottom={
          <Box
            sx={{ marginLeft: '12px', padding: '12px' }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link
              style={{ textDecoration: 'none', color: theme.palette.info.main }}
              to={SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path}
              state={{ clinicianId }}
            >
              <Typography variant="button" color={theme.palette.primary.main}>
                View All Patients
              </Typography>
            </Link>
          </Box>
        }
      />
    </Fragment>
  );
}

export default PatientsTable;
