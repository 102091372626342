import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import Chart from 'chart.js/auto';
import { getElementsAtEvent, Line } from 'react-chartjs-2';
import { CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend, Colors } from 'chart.js';
import _ from 'lodash';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import BlockIcon from '@mui/icons-material/Block';
import { linearToDecibel } from '../../utils/volume-utils';
import { AudioManager } from '../../utils/audio-manager';
import { v4 as uuidv4 } from 'uuid';
import { FilterRollOff } from 'tone';
import { EQDataType } from '../../utils/enums/eq-data-type.enum';

Chart.register(LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend, Colors);

const MAX_VALUE = 120;
const MIN_VALUE = 0;

export interface EqualizerProps {
  pan: 'left' | 'right' | 'both';
  isPlay: boolean;
  data: number[];
  validStatus: boolean[];
  lineColorPrimary: string;
  lineColorSecondary: string;
  backgroundColor: string;
  width: number;
  height: number | string;
  handleEqValueChange: (values: number[]) => void;
  handleEqValidStatusChange: (values: boolean[]) => void;
  handleIsEqPlayChange: (isPlay: boolean) => void;
  dataType?: EQDataType;
  noiseThreshold?: number;
  eqThresholdValues?: number[]
}

const frequencyBand = [
  { frequency: 500, lowerLimit: 447, upperLimit: 562 },
  { frequency: 1000, lowerLimit: 891, upperLimit: 1122 },
  { frequency: 2000, lowerLimit: 1778, upperLimit: 2239 },
  { frequency: 4000, lowerLimit: 3548, upperLimit: 4467 },
  { frequency: 8000, lowerLimit: 7079, upperLimit: 8913 },
  { frequency: 16000, lowerLimit: 14130, upperLimit: 17780 },
];

const keyPressValueMap = new Map<string, number>([
  ['ArrowUp', 1],
  ['ArrowDown', -1],
  ['+', 5],
  ['-', -5],
]);

function EQChart(props: EqualizerProps) {
  const theme = useTheme();
  const chartRef = useRef(null);
  const [volumeChangeIndex, setVolumeChangeIndex] = useState(-1);
  const [lastSelectedColumnIndex, setLastSelectedColumnIndex] = useState(-1);
  const [chartTopOffset, setChartTopOffset] = useState<number>(0);
  const [columnLinePosition, setColumnLinePosition] = useState<
    { x1: number; x2: number; y1: number; y2: number }[] | undefined
  >(undefined);
  const [chartData, setChartData] = useState({
    labels: ['0.5', '1', '2', '4', '8', '16'],
    datasets: [
      {
        label: '',
        data: props.data,
        borderColor: props.lineColorSecondary,
        backgroundColor: props.backgroundColor,
        pointStyle: 'circle',
        pointBorderColor: [
          props.lineColorSecondary,
          props.lineColorSecondary,
          props.lineColorSecondary,
          props.lineColorSecondary,
          props.lineColorSecondary,
          props.lineColorSecondary,
        ],
        pointRadius: 10,
        pointHoverRadius: 12,
        pointBorderWidth: [3, 3, 3, 3, 3, 3],
        borderWidth: 3,
        tension: 0,
        fill: false,
      },
    ],
  });

  const toneObj = useMemo(() => {
    const audioManager = new AudioManager();

    const noise = audioManager.getNoise('white');

    const filter = audioManager.getFilter();
    filter.type = 'bandpass';
    filter.rolloff = Number(process.env.REACT_APP_EQ_FILTER_ROLLOFF ?? -24) as FilterRollOff;

    noise.connect(filter);

    const volume = audioManager.getVolume(linearToDecibel(0));

    if (props.pan === 'both') {
      filter.connect(volume);
      volume.toDestination();
    } else {
      const panner = audioManager.getPanner(props.pan);
      filter.connect(volume);
      volume.connect(panner);
      panner.toDestination();
    }

    return {
      filter,
      noise,
      volume,
    };
  }, []);

  useEffect(() => {
    const bandIndex = volumeChangeIndex >= 0 ? volumeChangeIndex : lastSelectedColumnIndex;

    if (bandIndex >= 0) {
      toneObj.volume.volume.value = linearToDecibel(props.data[bandIndex]);

      const band = frequencyBand[bandIndex];
      toneObj.filter.frequency.value = band.frequency;
      toneObj.filter.Q.value = band.frequency / (band.upperLimit - band.lowerLimit);
    }

    if (props.isPlay && bandIndex >= 0) {
      if (toneObj.noise.state === 'stopped') {
        toneObj.noise.start();
      }
    } else {
      toneObj.noise.stop();
    }
  }, [
    props.isPlay,
    props.data[0],
    props.data[1],
    props.data[2],
    props.data[3],
    props.data[4],
    props.data[5],
    volumeChangeIndex,
    lastSelectedColumnIndex,
  ]);

  useEffect(() => {
    return () => {
      toneObj.noise.stop();
    };
  }, []);

  useEffect(() => {
    const temp = _.cloneDeep(chartData);
    const datasetLength = temp.datasets[0].data.length;
    temp.datasets[0].pointBorderColor = Array.from(Array(datasetLength)).map((e, i) => {
      if (i === lastSelectedColumnIndex) return props.lineColorPrimary;
      else return props.lineColorSecondary;
    });
    temp.datasets[0].pointBorderWidth = Array.from(Array(datasetLength)).map((e, i) => {
      if (i === lastSelectedColumnIndex) return 6;
      else return 3;
    });

    setChartData(temp);
  }, [lastSelectedColumnIndex]);

  useEffect(() => {
    setColumnLinePosition(_.get(chartRef, 'current.scales.x._gridLineItems', undefined));
  }, []);

  useEffect(() => {
    document.onmousemove = handleOnMouseMove;
    document.onmouseup = handleOnMouseUp;
    document.onkeydown = handleOnKeyDown;
    return () => {
      document.onmousemove = null;
      document.onmouseup = null;
      document.onkeydown = null;
    };
  }, [
    props.data[0],
    props.data[1],
    props.data[2],
    props.data[3],
    props.data[4],
    props.data[5],
    volumeChangeIndex,
    lastSelectedColumnIndex,
  ]);

  const handleOnMouseMove = (event: any) => {
    const chartAreaBottom = _.get(chartRef, 'current.chartArea.bottom', 0);
    const chartAreaTop = _.get(chartRef, 'current.chartArea.top', 0);
    if (volumeChangeIndex >= 0) {
      const eqValues = chartData.datasets[0].data;
      let minThreshold = MIN_VALUE;
      if(props.noiseThreshold && props.noiseThreshold >=10){
        minThreshold = props.noiseThreshold - 10;
      }
      if (props.dataType === EQDataType.OVERALL_EQ_MML && props.eqThresholdValues) {
        minThreshold = props.eqThresholdValues[volumeChangeIndex];
      }

      if (event.pageY <= chartTopOffset + chartAreaTop) {
        eqValues[volumeChangeIndex] = MAX_VALUE;
      } else if (event.pageY >= chartTopOffset + chartAreaBottom) {
        eqValues[volumeChangeIndex] = minThreshold;
      } else {
        const calculatedValue = Math.round(
          MIN_VALUE +
            ((chartAreaBottom - (event.pageY - chartTopOffset)) / (chartAreaBottom - chartAreaTop)) *
              (MAX_VALUE - MIN_VALUE),
        );
        if (calculatedValue < minThreshold) {
          eqValues[volumeChangeIndex] = minThreshold;
        } else {
          eqValues[volumeChangeIndex] = calculatedValue;
        }
      }
      props.handleEqValueChange(eqValues);
      props.handleIsEqPlayChange(true);
    }
  };

  const handleOnMouseDown = (event: any) => {
    if (chartRef.current && getElementsAtEvent(chartRef.current, event).length > 0) {
      const selectedPoint = getElementsAtEvent(chartRef.current, event)[0];
      const columnIndex = selectedPoint.index;
      setVolumeChangeIndex(columnIndex);

      setChartTopOffset(event.pageY - selectedPoint.element.y);
      setColumnLinePosition(_.get(chartRef, 'current.scales.x._gridLineItems', undefined));
    } else {
      setLastSelectedColumnIndex(-1);
      props.handleIsEqPlayChange(false);
    }
  };

  const handleOnMouseUp = (event: any) => {
    if (volumeChangeIndex >= 0) {
      setLastSelectedColumnIndex(volumeChangeIndex);
      setVolumeChangeIndex(-1);
      setChartTopOffset(0);
    }
  };

  const handleVolumeIncrease = () => {
    if (lastSelectedColumnIndex >= 0 && chartData.datasets[0].data[lastSelectedColumnIndex] < MAX_VALUE) {
      const eqValues = chartData.datasets[0].data;
      eqValues[lastSelectedColumnIndex] += 1;
      props.handleEqValueChange(eqValues);
    }
  };

  const handleVolumeDecrease = () => {
    if (lastSelectedColumnIndex >= 0 && chartData.datasets[0].data[lastSelectedColumnIndex] > MIN_VALUE) {
      const eqValues = chartData.datasets[0].data;
      const currentValue = eqValues[lastSelectedColumnIndex];
      let minThreshold = MIN_VALUE;
      if(props.noiseThreshold && props.noiseThreshold >=10){
        minThreshold = props.noiseThreshold - 10;
      }
      if (props.dataType === EQDataType.OVERALL_EQ_MML && props.eqThresholdValues) {
        minThreshold = props.eqThresholdValues[lastSelectedColumnIndex];
      }
      if (currentValue <= minThreshold) {
        eqValues[lastSelectedColumnIndex] = minThreshold;
      } else {
        eqValues[lastSelectedColumnIndex] -= 1;
      }
      props.handleEqValueChange(eqValues);
    }
  };

  const handleEqValidStatusChange = (index: number) => {
    const temp = [...props.validStatus];
    temp[index] = !temp[index];
    props.handleEqValidStatusChange(temp);
  };

  const handleOnKeyDown = (event: KeyboardEvent) => {
    if (lastSelectedColumnIndex >= 0) {
      const pressKey = event.key;

      if (keyPressValueMap.has(pressKey)) {
        const eqValues = chartData.datasets[0].data;
        eqValues[lastSelectedColumnIndex] += Number(keyPressValueMap.get(pressKey));
        if (eqValues[lastSelectedColumnIndex] > MAX_VALUE) {
          eqValues[lastSelectedColumnIndex] = MAX_VALUE;
        } else if (eqValues[lastSelectedColumnIndex] < MIN_VALUE) {
          eqValues[lastSelectedColumnIndex] = MIN_VALUE;
        }
        props.handleEqValueChange(eqValues);
      }
    }
  };

  return (
    <Box width={props.width} height={props.height} position="relative" bgcolor={theme.palette.white.main} marginTop={5}>
      {columnLinePosition && (
        <Box>
          {chartData.datasets[0].data.map((value, index) => {
            return (
              <IconButton
                id={`applicability-btn-${index}`}
                key={uuidv4()}
                size="small"
                sx={{
                  position: 'absolute',
                  marginLeft: '0',
                  top: -20,
                  left: columnLinePosition[index].x1 - 15,
                }}
                color={props.validStatus[index] ? 'inherit' : 'error'}
                onClick={() => {
                  handleEqValidStatusChange(index);
                }}
              >
                <BlockIcon />
              </IconButton>
            );
          })}
        </Box>
      )}
      <Line
        id="eq-chart-canvas"
        role="eq-chart-canvas"
        ref={chartRef}
        onMouseDown={handleOnMouseDown}
        data={chartData}
        options={{
          animation: {
            duration: 0,
          },
          layout: { autoPadding: true },
          elements: {
            line: {
              backgroundColor: theme.palette.shades.purpleBorder,
              borderColor: theme.palette.shades.purpleBorder,
            },
          },
          maintainAspectRatio: false,
          responsive: process.env.NODE_ENV !== 'test',
          plugins: {
            title: {
              display: false,
              text: 'title',
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            y: {
              border: {
                display: false,
              },
              grid: {
                display: true,
                color: theme.palette.secondary.main,
                lineWidth: 2,
              },
              title: {
                display: true,
                text: 'Volume',
                font: {
                  family: theme.typography.meta.fontFamily,
                  size: 16,
                  weight: 'normal',
                },
              },
              min: MIN_VALUE,
              max: MAX_VALUE,
              ticks: {
                stepSize: 10,
                color: theme.palette.shades.blackBorder,
                font: {
                  family: theme.typography.meta.fontFamily,
                  size: 14,
                  weight: 'normal',
                },
              },
            },
            x: {
              border: {
                display: false,
              },
              grid: {
                display: true,
                color: (context) => {
                  if (context.index === lastSelectedColumnIndex) {
                    return props.lineColorPrimary;
                  } else {
                    return theme.palette.secondary.main;
                  }
                },
                lineWidth: 2,
              },
              title: {
                display: false,
                text: 'kHz',
                font: {
                  family: theme.typography.meta.fontFamily,
                  size: 14,
                  weight: 'normal',
                },
                align: 'end',
              },
              ticks: {
                font: {
                  family: theme.typography.meta.fontFamily,
                  size: 14,
                  weight: 'normal',
                },
              },
            },
          },
        }}
      />
      {columnLinePosition && (
        <Box>
          {chartData.datasets[0].data.map((value, index) => {
            if (index !== lastSelectedColumnIndex) {
              return (
                <Typography
                  key={uuidv4()}
                  marginTop={2}
                  component="p"
                  variant="meta"
                  position="absolute"
                  align="center"
                  width={30}
                  sx={{ left: columnLinePosition[index].x1 - 15 }}
                >
                  {value}
                </Typography>
              );
            } else {
              return (
                <Box
                  key={uuidv4()}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  position="absolute"
                  bgcolor={theme.palette.white.main}
                  width={110}
                  marginTop={0.5}
                  sx={{
                    borderWidth: '0.5px',
                    borderStyle: 'solid',
                    borderRadius: '10px',
                    left: columnLinePosition[index].x1 - 50,
                  }}
                >
                  <IconButton id={`volume-decrease-btn-${index}`} size="small" onClick={handleVolumeDecrease}>
                    <RemoveCircleOutlineIcon color="primary" />
                  </IconButton>
                  <Typography marginX={1}>{chartData.datasets[0].data[index]}</Typography>
                  <IconButton id={`volume-increase-btn-${index}`} size="small" onClick={handleVolumeIncrease}>
                    <ControlPointIcon color="primary" />
                  </IconButton>
                </Box>
              );
            }
          })}
          <Typography
            component="p"
            variant="meta"
            position="absolute"
            marginTop="-20px"
            left={columnLinePosition[columnLinePosition.length - 1].x1 + 20}
          >
            kHz
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default EQChart;
