import { AxiosResponse } from 'axios';
import { backendApiInstance } from './backendApiInstance';
import { SaveFeedbackPayload } from '../redux/user/userSlice';

export async function updatePassword(token: string, newPassword: string, isNewUser: boolean): Promise<AxiosResponse> {
  return backendApiInstance.put('users/reset-password', {
    token,
    newPassword,
    isNewUser,
  });
}

export async function forgotPassword(email: string): Promise<AxiosResponse> {
  return backendApiInstance.post('users/send-reset-password-link', { email });
}

export async function validatePasswordResetToken(token: string): Promise<AxiosResponse> {
  return backendApiInstance.post('users/validate-password-reset-token', {
    token,
  });
}

export async function getUser(): Promise<AxiosResponse> {
  return backendApiInstance.get('users');
}

interface UserUpdateData {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  profileImageUrl?: string;
}
export async function updateUser(data: UserUpdateData): Promise<AxiosResponse> {
  return backendApiInstance.put('users', data);
}

export async function sendEmailVerificationCode(email: string): Promise<AxiosResponse> {
  return backendApiInstance.post('users/send-email-verification-otp', {
    email,
  });
}

export async function verifyEmailVerificationCode(verificationCode: string): Promise<AxiosResponse> {
  return backendApiInstance.post('users/verify-email', {
    otp: verificationCode,
  });
}

export async function changePassword(password: string, newPassword: string): Promise<AxiosResponse> {
  return backendApiInstance.put('users/change-password', {
    password,
    newPassword,
  });
}

export async function uploadAvatar(image: FormData): Promise<AxiosResponse> {
  return backendApiInstance.post('users/avatar', image, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export async function validateEmail(email: string): Promise<AxiosResponse> {
  return backendApiInstance.get('users/validate-email', { params: { email } });
}

export async function saveFeedback(feedback: SaveFeedbackPayload): Promise<AxiosResponse> {
  return backendApiInstance.post('users/feedback', feedback);
}
