import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Box, Button, Card, Grid, Typography, useTheme, Fade } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useAuth } from '../../../context/AuthContext';
import { TopBarViewProps, authActions } from '../../../redux/auth/authSlice';
import { SIDEBAR_OPTIONS } from '../../../utils/sidebar-schema';
import { SidebarOption } from '../../../utils/enums/sidebar-option.enum';
import { Roles } from '../../../utils/enums/roles.enum';
import { Permission } from '../../../utils/enums/permission.enum';
import { generalActions } from '../../../redux/general/generalSlice';
import { RootState } from '../../../redux/store';
import _ from 'lodash';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import LoadingTypography from '../../../components/LoadingTypography/LoadingTypography';
import { patientActions } from '../../../redux/patient/patientSlice';
import PatientsBold from '../../../assets/img/patients-bold.svg';
import * as dateFns from 'date-fns';
import { ReactComponent as PenLinearSVG } from '../../../assets/img/pen-linear.svg';
import AddEditPatient from '../AddEditPatient/AddEditPatient';
import AddEditPatientSuccessModal from '../AddEditPatient/AddEditPatientSuccessModal';
import Cosit from './TreatmentDetails/Cosit/CositDetails';
import TinnitusAvatar from './TreatmentDetails/TinnitusAvatar/TinnitusAvatar';
import TreatmentTimeline from './TreatmentDetails/TreatmentTimeline/TreatmentTimeline';
import DailyFeedback from '../../../components/DailyFeedback/DailyFeedback';
import Constant from '../../../constants/constant.json';
import GoalImprovementFeedback from '../../../components/DailyFeedback/GoalImprovementFeedback';
import InfoIconSVG from '../../../assets/img/infoIcon.svg';
import { IdDescriptions } from '../../../utils/enums/ids.enum';
import { LightTooltip } from '../../../components/LightTooltip/LightTooltip';

function PatientDetails(props: { userId?: string }) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();
  let { userId } = useParams();

  function getDescription(name: string) {
    const descriptions: Record<string, string> = {
      TSPID: IdDescriptions.TSPID,
      PID: IdDescriptions.PID,
      TID: IdDescriptions.TID,
      TSTID: IdDescriptions.TSTID,
    };

    return descriptions[name] ?? '';
  }

  const patientsDetails = useAppSelector((state: RootState) => state.patient.patientsDetails);
  const cositData = useAppSelector((state: RootState) => state.patient.cosit);
  const previousSidebarType = useAppSelector((state: RootState) => state.patient.currentSidebarType);

  const componentStatus = useAppSelector((state: RootState) => state.general.componentStatus);
  const isPatientDetailsLoading = _.get(componentStatus, 'isPatientDetailsLoading', false) as boolean;
  const shouldPatientDetailsReload = _.get(componentStatus, 'shouldPatientDetailsReload', '') as string;
  const isCositLoading = _.get(componentStatus, 'isCositLoading', false) as boolean;
  const avatarData = useAppSelector((state: RootState) => state.patient.avatar);
  const isTinnitusAvatarDataLoading = _.get(componentStatus, 'isTinnitusAvatarDataLoading', false) as boolean;
  const isPatientFeedbackLoading = _.get(componentStatus, 'isPatientFeedbackLoading', false) as boolean;
  const feedbackData = useAppSelector((state: RootState) => state.patient.patientFeedback);

  const isSuperAdminOrTrueSilenceAdmin = (role: string) =>
    [Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role);
  const getEQText = (isSoundConfigSet: boolean | undefined): string => {
    return isSoundConfigSet ? 'Update EQ' : 'Set EQ';
  };
  const getColor = (isSoundConfigSet: boolean | undefined): 'primary' | 'error' => {
    return isSoundConfigSet ? 'primary' : 'error';
  };
  const getCositText = (patientId: string | null): string => {
    return patientId !== null ? 'Update COSIT' : 'Set COSIT';
  };
  const getPatientName = (firstName: string | undefined, lastName: string | undefined): string => {
    return `${firstName ?? ''} ${lastName ?? ''}`;
  };
  const getTopBarText = (
    role: string,
    firstName: string | undefined,
    lastName: string | undefined,
    globalId: string | undefined,
  ): string => {
    if ([Roles.CLINIC_ADMIN, Roles.CLINICIAN].includes(user.role)) {
      return `${firstName ?? ''} ${lastName ?? ''}`;
    }
    return globalId ?? '';
  };
  const getEditPatientButton = (
    role: string,
    permissions: string[],
  ): { text: string; icon: React.JSX.Element; color: 'error' | 'primary'; onClick: () => void } | undefined => {
    if (
      (role === Roles.CLINIC_ADMIN && permissions.includes(Permission.CLINIC_ADMIN_EDIT_PATIENT)) ||
      (role === Roles.CLINICIAN && patientsDetails?.clinicianUserId === user.userId)
    ) {
      return {
        text: 'Edit Patient',
        icon: <PenLinearSVG />,
        color: 'primary',
        onClick: () => {
          dispatch(patientActions.setAddEditPatientData(undefined));
          dispatch(generalActions.clearComponentStatus());
          dispatch(generalActions.setComponentStatus({ isAddEditPatientModalOpen: true }));
        },
      };
    }
    return undefined;
  };

  const getSideMenuPath = (previousSidebarType: string | undefined): string => {
    if (user.role === Roles.CLINICIAN && patientsDetails?.clinicianUserId === user.userId) {
      return previousSidebarType === SidebarOption.CLINIC_PATIENTS
      ? SIDEBAR_OPTIONS[SidebarOption.CLINIC_PATIENTS].path
      : SIDEBAR_OPTIONS[SidebarOption.MY_PATIENTS].path;
    } else {
      return previousSidebarType === SidebarOption.CLINIC_PATIENTS
      ? SIDEBAR_OPTIONS[SidebarOption.CLINIC_PATIENTS].path
      : SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path;
    }    
  };

  useEffect(() => {
    let topBarview: TopBarViewProps = {};

    if (!isPatientDetailsLoading) {
      topBarview = {
        text: getTopBarText(
          user.role,
          patientsDetails?.firstName,
          patientsDetails?.lastName,
          patientsDetails?.globalId,
        ),
        path: SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path,
        sideMenuPath: SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path,
        backButton: { path: SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path },
        backNav: Boolean(userId),
      };
      if (user.role === Roles.CLINIC_ADMIN) {
        dispatch(
          authActions.setTopBarView({
            text: getPatientName(patientsDetails?.firstName, patientsDetails?.lastName),
            path: SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path,
            sideMenuPath: SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path,
            backButton: { path: SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path },
            backNav: Boolean(userId),
            button: getEditPatientButton(user.role, user.permissions),
          }),
        );
      } else if (user.role === Roles.CLINICIAN) {
        dispatch(
          authActions.setTopBarView({
            text: getPatientName(patientsDetails?.firstName, patientsDetails?.lastName),
            path: SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path,
            sideMenuPath: getSideMenuPath(previousSidebarType),
            backButton: { path: SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path },
            backNav: Boolean(userId),
            customElement:
              patientsDetails?.clinicianUserId === user.userId ? (
                <Box sx={{ display: 'inline-flex', minWidth: 'fit-content'}}>
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: '10px', marginRight: '5px' }}
                    color='primary'
                    onClick={() => {
                      dispatch(patientActions.setAddEditPatientData(undefined));
                      dispatch(generalActions.clearComponentStatus());
                      dispatch(generalActions.setComponentStatus({ isAddEditPatientModalOpen: true }));
                    }}
                  >
                    <PenLinearSVG></PenLinearSVG>
                    <Typography sx={{ paddingLeft: '5px' }} variant="meta">Edit Patient</Typography>
                  </Button>
                  {cositData.patientId !== '' && (
                    <Button
                      variant="contained"
                      sx={{ borderRadius: '10px', marginRight: '5px' }}
                      color={cositData.patientId !== null ? 'primary' : 'error'}
                      onClick={() => {
                        navigate(`/patients/${userId}/cosit`, { replace: true });
                        navigate(0);
                      }}
                    >
                      <Typography variant="meta">{getCositText(cositData.patientId)}</Typography>
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    sx={{ borderRadius: '10px', marginRight: '5px' }}
                    color={getColor(patientsDetails?.isSoundConfigSet)}
                    onClick={() => {
                      navigate(`/patients/${userId}/set-eq`, { replace: true });
                      navigate(0);
                    }}
                  >
                    <Typography variant="meta">{getEQText(patientsDetails?.isSoundConfigSet)}</Typography>
                  </Button>
                </Box>
              ) : undefined,
          }),
        );
      } else if (isSuperAdminOrTrueSilenceAdmin(user.role)) {
        dispatch(
          authActions.setTopBarView({
            text: patientsDetails?.globalId ?? '',
            path: SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path,
            sideMenuPath: SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path,
            backButton: { path: SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path },
            backNav: Boolean(userId),
          }),
        );
      } else {
        dispatch(authActions.setTopBarView(topBarview));
      }
    } else {
      dispatch(authActions.setTopBarView(undefined));
    }
  }, [user, patientsDetails, isPatientDetailsLoading, isCositLoading]);

  useEffect(() => {
    if (
      patientsDetails?.patientId &&
      [Roles.CLINICIAN, Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role)
    ) {
      dispatch(patientActions.getAllPatientFeedbackAsync({ patientId: patientsDetails.patientId }));
    }
  }, [shouldPatientDetailsReload, patientsDetails, isPatientDetailsLoading]);

  useEffect(() => {
    userId = userId ?? props.userId;
    if (userId) {
      dispatch(patientActions.getPatientAsync({ userId }));
      if ([Roles.CLINICIAN, Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role)) {
        dispatch(patientActions.getCositDataAsync({ patientId: userId }));
        dispatch(patientActions.getTinnitusAvatarDataAsync({ patientId: userId }));
      }
    } else {
      navigate(`/patients`);
    }
  }, [shouldPatientDetailsReload]);

  const gridRef = useRef<HTMLDivElement | null>(null);
  const [barThickness, setBarThickness] = useState(3.5);

  useEffect(() => {
    if (gridRef.current) {
      setBarThickness((gridRef.current.offsetWidth - 64) / 90 - 2);
    }
  }, []);

  const getOrderedPatientInfo = () => {
    if ([Roles.CLINICIAN, Roles.CLINIC_ADMIN].includes(user.role)) {
      return [
        { name: 'First Name', value: _.get(patientsDetails, 'firstName', '') },
        { name: 'Last Name', value: _.get(patientsDetails, 'lastName', '') },
        { name: 'Middle Name', value: _.get(patientsDetails, 'surname', '') },
        { name: 'Gender', value: _.get(patientsDetails, 'gender', '') },
        { name: 'PID', value: _.get(patientsDetails, 'pid', '') },
        { name: 'TSPID', value: _.get(patientsDetails, 'globalId', '') },
        { name: 'Email Address', value: _.get(patientsDetails, 'email', '') },
        { name: 'Phone Number', value: _.get(patientsDetails, 'phone', '') },
        { name: 'Age', value: _.get(patientsDetails, 'age', '') },
        { name: 'Address Line 1', value: _.get(patientsDetails, 'addressLine1', '') },
        { name: 'Address Line 2', value: _.get(patientsDetails, 'addressLine2', '') },
        { name: 'City', value: _.get(patientsDetails, 'city', '') },
        { name: 'State/ Region', value: _.get(patientsDetails, 'state', '') },
        { name: 'Zip Code', value: _.get(patientsDetails, 'zipCode', '') },
        { name: 'Country', value: _.get(patientsDetails, 'country', '') },
        {
          name: 'Joined Date',
          value: patientsDetails?.startDate ? dateFns.format(new Date(patientsDetails?.startDate), 'MM/dd/yyyy') : '',
        },
      ];
    } else {
      return [
        { name: 'TSPID', value: _.get(patientsDetails, 'globalId', '') },
        { name: 'Gender', value: _.get(patientsDetails, 'gender', '') },
        { name: 'Age', value: _.get(patientsDetails, 'age', '') },
        { name: 'City', value: _.get(patientsDetails, 'city', '') },
        { name: 'State/ Region', value: _.get(patientsDetails, 'state', '') },
        { name: 'Zip Code', value: _.get(patientsDetails, 'zipCode', '') },
        { name: 'Country', value: _.get(patientsDetails, 'country', '') },
        {
          name: 'Joined Date',
          value: patientsDetails?.startDate ? dateFns.format(new Date(patientsDetails?.startDate), 'MM/dd/yyyy') : '',
        },
        { name: 'Clinic', value: _.get(patientsDetails, 'clinicName', '') },
      ];
    }
  };

  const getOrderedClinicianInfo = () => {
    if ([Roles.CLINIC_ADMIN].includes(user.role)) {
      return [
        { name: 'First Name', value: _.get(patientsDetails, 'clinicianFirstName', '') },
        { name: 'Last Name', value: _.get(patientsDetails, 'clinicianLastName', '') },
        { name: 'Middle Name', value: _.get(patientsDetails, 'clinicianSurname', '') },
        { name: 'TID', value: _.get(patientsDetails, 'clinicianClinicBaseId', '') },
        { name: 'TSTID', value: _.get(patientsDetails, 'clinicianGlobalId', '') },
        { name: 'Email Address', value: _.get(patientsDetails, 'clinicianEmail', '') },
        { name: 'Phone Number', value: _.get(patientsDetails, 'clinicianPhone', '') },
        {
          name: 'Birth Date',
          value: patientsDetails?.clinicianBirthday
            ? dateFns.format(new Date(patientsDetails.clinicianBirthday), 'MM/dd/yyyy')
            : '',
        },
        { name: 'Address Line 1', value: _.get(patientsDetails, 'clinicianAddressLine1', '') },
        { name: 'Address Line 2', value: _.get(patientsDetails, 'clinicianAddressLine2', '') },
        { name: 'City', value: _.get(patientsDetails, 'clinicianCity', '') },
        { name: 'State/ Region', value: _.get(patientsDetails, 'clinicianState', '') },
        { name: 'Zip Code', value: _.get(patientsDetails, 'clinicianZipCode', '') },
        { name: 'Country', value: _.get(patientsDetails, 'clinicianCountry', '') },
      ];
    } else if (user.role === Roles.CLINICIAN && patientsDetails?.clinicianUserId !== user.userId) {
      return [
        { name: 'TID', value: _.get(patientsDetails, 'clinicianClinicBaseId', '') },
        { name: 'TSTID', value: _.get(patientsDetails, 'clinicianGlobalId', '') },
        { name: 'First Name', value: _.get(patientsDetails, 'clinicianFirstName', '') },
        { name: 'Last Name', value: _.get(patientsDetails, 'clinicianLastName', '') },
        { name: 'Middle Name', value: _.get(patientsDetails, 'clinicianSurname', '') },
      ];
    } else {
      return [
        {
          name: 'Clinician Name',
          value: `${_.get(patientsDetails, 'clinicianFirstName', '')} ${_.get(
            patientsDetails,
            'clinicianSurname',
            '',
          )} ${_.get(patientsDetails, 'clinicianLastName', '')}`.trim(),
        },
        { name: 'TSTID', value: _.get(patientsDetails, 'clinicianGlobalId', '') },
        { name: 'City', value: _.get(patientsDetails, 'clinicianCity', '') },
        { name: 'State/ Region', value: _.get(patientsDetails, 'clinicianState', '') },
        { name: 'Zip Code', value: _.get(patientsDetails, 'clinicianZipCode', '') },
        { name: 'Country', value: _.get(patientsDetails, 'clinicianCountry', '') },
        { name: 'Clinic', value: _.get(patientsDetails, 'clinicName', '') },
      ];
    }
  };

  return (
    <Fragment>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} lg={6}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12}>
              <Card
                sx={{
                  borderRadius: '10px',
                  padding: '24px',
                  minHeight: '209px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} md={3} display="flex" justifyContent="start">
                    <AvatarEdit
                      isLoading={isPatientDetailsLoading}
                      avatar={
                        [Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role)
                          ? undefined
                          : patientsDetails?.avatar
                      }
                      editable={false}
                      name={patientsDetails?.firstName}
                      name2={patientsDetails?.lastName}
                      font={theme.typography.h2}
                      size="100px"
                      dummy={[Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role)}
                    />
                  </Grid>
                  <Grid item xs={12} md={9} paddingX={2}>
                    <Box
                      sx={
                        [Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role)
                          ? {
                              display: 'flex',
                              flexWrap: 'wrap',
                              alignContent: 'flex-start',
                              borderRadius: '20px',
                              columnGap: '16px',
                              rowGap: '25px',
                            }
                          : { display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', borderRadius: '20px' }
                      }
                    >
                      {getOrderedPatientInfo().map(({ name, value }, index) => {
                        let description = getDescription(name);
                        return (
                          <Box
                            id={`patient ${name} id`}
                            key={`p-${name.replace(/ /g, '-')}`}
                            width={[Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role) ? 'auto' : '50%'}
                            padding={1}
                          >
                            <Box display="flex" alignItems="center">
                              <Typography
                                variant="bodyRegular"
                                color={theme.palette.shades.purpleBorder}
                                component="div"
                              >
                                {name}
                              </Typography>
                              {(name === 'TSPID' || name === 'PID') &&
                                [Roles.CLINIC_ADMIN, Roles.CLINICIAN].includes(user.role) && (
                                  <LightTooltip
                                    arrow
                                    placement="right"
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    title={description}
                                    children={
                                      <Box sx={{ cursor: 'pointer' }} component="img" src={InfoIconSVG} ml={0.8}></Box>
                                    }
                                  />
                                )}
                            </Box>
                            <LoadingTypography
                              isLoading={isPatientDetailsLoading}
                              variant="bodyRegular"
                              color={theme.palette.info.main}
                              component="p"
                              sx={{ wordWrap: 'break-word' }}
                            >
                              {value ?? '-'}
                            </LoadingTypography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            {([Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN, Roles.CLINIC_ADMIN].includes(user.role) ||
              (user.role === Roles.CLINICIAN && patientsDetails?.clinicianUserId !== user.userId)) && (
              <Grid item xs={12}>
                <Card
                  sx={{
                    borderRadius: '10px',
                    padding: '24px',
                    minHeight: '209px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: user.role === Roles.CLINICIAN ? 'column' : 'inherit',
                  }}
                >
                  {user.role === Roles.CLINICIAN && (
                    <Grid
                      container
                      sx={{ borderBottom: '0.5px solid #3800AE4D', width: '100%' }}
                      marginBottom={'24px'}
                      marginX={'24px'}
                      columnSpacing={2}
                    >
                      <Typography variant="h5" mb={'24px'}>
                        Clinician
                      </Typography>
                    </Grid>
                  )}
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12} md={3} display="flex" justifyContent="start">
                      <AvatarEdit
                        isLoading={isPatientDetailsLoading}
                        avatar={patientsDetails?.clinicianAvatar}
                        editable={false}
                        name={patientsDetails?.clinicianFirstName}
                        name2={patientsDetails?.clinicianLastName}
                        font={theme.typography.h2}
                        size="100px"
                        dummy={[Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role)}
                      />
                    </Grid>
                    <Grid item xs={12} md={9} paddingX={2}>
                      <Box
                        sx={
                          [Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role)
                            ? {
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignContent: 'flex-start',
                                borderRadius: '20px',
                                columnGap: '16px',
                                rowGap: '25px',
                              }
                            : { display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', borderRadius: '20px' }
                        }
                      >
                        {getOrderedClinicianInfo().map(({ name, value }, index) => {
                          let description = getDescription(name);

                          return (
                            <Box
                              id={`clinician ${name} id`}
                              key={`c-${name.replace(/ /g, '-')}`}
                              width={[Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role) ? 'auto' : '50%'}
                              padding={1}
                            >
                              <Box display="flex" alignItems="center">
                                <Typography
                                  variant="bodyRegular"
                                  color={theme.palette.shades.purpleBorder}
                                  component="div"
                                >
                                  {name}
                                </Typography>
                                {(name === 'TSTID' || name === 'TID') &&
                                  [Roles.CLINIC_ADMIN, Roles.CLINICIAN].includes(user.role) && (
                                    <LightTooltip
                                      arrow
                                      placement="right"
                                      TransitionComponent={Fade}
                                      TransitionProps={{ timeout: 600 }}
                                      title={description}
                                      children={
                                        <Box
                                          sx={{ cursor: 'pointer' }}
                                          component="img"
                                          src={InfoIconSVG}
                                          ml={0.8}
                                        ></Box>
                                      }
                                    />
                                  )}
                              </Box>
                              <LoadingTypography
                                isLoading={isPatientDetailsLoading}
                                variant="bodyRegular"
                                color={theme.palette.info.main}
                                component="p"
                                sx={{ wordWrap: 'break-word' }}
                              >
                                {value ?? '-'}
                              </LoadingTypography>
                            </Box>
                          );
                        })}
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}
            {[Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN, Roles.CLINICIAN].includes(user.role) && (
              <>
                <Grid item xs={12}>
                  <TreatmentTimeline patientUserId={userId ?? ''} />
                </Grid>

                {!isPatientFeedbackLoading && feedbackData.dailyFeedback && (
                  <Grid item xs={12}>
                    <DailyFeedback
                      title={'Daily Feedback'}
                      question={'Based on daily feedback to the question "How are you feeling today?"'}
                      data={[{ data: feedbackData.dailyFeedback, color: theme.palette.shades.purpleBorder }]}
                      answers={Constant.FeedbackAnswers.DAILY}
                      barThickness={barThickness}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={6}>
              <Card
                sx={{
                  borderRadius: '10px',
                  padding: '24px',
                  minHeight: '209px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                  position: 'relative',
                }}
              >
                <LoadingTypography
                  isLoading={isPatientDetailsLoading}
                  variant="h3"
                  color={theme.palette.black.main}
                  sx={{ minWidth: '100px' }}
                >
                  {`${patientsDetails?.progress ?? ''}%`}
                </LoadingTypography>
                <Typography position="absolute" bottom="24px" variant="bodyRegular" color={theme.palette.info.main}>
                  Progress
                </Typography>
                <Box component="img" src={PatientsBold}></Box>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                sx={{
                  borderRadius: '10px',
                  padding: '24px',
                  minHeight: '209px',
                  display: 'flex',
                  alignItems: 'start',
                  position: 'relative',
                }}
              >
                <LoadingTypography
                  isLoading={isPatientDetailsLoading}
                  variant="h3"
                  color={theme.palette.black.main}
                  sx={{ minWidth: '100px' }}
                >
                  {patientsDetails?.startDate ? dateFns.format(new Date(patientsDetails.startDate), 'MM/dd/yyyy') : ''}
                </LoadingTypography>
                <Typography position="absolute" bottom="24px" variant="bodyRegular" color={theme.palette.info.main}>
                  Start Date
                </Typography>
              </Card>
            </Grid>
            {user.role === Roles.CLINIC_ADMIN && (
              <Grid item xs={12}>
                <TreatmentTimeline patientUserId={userId ?? ''} />
              </Grid>
            )}
            {[Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN, Roles.CLINICIAN].includes(user.role) && (
              <>
                {!isTinnitusAvatarDataLoading && avatarData && (
                  <Grid item xs={12}>
                    <TinnitusAvatar
                      isLoading={isTinnitusAvatarDataLoading}
                      avatar={avatarData}
                      patientUserId={userId ?? ''}
                    />
                  </Grid>
                )}
                {cositData.patientId && (
                  <Grid item xs={12}>
                    <Cosit isLoading={isCositLoading} order={cositData.goals} patientUserId={userId as string} />
                  </Grid>
                )}

                {!isPatientFeedbackLoading && feedbackData.tinnitusProgressFeedback && (
                  <Grid ref={gridRef} item xs={12}>
                    <DailyFeedback
                      title={'Tinnitus Progress'}
                      question={'Based on daily feedback to the question "How is your Tinnitus today?"'}
                      data={[{ data: feedbackData.tinnitusProgressFeedback, color: theme.palette.shades.purpleBorder }]}
                      answers={Constant.FeedbackAnswers.TINNITUS}
                      barThickness={barThickness}
                    />
                  </Grid>
                )}

                {!isPatientFeedbackLoading && feedbackData.everyFiveDaysFeedback && (
                  <Grid item xs={12}>
                    <GoalImprovementFeedback />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>

      {patientsDetails && <AddEditPatient patientId={patientsDetails.patientId} />}
      <AddEditPatientSuccessModal />
    </Fragment>
  );
}

export default PatientDetails;
