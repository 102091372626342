import axios from 'axios';
import { store } from '../redux/store';
import { authActions } from '../redux/auth/authSlice';
import { createNewAccessToken } from './authApi';
import { generalActions } from '../redux/general/generalSlice';
import * as _ from 'lodash';

const createAxiosInstance = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_CLOUD_URL,
    withCredentials: true,
  });

  // Response interceptor
  instance.interceptors.response.use(
    (response) => {
      const timestamp = new Date().getTime();

      // update last BE req sent time (ignore get notification count)
      if (
        !['auth/access-token', 'notifications/count'].some((url) => response.request.responseURL.includes(url)) &&
        timestamp - store.getState().auth.lastActiveTime > 1000 * 5
      ) {
        // keeps 5 seconds min gap

        localStorage.setItem('lastActiveTime', String(timestamp));
        localStorage.removeItem('lastActiveTime');

        store.dispatch(authActions.setLastActiveTime(timestamp));
      }
      return response;
    },
    async (error) => {
      const initialRequest = error.config;

      // Check if the req is generate new access token and it gets 400 res, should need to log out the user or If the second request also gets unauthorized, log out the user
      if (
        (error.response && error.response.status === 400 && error.request?.responseURL.includes('auth/access-token')) ||
        (error.response && error.response.status === 401 && initialRequest._retry)
      ) {
        store.dispatch(authActions.setLogInStatus(false));
        store.dispatch(authActions.clearComponentStatus());
        store.dispatch({ type: 'CLEAR_STORE' });
      }

      // Check if the response status code is 401 (unauthorized)
      else if (
        error.response &&
        error.response.status === 401 &&
        !['auth/login'].some((url) => error.request.responseURL.includes(url)) &&
        !initialRequest._retry
      ) {
        initialRequest._retry = true; // Mark the request as already retried

        try {
          // Create new access token
          await createNewAccessToken();

          // Retry the original request with the new access token
          return instance(initialRequest);
        } catch (refreshError) {
          console.log(refreshError);
          // Handle the error when refreshing the token fails
          if (_.get(refreshError, 'response.status') === 400) {
            store.dispatch(authActions.setLogInStatus(false));
            store.dispatch(authActions.clearComponentStatus());
            store.dispatch({ type: 'CLEAR_STORE' });
          }
          return Promise.reject(refreshError);
        }
      }

      // service unavailable error message
      else if (error.response && error.response.status === 503) {
        store.dispatch(
          generalActions.setAlertMessage({
            severity: 'error',
            msg: 'Operation failed due to a server issue. Please retry now and if the issue persists, please try again later.',
          }),
        );
      }
      // network connectivity issue error message
      else if (error.code === 'ERR_NETWORK') {
        store.dispatch(
          generalActions.setAlertMessage({
            severity: 'error',
            msg: 'Operation failed due to a connectivity issue. Please try again later.',
          }),
        );
      }

      // Return any other errors
      return Promise.reject(error);
    },
  );

  return instance;
};

export const backendApiInstance = createAxiosInstance();
