import React, { Fragment, useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { theme } from '../../../theme/theme';
import CustomTextArea from '../../../components/CustomTextArea/CustomTextArea';
import { Theme, styled as materialStyled } from '@mui/material/styles';
import CustomDragList from '../../../components/CustomDragList/CustomDragList';
import { DropResult } from '@hello-pangea/dnd';
import CustomButtonLoadingButton from '../../../components/CustomButtonLoadingButton/CustomButtonLoadingButton';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { GoalsType, patientActions } from '../../../redux/patient/patientSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import _ from 'lodash';
import { useAuth } from '../../../context/AuthContext';
import { SIDEBAR_OPTIONS } from '../../../utils/sidebar-schema';
import { SidebarOption } from '../../../utils/enums/sidebar-option.enum';
import { authActions } from '../../../redux/auth/authSlice';
import { useParams } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomModal from '../../../components/CustomModal/CustomModal';
import GreenCheckCircleSVG from '../../../assets/img/check-circle-bold-duotone.svg';
import { generalActions } from '../../../redux/general/generalSlice';

const VerticalDivider = materialStyled(Divider)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.shades.purpleBorder,
  width: '0.5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const HorizontalDivider = materialStyled(Divider)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.shades.purpleBorder,
  margin: '26px 0',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

function Cosit() {
  const { user } = useAuth();
  const { userId: patientId } = useParams();
  const dispatch = useAppDispatch();

  const componentStatus = useAppSelector((state: RootState) => state.general.componentStatus);
  const patientsDetails = useAppSelector((state: RootState) => state.patient.patientsDetails);
  const isCositLoading = _.get(componentStatus, 'isCositLoading', false) as boolean;
  const cositData = useAppSelector((state: RootState) => state.patient.cosit);
  const isCositSuccessModalOpen = _.get(componentStatus, 'isCositSuccessModalOpen', false) as boolean;
  const isSubmitting = _.get(componentStatus, 'isSubmitting', false) as boolean;
  const isCositNew = _.get(componentStatus, 'isCositNew', false) as boolean;
  const [goals, setGoals] = useState<GoalsType[]>(cositData.goals);
  const formik = useFormik({
    initialValues: {
      sleep: '',
      communicationHearing: '',
      peaceQuiet: '',
      concentration: '',
      stressMood: '',
    },
    validationSchema: yup.object({
      sleep: yup.string().max(500),
      communicationHearing: yup.string().max(500),
      peaceQuiet: yup.string().max(500),
      concentration: yup.string().max(500),
      stressMood: yup.string().max(500),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {
      if (isCositNew) {
        dispatch(
          patientActions.createCositDataAsync({
            patientId: patientId as string,
            sleep: formik.values.sleep,
            communication: formik.values.communicationHearing,
            peace: formik.values.peaceQuiet,
            concentration: formik.values.concentration,
            stress: formik.values.stressMood,
            goals: _.map(goals, 'id'),
          }),
        );
      } else {
        dispatch(
          patientActions.updateCositDataAsync({
            patientId: patientId as string,
            sleep: formik.values.sleep,
            communication: formik.values.communicationHearing,
            peace: formik.values.peaceQuiet,
            concentration: formik.values.concentration,
            stress: formik.values.stressMood,
            goals: _.map(goals, 'id'),
          }),
        );
      }
    },
  });

  useEffect(() => {
    dispatch(
      authActions.setTopBarView({
        text: `${patientsDetails?.firstName ?? ''} ${patientsDetails?.lastName ?? ''}`,
        path: `${SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path}/${patientId}`,
        sideMenuPath: SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path,
        backButton: { path: `${SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path}/${patientId}` },
        customElement: (
          <Fragment>
            {isCositLoading ? (
              <Fragment></Fragment>
            ) : (
              <Fragment>
                <ArrowForwardIosIcon sx={{ color: theme.palette.primary.main }} />
                <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
                  {isCositNew ? 'Set COSIT' : 'Update COSIT'}
                </Typography>
              </Fragment>
            )}
          </Fragment>
        ),
      }),
    );
  }, [user, patientsDetails, isCositNew, isCositLoading]);

  useEffect(() => {
    dispatch(patientActions.getPatientAsync({ userId: patientId as string }));
    dispatch(patientActions.getCositDataAsync({ patientId: patientId as string }));
  }, []);

  useEffect(() => {
    setGoals(cositData.goals);
    formik.initialValues.sleep = cositData.sleep;
    formik.initialValues.communicationHearing = cositData.communication;
    formik.initialValues.peaceQuiet = cositData.peace;
    formik.initialValues.concentration = cositData.concentration;
    formik.initialValues.stressMood = cositData.stress;
  }, [cositData]);

  const onDragEnd = ({ destination, source, draggableId }: DropResult) => {
    // dropped outside the list
    if (!destination) {
      return;
    }
    //no change in positions
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }
    const updatedGoals = Array.from(goals);
    const [removed] = updatedGoals.splice(source.index, 1);
    updatedGoals.splice(destination.index, 0, removed);
    setGoals(updatedGoals);
  };

  const handleSuccessMessageModalClose = () => {
    dispatch(generalActions.setComponentStatus({ isCositSuccessModalOpen: false }));
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.white.main,
        borderRadius: '20px',
        padding: '32px',
        boxShadow: '1',
      }}
    >
      <Grid container>
        <Grid item xs={12} md={5.7}>
          <Typography marginBottom={2} variant="h5">
            Rank The Goals
          </Typography>
          <Typography variant="bodyRegular" sx={{ color: theme.palette.info.main }}>
            Rank patient goals by priority
          </Typography>
          <Grid container marginTop={1}>
            <CustomDragList
              key="droppable"
              onDragEnd={onDragEnd}
              goals={goals}
              isLoading={isCositLoading}
            ></CustomDragList>
          </Grid>
        </Grid>

        <Grid xs={12} item md={0.6}>
          <VerticalDivider orientation="vertical"></VerticalDivider>
        </Grid>
        <Grid
          item
          xs={12}
          md={5.7}
          sx={{
            [theme.breakpoints.down('md')]: {
              marginTop: '26px ',
            },
          }}
        >
          <Typography marginBottom={2} variant="h5">
            Notes
          </Typography>
          <Typography variant="bodyRegular" sx={{ color: theme.palette.info.main }}>
            Add any notes you have about patient for each goal
          </Typography>
          <Grid container marginTop={4} spacing={2}>
            <Grid item xs={12} lg={6}>
              <CustomTextArea
                label="Sleep"
                placeholder="Add Notes"
                name="sleep"
                value={formik.values.sleep}
                onChange={formik.handleChange}
                isLoading={isCositLoading}
              ></CustomTextArea>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomTextArea
                label="Communication/Hearing"
                placeholder="Add Notes"
                name="communicationHearing"
                value={formik.values.communicationHearing}
                onChange={formik.handleChange}
                isLoading={isCositLoading}
              ></CustomTextArea>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomTextArea
                label="Peace and Quiet"
                placeholder="Add Notes"
                name="peaceQuiet"
                value={formik.values.peaceQuiet}
                onChange={formik.handleChange}
                isLoading={isCositLoading}
              ></CustomTextArea>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomTextArea
                label="Concentration"
                placeholder="Add Notes"
                name="concentration"
                value={formik.values.concentration}
                onChange={formik.handleChange}
                isLoading={isCositLoading}
              ></CustomTextArea>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomTextArea
                label="Stress/Mood"
                placeholder="Add Notes"
                name="stressMood"
                value={formik.values.stressMood}
                onChange={formik.handleChange}
                isLoading={isCositLoading}
              ></CustomTextArea>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <HorizontalDivider />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={'flex-end'}
        sx={{
          [theme.breakpoints.down('md')]: {
            paddingTop: '26px ',
          },
        }}
      >
        <CustomButtonLoadingButton
          loading={isSubmitting}
          onClick={() => formik.handleSubmit()}
          width="112px"
          text={isCositNew ? 'Set' : 'Update'}
        />
      </Box>
      <CustomModal
        modal={true}
        open={isCositSuccessModalOpen}
        handleClose={handleSuccessMessageModalClose}
        handleButtonOnClick={handleSuccessMessageModalClose}
        icon={GreenCheckCircleSVG}
        title={'Goals Updated Successfully!'}
        btnText={'Dismiss'}
        width="374px"
      ></CustomModal>
    </Box>
  );
}

export default Cosit;
