import React, { Fragment, useEffect, useState } from 'react';
import VerifySVG from '../../../assets/img/verify.svg';
import { Box, Typography, useTheme, Grid, Autocomplete, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomButtonLoadingButton from '../../../components/CustomButtonLoadingButton/CustomButtonLoadingButton';
import CustomModal from '../../../components/CustomModal/CustomModal';
import GreenCheckCircleSVG from '../../../assets/img/check-circle-bold-duotone.svg';
import RedCloseCircleSVG from '../../../assets/img/close-circle-bold-duotone.svg';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';
import EmailVerify from '../EmailVerify/EmailVerify';
import { authActions } from '../../../redux/auth/authSlice';
import { userActions } from '../../../redux/user/userSlice';
import CustomInputBox from '../../../components/CustomInputBox/CustomInputBox';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import { useAuth } from '../../../context/AuthContext';
import { Roles } from '../../../utils/enums/roles.enum';
import { generalActions } from '../../../redux/general/generalSlice';
import SizeTooLargeError from '../../../components/SizeTooLargeError/SizeTooLargeError';
import CustomDatepicker from '../../../components/CustomeDatepicker/CustomeDatepicker';
import * as dateFns from 'date-fns';
import { validation } from '../../../schema/validation';

export function PersonalInfo() {
  const theme = useTheme();
  const { isLogin, user: loggedUser } = useAuth();
  const role = isLogin ? loggedUser.role : '';
  const [profileImage, setProfileImage] = useState<{
    dataUrl: string;
    file: FormData | undefined;
  }>({
    dataUrl: '',
    file: undefined,
  });
  const [fileSizeTooLargeError, setFileSizeTooLargeError] = useState(false);
  const user = useAppSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();

  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const isSubmitting = _.get(componentStatus, 'isSubmitting', false) as boolean;
  const error = _.get(componentStatus, 'error', '') as string;
  const successModalOpen = _.get(componentStatus, 'successModalOpen', false) as boolean;
  const emailVerifyModalOpen = _.get(componentStatus, 'emailVerifyModalOpen', false) as boolean;
  const isEmailAlreadyExists = _.get(componentStatus, 'isEmailAlreadyExists', false) as boolean;
  const countryList = useAppSelector((state: RootState) => state.general.countryList);
  const stateList = useAppSelector((state: RootState) => state.general.stateList);

  const formik = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      avatar: user.avatar,
    },
    validationSchema: yup.object({
      firstName: validation.firstName,
      lastName: validation.lastName,
      email: validation.email,
      phone: yup
        .string()
        .matches(/^\+?\d{6,14}$/, { message: 'Invalid phone number' })
        .required('Phone number is required'),
      avatar: yup.string().optional(),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({ email }, { setSubmitting }) => {
      dispatch(
        authActions.setComponentStatus({
          isSubmitting: true,
          successModalOpen: false,
          emailVerifyModalOpen: false,
          error: '',
          isEmailAlreadyExists: false,
        }),
      );
      const data = _.reduce(getChangeProfileAttributes(), (result, value) => _.set(result, value.key, value.new), {});
      dispatch(
        userActions.userUpdateAsync({
          data: _.omit(data, ['avatar']),
          image: profileImage.file,
        }),
      );
    },
  });

  const getInitialValue = (value: any, defaultValue: any) => value ?? defaultValue;

  const clinicianFormik = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      surname: getInitialValue(user?.clinician?.surname, ''),
      birthday: getInitialValue(user?.clinician?.birthday, ''),
      phone: user.phone,
      email: user.email,
      addressLine1: getInitialValue(user?.clinician?.addressLine1, ''),
      addressLine2: getInitialValue(user?.clinician?.addressLine2, ''),
      city: getInitialValue(user?.clinician?.city, ''),
      state: _.get(user, 'clinician.state.state', ''),
      country: _.get(user, 'clinician.country.country', ''),
      zipCode: getInitialValue(user?.clinician?.zipCode, ''),
      avatar: user.avatar,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      firstName: validation.firstName,
      lastName: validation.lastName,
      surname: validation.surname,
      email: validation.email,
      phone: yup.string().matches(/^\+?\d{6,14}$/, { message: 'Invalid phone number' }),
      addressLine1: yup.string().max(150),
      addressLine2: yup.string().max(150).optional(),
      city: yup.string().max(30),
      state: yup.string().max(30).optional(),
      country: yup.string(),
      zipCode: yup.string().max(30).matches(/^\d+$/, { message: 'Invalid zip code' }),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const countryId = _.find(countryList, { country: values.country })?.countryId;
      const stateId = _.find(stateList, { state: values.state })?.stateId;

      dispatch(
        authActions.setComponentStatus({
          isSubmitting: true,
          successModalOpen: false,
          emailVerifyModalOpen: false,
          error: '',
          isEmailAlreadyExists: false,
        }),
      );
      const data = _.reduce(
        getClinicianChangeProfileAttributes(),
        (result, value) => _.set(result, value.key, value.new),
        {},
      );

      if (_.has(data, 'country')) {
        _.set(data, 'countryId', countryId);
      }
      if (_.has(data, 'state')) {
        _.set(data, 'stateId', stateId);
      }

      dispatch(
        userActions.userUpdateAsync({
          data: _.omit(data, ['avatar', 'state', 'country']),
          image: profileImage.file,
        }),
      );
    },
  });

  useEffect(() => {
    dispatch(generalActions.getCountryListAsync());
  }, []);

  useEffect(() => {
    if (clinicianFormik.values.country) {
      dispatch(generalActions.getStateListAsync({ country: clinicianFormik.values.country }));
    }
  }, [clinicianFormik.values.country]);

  useEffect(() => {
    if (isEmailAlreadyExists) {
      if (user.role === Roles.CLINICIAN) {
        clinicianFormik.setFieldTouched('email', true);
        clinicianFormik.setFieldError('email', 'Email address already exists');
      } else {
        formik.setFieldTouched('email', true);
        formik.setFieldError('email', 'Email address already exists');
      }
    }
  }, [isEmailAlreadyExists]);

  const getChangeProfileAttributes = () => {
    const changeAttributes = [];
    if (user.firstName !== formik.values.firstName) {
      changeAttributes.push({
        name: 'first name',
        key: 'firstName',
        old: user.firstName,
        new: formik.values.firstName,
      });
    }
    if (user.lastName !== formik.values.lastName) {
      changeAttributes.push({
        name: 'last name',
        key: 'lastName',
        old: user.lastName,
        new: formik.values.lastName,
      });
    }
    if (user.email !== formik.values.email) {
      changeAttributes.push({
        name: 'email',
        key: 'email',
        old: user.email,
        new: formik.values.email,
      });
    }
    if (user.phone !== formik.values.phone) {
      changeAttributes.push({
        name: 'phone number',
        key: 'phone',
        old: user.phone,
        new: formik.values.phone,
      });
    }
    if (profileImage.file) {
      changeAttributes.push({
        name: 'Avatar',
        key: 'avatar',
        old: user.avatar,
        new: 'new avatar',
      });
    }
    return changeAttributes;
  };

  const getValue = (value: string | null | undefined) => {
    return value ?? '';
  };

  const getClinicianChangeProfileAttributes = () => {
    const changeAttributes = [];
    if (user.firstName !== clinicianFormik.values.firstName) {
      changeAttributes.push({
        name: 'first name',
        key: 'firstName',
        old: user.firstName,
        new: clinicianFormik.values.firstName,
      });
    }
    if (user.lastName !== clinicianFormik.values.lastName) {
      changeAttributes.push({
        name: 'last name',
        key: 'lastName',
        old: user.lastName,
        new: clinicianFormik.values.lastName,
      });
    }
    if (user.email !== clinicianFormik.values.email) {
      changeAttributes.push({
        name: 'email',
        key: 'email',
        old: user.email,
        new: clinicianFormik.values.email,
      });
    }
    if (user.phone !== clinicianFormik.values.phone) {
      changeAttributes.push({
        name: 'phone number',
        key: 'phone',
        old: user.phone,
        new: clinicianFormik.values.phone,
      });
    }
    if (profileImage.file) {
      changeAttributes.push({
        name: 'Avatar',
        key: 'avatar',
        old: user.avatar,
        new: 'new avatar',
      });
    }
    if (getValue(user.clinician?.surname) !== clinicianFormik.values.surname) {
      changeAttributes.push({
        name: 'Middle Name',
        key: 'surname',
        old: user.clinician?.surname,
        new: clinicianFormik.values.surname,
      });
    }
    if (getValue(user.clinician?.birthday) !== clinicianFormik.values.birthday) {
      changeAttributes.push({
        name: 'Birth day',
        key: 'birthday',
        old: user.clinician?.birthday,
        new: clinicianFormik.values.birthday,
      });
    }
    if (getValue(user.clinician?.addressLine1) !== clinicianFormik.values.addressLine1) {
      changeAttributes.push({
        name: 'Address Line 1',
        key: 'addressLine1',
        old: user.clinician?.addressLine1,
        new: clinicianFormik.values.addressLine1,
      });
    }
    if (getValue(user.clinician?.addressLine2) !== clinicianFormik.values.addressLine2) {
      changeAttributes.push({
        name: 'Address Line 2',
        key: 'addressLine2',
        old: user.clinician?.addressLine2,
        new: clinicianFormik.values.addressLine2,
      });
    }
    if (getValue(user.clinician?.city) !== clinicianFormik.values.city) {
      changeAttributes.push({
        name: 'City',
        key: 'city',
        old: user.clinician?.city,
        new: clinicianFormik.values.city,
      });
    }
    if (getValue(user.clinician?.zipCode) !== clinicianFormik.values.zipCode) {
      changeAttributes.push({
        name: 'Zip code',
        key: 'zipCode',
        old: user.clinician?.zipCode,
        new: clinicianFormik.values.zipCode,
      });
    }
    if (_.get(user, 'clinician.state.state', '') !== clinicianFormik.values.state) {
      changeAttributes.push({
        name: 'State',
        key: 'state',
        old: _.get(user, 'clinician.state.state', ''),
        new: clinicianFormik.values.state,
      });
    }
    if (_.get(user, 'clinician.country.country', '') !== clinicianFormik.values.country) {
      changeAttributes.push({
        name: 'Country',
        key: 'country',
        old: _.get(user, 'clinician.country.country', ''),
        new: clinicianFormik.values.country,
      });
    }
    return changeAttributes;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.size <= 5 * 1024 * 1024) {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function (e) {
          const selectedFile = reader.result as string;

          const bodyFormData = new FormData();
          bodyFormData.append('image', file);

          setProfileImage({ dataUrl: selectedFile, file: bodyFormData });
        };
      } else {
        setFileSizeTooLargeError(true);
      }
    }
  };

  const handleSuccessMessageModalClose = () => {
    dispatch(authActions.clearComponentStatus());
    dispatch(userActions.getUserAsync());
  };
  const handleFailMessageModalClose = () => {
    dispatch(authActions.clearComponentStatus());
    dispatch(userActions.getUserAsync());
  };
  const handleEmailVerificationModalClose = () => {
    dispatch(authActions.clearComponentStatus());
    dispatch(userActions.getUserAsync());
  };
  const getHelperText = (fieldName: keyof typeof clinicianFormik.values) => {
    return clinicianFormik.touched[fieldName] && clinicianFormik.errors[fieldName]
      ? String(clinicianFormik.errors[fieldName])
      : undefined;
  };
  const getBackgroundColor = (formik: any, fieldName: string) => {
    return formik.values[fieldName] ? 'initial' : theme.palette.shades.disabled;
  };

  return (
    <Box>
      <Typography marginBottom={2} variant="h5">
        Personal Info
      </Typography>
      <AvatarEdit
        avatar={formik.values.avatar}
        selectedAvatar={profileImage.dataUrl}
        editable={true}
        name={formik.values.firstName}
        name2={formik.values.lastName}
        font={theme.typography.h2}
        size="100px"
        onChange={handleFileChange}
      />

      {role === Roles.CLINICIAN ? (
        <Fragment>
          <Grid container marginTop={1} rowSpacing={2} columnSpacing={1}>
            <Grid item xs={6}>
              <CustomInputBox
                label="First Name"
                required={true}
                type="text"
                name="firstName"
                value={clinicianFormik.values.firstName}
                onChange={clinicianFormik.handleChange}
                onBlur={clinicianFormik.handleBlur}
                error={clinicianFormik.touched.firstName && Boolean(clinicianFormik.errors.firstName)}
                helperText={clinicianFormik.touched.firstName && clinicianFormik.errors.firstName}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputBox
                label="Last Name"
                required={true}
                type="text"
                name="lastName"
                value={clinicianFormik.values.lastName}
                onChange={clinicianFormik.handleChange}
                onBlur={clinicianFormik.handleBlur}
                error={clinicianFormik.touched.lastName && Boolean(clinicianFormik.errors.lastName)}
                helperText={clinicianFormik.touched.lastName && clinicianFormik.errors.lastName}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputBox
                label="Middle Name"
                required={false}
                type="text"
                name="surname"
                value={clinicianFormik.values.surname ?? ''}
                onChange={clinicianFormik.handleChange}
                onBlur={clinicianFormik.handleBlur}
                error={clinicianFormik.touched.surname && Boolean(clinicianFormik.errors.surname)}
                helperText={getHelperText('surname')}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatepicker
                label="Birth Day"
                required={false}
                name="birthday"
                value={clinicianFormik.values.birthday ? clinicianFormik.values.birthday : null}
                error={clinicianFormik.touched.birthday && Boolean(clinicianFormik.errors.birthday)}
                helperText={
                  clinicianFormik.touched.birthday && clinicianFormik.errors.birthday
                    ? String(clinicianFormik.errors.birthday)
                    : undefined
                }
                onBlur={clinicianFormik.handleBlur}
                maxDate={new Date()}
                onChange={(value) => {
                  clinicianFormik.setFieldValue('birthday', value ? dateFns.format(new Date(value), 'yyyy-MM-dd') : '');
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputBox
                label="Phone Number"
                required={false}
                type="text"
                name="phone"
                value={clinicianFormik.values.phone}
                onChange={clinicianFormik.handleChange}
                onBlur={clinicianFormik.handleBlur}
                error={clinicianFormik.touched.phone && Boolean(clinicianFormik.errors.phone)}
                helperText={clinicianFormik.touched.phone && clinicianFormik.errors.phone}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputBox
                label="Email Address"
                required={true}
                type="text"
                name="email"
                value={clinicianFormik.values.email}
                onChange={clinicianFormik.handleChange}
                onBlur={clinicianFormik.handleBlur}
                error={clinicianFormik.touched.email && Boolean(clinicianFormik.errors.email)}
                helperText={clinicianFormik.touched.email && clinicianFormik.errors.email}
              />
            </Grid>

            <Grid item xs={6}>
              <CustomInputBox
                label="Address Line 1"
                required={false}
                type="text"
                name="addressLine1"
                value={clinicianFormik.values.addressLine1 ?? ''}
                onChange={clinicianFormik.handleChange}
                onBlur={clinicianFormik.handleBlur}
                error={clinicianFormik.touched.addressLine1 && Boolean(clinicianFormik.errors.addressLine1)}
                helperText={getHelperText('addressLine1')}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputBox
                label="Address Line 2"
                required={false}
                type="text"
                name="addressLine2"
                value={clinicianFormik.values.addressLine2 ?? ''}
                onChange={clinicianFormik.handleChange}
                onBlur={clinicianFormik.handleBlur}
                error={clinicianFormik.touched.addressLine2 && Boolean(clinicianFormik.errors.addressLine2)}
                helperText={getHelperText('addressLine2')}
              />
            </Grid>

            <Grid item xs={3}>
              <CustomInputBox
                label="City"
                required={false}
                type="text"
                name="city"
                value={clinicianFormik.values.city ?? ''}
                onChange={clinicianFormik.handleChange}
                onBlur={clinicianFormik.handleBlur}
                error={clinicianFormik.touched.city && Boolean(clinicianFormik.errors.city)}
                helperText={getHelperText('city')}
              />
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" gap="2px">
                <Typography variant="meta" component="p" align="left">
                  State/ Province
                </Typography>
                <Autocomplete
                  freeSolo
                  value={clinicianFormik.values.state ?? ''}
                  onChange={(e, value) => clinicianFormik.setFieldValue('state', value)}
                  onBlur={clinicianFormik.handleBlur}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                  options={stateList.map((e) => e.state)}
                  sx={{
                    '& .MuiInputBase-root': {
                      alignContent: 'center',
                      height: '38px',
                      borderRadius: '10px',
                      backgroundColor: getBackgroundColor(clinicianFormik, 'country'),
                    },
                  }}
                  disabled={!clinicianFormik.values.country}
                />
                {clinicianFormik.touched.state && Boolean(clinicianFormik.errors.state) && (
                  <Typography paddingLeft={2} variant="meta" component="p" color="error">
                    {clinicianFormik.touched.state && clinicianFormik.errors.state}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <CustomInputBox
                label="Zip Code"
                required={false}
                type="text"
                name="zipCode"
                value={clinicianFormik.values.zipCode ?? ''}
                onChange={clinicianFormik.handleChange}
                onBlur={clinicianFormik.handleBlur}
                error={clinicianFormik.touched.zipCode && Boolean(clinicianFormik.errors.zipCode)}
                helperText={getHelperText('zipCode')}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomInputBox
                label="Country"
                required={false}
                type="text"
                select={true}
                name="country"
                value={clinicianFormik.values.country ?? ''}
                onChange={clinicianFormik.handleChange}
                onBlur={clinicianFormik.handleBlur}
                error={clinicianFormik.touched.country && Boolean(clinicianFormik.errors.country)}
                helperText={clinicianFormik.touched.country && clinicianFormik.errors.country}
                selectOptions={countryList.map((e) => ({
                  key: e.country,
                  value: e.country,
                }))}
              />
            </Grid>

            <Grid item xs={12} marginTop={2}>
              <CustomButtonLoadingButton
                role="clinician-update-btn"
                text="Update"
                disabled={getClinicianChangeProfileAttributes().length === 0}
                loading={isSubmitting && !emailVerifyModalOpen}
                variant="contained"
                color="primary"
                onClick={() => clinicianFormik.handleSubmit()}
              />
            </Grid>
          </Grid>

          {/* update success message */}
          <CustomModal
            modal={true}
            open={successModalOpen}
            handleClose={handleSuccessMessageModalClose}
            handleButtonOnClick={handleSuccessMessageModalClose}
            icon={GreenCheckCircleSVG}
            title={'Profile Updated!'}
            btnText={'Dismiss'}
            width="374px"
          >
            <Typography variant="bodyRegular" component="p" textAlign="center">
              Your {_.map(getClinicianChangeProfileAttributes(), 'name').join(', ')}{' '}
              {getClinicianChangeProfileAttributes().length === 1 ? 'has' : 'have'} been successfully updated to
              <strong>&nbsp;{_.map(getClinicianChangeProfileAttributes(), 'new').join(', ')}</strong>
            </Typography>
          </CustomModal>
        </Fragment>
      ) : (
        <Fragment>
          <Grid marginTop={1} container rowSpacing={2} columnSpacing={1}>
            <Grid item xs={6}>
              <CustomInputBox
                label="First Name"
                required={true}
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputBox
                label="Last Name"
                required={true}
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInputBox
                label="Email"
                required={true}
                type="email"
                name="email"
                placeholder="username@mail.com"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInputBox
                label="Phone Number"
                required={true}
                type="text"
                name="phone"
                placeholder="Phone Number"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>

            <Grid item xs={12} marginTop={2}>
              <CustomButtonLoadingButton
                text="Update"
                disabled={getChangeProfileAttributes().length === 0}
                loading={isSubmitting && !emailVerifyModalOpen}
                variant="contained"
                color="primary"
                onClick={() => formik.handleSubmit()}
              />
            </Grid>
          </Grid>

          {/* update success message */}
          <CustomModal
            modal={true}
            open={successModalOpen}
            handleClose={handleSuccessMessageModalClose}
            handleButtonOnClick={handleSuccessMessageModalClose}
            icon={GreenCheckCircleSVG}
            title={'Profile Updated!'}
            btnText={'Dismiss'}
            width="374px"
          >
            <Typography variant="bodyRegular" component="p" textAlign="center">
              Your {_.map(getChangeProfileAttributes(), 'name').join(', ')}{' '}
              {getChangeProfileAttributes().length === 1 ? 'has' : 'have'} been successfully updated to
              <strong>&nbsp;{_.map(getChangeProfileAttributes(), 'new').join(', ')}</strong>
            </Typography>
          </CustomModal>
        </Fragment>
      )}

      {/*update fail message*/}
      <CustomModal
        modal={true}
        open={!!error && !emailVerifyModalOpen}
        handleClose={handleFailMessageModalClose}
        handleButtonOnClick={handleFailMessageModalClose}
        icon={RedCloseCircleSVG}
        title={'Profile Updated Failed!'}
        btnText={'Dismiss'}
        width="374px"
      >
        <Typography mb={1} variant="bodyRegular" component="p" textAlign="center">
          Your profile failed to update
        </Typography>
        <Typography variant="bodyRegular" component="p" textAlign="center" color="error">
          {error}
        </Typography>
      </CustomModal>

      {/*email verification modal*/}
      <CustomModal
        modal={true}
        open={emailVerifyModalOpen}
        handleButtonOnClick={handleEmailVerificationModalClose}
        icon={VerifySVG}
        title={'Verify New Email Address'}
        btnText={'Dismiss'}
        btnVariant={'outlined'}
        width="445px"
      >
        <EmailVerify email={user.role === Roles.CLINICIAN ? clinicianFormik.values.email : formik.values.email} />
      </CustomModal>

      <SizeTooLargeError
        open={fileSizeTooLargeError}
        maxFileSize={'5MB'}
        onClickDismiss={() => setFileSizeTooLargeError(false)}
      />
    </Box>
  );
}

export default PersonalInfo;
