import { Fragment, useState, useEffect } from 'react';
import { Typography, useTheme, Box, Stack, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import CustomTable, { ColumnProps } from '../../../components/CustomTable/CustomTable';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import EyeBoldSVG from '../../../assets/img/eye-bold.svg';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import _ from 'lodash';
import { ClinicAdminProps, clinicActions } from '../../../redux/clinic/clinicSlice';
import { authActions } from '../../../redux/auth/authSlice';
import ViewClinicAdmin from '../ManageClinicAdmins/ViewClinicAdmin/ViewClinicAdmin';
import { Roles } from '../../../utils/enums/roles.enum';
import { useAuth } from '../../../context/AuthContext';
import { SIDEBAR_OPTIONS } from '../../../utils/sidebar-schema';
import { SidebarOption } from '../../../utils/enums/sidebar-option.enum';
import { SortOrder } from '../../../utils/enums/sort-order.enum';

interface ClinicAdminTableProps {
  clinicId: string;
}

function ClinicAdminsTable({ clinicId }: ClinicAdminTableProps) {
  const theme = useTheme();
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [sortBy, setSortBy] = useState<string>('globalId');
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.ASC);

  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const totalClinicAdminCount = useAppSelector((state: RootState) => state.clinic.totalClinicAdminCount);
  const clinicAdminList = useAppSelector((state: RootState) => state.clinic.clinicAdminList);
  const isClinicAdminTableLoading = _.get(componentStatus, 'isClinicAdminTableLoading', false) as boolean;

  useEffect(() => {
    const offset = (page - 1) * rowsPerPage;
    dispatch(
      clinicActions.getClinicAdminListAsync({
        offset,
        limit: rowsPerPage,
        sortBy,
        sortOrder,
        clinicId: clinicId,
        type: 'clinic',
      }),
    );
  }, [page, rowsPerPage, sortBy, sortOrder]);

  const clinicianColumns: ColumnProps[] = [
    {
      key: 'name',
      name: 'Name',
      enableSort: false,
      align: 'left',
      width: 'auto',
      minWidth: 100,
      resizable: true,
    },
    {
      key: 'action',
      name: '',
      enableSort: false,
      align: 'right',
      width: '10%',
      minWidth: 100,
      resizable: false,
    },
  ];

  const handleViewAdminDetails = (userId: string) => {
    dispatch(authActions.setComponentStatus({ openClinicAdminDetailsModal: true }));
    dispatch(clinicActions.getClinicAdminByUserIdAsync(userId));
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setRowsPerPage(rowsPerPage);
    setPage(1);
  };

  const getFormattedClinicAdminsList = (clinicAdminList: ClinicAdminProps[]) => {
    return clinicAdminList.map((clinicAdmin) => {
      const fullName = `${clinicAdmin.firstName} ${clinicAdmin.lastName}`;
      return {
        clinicBaseId: clinicAdmin.clinicBaseId ? clinicAdmin.clinicBaseId.replace('CAID', '') : '',
        name: (
          <Box display="flex" gap={1}>
            <AvatarEdit
              editable={false}
              avatar={clinicAdmin.avatar}
              name={clinicAdmin.firstName}
              name2={clinicAdmin.lastName}
              size="24px"
              font={theme.typography.subtitle1}
            />
            <Typography variant="bodyRegular">{fullName}</Typography>
          </Box>
        ),
        action: (
          <Stack direction="row" spacing={1} justifyContent="center">
            <IconButton onClick={() => handleViewAdminDetails(clinicAdmin.userId)} size="small">
              <Box component="img" src={EyeBoldSVG}></Box>
            </IconButton>
          </Stack>
        ),
      };
    });
  };

  return (
    <Fragment>
      <CustomTable
        isLoading={isClinicAdminTableLoading}
        totalDataRows={totalClinicAdminCount}
        enablePagination={true}
        selectedPage={page}
        enablePagePerRows={false}
        rowsPerList={[10, 20, 50, 100]}
        selectedRowsPerPage={rowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        columns={clinicianColumns}
        rows={getFormattedClinicAdminsList(clinicAdminList)}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSortByChange={setSortBy}
        handleSortOrderChange={setSortOrder}
        minBodyRowSpace={5}
        customTableTop={
          <Box sx={{ padding: '24px' }} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" component="div" color={theme.palette.black.main}>
              Clinic Admins
            </Typography>
            {user.role === Roles.CLINIC_ADMIN && (
              <Link
                style={{ textDecoration: 'none', color: theme.palette.info.main }}
                to={SIDEBAR_OPTIONS[SidebarOption.MANAGE_ADMINS].path}
                state={{ clinicId }}
              >
                <Typography variant="button" color={theme.palette.primary.main}>
                  View All Clinic Admins
                </Typography>
              </Link>
            )}
          </Box>
        }
      />
      <ViewClinicAdmin />
    </Fragment>
  );
}

export default ClinicAdminsTable;
