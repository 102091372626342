import { styled as materialStyled } from '@mui/material/styles';
import { TextField, Theme } from '@mui/material';

export const SquareInputBox = materialStyled(TextField)(({
  theme,
  verifystatus,
  height,
  width,
}: {
  theme: Theme;
  verifystatus: string | undefined;
  height: string;
  width: string;
}) => {
  let backgroundColor = '';
  let color = '';

  if (verifystatus === undefined) {
    backgroundColor = 'transparent';
    color = 'inherit';
  } else if (verifystatus === 'verifying') {
    backgroundColor = `${theme.palette.success.main}1A`;
    color = theme.palette.success.main;
  } else {
    backgroundColor = `${theme.palette.error.main}1A`;
    color = theme.palette.error.main;
  }

  return {
    '& .MuiInputBase-root': {
      height: height,
      width: width,
      borderRadius: '10px',
      backgroundColor,
      color,
    },
    '& .Mui-disabled': {
      backgroundColor: `${theme.palette.success.main}1A`,
      color: theme.palette.success.main,
      borderRadius: '10px',
      padding: 0,
      height: height,
      width: width,
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
      WebkitTextFillColor: 'inherit',
    },
  };
});
