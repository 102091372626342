import React, { Fragment } from 'react';
import { Stack, Box, Typography, useTheme } from '@mui/material';
import * as _ from 'lodash';
import CustomModal from '../../../../components/CustomModal/CustomModal';
import CloseCircleBoldSVG from '../../../../assets/img/close-circle-bold.svg';
import GeneralInfo from './GeneralInfo';
import ConfigPermission from './ConfigPermission';
import Confirmation from './Confirmation';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import CustomTabHeader from '../../../../components/CustomTabHeader/CustomTabHeader';
import { RootState } from '../../../../redux/store';
import Constant from '../../../../constants/constant.json';
import AvatarEdit from '../../../../components/AvatarEdit/AvatarEdit';
import GreenCheckCircleSVG from '../../../../assets/img/check-circle-bold-duotone.svg';
import { clinicActions } from '../../../../redux/clinic/clinicSlice';
import { authActions } from '../../../../redux/auth/authSlice';

interface AddAdminProps {
  isAddOpen: boolean;
}

function AddEditAdmin({ isAddOpen }: AddAdminProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const clinic = useAppSelector((state: RootState) => state.clinic);
  const adminData = clinic.adminData;
  const tabs = [Constant.AdminTabs.GENERAL_INFO, Constant.AdminTabs.CONFIG_PERMISSION, Constant.AdminTabs.CONFIRMATION];

  const componentStatus = useAppSelector((state: RootState) => state.clinic.componentStatus);
  const tab = _.get(componentStatus, 'selectedTab', Constant.AdminTabs.GENERAL_INFO.slug) as string;
  const addSuccessModalOpen = _.get(componentStatus, 'addSuccessModalOpen', false) as boolean;

  const handleSuccessMessageModalClose = () => {
    dispatch(clinicActions.setCloseSuccessModel(true));
    dispatch(authActions.setComponentStatus({ shouldAdminListLoad: false }));
  };

  return (
    <Fragment>
      <CustomModal modal={true} open={isAddOpen} title="Add New Admin" maxWidth="900px" align="left">
        <Stack direction="column" alignItems="left" justifyContent="center">
          <Box
            component="img"
            src={CloseCircleBoldSVG}
            onClick={() => {
              dispatch(clinicActions.setAddAdminPopupClose(false));
            }}
            sx={{ position: 'absolute', bottom: '75%', left: '93%', top: '6%', width: '36px', cursor: 'pointer' }}
          ></Box>
          <CustomTabHeader activeTab={tab} tabs={tabs} />
          {tab === Constant.AdminTabs.GENERAL_INFO.slug && <GeneralInfo />}
          {tab === Constant.AdminTabs.CONFIG_PERMISSION.slug && <ConfigPermission />}
          {tab === Constant.AdminTabs.CONFIRMATION.slug && <Confirmation />}
        </Stack>
      </CustomModal>

      {/*update success message*/}
      <CustomModal
        modal={true}
        open={addSuccessModalOpen}
        handleClose={handleSuccessMessageModalClose}
        handleButtonOnClick={handleSuccessMessageModalClose}
        icon={GreenCheckCircleSVG}
        btnText={'Dismiss'}
        width="374px"
      >
        <Typography variant="h5" textAlign="center">
          Admin Added
        </Typography>
        <Box
          alignContent="center"
          sx={{
            align: 'center',
            display: 'inline-block',
            left: '37%',
            position: 'relative',
            marginBottom: '26px',
            marginTop: '26px',
          }}
        >
          <AvatarEdit
            editable={false}
            avatar={adminData.avatar}
            name={adminData.firstName}
            name2={adminData.lastName}
            size="72px"
            font={theme.typography.h3}
          />
        </Box>

        <Typography variant="h6" component="p" textAlign="center">
          {adminData.firstName} {adminData.lastName}
        </Typography>
        <Typography variant="meta" component="p" textAlign="center" color={theme.palette.info.main}>
          {adminData.email}
        </Typography>
        <Typography variant="meta" component="p" textAlign="center" color={theme.palette.info.main}>
          {adminData.phone}
        </Typography>
      </CustomModal>
    </Fragment>
  );
}

export default AddEditAdmin;
