import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortOrder } from '../../utils/enums/sort-order.enum';

export interface ClinicianType {
  clinicianId?: string;
  userId: string;
  globalId: string;
  clinicBaseId: string;
  clinicId: string;
  firstName: string;
  lastName: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  phone: string;
  avatar: string;
  status: string;
  birthday: string;
  patientCount: number;
  underTreatmentCount: number;
  completedCount: number;
  createAt: string;
  clinicName?: string;
}

export interface AddClinicianProps {
  clinicianId?: string;
  clinicId?: string;
  firstName?: string;
  lastName?: string;
  surname?: string;
  email?: string;
  birthday?: string | null;
  phone?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: { state: string };
  country?: { country: string };
  stateId?: number;
  countryId?: number;
  zipCode?: string;
  avatar?: { url: string; file: File | undefined };
}

export interface CliniciansDropdownItemType {
  clinicBaseId?: string;
  userId: string;
  firstName: string;
  lastName: string;
  avatar: string;
  clinicianId: string;
}

export interface IClinicianSlice {
  totalClinicianCount: number;
  clinicianList: ClinicianType[];
  selectedClinician?: ClinicianType;
  componentStatus: { [name: string]: undefined | string | boolean };
  addClinicianData?: AddClinicianProps;
  cliniciansDropdownList: CliniciansDropdownItemType[];
}

const initialState: IClinicianSlice = {
  totalClinicianCount: 0,
  clinicianList: [],
  selectedClinician: undefined,
  componentStatus: {},
  cliniciansDropdownList: [],
};

const clinicianSlice = createSlice({
  name: 'clinician',
  initialState,
  reducers: {
    setClinicianList(state, action: PayloadAction<ClinicianType[]>) {
      state.clinicianList = action.payload;
    },
    setCliniciansDropdownList(state, action: PayloadAction<CliniciansDropdownItemType[]>) {
      state.cliniciansDropdownList = action.payload;
    },
    setTotalClinicianCount(state, action: PayloadAction<number>) {
      state.totalClinicianCount = action.payload;
    },
    setSelectedClinician(state, action: PayloadAction<ClinicianType | undefined>) {
      state.selectedClinician = action.payload;
    },
    getClinicianByUserIdAsync: (state, action: PayloadAction<string>) => {},
    getClinicianListAsync: (
      state,
      action: PayloadAction<{
        clinicId: string;
        offset: number;
        limit: number;
        sortBy: string;
        sortOrder: SortOrder;
        searchText?: string;
      }>,
    ) => {},
    setAddClinicianData(state, action: PayloadAction<Partial<AddClinicianProps>>) {
      state.addClinicianData = { ...state.addClinicianData, ...action.payload };
    },
    clearAddClinicianData(state) {
      state.addClinicianData = undefined;
    },
    createClinicianAsync: (state, action: PayloadAction<AddClinicianProps>) => {},
    deactivateClinicianAsync: (state, action: PayloadAction<{ userId: string; password: string }>) => {},
    reactivateClinicianAsync: (state, action: PayloadAction<{ userId: string; password: string }>) => {},
    getAllCliniciansInClinicAsync: () => {},
  },
});

export const { actions: clinicianActions, reducer: clinicianReducer } = clinicianSlice;
