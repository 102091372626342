import React from 'react';
import CustomModal from '../../../components/CustomModal/CustomModal';
import GreenCheckCircleSVG from '../../../assets/img/check-circle-bold-duotone.svg';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';
import { generalActions } from '../../../redux/general/generalSlice';
import { patientActions } from '../../../redux/patient/patientSlice';
import { useNavigate } from 'react-router-dom';

function SuccessModal({ patientUserId }: { patientUserId: string }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const componentStatus = useAppSelector((state: RootState) => state.general.componentStatus);
  const openEqSaveSuccessModal = _.get(componentStatus, 'openEqSaveSuccessModal', false) as boolean;

  const handleDismiss = () => {
    dispatch(generalActions.clearComponentStatus());
    dispatch(patientActions.clearEqData());

    navigate(`/patients/${patientUserId}`, { replace: true });
  };

  return (
    <CustomModal
      modal={true}
      open={openEqSaveSuccessModal}
      title="EQ Updated"
      icon={GreenCheckCircleSVG}
      btnText="Dismiss"
      width="374px"
      handleClose={handleDismiss}
      handleButtonOnClick={handleDismiss}
    ></CustomModal>
  );
}

export default SuccessModal;
