import React, { ChangeEvent, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { authActions } from '../../redux/auth/authSlice';
import { SIDEBAR_OPTIONS } from '../../utils/sidebar-schema';
import { SidebarOption } from '../../utils/enums/sidebar-option.enum';
import { Roles } from '../../utils/enums/roles.enum';
import { Permission } from '../../utils/enums/permission.enum';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useAuth } from '../../context/AuthContext';
import AddEditPatient from './AddEditPatient/AddEditPatient';
import { generalActions } from '../../redux/general/generalSlice';
import AddEditPatientSuccessModal from './AddEditPatient/AddEditPatientSuccessModal';
import CustomTable, { ColumnProps } from '../../components/CustomTable/CustomTable';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import CustomModal from '../../components/CustomModal/CustomModal';
import MagniferOutlineSVG from '../../assets/img/magnifer-outline.svg';
import CheckCircleBoldDuotoneRed from '../../assets/img/check-circle-bold-duotone-red.svg';
import { RootState } from '../../redux/store';
import _ from 'lodash';
import { clinicianActions } from '../../redux/clinician/clinicianSlice';
import AvatarEdit from '../../components/AvatarEdit/AvatarEdit';
import { patientActions, PatientType } from '../../redux/patient/patientSlice';
import * as dateFns from 'date-fns';
import EyeBoldSVG from '../../assets/img/eye-bold.svg';
import SquareSortHorizontalBoldSVG from '../../assets/img/square-sort-horizontal-bold.svg';
import TransferPatient from './TransferPatient/TransferPatient';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { clinicActions } from '../../redux/clinic/clinicSlice';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SortOrder } from '../../utils/enums/sort-order.enum';

function Patients() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const { state } = useLocation();
  const searchBtn = useRef<HTMLButtonElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page');
  const sortByParam = searchParams.get('sort-by');
  const rowsPerPageParam = searchParams.get('rows-per-page');
  const sortOrderParam = searchParams.get('sort-order');
  const searchParam = searchParams.get('search');
  const clinicianIdParam = searchParams.get('clinicianId');
  const clinicIdParam = searchParams.get('clinicId');
  const genderParam = searchParams.get('gender');
  const minProgressParam = searchParams.get('min-progress');
  const maxProgressParam = searchParams.get('max-progress');
  const minAgeParam = searchParams.get('min-age');
  const maxAgeParam = searchParams.get('max-age');
  const startDateMinParam = searchParams.get('start-date-min');
  const startDateMaxParam = searchParams.get('start-date-max');
  const clinicPatientsParam = searchParams.get('clinic_patients');

  const isClinicPatients = clinicPatientsParam === '1';
  const [page, setPage] = useState<number>(pageParam ? parseInt(pageParam) : 1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageParam ? parseInt(rowsPerPageParam) : 10);
  const [sortBy, setSortBy] = useState<string>(
    sortByParam ?? ([Roles.CLINIC_ADMIN, Roles.CLINICIAN].includes(user.role) ? 'pid' : 'globalId'),
  );
  const [sortOrder, setSortOrder] = useState<SortOrder>((sortOrderParam as SortOrder) ?? SortOrder.DESC);

  const [searchText, setSearchText] = useState<string>(searchParam ?? '');
  const [clinicianId, setClinicianId] = useState<string | undefined>(clinicianIdParam ?? state?.clinicianId);
  const [clinicId, setClinicId] = useState<string | undefined>(clinicIdParam ?? undefined);
  const [gender, setGender] = useState<string>(genderParam ?? 'All');

  const parseProgress = (progressParam: string | null): number | undefined => {
    return progressParam ? parseInt(progressParam) : undefined;
  };
  const [minProgress, setMinProgress] = useState<number | undefined>(parseProgress(minProgressParam));
  const [maxProgress, setMaxProgress] = useState<number | undefined>(parseProgress(maxProgressParam));

  const parseAge = (ageParam: string | null): number | undefined => {
    return ageParam ? parseInt(ageParam) : undefined;
  };
  const [minAge, setMinAge] = useState<number | undefined>(parseAge(minAgeParam));
  const [maxAge, setMaxAge] = useState<number | undefined>(parseAge(maxAgeParam));

  const parseDate = (dateParam: string | null): Date | undefined => {
    return dateParam ? new Date(dateParam) : undefined;
  };
  const [startDateMin, setStartDateMin] = useState<Date | undefined>(parseDate(startDateMinParam));
  const [startDateMax, setStartDateMax] = useState<Date | undefined>(parseDate(startDateMaxParam));

  function getSelectedSidebarName(clinicPatientsParam: string, userRole: Roles) {
    if (clinicPatientsParam === '1') {
      return SIDEBAR_OPTIONS[SidebarOption.CLINIC_PATIENTS].name;
    } else if (userRole === Roles.CLINICIAN) {
      return SIDEBAR_OPTIONS[SidebarOption.MY_PATIENTS].name;
    } else {
      return SIDEBAR_OPTIONS[SidebarOption.PATIENTS].name;
    }
  }

  function getSelectedSidebarPath(clinicPatientsParam: string, userRole: Roles) {
    if (clinicPatientsParam === '1') {
      return SIDEBAR_OPTIONS[SidebarOption.CLINIC_PATIENTS].path;
    } else if (userRole === Roles.CLINICIAN) {
      return SIDEBAR_OPTIONS[SidebarOption.MY_PATIENTS].path;
    } else {
      return SIDEBAR_OPTIONS[SidebarOption.PATIENTS].path;
    }
  }
  const selectedSideBarName = getSelectedSidebarName(clinicPatientsParam ?? '', user.role);
  const selectedSideBarPath = getSelectedSidebarPath(clinicPatientsParam ?? '', user.role);
  const cliniciansDropdownList = useAppSelector((state: RootState) => state.clinician.cliniciansDropdownList);
  const clinicsDropdownList = useAppSelector((state: RootState) => state.clinic.clinicDropdownList);

  const totalPatientCount = useAppSelector((state: RootState) => state.patient.totalPatientCount);
  const patientList = useAppSelector((state: RootState) => state.patient.patientList);
  const previousSidebarType = useAppSelector((state: RootState) => state.patient.currentSidebarType);

  const componentStatus = useAppSelector((state: RootState) => state.patient.componentStatus);
  const isPatientListLoading = _.get(componentStatus, 'isPatientListLoading', false) as boolean;
  const shouldPatientsListLoad = _.get(componentStatus, 'shouldPatientsListLoad', '') as string;
  const isTransferPatientModalOpen = _.get(componentStatus, 'isTransferPatientModalOpen', false) as boolean;
  const duplicatePatientModalOpen = _.get(componentStatus, 'duplicatePatientModalOpen', false) as boolean;

  const onClickRowsFunctions: (() => void)[] = useMemo(() => {
    const rowClickFunctions: (() => void)[] = [];
    patientList.forEach((patient) => {
      rowClickFunctions.push(() => handleViewPatient(patient.userId));
    });
    return rowClickFunctions;
  }, [patientList]);

  useEffect(() => {
    if (user.role !== Roles.CLINICIAN) return;

    const currentSidebarType = clinicPatientsParam === '1' ? SidebarOption.CLINIC_PATIENTS : SidebarOption.MY_PATIENTS;
    if (currentSidebarType !== previousSidebarType) {
      setPage(1);
      setRowsPerPage(10);
      setSortBy('pid');
      setSortOrder(SortOrder.DESC);
      setSearchText('');
      setClinicianId(state?.clinicianId);
      setClinicId(undefined);
      setGender('All');
      setMinProgress(undefined);
      setMaxProgress(undefined);
      setMinAge(undefined);
      setMaxAge(undefined);
      setStartDateMin(undefined);
      setStartDateMax(undefined);
    }
    dispatch(patientActions.setCurrentSidebarType(currentSidebarType));
  }, [isClinicPatients]);

  useEffect(() => {
    dispatch(
      authActions.setTopBarView({
        text: selectedSideBarName,
        path: selectedSideBarPath,
        sideMenuPath: selectedSideBarPath,
        button:
          (user.role === Roles.CLINIC_ADMIN && user.permissions.includes(Permission.CLINIC_ADMIN_ADD_PATIENT)) ||
          user.role === Roles.CLINICIAN
            ? {
                text: 'Add Patient',
                icon: <AddCircleOutlineIcon fontSize="small" />,
                color: 'primary',
                onClick: () => {
                  dispatch(patientActions.setPatientDetails(undefined));
                  dispatch(generalActions.clearComponentStatus());
                  dispatch(generalActions.setComponentStatus({ isAddEditPatientModalOpen: true }));
                },
              }
            : undefined,
      }),
    );
  }, [user, isClinicPatients]);

  const enterKeyDownHandler = (event: { key: string; preventDefault: () => void }) => {
    if (event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (searchBtn.current) {
          searchBtn.current.click();
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', enterKeyDownHandler);
    return () => {
      document.removeEventListener('keydown', enterKeyDownHandler);
    };
  }, []);

  useEffect(() => {
    setSearchParams({
      ...(user.role === Roles.CLINICIAN && clinicPatientsParam && { clinic_patients: clinicPatientsParam }),
      page: page.toString(),
      'rows-per-page': rowsPerPage.toString(),
      'sort-by': sortBy,
      'sort-order': sortOrder,
      search: searchText,
      ...(clinicianId && { clinicianId }),
      ...(clinicId && { clinicId }),
      gender,
      ...(minProgress && { 'min-progress': minProgress.toString() }),
      ...(maxProgress && { 'max-progress': maxProgress.toString() }),
      ...(minAge && { 'min-age': minAge.toString() }),
      ...(maxAge && { 'max-age': maxAge.toString() }),
      ...(startDateMin && { 'start-date-min': startDateMin.toString() }),
      ...(startDateMax && { 'start-date-max': startDateMax.toString() }),
    });

    const offset = (page - 1) * rowsPerPage;

    dispatch(
      patientActions.getAllPatientListAsync({
        offset,
        limit: rowsPerPage,
        sortBy,
        sortOrder,
        searchText,
        clinicianId: clinicianId ?? state?.clinicianId,
        gender: gender !== 'All' ? gender : undefined,
        minProgress,
        maxProgress,
        minAge,
        maxAge,
        startDateMin: startDateMin ? dateFns.startOfDay(new Date(startDateMin)) : undefined,
        startDateMax: startDateMax ? dateFns.endOfDay(new Date(startDateMax)) : undefined,
        clinicId,
        ...(user.role === Roles.CLINICIAN && isClinicPatients && { isClinicPatientsPage: true }),
      }),
    );
  }, [page, rowsPerPage, sortBy, sortOrder, shouldPatientsListLoad, isClinicPatients]);

  useEffect(() => {
    if (user.role === Roles.CLINIC_ADMIN || (user.role === Roles.CLINICIAN && isClinicPatients)) {
      dispatch(clinicianActions.getAllCliniciansInClinicAsync());
    } else if ([Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role)) {
      dispatch(clinicActions.getAllClinicNamesAsync({ status: undefined }));
    }
  }, [user, isClinicPatients]);

  const handleOneRowCheckChange = (checked: boolean, patientId: string) => {
    const patients = patientList.map((patient) => ({
      ...patient,
      isCheck: patient.patientId === patientId ? checked : patient.isCheck,
    }));
    dispatch(patientActions.setPatientList(patients));
  };

  const handleAllCheckChange = (checked: boolean) => {
    const patients = patientList.map((patient, index) => ({ ...patient, isCheck: checked }));
    dispatch(patientActions.setPatientList(patients));
  };

  const getColumns = (): ColumnProps[] => {
    const columnsInClinicAdminViewOrder = [
      'check',
      'pid',
      'name',
      'age',
      'gender',
      'startDate',
      'progress',
      'clinician',
      'email',
      'action',
    ];
    const columnsInClinicanViewOrder = ['pid', 'name', 'age', 'gender', 'startDate', 'progress', 'email', 'action'];
    const columnsInClinicanClinicViewOrder = [
      'pid',
      'name',
      'age',
      'gender',
      'startDate',
      'progress',
      'clinician',
      'email',
      'action',
    ];
    const columnsInTrusilenceAdminViewOrder = [
      'globalId',
      'age',
      'gender',
      'startDate',
      'progress',
      'clinic',
      'action',
    ];

    const columns: Array<ColumnProps | undefined> = [
      {
        key: 'check',
        name: '',
        customComponent: (
          <Checkbox
            id="all-check-btn"
            disabled={isPatientListLoading}
            size="small"
            checked={patientList.length > 0 && _.every(patientList, { isCheck: true })}
            onChange={(event, checked) => {
              handleAllCheckChange(checked);
            }}
            sx={{ padding: 0 }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        ),
        enableSort: false,
        align: 'center',
        width: '2%',
        minWidth: 30,
        resizable: false,
      },
      { key: 'pid', name: 'PID', enableSort: true, align: 'left', width: '8%', minWidth: 90, resizable: true },
      { key: 'name', name: 'Name', enableSort: true, align: 'left', width: 'auto', minWidth: 160, resizable: true },
      { key: 'age', name: 'Age', enableSort: true, align: 'left', width: 'auto', minWidth: 80, resizable: true },
      { key: 'gender', name: 'Gender', enableSort: false, align: 'left', width: 'auto', minWidth: 80, resizable: true },
      {
        key: 'startDate',
        name: 'Start Date',
        enableSort: true,
        align: 'left',
        width: 'auto',
        minWidth: 80,
        resizable: true,
      },
      {
        key: 'progress',
        name: 'Progress',
        enableSort: true,
        align: 'left',
        width: 'auto',
        minWidth: 80,
        resizable: true,
      },
      {
        key: 'clinician',
        name: 'Clinician',
        enableSort: false,
        align: 'left',
        width: 'auto',
        minWidth: 160,
        resizable: true,
      },
      { key: 'email', name: 'Email', enableSort: false, align: 'left', width: 'auto', minWidth: 140, resizable: true },
      { key: 'action', name: '', enableSort: false, align: 'right', width: '10%', minWidth: 100, resizable: false },
      { key: 'globalId', name: 'TSPID', enableSort: true, align: 'left', width: '8%', minWidth: 90, resizable: true },
      {
        key: 'clinic',
        name: 'Clinic',
        enableSort: false,
        align: 'left',
        width: 'auto',
        minWidth: 160,
        resizable: true,
      },
    ];

    if (user.role === Roles.CLINICIAN && isClinicPatients) {
      return _.compact(_.map(columnsInClinicanClinicViewOrder, (col) => _.find(columns, { key: col })));
    } else if (user.role === Roles.CLINICIAN) {
      return _.compact(_.map(columnsInClinicanViewOrder, (col) => _.find(columns, { key: col })));
    } else if (user.role === Roles.CLINIC_ADMIN) {
      return _.compact(_.map(columnsInClinicAdminViewOrder, (col) => _.find(columns, { key: col })));
    } else {
      return _.compact(_.map(columnsInTrusilenceAdminViewOrder, (col) => _.find(columns, { key: col })));
    }
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setRowsPerPage(rowsPerPage);
    setPage(1);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const str = event.target.value;
    setSearchText(str);
  };

  const handleStartDateMinChange = (str: Date | undefined) => {
    setStartDateMin(str);
    if (startDateMax && str && dateFns.isAfter(new Date(str), new Date(startDateMax))) {
      setStartDateMax(str);
    }
  };

  const handleStartDateMaxChange = (str: Date | undefined) => {
    setStartDateMax(str);
    if (startDateMin && str && dateFns.isBefore(new Date(str), new Date(startDateMin))) {
      setStartDateMin(str);
    }
  };

  const handleSearchFilterOnClick = () => {
    setSearchParams({
      ...(user.role === Roles.CLINICIAN && clinicPatientsParam && { clinic_patients: clinicPatientsParam }),
      page: '1',
      'rows-per-page': rowsPerPage.toString(),
      'sort-by': sortBy,
      'sort-order': sortOrder,
      search: searchText,
      ...(clinicianId && { clinicianId }),
      ...(clinicId && { clinicId }),
      gender,
      ...(minProgress && { 'min-progress': minProgress.toString() }),
      ...(maxProgress && { 'max-progress': maxProgress.toString() }),
      ...(minAge && { 'min-age': minAge.toString() }),
      ...(maxAge && { 'max-age': maxAge.toString() }),
      ...(startDateMin && { 'start-date-min': startDateMin.toString() }),
      ...(startDateMax && { 'start-date-max': startDateMax.toString() }),
    });
    const data = {
      offset: 0,
      limit: rowsPerPage,
      sortBy,
      sortOrder,
      searchText,
      gender: gender !== 'All' ? gender : undefined,
      minProgress,
      maxProgress,
      minAge,
      maxAge,
      startDateMin: startDateMin ? dateFns.startOfDay(new Date(startDateMin)) : undefined,
      startDateMax: startDateMax ? dateFns.endOfDay(new Date(startDateMax)) : undefined,
      clinicId,
      clinicianId: [Roles.CLINICIAN, Roles.CLINIC_ADMIN].includes(user.role) ? clinicianId : undefined,
      ...(user.role === Roles.CLINICIAN && isClinicPatients && { isClinicPatientsPage: true }),
    };

    dispatch(patientActions.getAllPatientListAsync(data));
    setPage(1);
  };

  const handleMinProgressChange = (event: ChangeEvent<HTMLInputElement>) => {
    const progress = parseInt(event.target.value);
    if (!isNaN(progress)) {
      if (progress >= 0 && progress <= 100) {
        setMinProgress(progress);
      }
    } else {
      setMinProgress(undefined);
    }
  };

  const handleMaxProgressChange = (event: ChangeEvent<HTMLInputElement>) => {
    const progress = parseInt(event.target.value);
    if (!isNaN(progress)) {
      if (progress >= 0 && progress <= 100) {
        setMaxProgress(progress);
      }
    } else {
      setMaxProgress(undefined);
    }
  };

  const handleMinAgeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const age = parseInt(event.target.value);
    if (!isNaN(age)) {
      setMinAge(age);
    } else {
      setMinAge(undefined);
    }
  };

  const handleMaxAgeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const age = parseInt(event.target.value);
    if (!isNaN(age)) {
      setMaxAge(age);
    } else {
      setMaxAge(undefined);
    }
  };

  const handleTransferPatient = (userId: string, clinicianId: string) => {
    const selectedPatients = patientList.filter((patient) => !!patient.isCheck);
    const selectedUserId = _.uniq(selectedPatients.map((patient) => patient.userId));
    if (selectedPatients.length < 2 || !selectedUserId.includes(userId)) {
      // Single patient transfer
      dispatch(patientActions.clearComponentStatus());
      dispatch(patientActions.getPatientAsync({ userId }));
      dispatch(patientActions.setPatientIds([userId]));
      dispatch(patientActions.setCurrentClinicianId(clinicianId));
      dispatch(patientActions.setComponentStatus({ isTransferLoading: true }));
      dispatch(patientActions.setComponentStatus({ transferPatientStep: true }));
      dispatch(patientActions.setComponentStatus({ isTransferPatientModalOpen: true }));
    } else {
      // multiple patient transfer
      const uniqueClinicians = selectedPatients.map((patient) => patient.clinicianId);
      if (_.uniq(uniqueClinicians).length > 1) {
        dispatch(patientActions.setComponentStatus({ duplicatePatientModalOpen: true }));
      } else {
        dispatch(patientActions.clearComponentStatus());
        dispatch(patientActions.setPatientIds(selectedUserId));
        dispatch(patientActions.setCurrentClinicianId(clinicianId));
        dispatch(patientActions.setComponentStatus({ transferPatientStep: true }));
        dispatch(patientActions.setComponentStatus({ isTransferPatientModalOpen: true }));
      }
    }
  };

  const handleViewPatient = (userId: string) => {
    navigate(`/patients/${userId}`);
  };

  const handleErrorDismiss = () => {
    dispatch(patientActions.setComponentStatus({ duplicatePatientModalOpen: false }));
  };

  const getFormattedClinicianList = (patientList: PatientType[]) => {
    return patientList?.map((patient, index) => {
      const patientFullName = `${patient.firstName} ${patient.lastName}`;
      const clinicianFullName = `${patient.clinicianFirstName} ${patient.clinicianLastName}`;

      return {
        check: (
          <Checkbox
            id={`check-row-${index}`}
            size="small"
            checked={!!patient.isCheck}
            onChange={(event, checked) => {
              handleOneRowCheckChange(checked, patient.patientId);
            }}
            sx={{ padding: 0 }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        ),
        pid: patient.clinicBaseId ? patient.clinicBaseId.replace('PID', '') : '',
        globalId: patient.globalId ? patient.globalId.replace('TSPID', '') : '',
        name: (
          <Box display="flex" gap={1}>
            <AvatarEdit
              editable={false}
              avatar={patient.avatar}
              name={patient.firstName}
              name2={patient.lastName}
              size="24px"
              font={theme.typography.subtitle1}
            />
            <Typography variant="bodyRegular">{patientFullName}</Typography>
            {user.role === Roles.CLINICIAN && !!patient.isNew && (
              <Chip
                sx={{ borderRadius: '5px', '& .MuiChip-label': { color: theme.palette.white.main } }}
                label="New"
                size="small"
                color="success"
              />
            )}
          </Box>
        ),
        age: patient.age,
        gender: patient.gender,
        startDate: dateFns.format(new Date(patient.startDate), 'MM/dd/yyyy'),
        progress: `${patient.progress}%`,
        clinician: (
          <Box display="flex" gap={1}>
            <AvatarEdit
              editable={false}
              avatar={patient.clinicianAvatar}
              name={patient.clinicianFirstName}
              name2={patient.clinicianLastName}
              size="24px"
              font={theme.typography.subtitle1}
            />
            <Typography variant="bodyRegular">{clinicianFullName}</Typography>
          </Box>
        ),
        clinic: (
          <Box display="flex" gap={1}>
            <AvatarEdit
              editable={false}
              avatar={patient.clinicAvatar}
              name={patient.clinicName}
              size="24px"
              font={theme.typography.subtitle1}
            />
            <Typography variant="bodyRegular">{patient.clinicName}</Typography>
          </Box>
        ),
        email: patient.email,
        action: (
          <Stack direction="row" justifyContent="center" spacing={1}>
            {user.permissions.includes(Permission.CLINIC_ADMIN_TRANSFER_PATIENT) && (
              <IconButton
                id={`transfer-patient-btn-row-${index}`}
                onClick={() => {
                  handleTransferPatient(patient.userId, patient.clinicianId);
                }}
                size="small"
              >
                <Box component="img" src={SquareSortHorizontalBoldSVG}></Box>
              </IconButton>
            )}
            <IconButton
              id={`view-patient-btn-row-${index}`}
              size="small"
              onClick={() => {
                handleViewPatient(patient.userId);
              }}
            >
              <Box component="img" src={EyeBoldSVG}></Box>
            </IconButton>
          </Stack>
        ),
      };
    });
  };

  const getGridSize = (role: string, clinicianSize: number, otherSize: number) => {
    return role === Roles.CLINICIAN ? clinicianSize : otherSize;
  };

  // @ts-ignore
  return (
    <Fragment>
      <CustomTable
        isLoading={isPatientListLoading}
        totalDataRows={totalPatientCount}
        enablePagination={true}
        selectedPage={page}
        enablePagePerRows={true}
        rowsPerList={[10, 20, 50, 100]}
        selectedRowsPerPage={rowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        columns={getColumns()}
        rows={getFormattedClinicianList(patientList)}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSortByChange={setSortBy}
        handleSortOrderChange={setSortOrder}
        bottomResultsCount={totalPatientCount}
        minBodyRowSpace={10}
        rowOnDoubleClick={onClickRowsFunctions}
        customTableTop={
          <Grid marginY={1} paddingX={2} container rowSpacing={1.5} columnSpacing={1}>
            <Grid item xs={11}>
              <TextField
                id="search-input"
                fullWidth
                value={searchText}
                onChange={handleSearchChange}
                placeholder={
                  [Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role)
                    ? 'Search by TSPID'
                    : 'Search by Name'
                }
                sx={{ '& .MuiInputBase-root': { height: '38px', borderRadius: '10px' } }}
                inputProps={{ maxLength: 200 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box component="img" src={MagniferOutlineSVG} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                ref={searchBtn}
                id="search-btn"
                fullWidth
                variant="contained"
                sx={{ borderRadius: '10px', height: '38px' }}
                onClick={handleSearchFilterOnClick}
              >
                Search
              </Button>
            </Grid>

            <Grid item xs={11}>
              <Grid container rowSpacing={1.5} columnSpacing={1}>
                {(user.role === Roles.CLINIC_ADMIN || (user.role === Roles.CLINICIAN && isClinicPatients)) && (
                  <Grid item xxl={3} xs={4}>
                    <Box display="flex" flexDirection="column" gap="2px">
                      <Autocomplete
                        id="clinician-input"
                        size="small"
                        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                        onChange={(event, value) => setClinicianId(value?.clinicianId)}
                        options={cliniciansDropdownList}
                        value={_.find(cliniciansDropdownList, { clinicianId }) ?? null}
                        sx={{
                          '& .MuiInputBase-root': {
                            alignContent: 'center',
                            height: '40px',
                            borderRadius: '10px',
                          },
                        }}
                        renderInput={(params) => <TextField {...params} label="Clinician" variant="outlined" />}
                        renderOption={(props, option, state, ownerState) => {
                          const fullName = `${option.firstName} ${option.lastName}`;
                          return (
                            <Box component="li" display="flex" columnGap={1} {...props}>
                              <AvatarEdit
                                editable={false}
                                size="24px"
                                avatar={option.avatar}
                                name={option.firstName}
                                name2={option.lastName}
                                font={theme.typography.subtitle1}
                              />
                              <Typography variant="bodyRegular" component="p">
                                {fullName}
                              </Typography>
                            </Box>
                          );
                        }}
                      />
                    </Box>
                  </Grid>
                )}

                <Grid item xxl={getGridSize(user.role, 2, 1.5)} xs={2}>
                  <TextField
                    id="gender-input"
                    label="Gender"
                    fullWidth
                    select={true}
                    value={gender}
                    onChange={(event) => setGender(event.target.value)}
                    placeholder="Gender"
                    sx={{ '& .MuiInputBase-root': { height: '40px', borderRadius: '10px' } }}
                  >
                    {['All', 'Male', 'Female', 'Non-Binary'].map((option, index) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {[Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role) && (
                  <Grid item xxl={3} xs={4}>
                    <Box display="flex" flexDirection="column" gap="2px">
                      <Autocomplete
                        id="clinic-input"
                        size="small"
                        getOptionLabel={(option) => `${option.clinicId} - ${option.name}`}
                        isOptionEqualToValue={(option, value) => option.clinicId === value.clinicId}
                        onChange={(event, value) => setClinicId(value?.clinicId)}
                        options={clinicsDropdownList}
                        value={_.find(clinicsDropdownList, { clinicId: clinicId }) ?? null}
                        sx={{
                          '& .MuiInputBase-root': {
                            alignContent: 'center',
                            height: '40px',
                            borderRadius: '10px',
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Clinic"
                            variant="outlined"
                            inputProps={{ ...params.inputProps }}
                          />
                        )}
                        renderOption={(props, option, state, ownerState) => {
                          return (
                            <Box component="li" display="flex" columnGap={1} {...props}>
                              <AvatarEdit
                                editable={false}
                                size="24px"
                                avatar={option.avatar}
                                name={option.name}
                                font={theme.typography.subtitle1}
                              />
                              <Typography variant="bodyRegular" component="p">
                                {`${option.clinicId} - ${option.name}`}
                              </Typography>
                            </Box>
                          );
                        }}
                      />
                    </Box>
                  </Grid>
                )}

                <Grid item xxl={getGridSize(user.role, 3, 2)} xs={3}>
                  <Box
                    display="flex"
                    columnGap={0.5}
                    sx={{
                      height: '40px',
                      border: 'solid 1px',
                      borderColor: theme.palette.shades.blackBorder,
                      paddingX: '4px',
                      alignItems: 'end',
                      paddingBottom: '2px',
                      borderRadius: '10px',
                    }}
                  >
                    <TextField
                      id="progress-min-input"
                      label="Min. Progress"
                      type="text"
                      size="small"
                      fullWidth
                      value={minProgress ?? ''}
                      onChange={handleMinProgressChange}
                      sx={{ '& .MuiInputBase-root': { height: '29px', borderRadius: '8px' } }}
                      InputLabelProps={{ style: { ...theme.typography.subtitle1 } }}
                      InputProps={{ inputProps: { min: 0, max: 100 } }}
                    />
                    <Typography gutterBottom>-</Typography>
                    <TextField
                      id="progress-max-input"
                      label="Max. Progress"
                      type="text"
                      size="small"
                      fullWidth
                      value={maxProgress ?? ''}
                      onChange={handleMaxProgressChange}
                      sx={{ '& .MuiInputBase-root': { height: '29px', borderRadius: '8px' } }}
                      InputLabelProps={{ style: { ...theme.typography.subtitle1 } }}
                      InputProps={{ inputProps: { min: 0, max: 100 } }}
                    />
                  </Box>
                </Grid>

                <Grid item xxl={getGridSize(user.role, 3, 2)} xs={3}>
                  <Box
                    display="flex"
                    columnGap={0.5}
                    sx={{
                      height: '40px',
                      border: 'solid 1px',
                      borderColor: theme.palette.shades.blackBorder,
                      paddingX: '4px',
                      alignItems: 'end',
                      paddingBottom: '2px',
                      borderRadius: '10px',
                    }}
                  >
                    <TextField
                      id="age-min-input"
                      label="Min. Age"
                      type="text"
                      size="small"
                      fullWidth
                      value={minAge ?? ''}
                      onChange={handleMinAgeChange}
                      sx={{ '& .MuiInputBase-root': { height: '29px', borderRadius: '8px' } }}
                      InputLabelProps={{ style: { ...theme.typography.subtitle1 } }}
                      InputProps={{ inputProps: { min: 0, max: 9999, maxLength: 4 } }}
                    />
                    <Typography gutterBottom>-</Typography>
                    <TextField
                      id="age-max-input"
                      label="Max. Age"
                      type="text"
                      size="small"
                      fullWidth
                      value={maxAge ?? ''}
                      onChange={handleMaxAgeChange}
                      sx={{ '& .MuiInputBase-root': { height: '29px', borderRadius: '8px' } }}
                      InputLabelProps={{ style: { ...theme.typography.subtitle1 } }}
                      InputProps={{ inputProps: { min: 0, max: 9999, maxLength: 4 } }}
                    />
                  </Box>
                </Grid>

                <Grid item xxl={getGridSize(user.role, 4, 3.5)} xs={user.role === Roles.CLINICIAN ? 4 : 5}>
                  <Box
                    display="flex"
                    columnGap={0.5}
                    sx={{
                      height: '40px',
                      border: 'solid 1px',
                      borderColor: theme.palette.shades.blackBorder,
                      paddingX: '4px',
                      alignItems: 'end',
                      paddingBottom: '2px',
                      borderRadius: '10px',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label={
                          <Typography
                            sx={{
                              maxWidth: 'calc(8.5vw - 55px)',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            Min. Start Date
                          </Typography>
                        }
                        format="MM-dd-yyyy"
                        value={startDateMin ?? null}
                        sx={{
                          '& .MuiFormLabel-root': { ...theme.typography.meta, top: -10 },
                          '& .MuiInputLabel-shrink': { top: 0 },
                          '& .MuiInputBase-root': { height: '29px', borderRadius: '8px' },
                        }}
                        onChange={(newValue) => {
                          handleStartDateMinChange(newValue ?? undefined);
                        }}
                        // @ts-ignore
                        maxDate={new Date()}
                        slotProps={{
                          actionBar: {
                            actions: ['clear'],
                          },
                          textField: {
                            onKeyDown: (e) => {
                              e.preventDefault();
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                    <Typography gutterBottom>-</Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label={
                          <Typography
                            sx={{
                              maxWidth: 'calc(8.5vw - 55px)',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            Max. Start Date
                          </Typography>
                        }
                        format="MM-dd-yyyy"
                        value={startDateMax ?? null}
                        sx={{
                          '& .MuiFormLabel-root': { ...theme.typography.meta, top: -10 },
                          '& .MuiInputLabel-shrink': { top: 0 },
                          '& .MuiInputBase-root': { height: '29px', borderRadius: '8px' },
                        }}
                        onChange={(newValue) => {
                          handleStartDateMaxChange(newValue ?? undefined);
                        }}
                        // @ts-ignore
                        maxDate={new Date()}
                        slotProps={{
                          actionBar: {
                            actions: ['clear'],
                          },
                          textField: {
                            onKeyDown: (e) => {
                              e.preventDefault();
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Button
                id="filter-btn"
                fullWidth
                variant="contained"
                sx={{ borderRadius: '10px', height: '38px' }}
                onClick={handleSearchFilterOnClick}
              >
                Filter
              </Button>
            </Grid>
          </Grid>
        }
      />

      <AddEditPatient />
      <AddEditPatientSuccessModal />
      <TransferPatient open={isTransferPatientModalOpen} />

      <CustomModal modal={true} open={duplicatePatientModalOpen} width="374px" icon={CheckCircleBoldDuotoneRed}>
        <Stack direction="column" alignItems="center">
          <Grid container>
            <Typography
              mt={0.5}
              mb={2}
              component="p"
              variant="bodyRegular"
              align="center"
              color={theme.palette.info.main}
            >
              Please select patients of a single Clinicians to proceed with the patient transfer
            </Typography>
          </Grid>
          <Grid container>
            <LoadingButton
              variant="contained"
              sx={{ borderRadius: '10px', height: '43px' }}
              onClick={handleErrorDismiss}
              fullWidth
            >
              Dismiss
            </LoadingButton>
          </Grid>
        </Stack>
      </CustomModal>
    </Fragment>
  );
}

export default Patients;
