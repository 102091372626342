import React, { Fragment, useEffect } from 'react';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';
import { generalActions } from '../../../redux/general/generalSlice';
import { Autocomplete, Box, Grid, Skeleton, TextField, Typography, useTheme } from '@mui/material';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomInputBox from '../../../components/CustomInputBox/CustomInputBox';
import CustomButtonLoadingButton from '../../../components/CustomButtonLoadingButton/CustomButtonLoadingButton';
import { clinicianActions } from '../../../redux/clinician/clinicianSlice';
import { AddEditPatientDataType, PatientType, patientActions } from '../../../redux/patient/patientSlice';
import { useAuth } from '../../../context/AuthContext';
import { Roles } from '../../../utils/enums/roles.enum';
import CustomDatepicker from '../../../components/CustomeDatepicker/CustomeDatepicker';
import * as dateFns from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { validation } from '../../../schema/validation';

interface AddEditPatientProps {
  patientId?: string;
}

function AddEditPatient({ patientId }: AddEditPatientProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { user } = useAuth();

  const cliniciansDropdownList = useAppSelector((state: RootState) => state.clinician.cliniciansDropdownList);
  const patientsDetails = useAppSelector((state: RootState) => state.patient.patientsDetails);

  const componentStatus = useAppSelector((state: RootState) => state.general.componentStatus);
  const countryList = useAppSelector((state: RootState) => state.general.countryList);
  const stateList = useAppSelector((state: RootState) => state.general.stateList);
  const isAddEditPatientModalOpen = _.get(componentStatus, 'isAddEditPatientModalOpen', false) as boolean;
  const isLoading = _.get(componentStatus, 'isLoading', false) as boolean;
  const isSubmitting = _.get(componentStatus, 'isSubmitting', false) as boolean;
  const isEmailAlreadyExists = _.get(componentStatus, 'isEmailAlreadyExists', false);
  const getClinicianId = (user: { role: Roles }, patientsDetails: PatientType | undefined) => {
    if (user.role === Roles.CLINICIAN) {
      return _.get(user, 'clinician.clinicianId', '') as string;
    } else if (_.isNil(patientsDetails?.clinicianId)) {
      return '';
    } else {
      return patientsDetails?.clinicianId;
    }
  };

  const getInitialValue = (value: string | null | undefined) => {
    return value ?? '';
  };

  const initialValues = {
    firstName: getInitialValue(patientsDetails?.firstName),
    lastName: getInitialValue(patientsDetails?.lastName),
    clinicianId: getClinicianId(user, patientsDetails),
    surname: getInitialValue(patientsDetails?.surname),
    birthday: getInitialValue(patientsDetails?.birthday),
    gender: getInitialValue(patientsDetails?.gender),
    phone: getInitialValue(patientsDetails?.phone),
    email: getInitialValue(patientsDetails?.email),
    addressLine1: getInitialValue(patientsDetails?.addressLine1),
    addressLine2: getInitialValue(patientsDetails?.addressLine2),
    city: getInitialValue(patientsDetails?.city),
    state: getInitialValue(patientsDetails?.state),
    zipCode: getInitialValue(patientsDetails?.zipCode),
    country: getInitialValue(patientsDetails?.country),
    avatar: getInitialValue(patientsDetails?.avatar),
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      firstName: validation.firstName,
      lastName: validation.lastName,
      clinicianId: yup.string().when((values, schema, options) => {
        if (patientId) {
          return schema.notRequired();
        } else {
          return schema.required('Clinician is required');
        }
      }),
      surname: validation.surname,
      birthday: yup.date().max(new Date(), 'Birthday must be less than today').required('Birthday is required'),
      gender: yup.string().oneOf(['Male', 'Female', 'Non-Binary']).required('Gender is required'),
      phone: yup
        .string()
        .matches(/^\+?\d{6,14}$/, { message: 'Invalid phone number' })
        .optional(),
      email: validation.email,
      addressLine1: yup.string().max(150, 'Address line 1 should not contain more than 150 characters').optional(),
      addressLine2: yup.string().max(150, 'Address line 2 should not contain more than 150 characters').optional(),
      city: yup.string().max(30, 'City should not contain more than 30 characters').optional(),
      state: yup.string().optional(),
      zipCode: yup
        .string()
        .max(30, 'Zip code should not contain more than 30 characters')
        .matches(/^\d+$/, { message: 'Invalid zip code' })
        .optional(),
      country: yup.string().optional(),
      avatar: yup.string().optional(),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(generalActions.setComponentStatus({ isEmailAlreadyExists: false }));

      let patientInfo: AddEditPatientDataType = values;
      dispatch(patientActions.setAddEditPatientData(patientInfo));

      const countryId = _.find(countryList, { country: values.country })?.countryId;
      const stateId = _.find(stateList, { state: values.state })?.stateId;

      if (patientId) {
        // edit patient
        const changedFields = _.omitBy(values, (val, key) => _.get(initialValues, key, '') === val);
        if (changedFields.country && countryId) {
          _.set(changedFields, 'countryId', countryId);
        }
        if (changedFields.state && stateId) {
          _.set(changedFields, 'stateId', stateId);
        }
        dispatch(
          patientActions.updatePatientAsync({ patientId, data: _.omit(changedFields, ['country', 'state', 'avatar']) }),
        );
      } else {
        // add new patient
        if (countryId) {
          patientInfo = { ...patientInfo, countryId: countryId };
        }
        if (stateId) {
          patientInfo = { ...patientInfo, stateId: stateId };
        }

        dispatch(
          patientActions.createPatientAsync(
            _.pickBy(_.omit(patientInfo, ['country', 'state', 'avatar']), (value, key) => value !== ''),
          ),
        );
      }
    },
  });

  useEffect(() => {
    if (isEmailAlreadyExists) {
      formik.setFieldTouched('email', true);
      formik.setFieldError('email', 'Email address already exists');
    }
  }, [isEmailAlreadyExists]);

  useEffect(() => {
    if (isAddEditPatientModalOpen) {
      if (isAddEditPatientModalOpen && user.role === Roles.CLINIC_ADMIN) {
        dispatch(clinicianActions.getAllCliniciansInClinicAsync());
      }
      dispatch(generalActions.getCountryListAsync());
    } else {
      formik.resetForm();
    }
  }, [user, isAddEditPatientModalOpen]);

  useEffect(() => {
    const { country } = formik.values;

    if (!country) {
      return;
    }

    if (country !== patientsDetails?.country) {
      formik.setFieldValue('state', null);
    }

    dispatch(generalActions.getStateListAsync({ country }));
  }, [formik.values.country]);

  return (
    <Fragment>
      <CustomModal
        modal={true}
        open={isAddEditPatientModalOpen}
        title={patientId ? 'Edit Patient' : 'Add New Patient'}
        maxWidth="900px"
        minHeight="650px"
        height="auto"
        topCloseBtn={() => {
          dispatch(generalActions.clearComponentStatus());
          dispatch(patientActions.setAddEditPatientData(undefined));
          formik.resetForm();
        }}
      >
        {isLoading && (
          <Grid width="850px" marginBottom={4} container rowSpacing={4} columnSpacing={2}>
            <Grid item xs={12}>
              <Skeleton variant="circular" height="100px" width="100px" />
            </Grid>
            {[4, 4, 4, 4, 4, 4, 6, 6, 6, 6, 3, 3, 3, 3].map((e, i) => (
              <Grid item xs={e} key={uuidv4()}>
                <Skeleton variant="rounded" height="38px" />
              </Grid>
            ))}
          </Grid>
        )}
        {!isLoading && (
          <Box sx={{ paddingTop: '20px', borderTop: `1px solid ${theme.palette.shades.purpleBorder}` }}>
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12}>
                <AvatarEdit
                  avatar={formik.values.avatar}
                  editable={false}
                  name={formik.values.firstName}
                  name2={formik.values.lastName}
                  font={theme.typography.h2}
                  size="100px"
                />
              </Grid>
              <Grid item xs={4}>
                <CustomInputBox
                  label="First Name"
                  required={true}
                  type="text"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomInputBox
                  label="Last Name"
                  required={true}
                  type="text"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              {user.role === Roles.CLINIC_ADMIN &&
                !patientId && ( // only for add new patient clinic admin
                  <Grid item xs={4}>
                    <Box display="flex" flexDirection="column" gap="2px">
                      <Typography variant="meta" component="p" align="left">
                        Clinician
                        <Typography component="span" color="primary">
                          &nbsp;*
                        </Typography>
                      </Typography>
                      <Autocomplete
                        id="clinicain-select-dropdown"
                        data-testid="clinicain-autocomplete-search"
                        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                        isOptionEqualToValue={(option, value) => option.clinicianId === value.clinicianId}
                        value={
                          formik.values.clinicianId
                            ? _.find(cliniciansDropdownList, { clinicianId: formik.values.clinicianId })
                            : { firstName: '', lastName: '', clinicianId: '' }
                        }
                        onChange={(event, value) => {
                          formik.setFieldValue('clinicianId', value ? value.clinicianId : '');
                        }}
                        onBlur={formik.handleBlur}
                        options={cliniciansDropdownList}
                        sx={{
                          '& .MuiInputBase-root': { alignContent: 'center', height: '38px', borderRadius: '10px' },
                        }}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        renderOption={(props, option, state, ownerState) => {
                          const fullName = `${option.firstName} ${option.lastName}`;
                          return (
                            <Box component="li" display="flex" columnGap={1} {...props}>
                              <AvatarEdit
                                editable={false}
                                size="24px"
                                name={option.firstName}
                                name2={option.lastName}
                                font={theme.typography.subtitle1}
                              />
                              <Typography variant="bodyRegular" component="p">
                                {fullName}
                              </Typography>
                            </Box>
                          );
                        }}
                      />
                      {formik.touched.clinicianId && Boolean(formik.errors.clinicianId) && (
                        <Typography paddingLeft={2} variant="meta" component="p" color="error">
                          {formik.touched.clinicianId && formik.errors.clinicianId}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                )}

              <Grid item xs={4}>
                <CustomInputBox
                  label="Middle Name"
                  required={false}
                  type="text"
                  name="surname"
                  value={formik.values.surname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.surname && Boolean(formik.errors.surname)}
                  helperText={formik.touched.surname && formik.errors.surname}
                />
              </Grid>
              <Grid item xs={user.role === Roles.CLINIC_ADMIN && !patientId ? 4 : 6}>
                <CustomDatepicker
                  label="Birth Day"
                  required={true}
                  name="birthday"
                  value={formik.values.birthday ? formik.values.birthday : null}
                  error={formik.touched.birthday && Boolean(formik.errors.birthday)}
                  helperText={formik.touched.birthday && formik.errors.birthday}
                  onBlur={formik.handleBlur}
                  maxDate={new Date()}
                  onChange={(value) => {
                    formik.setFieldValue('birthday', value ? dateFns.format(new Date(value), 'yyyy-MM-dd') : '');
                  }}
                />
              </Grid>
              <Grid item xs={user.role === Roles.CLINIC_ADMIN && !patientId ? 4 : 6}>
                <CustomInputBox
                  label="Gender"
                  required={true}
                  type="text"
                  select={true}
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.gender && Boolean(formik.errors.gender)}
                  helperText={formik.touched.gender && formik.errors.gender}
                  selectOptions={['Male', 'Female', 'Non-Binary'].map((e) => ({ key: e, value: e }))}
                />
              </Grid>

              <Grid item xs={6}>
                <CustomInputBox
                  label="Phone Number"
                  required={false}
                  type="text"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomInputBox
                  label="Email Address"
                  required={true}
                  type="text"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>

              <Grid item xs={6}>
                <CustomInputBox
                  label="Address Line 1"
                  required={false}
                  type="text"
                  name="addressLine1"
                  value={formik.values.addressLine1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)}
                  helperText={formik.touched.addressLine1 && formik.errors.addressLine1}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomInputBox
                  label="Address Line 2"
                  required={false}
                  type="text"
                  name="addressLine2"
                  value={formik.values.addressLine2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.addressLine2 && Boolean(formik.errors.addressLine2)}
                  helperText={formik.touched.addressLine2 && formik.errors.addressLine2}
                />
              </Grid>

              <Grid item xs={3}>
                <CustomInputBox
                  label="City"
                  required={false}
                  type="text"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>
              <Grid item xs={3}>
                <Box display="flex" flexDirection="column" gap="2px">
                  <Typography variant="meta" component="p" align="left">
                    State/ Province
                  </Typography>
                  <Autocomplete
                    value={formik.values.state}
                    onChange={(e, value) => formik.setFieldValue('state', value)}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    options={stateList.map((e) => e.state)}
                    sx={{
                      '& .MuiInputBase-root': {
                        alignContent: 'center',
                        height: '38px',
                        borderRadius: '10px',
                        backgroundColor: formik.values.country ? 'initial' : theme.palette.shades.disabled,
                      },
                    }}
                    disabled={!formik.values.country}
                  />
                  {formik.touched.state && Boolean(formik.errors.state) && (
                    <Typography paddingLeft={2} variant="meta" component="p" color="error">
                      {formik.touched.state && formik.errors.state}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <CustomInputBox
                  label="Country"
                  required={false}
                  type="text"
                  select={true}
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.country && Boolean(formik.errors.country)}
                  helperText={formik.touched.country && formik.errors.country}
                  selectOptions={countryList.map((e) => ({
                    key: e.country,
                    value: e.country,
                  }))}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomInputBox
                  label="Zip Code"
                  required={false}
                  type="text"
                  name="zipCode"
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                  helperText={formik.touched.zipCode && formik.errors.zipCode}
                />
              </Grid>
            </Grid>
            <Box
              display="flex"
              justifyContent="right"
              marginTop={4}
              sx={{
                paddingTop: '20px',
                borderTop: `1px solid ${theme.palette.shades.purpleBorder}`,
              }}
            >
              <CustomButtonLoadingButton
                disabled={!!patientId && _.isEqual(formik.initialValues, formik.values)}
                loading={isSubmitting}
                text={patientId ? 'Update Patient' : 'Add Patient'}
                variant="contained"
                color="primary"
                fullWidth={false}
                onClick={() => {
                  formik.handleSubmit();
                }}
              />
            </Box>
          </Box>
        )}
      </CustomModal>
    </Fragment>
  );
}

export default AddEditPatient;
