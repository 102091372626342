import React, { Fragment } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import AvatarEdit from '../../AvatarEdit/AvatarEdit';
import { styled as materialStyled, Theme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { clinicActions } from '../../../redux/clinic/clinicSlice';
import { useAppDispatch } from '../../../redux/hooks';

interface ClinicApproveRejectProps {
  notificationId: string;
  createdUserName: string;
  image: string;
  subject: string;
  description: string;
  actionDisable: boolean;
  approveBtnLoading: boolean;
  rejectBtnLoading: boolean;
}

const CustomeButton = materialStyled(LoadingButton)(({ theme }: { theme: Theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(1, 2),
  marginTop: theme.spacing(1.5),
  marginRight: theme.spacing(1.5),
  borderRadius: theme.spacing(1.5),
  fontWeight: 'normal',
  [theme.breakpoints.up('sm')]: {
    width: '195px',
  },
}));

function ClinicApproveReject(props: ClinicApproveRejectProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const handleApprove = (notificationId: string) => {
    dispatch(clinicActions.approveClinicAttributeAsync({ notificationId }));
  };

  const handleReject = (notificationId: string) => {
    dispatch(clinicActions.rejectClinicAttributeAsync({ notificationId }));
  };

  return (
    <Fragment>
      <Grid item xs={2} sm={1}>
        <AvatarEdit
          editable={false}
          avatar={props.image}
          size="28px"
          name={props.subject}
          alt="notification image"
          font={theme.typography.subtitle1}
        />
      </Grid>
      <Grid item mb={3} xs={10} sm={11}>
        <Typography variant="h6" fontSize="20px" mb={2} sx={{ whiteSpace: 'normal' }}>
          {props.subject}
        </Typography>
        <Typography variant="bodyRegular" component="p" sx={{ whiteSpace: 'normal', color: theme.palette.info.main }}>
          <Typography
            variant="bodyRegular"
            component="span"
            sx={{ whiteSpace: 'normal', color: theme.palette.primary.main, paddingRight: 1 }}
          >
            {props.createdUserName}
          </Typography>
          {props.description}
        </Typography>

        <Grid item md>
          <CustomeButton
            disabled={props.actionDisable || props.approveBtnLoading}
            loading={props.rejectBtnLoading}
            color="error"
            variant="outlined"
            onClick={() => {
              handleReject(props.notificationId);
            }}
          >
            Reject
          </CustomeButton>
          <CustomeButton
            disabled={props.actionDisable || props.rejectBtnLoading}
            loading={props.approveBtnLoading}
            variant="outlined"
            onClick={() => {
              handleApprove(props.notificationId);
            }}
          >
            Approve
          </CustomeButton>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default ClinicApproveReject;
