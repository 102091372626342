import React, { ChangeEvent, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { authActions } from '../../redux/auth/authSlice';
import { SIDEBAR_OPTIONS } from '../../utils/sidebar-schema';
import { SidebarOption } from '../../utils/enums/sidebar-option.enum';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Permission } from '../../utils/enums/permission.enum';
import {
  Box,
  IconButton,
  Stack,
  useTheme,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Grid,
  Autocomplete,
} from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { Roles } from '../../utils/enums/roles.enum';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { generalActions } from '../../redux/general/generalSlice';
import CustomTable, { ColumnProps } from '../../components/CustomTable/CustomTable';
import { RootState } from '../../redux/store';
import _ from 'lodash';
import { ClinicianType, clinicianActions } from '../../redux/clinician/clinicianSlice';
import AvatarEdit from '../../components/AvatarEdit/AvatarEdit';
import EyeBoldSVG from '../../assets/img/eye-bold.svg';
import GreenCheckCircleSVG from '../../assets/img/check-circle-bold-duotone.svg';
import SlashCircleBoldSVG from '../../assets/img/slash-circle-bold.svg';
import MagniferOutlineSVG from '../../assets/img/magnifer-outline.svg';
import AddClinician from './AddClinician/AddClinician';
import ClinicianSuccessModal from './AddClinician/ClinicianSuccessModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { clinicActions } from '../../redux/clinic/clinicSlice';
import { SortOrder } from '../../utils/enums/sort-order.enum';

function Clinicians() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const navigate = useNavigate();
  const searchBtn = useRef<HTMLButtonElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page');
  const sortByParam = searchParams.get('sort-by');
  const rowsPerPageParam = searchParams.get('rows-per-page');
  const sortOrderParam = searchParams.get('sort-order');
  const searchParam = searchParams.get('search');
  const clinicIdParam = searchParams.get('clinicId');

  const [page, setPage] = useState<number>(pageParam ? parseInt(pageParam) : 1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageParam ? parseInt(rowsPerPageParam) : 10);
  const [sortBy, setSortBy] = useState<string>(sortByParam ?? 'patientCount');
  const [sortOrder, setSortOrder] = useState<SortOrder>((sortOrderParam as SortOrder) ?? SortOrder.DESC);
  const [searchText, setSearchText] = useState<string>(searchParam ?? '');
  const [clinicId, setClinicId] = useState<string | undefined>(clinicIdParam ?? user.clinicId);

  const totalClinicianCount = useAppSelector((state: RootState) => state.clinician.totalClinicianCount);
  const clinicianList = useAppSelector((state: RootState) => state.clinician.clinicianList);

  const clinicsDropdownList = useAppSelector((state: RootState) => state.clinic.clinicDropdownList);

  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const isClinicianTableLoading = _.get(componentStatus, 'isClinicianTableLoading', false) as boolean;
  const shouldClinicianListLoad = _.get(componentStatus, 'shouldClinicianListLoad', false) as boolean;
  const onClickRowsFunctions: (() => void)[] = useMemo(() => {
    const rowClickFunctions: (() => void)[] = [];
    clinicianList.forEach((clinician) => {
      rowClickFunctions.push(() => handleViewClinician(clinician));
    });
    return rowClickFunctions;
  }, [clinicianList]);

  const isSuperOrTruesilenceAdmin = (role: Roles) => {
    return [Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(role);
  };

  useEffect(() => {
    dispatch(
      authActions.setTopBarView({
        text: SIDEBAR_OPTIONS[SidebarOption.CLINICIANS].name,
        path: SIDEBAR_OPTIONS[SidebarOption.CLINICIANS].path,
        sideMenuPath: SIDEBAR_OPTIONS[SidebarOption.CLINICIANS].path,
        button:
          user.role === Roles.CLINIC_ADMIN && user.permissions.includes(Permission.CLINIC_ADMIN_ADD_CLINICIAN)
            ? {
                text: 'Add Clinician',
                icon: <AddCircleOutlineIcon fontSize="small" />,
                color: 'primary',
                onClick: handleAddClinicianModalOpen,
              }
            : undefined,
      }),
    );

    if (isSuperOrTruesilenceAdmin(user.role)) {
      setClinicId(searchParams.get('clinicId') ?? undefined);
    } else if (user.role === Roles.CLINIC_ADMIN) {
      setClinicId(user.clinicId);
    }
  }, [user]);

  useEffect(() => {
    setSearchParams({
      page: page.toString(),
      'rows-per-page': rowsPerPage.toString(),
      'sort-by': sortBy,
      'sort-order': sortOrder,
      ...(clinicId && { clinicId }),
      search: searchText,
    });
    const offset = (page - 1) * rowsPerPage;
    dispatch(
      clinicianActions.getClinicianListAsync({
        clinicId: clinicId ?? '',
        offset,
        limit: rowsPerPage,
        sortBy,
        sortOrder,
        searchText,
      }),
    );
  }, [page, rowsPerPage, sortBy, sortOrder, clinicId]);

  useEffect(() => {
    if (shouldClinicianListLoad) {
      const offset = (page - 1) * rowsPerPage;
      dispatch(
        clinicianActions.getClinicianListAsync({
          clinicId: clinicId ?? '',
          offset,
          limit: rowsPerPage,
          sortBy,
          sortOrder,
          searchText,
        }),
      );
    }
  }, [shouldClinicianListLoad]);

  useEffect(() => {
    if (isSuperOrTruesilenceAdmin(user.role)) {
      dispatch(clinicActions.getAllClinicNamesAsync({ status: undefined }));
    }
  }, [user.role]);

  const enterKeyDownHandler = (event: { key: string; preventDefault: () => void }) => {
    if (event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (searchBtn.current) {
          searchBtn.current.click();
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', enterKeyDownHandler);
    return () => {
      document.removeEventListener('keydown', enterKeyDownHandler);
    };
  }, []);

  const columns: ColumnProps[] = [
    { key: 'clinicBaseId', name: 'TID', enableSort: true, align: 'left', width: '8%', minWidth: 90, resizable: true },
    { key: 'name', name: 'Name', enableSort: true, align: 'left', width: 'auto', minWidth: 160, resizable: true },
    {
      key: 'patientCount',
      name: 'No. of patients',
      enableSort: true,
      align: 'left',
      width: 'auto',
      minWidth: 80,
      resizable: true,
    },
    { key: 'email', name: 'Email', enableSort: false, align: 'left', width: 'auto', minWidth: 140, resizable: true },
    { key: 'action', name: '', enableSort: false, align: 'right', width: '10%', minWidth: 100, resizable: false },
  ];

  const adminViewColumns: ColumnProps[] = [
    { key: 'globalId', name: 'TSTID', enableSort: true, align: 'left', width: '8%', minWidth: 90, resizable: true },
    { key: 'name', name: 'Name', enableSort: true, align: 'left', width: 'auto', minWidth: 160, resizable: true },
    {
      key: 'clinicName',
      name: 'Clinic',
      enableSort: true,
      align: 'left',
      width: 'auto',
      minWidth: 160,
      resizable: true,
    },
    {
      key: 'patientCount',
      name: 'No. of patients',
      enableSort: true,
      align: 'left',
      width: 'auto',
      minWidth: 80,
      resizable: true,
    },
    { key: 'status', name: 'Status', enableSort: false, align: 'left', width: 'auto', minWidth: 140, resizable: true },
    { key: 'action', name: '', enableSort: false, align: 'right', width: '10%', minWidth: 100, resizable: false },
  ];

  const handleAddClinicianModalOpen = () => {
    dispatch(generalActions.clearComponentStatus());
    dispatch(generalActions.setComponentStatus({ isAddClinicianModalOpen: true }));
  };

  const handleViewClinician = (clinician: ClinicianType) => {
    navigate(`/clinicians/${clinician.userId}`, { state: clinician });
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setRowsPerPage(rowsPerPage);
    setPage(1);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const str = event.target.value;
    setSearchText(str);
  };

  const handleSearchButton = () => {
    setSearchParams({
      page: '1',
      'rows-per-page': rowsPerPage.toString(),
      'sort-by': sortBy,
      'sort-order': sortOrder,
      ...(clinicId && { clinicId }),
      search: searchText,
    });
    dispatch(
      clinicianActions.getClinicianListAsync({
        clinicId: clinicId ?? '',
        offset: 0,
        limit: rowsPerPage,
        sortBy,
        sortOrder,
        searchText,
      }),
    );
  };

  const getFormattedClinicianList = (clinicianList: ClinicianType[]) => {
    return clinicianList.map((clinician) => {
      const name = `${clinician.firstName} ${clinician.lastName}`;

      return {
        globalId: clinician.globalId.replace('TSTID', ''),
        clinicBaseId: clinician.clinicBaseId.replace('TID', ''),
        name: (
          <Box display="flex" gap={1}>
            <AvatarEdit
              editable={false}
              avatar={[Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role) ? undefined : clinician.avatar}
              name={clinician.firstName}
              name2={clinician.lastName}
              size="24px"
              font={theme.typography.subtitle1}
            />
            <Typography variant="bodyRegular">{name}</Typography>
          </Box>
        ),
        clinicName: (
          <Box display="flex" gap={1}>
            <AvatarEdit
              editable={false}
              avatar={clinician.avatar}
              name={clinician.clinicName}
              size="24px"
              font={theme.typography.subtitle1}
            />
            <Typography variant="bodyRegular">{clinician.clinicName}</Typography>
          </Box>
        ),
        patientCount: clinician.patientCount,
        email: clinician.email,
        status: (
          <Typography
            variant="bodyRegular"
            color={clinician.status === 'ACTIVE' ? theme.palette.info.main : theme.palette.error.main}
          >
            {clinician.status.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase())}
          </Typography>
        ),
        action: (
          <Stack direction="row" justifyContent="center" spacing={1}>
            <IconButton data-testid="view-clinicians" onClick={() => handleViewClinician(clinician)} size="small">
              <Box component="img" src={EyeBoldSVG}></Box>
            </IconButton>
            <Box
              width="20px"
              component="img"
              src={clinician.status === 'ACTIVE' ? GreenCheckCircleSVG : SlashCircleBoldSVG}
            />
          </Stack>
        ),
      };
    });
  };

  const getColumns = () => {
    return isSuperOrTruesilenceAdmin(user.role) ? adminViewColumns : columns;
  };

  return (
    <Fragment>
      <CustomTable
        isLoading={isClinicianTableLoading}
        totalDataRows={totalClinicianCount}
        enablePagination={true}
        selectedPage={page}
        enablePagePerRows={true}
        rowsPerList={[10, 20, 50, 100]}
        minBodyRowSpace={10}
        selectedRowsPerPage={rowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        columns={getColumns()}
        rows={getFormattedClinicianList(clinicianList)}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSortByChange={setSortBy}
        handleSortOrderChange={setSortOrder}
        bottomResultsCount={totalClinicianCount}
        customTableTop={
          <Grid marginY={1} paddingX={2} container rowSpacing={1.5} columnSpacing={1}>
            {[Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role) && (
              <Grid item xs={4}>
                <Box display="flex" flexDirection="column" gap="2px">
                  <Autocomplete
                    id="clinic-input"
                    size="small"
                    value={_.find(clinicsDropdownList, { clinicId: clinicId }) ?? null}
                    getOptionLabel={(option) => (option.clinicId ? `${option.clinicId} - ${option.name}` : '')}
                    isOptionEqualToValue={(option, value) => option.clinicId === value.clinicId}
                    onChange={(event, value) => {
                      setSearchParams({
                        page: '1',
                        'rows-per-page': rowsPerPage.toString(),
                        'sort-by': sortBy,
                        'sort-order': sortOrder,
                        clinicId: value?.clinicId ? value?.clinicId : '',
                        search: searchText,
                      });
                      setClinicId(value?.clinicId);
                    }}
                    options={clinicsDropdownList}
                    sx={{ '& .MuiInputBase-root': { alignContent: 'center', height: '40px', borderRadius: '10px' } }}
                    renderInput={(params) => (
                      <TextField {...params} label="Clinic" variant="outlined" inputProps={{ ...params.inputProps }} />
                    )}
                    renderOption={(props, option, state, ownerState) => {
                      return (
                        <Box component="li" display="flex" columnGap={1} {...props}>
                          <AvatarEdit
                            editable={false}
                            size="24px"
                            avatar={option.avatar}
                            name={option.name}
                            font={theme.typography.subtitle1}
                          />
                          <Typography variant="bodyRegular" component="p">
                            {`${option.clinicId} - ${option.name}`}
                          </Typography>
                        </Box>
                      );
                    }}
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={user.role === Roles.CLINIC_ADMIN ? 11 : 7}>
              <TextField
                fullWidth
                value={searchText}
                name="search"
                onChange={handleSearchChange}
                placeholder={
                  [Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role)
                    ? 'Search by TSTID, Name'
                    : 'Search by Name'
                }
                sx={{ '& .MuiInputBase-root': { height: '38px', borderRadius: '10px' } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box component="img" src={MagniferOutlineSVG} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                ref={searchBtn}
                fullWidth
                variant="contained"
                onClick={handleSearchButton}
                sx={{ borderRadius: '10px', height: '38px' }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        }
        rowOnDoubleClick={onClickRowsFunctions}
      />

      {/*Add clinician modal*/}
      <AddClinician />

      {/*Add clinician success*/}
      <ClinicianSuccessModal />
    </Fragment>
  );
}

export default Clinicians;
