import React, { useEffect } from 'react';
import GeneralInfo from '../AddClinic/GeneralInfoTab';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import _ from 'lodash';
import { generalActions } from '../../../redux/general/generalSlice';
import { Grid, Skeleton } from '@mui/material';
import { clinicActions } from '../../../redux/clinic/clinicSlice';

interface EditClinicProps {
  clinicId: string;
}

function EditClinic({ clinicId }: EditClinicProps) {
  const dispatch = useAppDispatch();

  const componentStatus = useAppSelector((state: RootState) => state.general.componentStatus);
  const editClinicModalOpen = _.get(componentStatus, 'editClinicModalOpen', false) as boolean;
  const isLoading = _.get(componentStatus, 'isLoading', true) as boolean;

  useEffect(() => {
    if (clinicId && editClinicModalOpen) {
      dispatch(generalActions.getCountryListAsync());
      dispatch(clinicActions.getClinicIntermediateInfoAsync({ clinicId }));
    }
  }, [editClinicModalOpen]);

  return (
    <CustomModal
      modal={true}
      open={editClinicModalOpen}
      title={'Edit Clinic'}
      maxWidth="900px"
      minHeight="500px"
      topCloseBtn={() => dispatch(generalActions.clearComponentStatus())}
    >
      {isLoading && (
        <Grid width="850px" marginBottom={4} container rowSpacing={4} columnSpacing={2}>
          <Grid item xs={12}>
            <Skeleton variant="circular" height="100px" width="100px" />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rounded" height="38px" />
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant="rounded" height="38px" />
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant="rounded" height="38px" />
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant="rounded" height="38px" />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="rounded" height="38px" />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="rounded" height="38px" />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rounded" height="38px" />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rounded" height="38px" />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rounded" height="38px" />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rounded" height="38px" />
          </Grid>
        </Grid>
      )}
      {!isLoading && <GeneralInfo clinicId={clinicId} />}
    </CustomModal>
  );
}

export default EditClinic;
