import React, { Fragment, useEffect } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { generalActions } from '../../../redux/general/generalSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import CustomInputBox from '../../../components/CustomInputBox/CustomInputBox';
import CustomButtonLoadingButton from '../../../components/CustomButtonLoadingButton/CustomButtonLoadingButton';
import { RootState } from '../../../redux/store';
import _ from 'lodash';
import { clinicActions } from '../../../redux/clinic/clinicSlice';
import { AddClinicTabs } from '../../../utils/enums/add-clinic-tabs.enum';
import { validation } from '../../../schema/validation';

function ClinicAdminTab() {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const addClinicData = useAppSelector((state: RootState) => state.clinic.addClinicData);

  const componentStatus = useAppSelector((state: RootState) => state.general.componentStatus);
  const isSubmitting = _.get(componentStatus, 'isSubmitting', false) as boolean;
  const isEmailAlreadyExists = _.get(componentStatus, 'isEmailAlreadyExists', false);

  const formik = useFormik({
    initialValues: {
      firstName: addClinicData?.clinicAdmin?.firstName ?? '',
      lastName: addClinicData?.clinicAdmin?.lastName ?? '',
      email: addClinicData?.clinicAdmin?.email ?? '',
      phone: addClinicData?.clinicAdmin?.phone ?? '',
      avatar: addClinicData?.clinicAdmin?.avatar ?? '',
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      firstName: yup.string().max(30).required('First name is required'),
      lastName: yup.string().max(30).required('Last name is required'),
      email: validation.email,
      phone: yup
        .string()
        .matches(/^\+?\d{6,14}$/, { message: 'Invalid phone number' })
        .required('Phone number is required'),
      avatar: yup.string().optional(),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      dispatch(generalActions.setComponentStatus({ isEmailAlreadyExists: false }));
      dispatch(clinicActions.handleNextToConfirmAddClinicTab({ clinicAdmin: values }));
    },
  });

  useEffect(() => {
    if (isEmailAlreadyExists) {
      formik.setFieldTouched('email', true);
      formik.setFieldError('email', 'Email address already exists');
    }
  }, [isEmailAlreadyExists]);

  const handleBack = () => {
    dispatch(generalActions.setComponentStatus({ selectedTab: AddClinicTabs.GENERAL_INFO }));
  };

  return (
    <Fragment>
      <Box marginTop={2}>
        <AvatarEdit
          avatar={formik.values.avatar}
          editable={false}
          name={formik.values.firstName}
          name2={formik.values.lastName}
          font={theme.typography.h2}
          size="100px"
        />
      </Box>
      <Grid container marginTop={1} rowSpacing={2} columnSpacing={1}>
        <Grid item xs={6}>
          <CustomInputBox
            label="First Name"
            required={true}
            type="text"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInputBox
            label="Last Name"
            required={true}
            type="text"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInputBox
            label="Email Address"
            required={true}
            type="text"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInputBox
            label="Phone Number"
            required={true}
            type="text"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </Grid>
      </Grid>
      <Box
        display="flex"
        justifyContent="space-between"
        marginTop={4}
        position="absolute"
        bottom="30px"
        left="30px"
        right="30px"
        sx={{
          paddingTop: '20px',
          borderTop: `1px solid ${theme.palette.shades.purpleBorder}`,
        }}
      >
        <CustomButtonLoadingButton
          text="Back"
          variant="outlined"
          color="primary"
          fullWidth={false}
          onClick={handleBack}
          width="95px"
        />
        <CustomButtonLoadingButton
          loading={isSubmitting}
          text="Next"
          variant="contained"
          color="primary"
          fullWidth={false}
          width="95px"
          onClick={() => formik.handleSubmit()}
        />
      </Box>
    </Fragment>
  );
}

export default ClinicAdminTab;
