import { Box, Skeleton, TextareaAutosize, Typography } from '@mui/material';
import { theme } from '../../theme/theme';
import { Theme, styled as materialStyled } from '@mui/material/styles';

const TextAreaStyled = materialStyled(TextareaAutosize)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.black.main,
  border: '0.5px solid',
  borderRadius: '10px',
  padding: '8px 12px',
  borderColor: theme.palette.shades.blackBorder,
  resize: 'vertical',
  fontFamily: 'Satoshi',
  fontWeight: 400,
  fontSize: '16px',
  letterSpacing: 0,
  lineHeight: 'normal',
  '&:focus-visible': {
    outlineColor: theme.palette.primary.main,
  },
  '&::placeholder': {
    color: theme.palette.shades.placeholderText,
  },
}));

function CustomTextArea(props: {
  placeholder: string;
  label: string;
  name: string;
  value: string;
  onChange: any;
  maxLength?: number;
  isLoading: boolean;
}) {
  return (
    <Box display="flex" flexDirection="column" gap="4px">
      <Typography variant="meta" sx={{ color: theme.palette.info.main }}>
        {props.label}
      </Typography>
      {!props.isLoading && (
        <TextAreaStyled
          minRows={5}
          maxRows={6}
          maxLength={props.maxLength ?? 500}
          placeholder={props.placeholder}
          theme={theme}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
        ></TextAreaStyled>
      )}
      {props.isLoading && <Skeleton animation="wave" height={121} variant="rounded" />}
    </Box>
  );
}
export default CustomTextArea;
