import React, { Fragment } from 'react';
import { Grid, FormGroup, useTheme, Typography } from '@mui/material';
import * as _ from 'lodash';
import CustomButtonLoadingButton from '../../../../components/CustomButtonLoadingButton/CustomButtonLoadingButton';
import CustomCheckBox from '../../../../components/CustomCheckBox/CustomCheckBox';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { RootState } from '../../../../redux/store';
import { Roles } from '../../../../utils/enums/roles.enum';
import Constant from '../../../../constants/constant.json';
import { clinicActions } from '../../../../redux/clinic/clinicSlice';
import { useAuth } from '../../../../context/AuthContext';

function ConfigPermission() {
  const { user } = useAuth();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const clinic = useAppSelector((state: RootState) => state.clinic);
  const componentStatus = useAppSelector((state: RootState) => state.clinic.componentStatus);
  const isSubmitting = _.get(componentStatus, 'isSubmitting', false) as boolean;

  const handlePermission = (e: any, permissionId: number, description: string, permission: string) => {
    const permissions = clinic.adminData.permissions ? [...clinic.adminData.permissions] : [];

    if (e.target.checked) {
      const index = permissions.findIndex((permission) => permission.permissionId === permissionId);
      if (index === -1) {
        permissions.push({ permissionId, role: Roles.CLINIC_ADMIN, permission, description });
      }
    } else {
      _.remove(permissions, (permission) => permission.permissionId === permissionId);
    }
    dispatch(clinicActions.setAdminData({ ...clinic.adminData, permissions }));
  };

  return (
    <Fragment>
      <Grid marginTop={1} container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} key={1}>
          <Typography key={1} variant="bodyRegular" color={theme.palette.info.main}>
            Please check the permission you want this admin to have
          </Typography>
        </Grid>
        <Grid item xs={12} key={2}>
          <FormGroup>
            {clinic.permissions.map(({ description, permission, permissionId }, index) => {
              let isChecked = false;
              if (clinic.adminData.permissions) {
                isChecked = !!_.find(clinic.adminData.permissions, { permissionId });
              }
              return (
                <CustomCheckBox
                  index={index}
                  label={description}
                  name={permission}
                  onClick={(e) => handlePermission(e, permissionId, description, permission)}
                  isChecked={isChecked}
                  key={permissionId}
                  disabled={!user.permissions.includes(permission)}
                />
              );
            })}
          </FormGroup>
        </Grid>
        <Grid
          key={3}
          container
          rowSpacing={2}
          columnSpacing={1}
          marginTop={2}
          justifyContent="end"
          sx={{ paddingTop: '30px', borderTop: `1px solid ${theme.palette.shades.purpleBorder}` }}
        >
          <CustomButtonLoadingButton
            text="Next"
            variant="contained"
            color="primary"
            fullWidth={false}
            onClick={() => {
              dispatch(clinicActions.setComponentStatus({ selectedTab: Constant.AdminTabs.CONFIRMATION.slug }));
            }}
            width="95px"
            loading={isSubmitting}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default ConfigPermission;
