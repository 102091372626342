import React, { useEffect, useState } from 'react';
import { Box, useTheme, Divider, Button, Theme } from '@mui/material';
import ProfileLinear from '../../assets/img/profile-linear.svg';
import ProfileBold from '../../assets/img/profile-bold.svg';
import LockLinear from '../../assets/img/lock-outline.svg';
import LockBold from '../../assets/img/lock-bold.svg';
import { PersonalInfo } from './PersonalInfo/PersonalInfo';
import { SecuritySettings } from './SecuritySettings/SecuritySettings';
import { styled as materialStyled } from '@mui/material/styles';
import { useAppDispatch } from '../../redux/hooks';
import { authActions } from '../../redux/auth/authSlice';
import { useAuth } from '../../context/AuthContext';
import { Roles } from '../../utils/enums/roles.enum';

export const CustomButton = materialStyled(Button)(({ theme, isselected }: { theme: Theme; isselected: string }) => ({
  ...theme.typography.bodyBold,
  gap: '10px',
  width: '100%',
  height: '56px',
  justifyContent: 'center',
  padding: '12px',
  borderRadius: '15px',
  textTransform: 'none',
  backgroundColor: isselected === 'true' ? theme.palette.secondary.main : 'inherit',
  color: isselected === 'true' ? theme.palette.primary.main : theme.palette.info.main,
}));

function MyProfile() {
  const theme = useTheme();
  const [option, setOption] = useState<'personal_info' | 'security_settings'>('personal_info');
  const dispatch = useAppDispatch();
  const { user } = useAuth();

  useEffect(() => {
    dispatch(authActions.clearComponentStatus());
  }, []);

  const handleTabSelect = (option: 'personal_info' | 'security_settings') => {
    dispatch(authActions.clearComponentStatus());
    setOption(option);
  };

  useEffect(() => {
    dispatch(authActions.setTopBarView({ text: 'My Profile', path: '/my-profile', sideMenuPath: '/my-profile' }));
  }, []);

  return (
    <Box
      sx={{
        width: user.role === Roles.CLINICIAN ? '823px' : '538px',
        backgroundColor: theme.palette.white.main,
        borderRadius: '20px',
        padding: '32px',
        boxShadow: '1',
      }}
    >
      <Box columnGap={1} display="flex" justifyContent="space-between">
        <CustomButton
          theme={theme}
          isselected={`${option === 'personal_info'}`}
          startIcon={<Box component="img" src={option === 'personal_info' ? ProfileBold : ProfileLinear}></Box>}
          onClick={() => handleTabSelect('personal_info')}
        >
          Personal Info
        </CustomButton>
        <CustomButton
          theme={theme}
          isselected={`${option === 'security_settings'}`}
          startIcon={
            <Box component="img" width="32px" src={option === 'security_settings' ? LockBold : LockLinear}></Box>
          }
          onClick={() => handleTabSelect('security_settings')}
        >
          Security Settings
        </CustomButton>
      </Box>
      <Divider sx={{ marginY: '20px' }} />
      {option === 'personal_info' ? <PersonalInfo /> : <SecuritySettings />}
    </Box>
  );
}

export default MyProfile;
