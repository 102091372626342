import { ChangeEvent, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import CustomTable, { ColumnProps } from '../../../components/CustomTable/CustomTable';
import { Box, Button, IconButton, InputAdornment, Stack, TextField, Typography, useTheme } from '@mui/material';
import MagniferOutlineSVG from '../../../assets/img/magnifer-outline.svg';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useAuth } from '../../../context/AuthContext';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import EyeBoldSVG from '../../../assets/img/eye-bold.svg';
import SolarPenBoldSVG from '../../../assets/img/solar_pen-bold.svg';
import TrashBinBoldSVG from '../../../assets/img/trash-bin-2-bold.svg';
import { Permission } from '../../../utils/enums/permission.enum';
import { authActions } from '../../../redux/auth/authSlice';
import ViewClinicAdmin from './ViewClinicAdmin/ViewClinicAdmin';
import { SIDEBAR_OPTIONS } from '../../../utils/sidebar-schema';
import { SidebarOption } from '../../../utils/enums/sidebar-option.enum';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ClinicAdminProps, clinicActions } from '../../../redux/clinic/clinicSlice';
import AddEditAdmin from './Add/AddEditAdmin';
import EditAdmin from './Edit/EditAdmin';
import DeleteAdmin from './Delete/AdminDelete';
import { SortOrder } from '../../../utils/enums/sort-order.enum';

const ManageClinicAdmins = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const admin = useAppSelector((state: RootState) => state.clinic);
  const { user } = useAuth();
  const searchBtn = useRef<HTMLButtonElement>(null);

  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortBy, setSortBy] = useState<string>('clinicBaseId');
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.DESC);
  const [searchText, setSearchText] = useState<string>('');

  const totalClinicAdminCount = useAppSelector((state: RootState) => state.clinic.totalClinicAdminCount);
  const clinicAdminList = useAppSelector((state: RootState) => state.clinic.clinicAdminList);

  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const shouldAdminListLoad = _.get(componentStatus, 'shouldAdminListLoad', false) as boolean;
  const isClinicAdminTableLoading = _.get(componentStatus, 'isClinicAdminTableLoading', false) as boolean;

  const columns: ColumnProps[] = [
    { key: 'clinicBaseId', name: 'No.', enableSort: true, align: 'left', width: '5%', minWidth: 70, resizable: true },
    { key: 'name', name: 'Name', enableSort: true, align: 'left', width: '30%', minWidth: 160, resizable: true },
    { key: 'email', name: 'Email', enableSort: false, align: 'left', width: 'auto', minWidth: 140, resizable: true },
    { key: 'phone', name: 'Phone', enableSort: false, align: 'left', width: 'auto', minWidth: 80, resizable: true },
    { key: 'action', name: '', enableSort: false, align: 'right', width: '10%', minWidth: 100, resizable: false },
  ];

  const onClickRowsFunctions = useMemo(() => {
    const rowClickFunctions: (() => void)[] = [];
    clinicAdminList.forEach((clinicAdmin) => {
      rowClickFunctions.push(() => handleViewClinicAdminDetails(clinicAdmin.userId));
    });
    return rowClickFunctions;
  }, [clinicAdminList]);

  useEffect(() => {
    dispatch(
      authActions.setTopBarView({
        text: SIDEBAR_OPTIONS[SidebarOption.MANAGE_ADMINS].name,
        path: SIDEBAR_OPTIONS[SidebarOption.MANAGE_ADMINS].path,
        sideMenuPath: SIDEBAR_OPTIONS[SidebarOption.MANAGE_ADMINS].path,
        button: user.permissions.includes(Permission.CLINIC_ADMIN_ADD_CLINIC_ADMIN)
          ? {
              text: 'Add New Admin',
              icon: <AddCircleOutlineIcon fontSize="small" />,
              color: 'primary',
              onClick: () => {
                dispatch(clinicActions.setAddAdminPopupClose(true));
              },
            }
          : undefined,
      }),
    );
    dispatch(clinicActions.setClinicAdminList([]));
  }, []);

  const enterKeyDownHandler = (event: { key: string; preventDefault: () => void }) => {
    if (event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (searchBtn.current) {
          searchBtn.current.click();
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', enterKeyDownHandler);
    return () => {
      document.removeEventListener('keydown', enterKeyDownHandler);
    };
  }, []);

  useEffect(() => {
    const offset = (page - 1) * rowsPerPage;
    dispatch(
      clinicActions.getClinicAdminListAsync({
        offset,
        limit: rowsPerPage,
        sortBy,
        sortOrder,
        clinicId: user.clinicId,
        type: 'admin',
      }),
    );
  }, [page, rowsPerPage, sortBy, sortOrder]);

  useEffect(() => {
    if (shouldAdminListLoad) {
      const offset = (page - 1) * rowsPerPage;
      dispatch(
        clinicActions.getClinicAdminListAsync({
          offset,
          limit: rowsPerPage,
          sortBy,
          sortOrder,
          clinicId: user.clinicId,
          type: 'admin',
        }),
      );
    }
  }, [shouldAdminListLoad]);

  const handleSearchButton = () => {
    const offset = (page - 1) * rowsPerPage;
    dispatch(
      clinicActions.getClinicAdminListAsync({
        offset,
        limit: rowsPerPage,
        sortBy,
        sortOrder,
        clinicId: user.clinicId,
        searchText,
        type: 'admin',
      }),
    );
  };

  useEffect(() => {
    setIsAddOpen(admin.isAdminPopupOpen);
  }, [admin.isAdminPopupOpen]);

  useEffect(() => {
    setIsEditOpen(admin.isEditAdminPopupOpen);
  }, [admin.isEditAdminPopupOpen]);

  useEffect(() => {
    setIsDeleteOpen(admin.isDeleteAdminPopupOpen);
  }, [admin.isDeleteAdminPopupOpen]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setRowsPerPage(rowsPerPage);
    setPage(1);
  };

  const handleViewClinicAdminDetails = (userId: string) => {
    dispatch(authActions.setComponentStatus({ openClinicAdminDetailsModal: true }));
    dispatch(clinicActions.getClinicAdminByUserIdAsync(userId));
  };

  const handleEditAdmin = (adminId: string) => {
    setSelectedUserId(adminId);
    dispatch(clinicActions.setEditAdminPopupClose(true));
    dispatch(clinicActions.getClinicAdminByUserIdAsync(adminId));
  };

  const handleDeleteAdmin = (adminId: string) => {
    setSelectedUserId(adminId);
    dispatch(clinicActions.setDeleteAdminPopupClose(true));
    dispatch(clinicActions.getClinicAdminByUserIdAsync(adminId));
  };

  const getFormattedClinicAdminList = (clinicAdminList: ClinicAdminProps[]) => {
    return clinicAdminList.map((clinicAdmin) => {
      return {
        clinicBaseId: clinicAdmin.clinicBaseId,
        name: (
          <Box display="flex" gap={1}>
            <AvatarEdit
              editable={false}
              avatar={clinicAdmin.avatar}
              name={clinicAdmin.firstName}
              name2={clinicAdmin.lastName}
              size="24px"
              font={theme.typography.subtitle1}
            />
            <Typography variant="bodyRegular">{`${clinicAdmin.firstName} ${clinicAdmin.lastName}`} </Typography>
          </Box>
        ),
        email: clinicAdmin.email,
        phone: clinicAdmin.phone,
        action: (
          <Stack direction="row" spacing={1}>
            <IconButton onClick={() => handleViewClinicAdminDetails(clinicAdmin.userId)} size="small">
              <Box component="img" src={EyeBoldSVG}></Box>
            </IconButton>
            {user.permissions.includes(Permission.CLINIC_ADMIN_CHANGE_PERMISSIONS_CLINIC_ADMIN) &&
              user.userId !== clinicAdmin.userId && (
                <IconButton onClick={() => handleEditAdmin(clinicAdmin.userId)} size="small" data-testid="edit-btns">
                  <Box component="img" src={SolarPenBoldSVG}></Box>
                </IconButton>
              )}
            {user.permissions.includes(Permission.CLINIC_ADMIN_REMOVE_CLINIC_ADMIN) &&
              user.userId !== clinicAdmin.userId && (
                <IconButton
                  onClick={() => handleDeleteAdmin(clinicAdmin.userId)}
                  size="small"
                  data-testid="delete-btns"
                >
                  <Box component="img" src={TrashBinBoldSVG}></Box>
                </IconButton>
              )}
          </Stack>
        ),
      };
    });
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const str = event.target.value;
    setSearchText(str);
  };

  return (
    <Fragment>
      <CustomTable
        isLoading={isClinicAdminTableLoading}
        totalDataRows={totalClinicAdminCount}
        enablePagination={true}
        selectedPage={page}
        enablePagePerRows={true}
        rowsPerList={[10, 20, 50, 100]}
        selectedRowsPerPage={rowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        columns={columns}
        rows={getFormattedClinicAdminList(clinicAdminList)}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSortByChange={setSortBy}
        handleSortOrderChange={setSortOrder}
        minBodyRowSpace={10}
        rowOnDoubleClick={onClickRowsFunctions}
        customTableTop={
          <Box mx={2} my={2} display="flex" columnGap={1}>
            <TextField
              fullWidth
              value={searchText}
              name="search"
              onChange={handleSearchChange}
              placeholder="Search by Name"
              sx={{ '& .MuiInputBase-root': { height: '38px', borderRadius: '10px' } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box component="img" src={MagniferOutlineSVG} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              ref={searchBtn}
              fullWidth
              variant="contained"
              onClick={handleSearchButton}
              sx={{ borderRadius: '10px', width: '109px' }}
            >
              Search
            </Button>
          </Box>
        }
      />

      <AddEditAdmin isAddOpen={isAddOpen} />
      <EditAdmin userId={selectedUserId} isEditOpen={isEditOpen} />
      <DeleteAdmin userId={selectedUserId} isDeleteOpen={isDeleteOpen} />
      <ViewClinicAdmin />
    </Fragment>
  );
};

export default ManageClinicAdmins;
