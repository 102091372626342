import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { AlertColor } from '@mui/material';

export interface IGeneralSlice {
  componentStatus: { [name: string]: undefined | string | boolean };
  countryList: { countryId: number; country: string }[];
  stateList: { stateId: number; state: string }[];
  alertMessage: { msg: string; severity: AlertColor } | undefined;
}

const initialState: IGeneralSlice = {
  componentStatus: {},
  countryList: [],
  stateList: [],
  alertMessage: undefined,
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setComponentStatus(state, action: PayloadAction<{ [key: string]: undefined | string | boolean }>) {
      state.componentStatus = { ...state.componentStatus, ...action.payload };
    },
    clearComponentStatus(state, action: PayloadAction<string[] | undefined>) {
      if (action.payload?.length) {
        state.componentStatus = _.omit(state.componentStatus, action.payload);
      } else {
        state.componentStatus = {};
      }
    },
    setCountryList(state, action: PayloadAction<{ countryId: number; country: string }[]>) {
      state.countryList = action.payload;
    },
    setStateList(state, action: PayloadAction<{ stateId: number; state: string }[]>) {
      state.stateList = action.payload;
    },
    setAlertMessage(state, action: PayloadAction<{ msg: string; severity: AlertColor }>) {
      state.alertMessage = action.payload;
    },
    clearAlertMessage(state) {
      state.alertMessage = undefined;
    },
    getCountryListAsync() {},
    getStateListAsync(state, action: PayloadAction<{ country: string }>) {},
  },
});

export const { actions: generalActions, reducer: generalReducer } = generalSlice;
