import { Divider, Grid } from '@mui/material';
import { Box } from '@mui/system';
import ClinicApproveReject from './Components/ClinicApproveReject';
import ClinicApproveRejectInform from './Components/ClinicApproveRejectInform';
import PatientAddInform from './Components/PatientAddInform';
import CommonInfo from './Components/CommonInfo';
import { NotificationType } from '../../utils/enums/notification-type.enum';
import C2CTransferApproveReject from './Components/C2CTransferApproveReject';
import C2CTransferInform from './Components/C2CTransferInform';

export interface NotificationCardProp {
  notificationId: string;
  notificationType: NotificationType;
  subject: string;
  description?: string;
  image?: string;
  isActionNeeded: boolean;
  createdUser?: string;
  createdUserAvatar?: string;
  approveBtnLoading?: boolean;
  rejectBtnLoading?: boolean;
  actionDisable?: boolean;
  patient?: {
    firstName: string;
    lastName: string;
    avatar: string;
  };
}

export function NotificationCard(props: NotificationCardProp) {
  return (
    <Box mt={2}>
      <Grid container>
        {props.notificationType === NotificationType.UPDATE_CLINIC && (
          <ClinicApproveReject
            notificationId={props.notificationId}
            createdUserName={props.createdUser ?? ''}
            image={props.image ?? ''}
            subject={props.subject}
            description={props.description ?? ''}
            actionDisable={props.actionDisable ?? false}
            approveBtnLoading={props.approveBtnLoading ?? false}
            rejectBtnLoading={props.rejectBtnLoading ?? false}
          />
        )}

        {(props.notificationType === NotificationType.APPROVAL_INFORM ||
          props.notificationType === NotificationType.REJECT_INFORM) && (
          <ClinicApproveRejectInform
            notificationType={props.notificationType}
            createdUserName={props.createdUser ?? ''}
            subject={props.subject}
            description={props.description ?? ''}
          />
        )}

        {props.notificationType === NotificationType.PATIENT_ADD_INFORM && (
          <PatientAddInform
            subject={props.subject}
            description={props.description ?? ''}
            clinicAdminName={props.createdUser ?? ''}
            clinicAdminAvatar={props.createdUserAvatar ?? ''}
            patientFirstName={props.patient?.firstName ?? ''}
            patientLastName={props.patient?.lastName ?? ''}
            patientAvatar={props.patient?.avatar ?? ''}
          />
        )}

        {props.notificationType === NotificationType.CLINIC_TO_CLINIC_TRANSFER_REQUEST && (
          <C2CTransferApproveReject
            notificationId={props.notificationId}
            subject={props.subject}
            description={props.description ?? ''}
            actionDisable={props.actionDisable ?? false}
            approveBtnLoading={props.approveBtnLoading ?? false}
            rejectBtnLoading={props.rejectBtnLoading ?? false}
            notificationType={props.notificationType}
          />
        )}

        {props.notificationType === NotificationType.CLINIC_TO_CLINIC_TRANSFER_INFORM && (
          <C2CTransferInform
            notificationId={props.notificationId}
            subject={props.subject}
            description={props.description ?? ''}
            actionDisable={props.actionDisable ?? false}
            approveBtnLoading={props.approveBtnLoading ?? false}
            rejectBtnLoading={props.rejectBtnLoading ?? false}
            notificationType={props.notificationType}
          />
        )}

        {props.notificationType === NotificationType.COMMON_INFO && (
          <CommonInfo subject={props.subject} description={props.description ?? ''} />
        )}
      </Grid>
      <Divider />
    </Box>
  );
}
