import { Roles } from '../../utils/enums/roles.enum';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddClinicianProps } from '../clinician/clinicianSlice';
import _ from 'lodash';

export interface SaveFeedbackPayload {
  device: string;
  deviceOS: string;
  deviceOSVersion?: string | null;
  browser?: string | null;
  browserVersion?: string | null;
  releaseVersion?: string | null;
  feedbackSubmittedPage: string;
  feedback: string;
}
export interface IUserSlice {
  componentStatus: { [name: string]: undefined | string | boolean };
  userId: string;
  email: string;
  role: Roles | string;
  firstName: string;
  lastName: string;
  phone: string;
  avatar: string;
  permissions: string[];
  clinicId: string | null;
  clinician?: AddClinicianProps;
  clinicName: string | null;
}

const initialState: IUserSlice = {
  userId: '',
  email: '',
  role: '',
  firstName: '',
  lastName: '',
  phone: '',
  avatar: '',
  permissions: [],
  clinicId: null,
  clinician: undefined,
  clinicName: '',
  componentStatus: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setComponentStatus(state, action: PayloadAction<{ [key: string]: undefined | string | boolean }>) {
      state.componentStatus = { ...state.componentStatus, ...action.payload };
    },
    clearComponentStatus(state, action: PayloadAction<string[] | undefined>) {
      if (action.payload?.length) {
        state.componentStatus = _.omit(state.componentStatus, action.payload);
      } else {
        state.componentStatus = {};
      }
    },
    setUser(state, action: PayloadAction<Partial<IUserSlice>>) {
      state.userId = action.payload.userId ? action.payload.userId : state.userId;
      state.role = action.payload.role ? action.payload.role : state.role;
      state.email = action.payload.email ? action.payload.email : state.email;
      state.firstName = action.payload.firstName ? action.payload.firstName : state.firstName;
      state.lastName = action.payload.lastName ? action.payload.lastName : state.lastName;
      state.phone = action.payload.phone ? action.payload.phone : state.phone;
      state.avatar = action.payload.avatar ? action.payload.avatar : state.avatar;
      state.permissions = action.payload.permissions ? action.payload.permissions : state.permissions;
      state.clinicId = action.payload.clinicId ? action.payload.clinicId : state.clinicId;
      state.clinician = action.payload.clinician ? action.payload.clinician : state.clinician;
      state.clinicName = action.payload.clinicName ? action.payload.clinicName : state.clinicName;
    },
    getUserAsync: (state) => {},
    userUpdateAsync: (state, action: PayloadAction<{ data: Partial<IUserSlice>; image: FormData | undefined }>) => {},
    sendEmailVerificationCodeAsync: (state, action: PayloadAction<{ email: string }>) => {},
    verifyEmailVerificationCodeAsync: (state, action: PayloadAction<{ verificationCode: string }>) => {},
    changePasswordAsync: (state, action: PayloadAction<{ password: string; newPassword: string }>) => {},
    saveFeedback(state, action: PayloadAction<SaveFeedbackPayload>) {},
  },
});

export const { actions: userActions, reducer: userReducer } = userSlice;
