import { AxiosResponse } from 'axios';
import { backendApiInstance } from './backendApiInstance';
import { AddClinicianProps } from '../redux/clinician/clinicianSlice';
import { SortOrder } from '../utils/enums/sort-order.enum';

export interface GetAllClinicianParamType {
  clinicId?: string;
  offset: number;
  limit: number;
  sortBy: string;
  sortOrder: SortOrder;
  searchText?: string;
}

export async function getAllClinicians({
  clinicId,
  offset,
  limit,
  sortBy,
  sortOrder,
  searchText,
}: GetAllClinicianParamType): Promise<AxiosResponse> {
  return backendApiInstance.get('clinicians', {
    params: { clinicId, offset, limit, sortBy, sortOrder, query: searchText },
  });
}

export async function getClinicianByUserId(userId: string): Promise<AxiosResponse> {
  return backendApiInstance.get(`clinicians/${userId}`);
}

export async function createClinician(clinicianData: AddClinicianProps): Promise<AxiosResponse> {
  return backendApiInstance.post('clinicians', { ...clinicianData });
}

export async function getAllCliniciansInClinic(): Promise<AxiosResponse> {
  return backendApiInstance.get('clinicians/all-clinicians-in-clinic');
}

export async function deactivateClinician(userId: string, password: string): Promise<AxiosResponse> {
  return backendApiInstance.post(`clinicians/${userId}/deactivate`, { password });
}

export async function reactivateClinician(userId: string, password: string): Promise<AxiosResponse> {
  return backendApiInstance.post(`clinicians/${userId}/reactivate`, { password });
}

export async function updateClinician(data: Partial<AddClinicianProps>): Promise<AxiosResponse> {
  return backendApiInstance.put('clinicians', data);
}
