import React, { Fragment, useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import * as _ from 'lodash';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import GreenCheckCircleSVG from '../../../assets/img/check-circle-bold-duotone.svg';
import ShieldOutlinedSVG from '../../../assets/img/shield-warning-bold-duotone.svg';

import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { soundActions } from '../../../redux/sound/soundSlice';
import { authActions } from '../../../redux/auth/authSlice';
import { getFormattedTimeInMinutes } from '../SoundLibrary';
import { TimeConversionVariant } from '../../../utils/enums/time-conversion-variant.enum';

interface ConfirmAddSoundProps {
  isOpen: boolean;
}

function ConfirmAddSound({ isOpen }: ConfirmAddSoundProps) {
  const dispatch = useAppDispatch();

  const soundTrack = useAppSelector((state: RootState) => state.sound.newSoundTrack);
  const componentStatusAuth = useAppSelector((state: RootState) => state.auth.componentStatus);
  const componentStatusSound = useAppSelector((state: RootState) => state.sound.componentStatus);
  const isAddSoundSuccess = _.get(componentStatusSound, 'isSubmitSoundSuccess', false) as boolean;
  const isSoundTrackSubmitting = _.get(componentStatusSound, 'isSoundSubmitting', false) as boolean;

  const error = _.get(componentStatusAuth, 'error', '') as string;
  const userId = useAppSelector((state: RootState) => state.user.userId);

  const initialValues = {};
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: yup.object({}),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      dispatch(soundActions.setComponentStatus({ isSoundSubmitting: true }));
      const soundData = { ...soundTrack.soundData, createdBy: userId };
      dispatch(soundActions.addSoundTrackAsync({ ...soundTrack, soundData }));
    },
  });

  const handleModalClose = () => {
    dispatch(soundActions.setAddSoundSuccessModalClose());
    dispatch(authActions.clearComponentStatus());
    dispatch(soundActions.clearComponentStatus());
  };

  useEffect(() => {
    dispatch(soundActions.setComponentStatus({ isSoundSubmitting: false, isSubmitSoundSuccess: false }));
  }, []);

  return (
    <Fragment>
      <CustomModal
        modal={true}
        open={isOpen}
        btnDisabled={isSoundTrackSubmitting}
        handleButtonOnClick={handleModalClose}
        icon={!isAddSoundSuccess ? ShieldOutlinedSVG : GreenCheckCircleSVG}
        btnText={isAddSoundSuccess ? 'Dismiss' : ''}
        width="374px"
      >
        <Typography variant="h5" textAlign="center">
          {!isAddSoundSuccess ? 'Confirm adding new sound' : 'New sound added!'}
        </Typography>

        <Typography mt={4} variant="h6" component="p" textAlign="center">
          {soundTrack.soundData.title}
        </Typography>
        <Typography mt={0.5} variant="h6" component="p" textAlign="center">
          {getFormattedTimeInMinutes(soundTrack.soundData.duration, TimeConversionVariant.CAPITALIZED)}
        </Typography>
        <Typography mt={0.5} variant="h6" component="p" textAlign="center">
          {String(soundTrack.soundData.type).charAt(0).toUpperCase() +
            String(soundTrack.soundData.type).slice(1) +
            ' Audio'}
        </Typography>

        <Box mt={2} />

        {!isAddSoundSuccess && (
          <Grid container marginTop={3} columnSpacing={2}>
            {error && (
              <Grid item xs={12}>
                <Typography mb={1} variant="bodyRegular" component="p" textAlign="center" color="error">
                  {error}
                </Typography>
              </Grid>
            )}
            <Grid item xs={6}>
              <LoadingButton
                variant="outlined"
                onClick={handleModalClose}
                fullWidth
                sx={{ borderRadius: '10px', height: '43px' }}
              >
                Dismiss
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                loading={isSoundTrackSubmitting}
                onClick={() => formik.handleSubmit()}
                variant="contained"
                fullWidth
                color="primary"
                sx={{ borderRadius: '10px', height: '43px' }}
              >
                Add
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </CustomModal>
    </Fragment>
  );
}

export default ConfirmAddSound;
