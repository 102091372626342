import React, { Fragment } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import CustomButtonLoadingButton from '../../../components/CustomButtonLoadingButton/CustomButtonLoadingButton';
import { generalActions } from '../../../redux/general/generalSlice';
import _ from 'lodash';
import { AddClinicProps, clinicActions } from '../../../redux/clinic/clinicSlice';
import { AddClinicTabs } from '../../../utils/enums/add-clinic-tabs.enum';

function ConfirmTab() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const addClinicData = useAppSelector((state: RootState) => state.clinic.addClinicData) as AddClinicProps;

  const componentStatus = useAppSelector((state: RootState) => state.general.componentStatus);
  const isSubmitting = _.get(componentStatus, 'isSubmitting', false) as boolean;

  const handleBack = () => {
    dispatch(generalActions.setComponentStatus({ selectedTab: AddClinicTabs.CLINIC_ADMIN }));
  };

  const handleAddClinic = () => {
    dispatch(clinicActions.createClinicAsync(addClinicData));
  };

  return (
    <Fragment>
      <Grid container marginTop={2} rowSpacing={2} columnSpacing={10}>
        <Grid item xs={6} display="flex">
          <AvatarEdit
            selectedAvatar={addClinicData?.avatar?.url}
            avatar=""
            editable={false}
            name={`${addClinicData?.name}`}
            font={theme.typography.h2}
            size="100px"
          />
          <Box marginLeft={4}>
            <Typography variant="h6">{addClinicData?.name}</Typography>
            <Typography component="p" variant="bodyRegular" color={theme.palette.info.main}>
              {addClinicData?.email}
            </Typography>
            <Typography component="p" variant="bodyRegular" color={theme.palette.info.main}>
              {addClinicData?.phone}
            </Typography>
            <Typography
              component="p"
              variant="bodyRegular"
              color={theme.palette.info.main}
            >{`${addClinicData?.addressLine1}, ${addClinicData?.addressLine2}`}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} display="flex">
          <AvatarEdit
            avatar={addClinicData?.clinicAdmin?.avatar}
            editable={false}
            name={addClinicData?.clinicAdmin?.firstName}
            name2={addClinicData?.clinicAdmin?.lastName}
            font={theme.typography.h2}
            size="100px"
          />
          <Box marginLeft={4}>
            <Typography variant="h6">{`${addClinicData?.clinicAdmin?.firstName} ${addClinicData?.clinicAdmin?.lastName}`}</Typography>
            <Typography mt={1} component="p" variant="bodyBold">
              Clinic Admin
            </Typography>
            <Typography mt={1} component="p" variant="bodyRegular" color={theme.palette.info.main}>
              {addClinicData?.clinicAdmin?.email}
            </Typography>
            <Typography component="p" variant="bodyRegular" color={theme.palette.info.main}>
              {addClinicData?.clinicAdmin?.phone}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box
        display="flex"
        justifyContent="space-between"
        marginTop={4}
        position="absolute"
        bottom="30px"
        left="30px"
        right="30px"
        sx={{
          paddingTop: '20px',
          borderTop: `1px solid ${theme.palette.shades.purpleBorder}`,
        }}
      >
        <CustomButtonLoadingButton
          text="Back"
          variant="outlined"
          color="primary"
          fullWidth={false}
          onClick={handleBack}
          width="95px"
        />
        <CustomButtonLoadingButton
          loading={isSubmitting}
          text="Add Clinic"
          variant="contained"
          color="primary"
          fullWidth={false}
          width="132px"
          onClick={handleAddClinic}
        />
      </Box>
    </Fragment>
  );
}

export default ConfirmTab;
