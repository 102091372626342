import CustomModal from '../../../components/CustomModal/CustomModal';
import GreenCheckCircleSVG from '../../../assets/img/check-circle-bold-duotone.svg';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';
import { generalActions } from '../../../redux/general/generalSlice';
import * as dateFn from 'date-fns';
import { patientActions } from '../../../redux/patient/patientSlice';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { Roles } from '../../../utils/enums/roles.enum';

function AddEditPatientSuccessModal() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();

  const addEditPatientData = useAppSelector((state: RootState) => state.patient.addEditPatientData);

  const componentStatus = useAppSelector((state: RootState) => state.general.componentStatus);
  const isAddPatientSuccessModalOpen = _.get(componentStatus, 'isAddPatientSuccessModalOpen', false) as boolean;
  const isEditPatientSuccessModalOpen = _.get(componentStatus, 'isEditPatientSuccessModalOpen', false) as boolean;

  const handleDismiss = () => {
    if (user.role === Roles.CLINICIAN && isAddPatientSuccessModalOpen) {
      // navigate patient details page if patient added by clinician
      const newlyCreatedPatientUserId = componentStatus['newlyCreatedPatientUserId'];
      dispatch(generalActions.clearComponentStatus());
      dispatch(patientActions.setAddEditPatientData(undefined));
      navigate(`/patients/${newlyCreatedPatientUserId}`);
    } else {
      dispatch(generalActions.clearComponentStatus());
      dispatch(patientActions.setAddEditPatientData(undefined));
      dispatch(patientActions.setComponentStatus({ shouldPatientsListLoad: Date.now().toString() }));
      dispatch(generalActions.setComponentStatus({ shouldPatientDetailsReload: Date.now().toString() }));
    }
  };

  return (
    <CustomModal
      modal={true}
      open={isAddPatientSuccessModalOpen || isEditPatientSuccessModalOpen}
      title={isAddPatientSuccessModalOpen ? 'Patient Added' : 'Patient Updated'}
      icon={GreenCheckCircleSVG}
      btnText="Dismiss"
      width="374px"
      handleButtonOnClick={handleDismiss}
    >
      <Stack direction="column" alignItems="center" justifyContent="center">
        <Box>
          <AvatarEdit
            editable={false}
            avatar={addEditPatientData?.avatar}
            name={addEditPatientData?.firstName}
            name2={addEditPatientData?.lastName}
            size="72px"
            font={theme.typography.h3}
          />
        </Box>

        <Typography mt={2} variant="h6" component="p" textAlign="center">
          {`${addEditPatientData?.firstName} ${addEditPatientData?.lastName}`}
        </Typography>
        <Typography mt={1} variant="bodyRegular" component="p" textAlign="center" color={theme.palette.info.main}>
          {addEditPatientData?.email}
        </Typography>
        <Typography mt={1} variant="bodyRegular" component="p" textAlign="center" color={theme.palette.info.main}>
          {addEditPatientData?.phone}
        </Typography>
        {addEditPatientData?.birthday && (
          <Typography mt={1} variant="bodyRegular" component="p" textAlign="center" color={theme.palette.info.main}>
            {addEditPatientData?.birthday
              ? dateFn.differenceInYears(new Date(), new Date(addEditPatientData.birthday))
              : ''}
            &nbsp;years old
          </Typography>
        )}
        <Typography mt={1} variant="bodyRegular" component="p" textAlign="center" color={theme.palette.info.main}>
          {addEditPatientData?.gender}
        </Typography>
      </Stack>
    </CustomModal>
  );
}

export default AddEditPatientSuccessModal;
