import React, { Fragment, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { authActions } from '../../redux/auth/authSlice';
import { Roles } from '../../utils/enums/roles.enum';
import CustomTable, { ColumnProps } from '../../components/CustomTable/CustomTable';
import { Autocomplete, Box, Button, Grid, IconButton, Stack, TextField, Typography, useTheme } from '@mui/material';
import { RootState } from '../../redux/store';
import _ from 'lodash';
import AvatarEdit from '../../components/AvatarEdit/AvatarEdit';
import * as dateFns from 'date-fns';
import EyeBoldSVG from '../../assets/img/eye-bold.svg';
import { clinicActions } from '../../redux/clinic/clinicSlice';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Notification, notificationAction } from '../../redux/notification/notificationSlice';
import NotificationView from './NotificationView/NotificationView';
import { NotificationStatus } from '../../utils/enums/notification-status.enum';
import { SIDEBAR_OPTIONS } from '../../utils/sidebar-schema';
import { SidebarOption } from '../../utils/enums/sidebar-option.enum';
import { SortOrder } from '../../utils/enums/sort-order.enum';
import { formatDate } from '../../utils/time-util';

function Requests() {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortBy, setSortBy] = useState<string>('date');
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.DESC);

  const [clinicId, setClinicId] = useState<string | undefined>(undefined);
  const [requestor, setRequestor] = useState<string | undefined>(undefined);
  const [requestorId, setRequestorId] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<NotificationStatus | undefined>(undefined);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);

  const clinicsDropdownList = useAppSelector((state: RootState) => state.clinic.clinicDropdownList);
  const requestorNamesDropdownList = useAppSelector((state: RootState) => state.notification.requestorsDropdownList);
  const requestorDropdownList = [
    { name: 'Clinic Admin', role: Roles.CLINIC_ADMIN },
    { name: 'Clinician', role: Roles.CLINICIAN },
    { name: 'Patient', role: Roles.PATIENT },
  ];

  const statusDropdownList = [
    { name: 'Pending', status: NotificationStatus.PENDING },
    { name: 'Approved', status: NotificationStatus.APPROVE },
    { name: 'Rejected', status: NotificationStatus.REJECT },
  ];

  const totalNotificationCount = useAppSelector((state: RootState) => state.notification.notificationListCount);
  const notificationList = useAppSelector((state: RootState) => state.notification.notificationList);

  const componentStatus = useAppSelector((state: RootState) => state.notification.componentStatus);
  const isNotificationsListLoading = _.get(componentStatus, 'isNotificationsListLoading', false) as boolean;
  const isNotificationModalOpen = _.get(componentStatus, 'isNotificationModalOpen', false) as boolean;

  useEffect(() => {
    dispatch(
      authActions.setTopBarView({
        text: 'Requests',
        backButton: { path: SIDEBAR_OPTIONS[SidebarOption.DASHBOARD].path },
      }),
    );
  }, []);

  useEffect(() => {
    const offset = (page - 1) * rowsPerPage;
    dispatch(
      notificationAction.getNotificationListAsync({
        offset,
        limit: rowsPerPage,
        sortOrder,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        clinicId,
        requestor,
        status,
      }),
    );
  }, [page, rowsPerPage, sortOrder]);

  useEffect(() => {
    dispatch(clinicActions.getAllClinicNamesAsync({ status: undefined }));
    dispatch(notificationAction.getAllRequestorNamesAsync());
  }, []);

  const getColumns = (): ColumnProps[] => {
    const columnsViewOrder = ['date', 'clinicName', 'requestor', 'requestorName', 'request', 'status', 'action'];

    const columns: Array<ColumnProps | undefined> = [
      { key: 'date', name: 'Date/Time', enableSort: true, align: 'left', width: '12%', minWidth: 30, resizable: true },
      {
        key: 'clinicName',
        name: 'Clinic Name',
        enableSort: false,
        align: 'left',
        width: 'auto',
        minWidth: 100,
        resizable: true,
      },
      {
        key: 'requestor',
        name: 'Requestor',
        enableSort: false,
        align: 'left',
        width: 'auto',
        minWidth: 50,
        resizable: true,
      },
      {
        key: 'requestorName',
        name: 'Requestor Name',
        enableSort: false,
        align: 'left',
        width: 'auto',
        minWidth: 100,
        resizable: true,
      },
      {
        key: 'request',
        name: 'Request',
        enableSort: false,
        align: 'left',
        width: 'auto',
        minWidth: 100,
        resizable: true,
      },
      { key: 'status', name: 'Status', enableSort: false, align: 'left', width: 'auto', minWidth: 30, resizable: true },
      { key: 'action', name: '', enableSort: false, align: 'right', width: '5%', minWidth: 20, resizable: false },
    ];
    return _.compact(_.map(columnsViewOrder, (col) => _.find(columns, { key: col })));
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setRowsPerPage(rowsPerPage);
    setPage(1);
  };

  const handleStartDateChange = (str: string | undefined) => {
    setStartDate(str);
    if (startDate && str && dateFns.isAfter(new Date(str), new Date(startDate))) {
      setStartDate(str);
    }
  };

  const handleEndDateChange = (str: string | undefined) => {
    setEndDate(str);
    if (endDate && str && dateFns.isAfter(new Date(str), new Date(endDate))) {
      setEndDate(str);
    }
  };

  const handleSearchFilterOnClick = () => {
    const offset = (page - 1) * rowsPerPage;
    const data = {
      offset,
      limit: rowsPerPage,
      sortOrder,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      clinicId,
      requestor,
      requestorId,
      status,
    };

    dispatch(notificationAction.getNotificationListAsync(data));
  };

  const handleViewNotification = (notification: Notification) => {
    dispatch(notificationAction.setComponentStatus({ isNotificationModalOpen: true }));
    dispatch(notificationAction.setSelectedNotification(notification));
  };

  const getFormattedNotificationList = (notificationList: Notification[]) => {
    return notificationList?.map((n, index) => {
      const acceptRejectText =
        n.status === NotificationStatus.APPROVE
          ? {
              text: 'Approved',
              color: theme.palette.success.main,
            }
          : { text: 'Rejected', color: theme.palette.error.main };
      const status =
        n.status === NotificationStatus.PENDING
          ? {
              text: 'Pending',
              color: theme.palette.black.main,
            }
          : acceptRejectText;
      return {
        date: dateFns.format(new Date(n.updatedDate), 'MMM/dd HH:mm'),
        clinicName: (
          <Box display="flex" gap={1}>
            <AvatarEdit editable={false} avatar={n.ClinicImage} size="24px" font={theme.typography.subtitle1} />
            <Typography variant="bodyRegular">{n.clinicName}</Typography>
          </Box>
        ),
        requestor: n.createdUserRole,
        requestorName: (
          <Box display="flex" gap={1}>
            <AvatarEdit editable={false} avatar={n.createdUserAvatar} size="24px" font={theme.typography.subtitle1} />
            <Typography variant="bodyRegular">{n.createdUser}</Typography>
          </Box>
        ),
        request: `${n.createdUser} ${n.description}`,
        status: (
          <Typography variant="bodyBold" color={status.color}>
            {status.text}
          </Typography>
        ),
        action: (
          <Stack direction="row" justifyContent="center" spacing={1}>
            <IconButton
              id={`view-patient-btn-row-${index}`}
              size="small"
              onClick={() => {
                handleViewNotification(n);
              }}
            >
              <Box component="img" src={EyeBoldSVG}></Box>
            </IconButton>
          </Stack>
        ),
      };
    });
  };

  return (
    <Fragment>
      <CustomTable
        isLoading={isNotificationsListLoading}
        totalDataRows={totalNotificationCount}
        enablePagination={true}
        selectedPage={page}
        enablePagePerRows={true}
        rowsPerList={[10, 20, 50, 100]}
        selectedRowsPerPage={rowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        columns={getColumns()}
        rows={getFormattedNotificationList(notificationList)}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSortByChange={setSortBy}
        handleSortOrderChange={setSortOrder}
        bottomResultsCount={totalNotificationCount}
        minBodyRowSpace={10}
        customTableTop={
          <Grid marginY={1} paddingX={2} container rowSpacing={1.5} columnSpacing={1}>
            <Grid item xs={11}>
              <Grid container rowSpacing={1.5} columnSpacing={1}>
                <Grid item xxl={3} lg={4} xs={4}>
                  <Box display="flex" flexDirection="column" gap="2px">
                    <Autocomplete
                      data-testid="clinic-input"
                      id="clinic-input"
                      size="small"
                      getOptionLabel={(option) => `${option.clinicId} - ${option.name}`}
                      isOptionEqualToValue={(option, value) => option.clinicId === value.clinicId}
                      onChange={(event, value) => setClinicId(value?.clinicId)}
                      options={clinicsDropdownList}
                      sx={{ '& .MuiInputBase-root': { alignContent: 'center', height: '40px', borderRadius: '10px' } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Clinic"
                          variant="outlined"
                          inputProps={{ ...params.inputProps }}
                        />
                      )}
                      renderOption={(props, option, state, ownerState) => {
                        return (
                          <Box component="li" display="flex" columnGap={1} {...props}>
                            <AvatarEdit
                              editable={false}
                              size="24px"
                              avatar={option.avatar}
                              name={option.name}
                              font={theme.typography.subtitle1}
                            />
                            <Typography variant="bodyRegular" component="p">
                              {`${option.clinicId} - ${option.name}`}
                            </Typography>
                          </Box>
                        );
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xxl={1.5} lg={2} xs={4}>
                  <Box display="flex" flexDirection="column" gap="2px">
                    <Autocomplete
                      data-testid="requestor-input"
                      id="requestor-input"
                      size="small"
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.role === value.role}
                      onChange={(event, value) => setRequestor(value?.role)}
                      options={requestorDropdownList}
                      sx={{ '& .MuiInputBase-root': { alignContent: 'center', height: '40px', borderRadius: '10px' } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Requestor"
                          variant="outlined"
                          inputProps={{ ...params.inputProps }}
                        />
                      )}
                      renderOption={(props, option, state, ownerState) => {
                        return (
                          <Box component="li" display="flex" columnGap={1} {...props}>
                            <Typography variant="bodyRegular" component="p">
                              {option.name}
                            </Typography>
                          </Box>
                        );
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xxl={3} lg={4} xs={4}>
                  <Box display="flex" flexDirection="column" gap="2px">
                    <Autocomplete
                      id="requestor-name-input"
                      size="small"
                      getOptionLabel={(option) => `${option.clinicBaseId} - ${option.name}`}
                      isOptionEqualToValue={(option, value) => option.userId === value.userId}
                      onChange={(event, value) => setRequestorId(value?.userId)}
                      options={requestorNamesDropdownList.filter((user) => user.clinicId === clinicId)}
                      sx={{ '& .MuiInputBase-root': { alignContent: 'center', height: '40px', borderRadius: '10px' } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Requestor Name"
                          variant="outlined"
                          inputProps={{ ...params.inputProps }}
                        />
                      )}
                      renderOption={(props, option, state, ownerState) => {
                        return (
                          <Box component="li" display="flex" columnGap={1} {...props}>
                            <AvatarEdit
                              editable={false}
                              size="24px"
                              avatar={option.avatar}
                              name={option.name}
                              font={theme.typography.subtitle1}
                            />
                            <Typography variant="bodyRegular" component="p">
                              {`${option.clinicBaseId} - ${option.name}`}
                            </Typography>
                          </Box>
                        );
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xxl={1.5} lg={2} xs={4}>
                  <Box display="flex" flexDirection="column" gap="2px">
                    <Autocomplete
                      id="status-input"
                      size="small"
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.status === value.status}
                      onChange={(event, value) => setStatus(value?.status)}
                      options={statusDropdownList}
                      sx={{ '& .MuiInputBase-root': { alignContent: 'center', height: '40px', borderRadius: '10px' } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Status"
                          variant="outlined"
                          inputProps={{ ...params.inputProps }}
                        />
                      )}
                      renderOption={(props, option, state, ownerState) => {
                        return (
                          <Box component="li" display="flex" columnGap={1} {...props}>
                            <Typography variant="bodyRegular" component="p">
                              {option.name}
                            </Typography>
                          </Box>
                        );
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xxl={3} lg={4} xs={6}>
                  <Box
                    display="flex"
                    columnGap={0.5}
                    sx={{
                      height: '40px',
                      border: 'solid 1px',
                      borderColor: theme.palette.shades.blackBorder,
                      paddingX: '4px',
                      alignItems: 'end',
                      paddingBottom: '2px',
                      borderRadius: '10px',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label={
                          <Typography
                            sx={{
                              maxWidth: 'calc(8.5vw - 55px)',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            Start Date
                          </Typography>
                        }
                        format="MM-dd-yyyy"
                        value={startDate ?? null}
                        sx={{
                          '& .MuiFormLabel-root': { ...theme.typography.meta, top: -10 },
                          '& .MuiInputLabel-shrink': { top: 0 },
                          '& .MuiInputBase-root': { height: '29px', borderRadius: '8px' },
                        }}
                        onChange={(newValue) => {
                          handleStartDateChange(newValue ?? undefined);
                        }}
                        // @ts-ignore
                        maxDate={new Date()}
                        slotProps={{
                          actionBar: {
                            actions: ['clear'],
                          },
                          textField: {
                            onKeyDown: (e) => {
                              e.preventDefault();
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                    <Typography gutterBottom>-</Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label={
                          <Typography
                            sx={{
                              maxWidth: 'calc(8.5vw - 55px)',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            End Date
                          </Typography>
                        }
                        format="MM-dd-yyyy"
                        value={endDate ?? null}
                        sx={{
                          '& .MuiFormLabel-root': { ...theme.typography.meta, top: -10 },
                          '& .MuiInputLabel-shrink': { top: 0 },
                          '& .MuiInputBase-root': { height: '29px', borderRadius: '8px' },
                        }}
                        onChange={(newValue) => {
                          handleEndDateChange(newValue ?? undefined);
                        }}
                        // @ts-ignore
                        maxDate={new Date()}
                        slotProps={{
                          actionBar: {
                            actions: ['clear'],
                          },
                          textField: {
                            onKeyDown: (e) => {
                              e.preventDefault();
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={1}>
              <Grid item xs={12}>
                <Button
                  id="filter-btn"
                  fullWidth
                  variant="contained"
                  sx={{ borderRadius: '10px', height: '38px' }}
                  onClick={handleSearchFilterOnClick}
                >
                  Filter
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }
      />

      <NotificationView open={isNotificationModalOpen} />
    </Fragment>
  );
}

export default Requests;
