import { Box, CircularProgress, Typography } from '@mui/material';
import { Theme, styled as materialStyled } from '@mui/material/styles';

interface CustomLoaderProps {
  progress: number;
}

const TypographyStyled = materialStyled(Typography)(({ theme }: { theme: Theme }) => ({
  fontSize: '10px',
  fontWeight: 400,
  fontFamily: 'Satoshi',
}));

function CustomLoader({ progress }: CustomLoaderProps) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" value={progress} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <TypographyStyled color="text.secondary">{`${Math.round(progress)}%`}</TypographyStyled>
      </Box>
    </Box>
  );
}

export default CustomLoader;
