import React, { Fragment } from 'react';
import { Button, Card, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import { GoalsType, patientActions } from '../../../../../redux/patient/patientSlice';
import LoadingTypography from '../../../../../components/LoadingTypography/LoadingTypography';
import CositHistoryModal from './CositHistoryModal';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { RootState } from '../../../../../redux/store';
import _ from 'lodash';

interface CositProps {
  order: GoalsType[];
  isLoading: boolean;
  patientUserId: string;
}

function CositDetails({ order, isLoading, patientUserId }: CositProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const componentStatus = useAppSelector((state: RootState) => state.patient.componentStatus);
  const isCositHistoryModalOpen = _.get(componentStatus, 'isCositHistoryModalOpen', false) as boolean;

  const handleHistoryOpen = () => {
    dispatch(patientActions.setComponentStatus({ isCositHistoryModalOpen: true }));
  };
  return (
    <Fragment>
      <Card
        sx={{
          borderRadius: '10px',
          padding: '24px',
          minHeight: '209px',
          display: 'flex',
          alignItems: 'start',
          position: 'relative',
        }}
      >
        <Grid container>
          <Grid item width="100%">
            <Grid container alignItems={'center'}>
              <Grid item xs={6}>
                <Typography variant="h5" mb={1}>
                  COSIT
                </Typography>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button id="cosit-history-btn" variant="text" onClick={handleHistoryOpen}>
                  View History
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item mt={2} width="100%" pt={2} sx={{ borderTop: '0.5px solid #3800AE4D', width: '100%' }}>
            <Grid container spacing={2} flexDirection="column">
              {isLoading && (
                <Fragment>
                  <Grid item display="flex" flexDirection="row">
                    <Skeleton animation="wave" height={20} width="100%" variant="rounded" />
                  </Grid>
                  <Grid item display="flex" flexDirection="row">
                    <Skeleton animation="wave" height={20} width="100%" variant="rounded" />
                  </Grid>
                  <Grid item display="flex" flexDirection="row">
                    <Skeleton animation="wave" height={20} width="100%" variant="rounded" />
                  </Grid>
                  <Grid item display="flex" flexDirection="row">
                    <Skeleton animation="wave" height={20} width="100%" variant="rounded" />
                  </Grid>
                  <Grid item display="flex" flexDirection="row">
                    <Skeleton animation="wave" height={20} width="100%" variant="rounded" />
                  </Grid>
                </Fragment>
              )}
              {!isLoading &&
                order.map((value, index) => (
                  <Grid item display="flex" flexDirection="row" key={value.id}>
                    <LoadingTypography
                      isLoading={isLoading}
                      color={theme.palette.primary.main}
                      sx={{ fontWeight: 500, width: 20 }}
                    >
                      {index + 1}.
                    </LoadingTypography>
                    <LoadingTypography isLoading={isLoading} sx={{ minWidth: '100px' }}>
                      {value.name}
                    </LoadingTypography>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <CositHistoryModal isOpen={isCositHistoryModalOpen} patientUserId={patientUserId} />
    </Fragment>
  );
}

export default CositDetails;
