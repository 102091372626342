import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Constant from '../../constants/constant.json';
import { SortOrder } from '../../utils/enums/sort-order.enum';

export interface AdminPermissions {
  permissionId: number;
  role: string;
  permission: string;
  description: string;
}

export interface ClinicProps {
  clinicId: string;
  name: string;
  email: string;
  phone: string;
  avatar: string;
  addressLine1: string;
  addressLine2: string;
  status: string;
}

export interface ClinicInfo {
  clinicId: string;
  name: string;
  email: string;
  phone: string;
  addressLine1: string;
  addressLine2: string;
  address: string;
  avatar: string;
  status: string;
  domain: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  underTreatmentPatientCount: number;
  treatmentCompletedPatientsCount: number;
  returningPatientsCount: number;
  clinicianCount: number;
}

export interface ClinicIntermediateInfo {
  field: string;
  fromValue: string;
  toValue: string;
}

export interface ClinicAdminProps {
  clinicId?: string;
  userId: string;
  globalId?: string;
  clinicBaseId?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  avatar: string;
  permissions: {
    permissionId: number;
    permission: string;
    role: string;
    description: string;
  }[];
}

export interface AddClinicProps {
  name?: string;
  email?: string;
  domain?: string;
  phone?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  avatar?: { url: string; file: File | undefined };
  clinicAdmin?: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    avatar: string;
  };
}

export interface AdminType {
  userId: string;
  clinicBaseId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  avatar: string;
  permissions: {
    permissionId: number;
    permission: string;
    role: string;
    description: string;
  }[];
}

export interface IClinicSlice {
  totalClinicCount: number;
  totalClinicAdminCount: number;
  clinicList: ClinicProps[];
  clinicAdminList: ClinicAdminProps[];
  selectedClinicAdmin?: ClinicAdminProps;
  clinicInfo?: ClinicInfo;
  addClinicData?: AddClinicProps;
  clinicIntermediateInfo?: ClinicIntermediateInfo[];
  permissions: AdminPermissions[];
  isAdminPopupOpen: boolean;
  isEditAdminPopupOpen: boolean;
  isDeleteAdminPopupOpen: boolean;
  isAdminAlreadyExist: boolean | null;
  adminData: ClinicAdminProps;
  componentStatus: { [name: string]: undefined | string | boolean };
  clinicDropdownList: { clinicId: string; name: string; avatar: string }[];
}

const adminData = {
  userId: '',
  clinicBaseId: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  avatar: '',
  permissions: [],
};

const initialState: IClinicSlice = {
  totalClinicCount: 0,
  clinicList: [],
  selectedClinicAdmin: undefined,
  totalClinicAdminCount: 0,
  clinicAdminList: [],
  clinicInfo: undefined,
  addClinicData: undefined,
  clinicIntermediateInfo: undefined,
  isAdminPopupOpen: false,
  isEditAdminPopupOpen: false,
  isDeleteAdminPopupOpen: false,
  permissions: [],
  isAdminAlreadyExist: null,
  adminData,
  componentStatus: {},
  clinicDropdownList: [],
};

const clinicSlice = createSlice({
  name: 'clinic',
  initialState,
  reducers: {
    setComponentStatus(state, action: PayloadAction<{ [key: string]: undefined | string | boolean }>) {
      state.componentStatus = { ...state.componentStatus, ...action.payload };
    },
    setAddAdminPopupClose(state, action: PayloadAction<boolean>) {
      state.isAdminPopupOpen = action.payload;
      state.selectedClinicAdmin = undefined;
      state.adminData = adminData;
      state.componentStatus = { ...state.componentStatus, selectedTab: Constant.AdminTabs.GENERAL_INFO.slug };
    },
    setEditAdminPopupClose(state, action: PayloadAction<boolean>) {
      state.isEditAdminPopupOpen = action.payload;
      state.selectedClinicAdmin = undefined;
      state.adminData = adminData;
      state.componentStatus = { ...state.componentStatus, selectedTab: Constant.AdminTabs.CONFIG_PERMISSION.slug };
    },
    setEditAdminPopupCloseInFailed(state) {
      state.isEditAdminPopupOpen = false;
      state.selectedClinicAdmin = undefined;
      state.adminData = adminData;
      state.componentStatus = {};
    },
    setDeleteAdminPopupClose(state, action: PayloadAction<boolean>) {
      state.isDeleteAdminPopupOpen = action.payload;
      state.selectedClinicAdmin = undefined;
      state.adminData = adminData;
    },
    setIsEmailAlreadyEsist(state, action: PayloadAction<boolean>) {
      state.isAdminAlreadyExist = action.payload;
    },
    setAdminPermissions(state, action: PayloadAction<Partial<any>>) {
      state.permissions = action.payload.data;
    },
    setSelectedAdmin(state, action: PayloadAction<ClinicAdminProps | undefined>) {
      state.selectedClinicAdmin = action.payload;
    },
    setAdminData(state, action: PayloadAction<ClinicAdminProps>) {
      state.adminData = { ...state.adminData, ...action.payload };
    },
    setCreateSuccess(state) {
      state.isAdminPopupOpen = false;
      state.isEditAdminPopupOpen = false;
      state.isDeleteAdminPopupOpen = false;
      state.componentStatus = { ...state.componentStatus, isSubmitting: false };
    },
    setCloseSuccessModel(state, action: PayloadAction<any>) {
      state.componentStatus = {
        ...state.componentStatus,
        editSuccessModalOpen: false,
        addSuccessModalOpen: false,
        isRefresh: true,
      };
      state.adminData = adminData;
      state.selectedClinicAdmin = undefined;
    },
    setTotalClinicCount(state, action: PayloadAction<number>) {
      state.totalClinicCount = action.payload;
    },
    setClinicList(state, action: PayloadAction<ClinicProps[]>) {
      state.clinicList = action.payload;
    },
    setClinicInfo(state, action: PayloadAction<ClinicInfo | undefined>) {
      state.clinicInfo = action.payload;
    },
    setClinicIntermediateInfo(state, action: PayloadAction<ClinicIntermediateInfo[] | undefined>) {
      state.clinicIntermediateInfo = action.payload;
    },
    setSelectedClinicAdmin(state, action: PayloadAction<AdminType | undefined>) {
      state.selectedClinicAdmin = action.payload;
    },
    getClinicAdminByUserIdAsync: (state, action: PayloadAction<string>) => {},
    setTotalClinicAdminCount(state, action: PayloadAction<number>) {
      state.totalClinicAdminCount = action.payload;
    },
    setClinicAdminList(state, action: PayloadAction<ClinicAdminProps[]>) {
      state.clinicAdminList = action.payload;
    },
    setAddClinicData(state, action: PayloadAction<Partial<AddClinicProps>>) {
      state.addClinicData = { ...state.addClinicData, ...action.payload };
    },
    clearAddClinicData(state) {
      state.addClinicData = undefined;
    },
    setClinicDropdownList(state, action: PayloadAction<{ clinicId: string; name: string; avatar: string }[]>) {
      state.clinicDropdownList = action.payload;
    },
    getClinicListAsync: (
      state,
      action: PayloadAction<{
        offset: number;
        limit: number;
        sortBy: string;
        sortOrder: SortOrder;
        searchText: string;
      }>,
    ) => {},
    deactivateClinicAsync: (state, action: PayloadAction<{ clinicId: string; password: string }>) => {},
    reactivateClinicAsync: (state, action: PayloadAction<{ clinicId: string; password: string }>) => {},
    getClinicInfoAsync: (state, action: PayloadAction<string>) => {},
    handleNextToAddClinicAdminTab: (state, action: PayloadAction<AddClinicProps>) => {},
    handleNextToConfirmAddClinicTab: (state, action: PayloadAction<Partial<AddClinicProps>>) => {},
    getClinicAdminListAsync: (
      state,
      action: PayloadAction<{
        offset: number;
        limit: number;
        sortBy: string;
        sortOrder: SortOrder;
        clinicId: string;
        searchText?: string;
        type?: string;
      }>,
    ) => {},
    createClinicAsync: (state, action: PayloadAction<AddClinicProps>) => {},
    createClinicAdminAsync: (state, action: PayloadAction<Partial<ClinicAdminProps>>) => {},
    updateClinicAdminAsync: (state, action: PayloadAction<Partial<ClinicAdminProps>>) => {},
    nextToAddClinicAdminPermissionTab: (state, action: PayloadAction<any>) => {},
    getClinicIntermediateInfoAsync: (state, action: PayloadAction<{ clinicId: string }>) => {},
    updateClinicAsync: (
      state,
      action: PayloadAction<{ clinicId: string; clinicInfo: Partial<ClinicInfo>; avatarFile?: File }>,
    ) => {},
    approveClinicAttributeAsync: (state, action: PayloadAction<{ notificationId: string }>) => {},
    rejectClinicAttributeAsync: (state, action: PayloadAction<{ notificationId: string }>) => {},
    deleteCliniAdminAsync: (state, action: PayloadAction<{ userId: string; password: string }>) => {},
    getAllClinicNamesAsync: (state, action: PayloadAction<{ status: string | undefined }>) => {},
  },
});

export const { actions: clinicActions, reducer: clinicReducer } = clinicSlice;
