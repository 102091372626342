import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import routes from './router/routes';
import { useAuth } from './context/AuthContext';
import { theme } from './theme/theme';
import { ThemeProvider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppSelector } from './redux/hooks';
import { RootState } from './redux/store';
import BlankLayout from './components/Layouts/BlankLayout';

function App(): React.JSX.Element {
  const { isLogin, user } = useAuth();
  const role = isLogin ? user.role : '';

  const router = createBrowserRouter(routes(isLogin, role, user));

  const { isPageLoading } = useAppSelector((state: RootState) => state.auth.componentStatus);
  return (
    <ThemeProvider theme={theme}>
      {isPageLoading ? (
        <BlankLayout>
          <CircularProgress size={80} />
        </BlankLayout>
      ) : (
        <RouterProvider router={router} />
      )}
    </ThemeProvider>
  );
}

export default App;
