import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { v4 as uuidv4 } from 'uuid';

Chart.register(...registerables);

interface ConfirmEqChartProps {
  label: string;
  data: number[];
  lineColorPrimary: string;
  lineColorSecondary: string;
  backgroundColor: string;
  minValue: number;
  maxValue: number;
  step: number;
}

function ConfirmEqChart(props: ConfirmEqChartProps) {
  const theme = useTheme();

  return (
    <Box position="relative" marginBottom={3}>
      <Typography marginBottom={2} component="p" variant="bodyRegular">
        {props.label}
      </Typography>
      <Line
        height="200px"
        data={{
          labels: ['0.5', '1', '2', '4', '8', '>16'],
          datasets: [
            {
              label: '',
              data: props.data,
              pointStyle: 'circle',
              pointRadius: 6,
              borderColor: props.lineColorSecondary,
              backgroundColor: props.backgroundColor,
              pointBorderColor: props.lineColorSecondary,
              pointHoverRadius: 7,
              pointBorderWidth: 2,
              borderWidth: 2,
              tension: 0,
              fill: false,
            },
          ],
        }}
        options={{
          elements: {
            line: {
              backgroundColor: theme.palette.shades.purpleBorder,
              borderColor: theme.palette.shades.purpleBorder,
            },
          },
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            y: {
              border: {
                display: false,
              },
              grid: {
                display: true,
                color: theme.palette.secondary.main,
                lineWidth: 2,
              },
              min: props.minValue,
              max: props.maxValue,
              ticks: {
                display: true,
                stepSize: props.step,
                color: theme.palette.shades.blackBorder,
                font: {
                  family: theme.typography.meta.fontFamily,
                  size: 14,
                  weight: 'normal',
                },
              },
            },
            x: {
              display: true,
              grid: {
                display: false,
              },
              ticks: {
                color: theme.palette.shades.blackBorder,
                font: {
                  family: theme.typography.meta.fontFamily,
                  size: 14,
                  weight: 'normal',
                },
              },
            },
          },
        }}
      />
      <Box
        width="100%"
        position="absolute"
        display="flex"
        justifyContent="space-between"
        paddingLeft={4}
        paddingRight={1}
        columnGap={1}
      >
        {props.data.map((value, index) => {
          return (
            <Typography key={uuidv4()} component="p" variant="meta">
              {value}
            </Typography>
          );
        })}
      </Box>
      <Typography
        position="absolute"
        right={-20}
        bottom={5}
        component="p"
        variant="meta"
        color={theme.palette.shades.blackBorder}
        sx={{ transform: 'rotate(-90deg)' }}
      >
        kHz
      </Typography>
    </Box>
  );
}

export default ConfirmEqChart;
