import React, { useState } from 'react';
import { Box, Card, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { Theme, styled as materialStyled } from '@mui/material/styles';
import CloseCircleBoldSVG from '../../assets/img/close-circle-bold.svg';
import BarChart, { ChartData } from './BarChart';
import CustomModal from '../CustomModal/CustomModal';

const TypographyStyled = materialStyled(Typography)(({ theme }: { theme: Theme }) => ({
  ...theme.typography.bodyRegular,
  color: theme.palette.info.main,
}));

const GridStyled = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  width: '100%',
  overflow: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: `${theme.palette.info.main} ${theme.palette.shades.placeholderText}`,
}));

const getLablesForBarChart = (dataLength: number) => {
  const labels = [];
  for (let i = 1; i <= dataLength; i++) {
    labels.push(i.toString());
  }
  return labels;
};

interface DailyFeedbackProps {
  title: string;
  question: string;
  data: ChartData[];
  answers: string[];
  barThickness: number;
}

function DailyFeedback({ title, question, data, answers, barThickness }: DailyFeedbackProps) {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const lables = getLablesForBarChart(data[0].data.length < 90 ? 90 : data[0].data.length);
  const modalLables = getLablesForBarChart(data[0].data.length < 30 ? 30 : data[0].data.length);

  const handleViewFullGraph = () => {
    setOpen(true);
  };

  const handleDismiss = () => {
    setOpen(false);
  };

  const answerGrid = (
    <Grid container pt={2} pb={2} gap={4}>
      {answers.map((value, index) => (
        <Grid item key={value}>
          <Typography variant="bodyRegular">
            {`${index + 1} - `}
            <TypographyStyled variant="bodyRegular">{value}</TypographyStyled>
          </Typography>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <>
      <Card sx={{ borderRadius: '10px', padding: '24px', minHeight: '209px' }}>
        <Grid container>
          <Grid mb={3} item width="100%">
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end">
                <Typography
                  variant="button"
                  color={theme.palette.primary.main}
                  onClick={handleViewFullGraph}
                  sx={{ cursor: 'pointer' }}
                >
                  View Full Graph
                </Typography>
              </Grid>
            </Grid>
            <Grid mt={2} item width="100%">
              <TypographyStyled variant="bodyRegular">{question}</TypographyStyled>
            </Grid>
          </Grid>
        </Grid>

        <GridStyled pt={2} sx={{ marginBottom: 2, borderTop: `0.5px solid ${theme.palette.shades.purpleBorder}` }}>
          <BarChart
            data={data}
            stepSizeX={5}
            height="244px"
            barThickness={barThickness}
            responsive={data[0].data.length < 90}
            stacked={false}
            labels={lables}
            maxY={5}
            skipLabelX={true}
            stepSizeY={1}
            xGridOffSet={false}
          />
        </GridStyled>
        {answerGrid}
      </Card>

      <CustomModal modal={true} open={open} align="left" width="90vw" padding={3}>
        <Box display="flex" justifyContent="space-between" pl={2}>
          <Typography variant="h4" textAlign="left">
            {title}
          </Typography>
          <IconButton size="small" aria-label="close" onClick={handleDismiss}>
            <Box component="img" src={CloseCircleBoldSVG} />
          </IconButton>
        </Box>

        <TypographyStyled variant="bodyRegular" pl={2}>
          {question}
        </TypographyStyled>

        <GridStyled mt={2}>
          <BarChart
            data={data}
            stepSizeX={1}
            height={'400px'}
            barThickness={(window.innerWidth * 0.9 - 48) / 30 - 2}
            responsive={data[0].data.length < 30}
            stacked={false}
            labels={modalLables}
            maxY={5}
            skipLabelX={false}
            stepSizeY={1}
            defaultbarCount={30}
            xAxisTitle={'Days'}
          />
          {answerGrid}
        </GridStyled>
      </CustomModal>
    </>
  );
}

export default DailyFeedback;
