import React from 'react';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { CategoryScale, Colors, Legend, LinearScale, LineElement, PointElement, Tooltip } from 'chart.js';
import { Box, Typography, useTheme } from '@mui/material';

Chart.register(LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend, Colors);

export interface MiniEqChartProps {
  label: string;
  data: number[];
  lineColorPrimary: string;
  lineColorSecondary: string;
  backgroundColor: string;
}

function MiniEqChart(props: MiniEqChartProps) {
  const theme = useTheme();

  return (
    <Box>
      <Typography gutterBottom component="p" variant="bodyRegular">
        {props.label}
      </Typography>
      <Line
        data={{
          labels: ['0.5', '1', '2', '4', '8', '>16'],
          datasets: [
            {
              label: '',
              data: props.data,
              pointStyle: 'circle',
              pointRadius: 6,
              borderColor: props.lineColorSecondary,
              backgroundColor: props.backgroundColor,
              pointBorderColor: props.lineColorSecondary,
              pointHoverRadius: 7,
              pointBorderWidth: 2,
              borderWidth: 2,
              tension: 0,
              fill: false,
            },
          ],
        }}
        options={{
          elements: {
            line: {
              backgroundColor: theme.palette.shades.purpleBorder,
              borderColor: theme.palette.shades.purpleBorder,
            },
          },
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            y: {
              border: {
                display: false,
              },
              grid: {
                display: true,
                color: theme.palette.secondary.main,
                lineWidth: 2,
              },
              min: 0,
              max: 120,
              ticks: {
                display: false,
                stepSize: 20,
              },
            },
            x: {
              display: false,
            },
          },
        }}
      />
    </Box>
  );
}

export default MiniEqChart;
