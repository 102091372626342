// Function to get browser and device OS information
export function getBrowserAndOSInfo() {
  const userAgent = navigator.userAgent;

  let device = 'pc';
  let browser = null;
  let browserVersion = null;
  let deviceOS = '';
  let deviceOSVersion = null;

  if (userAgent.indexOf('Win') !== -1) deviceOS = 'Windows';
  if (userAgent.indexOf('Mac') !== -1) deviceOS = 'MacOS';
  if (userAgent.indexOf('X11') !== -1) deviceOS = 'UNIX';
  if (userAgent.indexOf('Linux') !== -1) deviceOS = 'Linux';

  // Function to extract version from user agent string
  function extractVersion(regex: RegExp) {
    const match = regex.exec(userAgent);
    return match ? match[1] : 'Unknown';
  }

  // Detect browser
  if (userAgent.indexOf('Firefox') !== -1) {
    browser = 'Firefox';
    browserVersion = extractVersion(/Firefox\/(\d+\.\d+)/);
  } else if (userAgent.indexOf('Chrome') !== -1) {
    browser = 'Chrome';
    browserVersion = extractVersion(/Chrome\/(\d+\.\d+)/);
  } else if (userAgent.indexOf('Safari') !== -1) {
    browser = 'Safari';
    browserVersion = extractVersion(/Version\/(\d+\.\d+)/);
  } else if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident') !== -1) {
    browser = 'Internet Explorer';
    browserVersion = extractVersion(/(?:MSIE |rv:)(\d+\.\d+)/);
  } else if (userAgent.indexOf('Edge') !== -1) {
    browser = 'Edge';
    browserVersion = extractVersion(/Edge\/(\d+\.\d+)/);
  } else if (userAgent.indexOf('Opera') !== -1 || userAgent.indexOf('OPR') !== -1) {
    browser = 'Opera';
    browserVersion = extractVersion(/(?:Opera\/|OPR\/)(\d+\.\d+)/);
  }

  return { device, deviceOS, deviceOSVersion, browser, browserVersion };
}
