import { IAdminSlice, adminReducer } from './admin/adminSlice';
import { IAuthSlice, authReducer } from './auth/authSlice';
import { IUserSlice, userReducer } from './user/userSlice';
import { IClinicSlice, clinicReducer } from './clinic/clinicSlice';
import { AnyAction, CombinedState, combineReducers } from '@reduxjs/toolkit';
import { IGeneralSlice, generalReducer } from './general/generalSlice';
import { IClinicianSlice, clinicianReducer } from './clinician/clinicianSlice';
import { INotificationSlice, notificationReducer } from './notification/notificationSlice';
import { IPatientSlice, patientReducer } from './patient/patientSlice';
import { ISoundSlice, soundReducer } from './sound/soundSlice';
import { PersistPartial } from 'redux-persist/es/persistReducer';

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  admin: adminReducer,
  clinic: clinicReducer,
  clinician: clinicianReducer,
  general: generalReducer,
  notification: notificationReducer,
  patient: patientReducer,
  sound: soundReducer,
});

const rootReducer = (
  state:
    | CombinedState<{
        auth: IAuthSlice & PersistPartial;
        user: IUserSlice;
        admin: IAdminSlice;
        clinic: IClinicSlice;
        clinician: IClinicianSlice;
        general: IGeneralSlice;
        notification: INotificationSlice;
        patient: IPatientSlice;
        sound: ISoundSlice;
      }>
    | undefined,
  action: AnyAction,
) => {
  if (action.type === 'CLEAR_STORE') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
