import React, { Fragment } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { styled as materialStyled, Theme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import clinicTransferIcon from '../../../assets/img/square-sort-horizontal-bold-grey.svg';
import reactStringReplace from 'react-string-replace';
import { useDispatch } from 'react-redux';
import { notificationAction } from '../../../redux/notification/notificationSlice';
import { NotificationStatus } from '../../../utils/enums/notification-status.enum';
import { patientActions } from '../../../redux/patient/patientSlice';

interface ClinicApproveRejectProps {
  notificationId: string;
  subject: string;
  description: string;
  actionDisable: boolean;
  approveBtnLoading: boolean;
  rejectBtnLoading: boolean;
  notificationType: string;
}

const CustomButton = materialStyled(LoadingButton)(({ theme }: { theme: Theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(1, 2),
  marginTop: theme.spacing(1.5),
  marginRight: theme.spacing(1.5),
  borderRadius: theme.spacing(1.5),
  fontWeight: 'normal',
  [theme.breakpoints.up('sm')]: {
    width: '195px',
  },
}));

function C2CTransferApproveReject(props: ClinicApproveRejectProps) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleClick = (notificationId: string, isApprove: boolean) => {
    dispatch(patientActions.C2CTransferPendingDetailsAsync({ notificationId }));
    dispatch(notificationAction.setComponentStatus({ notificationId }));
    dispatch(notificationAction.setNotificationView(false));
    dispatch(
      notificationAction.setComponentStatus({
        notificationStatusC2C: isApprove ? NotificationStatus.APPROVE : NotificationStatus.REJECT,
      }),
    );
    dispatch(notificationAction.setComponentStatus({ isC2CModalOpen: true }));
  };

  return (
    <Fragment>
      <Grid item xs={2} sm={1}>
        <Box component="img" src={clinicTransferIcon} sx={{ width: '28px', height: '28px' }} />
      </Grid>
      <Grid item mb={3} xs={10} sm={11}>
        <Typography variant="h6" fontSize="20px" mb={2} sx={{ whiteSpace: 'normal' }}>
          {props.subject}
        </Typography>
        <Typography variant="bodyRegular" component="p" sx={{ whiteSpace: 'normal', color: theme.palette.info.main }}>
          {reactStringReplace(props.description, /<b>(.*?)<\/b>/g, (match, i) => (
            <strong key={`strong-${i}`}>{match}</strong>
          ))}
        </Typography>

        <Grid>
          <CustomButton
            disabled={props.actionDisable || props.approveBtnLoading}
            loading={props.rejectBtnLoading}
            color="error"
            variant="outlined"
            onClick={() => {
              handleClick(props.notificationId, false);
            }}
          >
            Reject
          </CustomButton>
          <CustomButton
            disabled={props.actionDisable || props.rejectBtnLoading}
            loading={props.approveBtnLoading}
            variant="outlined"
            onClick={() => {
              handleClick(props.notificationId, true);
            }}
          >
            Approve
          </CustomButton>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default C2CTransferApproveReject;
