import React from 'react';
import CustomModal from '../../../components/CustomModal/CustomModal';
import GreenCheckCircleSVG from '../../../assets/img/check-circle-bold-duotone.svg';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';
import { generalActions } from '../../../redux/general/generalSlice';
import { clinicActions } from '../../../redux/clinic/clinicSlice';

function SuccessModal() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const addClinicData = useAppSelector((state: RootState) => state.clinic.addClinicData);

  const componentStatus = useAppSelector((state: RootState) => state.general.componentStatus);
  const isAddClinicSuccessModalOpen = _.get(componentStatus, 'isAddClinicSuccessModalOpen', false) as boolean;

  const handleDismiss = () => {
    dispatch(generalActions.clearComponentStatus());
    dispatch(clinicActions.clearAddClinicData());
    dispatch(generalActions.setComponentStatus({ shouldClinicListLoad: true }));
  };

  return (
    <CustomModal
      modal={true}
      open={isAddClinicSuccessModalOpen}
      title="Clinic Added"
      icon={GreenCheckCircleSVG}
      btnText="Dismiss"
      width="374px"
      handleClose={handleDismiss}
      handleButtonOnClick={handleDismiss}
    >
      <Stack direction="column" alignItems="center" justifyContent="center">
        <Box>
          <AvatarEdit
            editable={false}
            selectedAvatar={addClinicData?.avatar?.url}
            avatar=""
            name={`${addClinicData?.name}`}
            size="72px"
            font={theme.typography.h3}
          />
        </Box>

        <Typography mt={2} variant="h6" component="p" textAlign="center">
          {addClinicData?.name}
        </Typography>
        <Typography mt={1} variant="meta" component="p" textAlign="center" color={theme.palette.info.main}>
          Clinic Admin
        </Typography>

        <Box mt={1} display="flex" gap={1}>
          <AvatarEdit
            editable={false}
            avatar={addClinicData?.clinicAdmin?.avatar}
            name={addClinicData?.clinicAdmin?.firstName}
            name2={addClinicData?.clinicAdmin?.lastName}
            size="24px"
            font={theme.typography.subtitle1}
          />
          <Typography variant="bodyRegular" color={theme.palette.primary.main} component="p">
            {`${addClinicData?.clinicAdmin?.firstName} ${addClinicData?.clinicAdmin?.lastName}`}
          </Typography>
        </Box>
      </Stack>
    </CustomModal>
  );
}

export default SuccessModal;
