import { convertToLocalTime } from 'date-fns-timezone';
import * as dateFns from 'date-fns';

export function getGreetingMessage(time: Date): string {
  const hour = time.getHours();

  if (hour >= 4 && hour < 12) {
    return 'Good morning!';
  }
  if (hour >= 12 && hour < 17) {
    return 'Good afternoon!';
  }
  if (hour >= 17 && hour < 19) {
    return 'Good evening!';
  }
  return 'Good night!';
}

export function getAgeFromDateOfBirth(dateOfBirth: any): number {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
}

export const formatDate = (date: string | undefined | Date) => {
  if (!date) return undefined;

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const localDate = convertToLocalTime(date, { timeZone: timezone });
  return dateFns.format(localDate, 'yyyy-MM-dd');
};
