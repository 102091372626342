import { Roles } from './enums/roles.enum';
import { SidebarOption } from './enums/sidebar-option.enum';
import DashboardBold from '../assets/img/sidebar/dashboard-bold.svg';
import DashboardLinear from '../assets/img/sidebar/dashboard-linear.svg';
import ClinicsBold from '../assets/img/sidebar/clinics-bold.svg';
import ClinicsLinear from '../assets/img/sidebar/clinics-linear.svg';
import UsersGroupRoundedLinear from '../assets/img/sidebar/users-group-rounded-linear.svg';
import UsersGroupRoundedBold from '../assets/img/sidebar/users-group-rounded-bold.svg';
import ClinicPatientsLinear from '../assets/img/sidebar/patients-linear.svg';
import ClinicPatientsBold from '../assets/img/sidebar/patients-bold.svg';
import SettingsLinear from '../assets/img/sidebar/settings-linear.svg';
import SettingsBold from '../assets/img/sidebar/settings-bold.svg';
import PractitionersLinear from '../assets/img/sidebar/practitioners-linear.svg';
import PractitionersBold from '../assets/img/sidebar/practitioners-bold.svg';
import MusicLibraryLinear from '../assets/img/sidebar/music-library-linear.svg';
import MusicLibraryBold from '../assets/img/sidebar/music-library-bold.svg';

export const SIDEBAR_OPTIONS = {
  [SidebarOption.DASHBOARD]: {
    name: SidebarOption.DASHBOARD,
    path: '/dashboard',
    icon: { selected: DashboardBold, unselected: DashboardLinear },
    roles: [Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN],
  },
  [SidebarOption.MANAGE_ADMINS]: {
    name: SidebarOption.MANAGE_ADMINS,
    path: '/manage-admins',
    icon: { selected: SettingsBold, unselected: SettingsLinear },
    roles: [Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN, Roles.CLINIC_ADMIN],
  },
  [SidebarOption.CLINICS]: {
    name: SidebarOption.CLINICS,
    path: '/clinics',
    icon: { selected: ClinicsBold, unselected: ClinicsLinear },
    roles: [Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN],
  },
  [SidebarOption.CLINICIANS]: {
    name: SidebarOption.CLINICIANS,
    path: '/clinicians',
    icon: { selected: PractitionersBold, unselected: PractitionersLinear },
    roles: [Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN, Roles.CLINIC_ADMIN],
  },
  [SidebarOption.PATIENTS]: {
    name: SidebarOption.PATIENTS,
    path: '/patients',
    icon: { selected: UsersGroupRoundedBold, unselected: UsersGroupRoundedLinear },
    roles: [Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN, Roles.CLINIC_ADMIN],
  },
  [SidebarOption.MY_PATIENTS]: {
    name: SidebarOption.MY_PATIENTS,
    path: '/my-patients',
    icon: { selected: UsersGroupRoundedBold, unselected: UsersGroupRoundedLinear },
    roles: [Roles.CLINICIAN],
  },
  [SidebarOption.CLINIC_PATIENTS]: {
    name: SidebarOption.CLINIC_PATIENTS,
    path: '/patients?clinic_patients=1',
    icon: { selected: ClinicPatientsBold, unselected: ClinicPatientsLinear },
    roles: [Roles.CLINICIAN],
  },
  [SidebarOption.SOUND_LIBRARY]: {
    name: SidebarOption.SOUND_LIBRARY,
    path: '/sound-library',
    icon: { selected: MusicLibraryBold, unselected: MusicLibraryLinear },
    roles: [Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN, Roles.CLINICIAN],
  },
};
