import React from 'react';
import GreenCheckCircleSVG from '../../../assets/img/check-circle-bold-duotone.svg';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import _ from 'lodash';
import { generalActions } from '../../../redux/general/generalSlice';

function EditClinicSuccessModal() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const clinicInfo = useAppSelector((state: RootState) => state.clinic.clinicInfo);

  const componentStatus = useAppSelector((state: RootState) => state.general.componentStatus);
  const isEditClinicSuccessModalOpen = _.get(componentStatus, 'isEditClinicSuccessModalOpen', false) as boolean;
  const isClinicPropertyChanged = _.get(componentStatus, 'isClinicPropertyChanged', false) as boolean;

  const handleDismiss = () => {
    const shouldClinicDetailsLoad = componentStatus['shouldClinicDetailsLoad'];
    dispatch(generalActions.clearComponentStatus());
    dispatch(generalActions.setComponentStatus({ shouldClinicDetailsLoad: !shouldClinicDetailsLoad }));
  };

  return (
    <CustomModal
      modal={true}
      open={isEditClinicSuccessModalOpen}
      title={isClinicPropertyChanged ? 'Update Request Sent' : 'Update Successful'}
      icon={GreenCheckCircleSVG}
      btnText="Dismiss"
      width="374px"
      handleClose={handleDismiss}
      handleButtonOnClick={handleDismiss}
    >
      <Stack direction="column" alignItems="center" justifyContent="center">
        <Box>
          <AvatarEdit
            editable={false}
            avatar={clinicInfo?.avatar ?? ''}
            name={clinicInfo?.name ?? ''}
            size="72px"
            font={theme.typography.h3}
          />
        </Box>

        <Typography mt={2} variant="h6" component="p" textAlign="center">
          {clinicInfo?.name}
        </Typography>
        <Typography mt={1} variant="bodyRegular" component="p" textAlign="center" color={theme.palette.info.main}>
          {clinicInfo?.email}
        </Typography>
        <Typography mt={1} variant="bodyRegular" component="p" textAlign="center" color={theme.palette.info.main}>
          {clinicInfo?.phone}
        </Typography>
        <Typography
          mt={1}
          width="85%"
          variant="bodyRegular"
          component="p"
          textAlign="center"
          color={theme.palette.info.main}
        >
          {_.trim(`${clinicInfo?.addressLine1 ?? ''}, ${clinicInfo?.addressLine2 ?? ''}`, ',')}
        </Typography>
      </Stack>
    </CustomModal>
  );
}

export default EditClinicSuccessModal;
