import { TreatmentStatus } from './enums/treatment-status.enum';

const treatmentStausColor = {
  pending: 'rgba(0, 0, 0, 0.1)',
  completed: 'rgba(162, 203, 191, 1)',
  in_progress: 'rgba(222, 207, 68, 1)',
  un_used_day: 'rgba(247, 74, 74, 1)',
};

export function getTreatmentStatusColor(status: TreatmentStatus) {
  return treatmentStausColor[status];
}
