import React, { ReactNode } from 'react';
import { SxProps, Theme, TypographyPropsVariantOverrides, Skeleton, Typography } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';

interface LoadingTypographyProps {
  sx?: SxProps<Theme>;
  component?: React.ElementType;
  color?: string;
  variant?: OverridableStringUnion<'inherit' | Variant, TypographyPropsVariantOverrides>;
  isLoading?: boolean;
  children: ReactNode;
  align?: 'center' | 'left' | 'right';
}

function LoadingTypography(props: LoadingTypographyProps) {
  return (
    <Typography
      variant={props.variant ?? 'inherit'}
      color={props.color ?? 'inherit'}
      component={props.component ?? 'span'}
      sx={props.sx ?? {}}
      align={props.align ?? 'inherit'}
    >
      {props.isLoading ? <Skeleton animation="wave" /> : props.children}
    </Typography>
  );
}

export default LoadingTypography;
