import React, { useEffect, useMemo } from 'react';
import { Box, Card, Grid, Skeleton, Typography } from '@mui/material';
import { getTreatmentStatusColor } from '../../../../../utils/treatment-timeline-utils';
import { TreatmentStatus } from '../../../../../utils/enums/treatment-status.enum';
import Constant from '../../../../../constants/constant.json';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { RootState } from '../../../../../redux/store';
import _ from 'lodash';
import { patientActions } from '../../../../../redux/patient/patientSlice';
import { v4 as uuidv4 } from 'uuid';

const CELL_PER_ROW = 10;

interface Phase {
  title: string;
  values: { status: TreatmentStatus }[];
}

interface TreatmentTimelineProps {
  patientUserId: string;
}

function TreatmentTimeline({ patientUserId }: TreatmentTimelineProps) {
  const dispatch = useAppDispatch();

  const componentStatus = useAppSelector((state: RootState) => state.general.componentStatus);
  const isTreatmentTimelineLoading = _.get(componentStatus, 'isTreatmentTimelineLoading', false) as boolean;

  const treatmentTimelineDetails = useAppSelector((state: RootState) => state.patient.treatmentTimelineDetails);

  useEffect(() => {
    if (patientUserId) {
      dispatch(patientActions.getTreatmentTimelineDetailsAsync({ patientUserId }));
    }
  }, [patientUserId]);

  const timeline = [
    Constant.TreatmentTimeline.RELIEF,
    Constant.TreatmentTimeline.RELAX,
    Constant.TreatmentTimeline.RETRAIN,
  ];
  const phases = useMemo(() => {
    const phases: Phase[] = [];

    timeline.forEach((phase) => {
      const logsInPhase =
        phase.name === Constant.TreatmentTimeline.RETRAIN.name
          ? treatmentTimelineDetails.filter((e) => e.phase === phase.name || e.phase === 'Apply')
          : treatmentTimelineDetails.filter((e) => e.phase === phase.name);
      const phaseGrid: { status: TreatmentStatus }[] = [];
      Array.from(Array(phase.count + _.filter(logsInPhase, { isComplete: false }).length)).forEach((e, index) => {
        if (logsInPhase.length > index) {
          phaseGrid.push({
            status: logsInPhase[index].isUnusedDay
              ? TreatmentStatus.UN_USEDDAY
              : logsInPhase[index].isComplete
              ? TreatmentStatus.COMPLETED
              : TreatmentStatus.IN_PROGRESS,
          });
        } else {
          phaseGrid.push({ status: TreatmentStatus.PENDING });
        }
      });

      phases.push({ title: phase.title, values: phaseGrid });
    });
    return phases;
  }, [treatmentTimelineDetails]);

  return (
    <Card sx={{ borderRadius: '10px', padding: '24px' }}>
      <Grid container>
        <Typography variant="h5" mb={1}>
          Treatment Timeline
        </Typography>
        {phases.map((phase) => (
          <Grid
            item
            mt={2}
            width="100%"
            pt={2}
            sx={{ borderTop: '0.5px solid #3800AE4D', width: '100%' }}
            key={phase.title}
            xs={12}
          >
            <Grid container rowSpacing={1}>
              <Grid item xl={3.4} md={12}>
                <Typography color="#595959" variant="bodyRegular">
                  {phase.title}
                </Typography>
              </Grid>
              <Grid item xl={8.6} md={12}>
                {_.chunk(phase.values, CELL_PER_ROW).map((row, index) => (
                  <Box key={uuidv4()} display="flex" columnGap={1} marginBottom={1}>
                    {row.map((day, dayIndex) =>
                      isTreatmentTimelineLoading ? (
                        <Skeleton key={uuidv4()} variant="rounded" width={32.4} height={24} animation="wave" />
                      ) : (
                        <Box
                          role="day-cell"
                          key={uuidv4()}
                          minWidth="32.4px"
                          width="32.4px"
                          height="24px"
                          borderRadius="5px"
                          sx={{ backgroundColor: getTreatmentStatusColor(day.status) }}
                        />
                      ),
                    )}
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
}

export default TreatmentTimeline;
