import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme, Grid, IconButton, InputAdornment } from '@mui/material';
import CustomButtonLoadingButton from '../../../components/CustomButtonLoadingButton/CustomButtonLoadingButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { authActions } from '../../../redux/auth/authSlice';
import * as _ from 'lodash';
import { userActions } from '../../../redux/user/userSlice';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import GreenCheckCircleSVG from '../../../assets/img/check-circle-bold-duotone.svg';
import RedCloseCircleSVG from '../../../assets/img/close-circle-bold-duotone.svg';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import CustomModal from '../../../components/CustomModal/CustomModal';
import CustomInputBox from '../../../components/CustomInputBox/CustomInputBox';

export function SecuritySettings() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const isSubmitting = _.get(componentStatus, 'isSubmitting', false) as boolean;
  const error = _.get(componentStatus, 'error', '') as string;
  const isCurrentPasswordIncorrect = _.get(componentStatus, 'isCurrentPasswordIncorrect', false) as boolean;
  const successModalOpen = _.get(componentStatus, 'successModalOpen', false) as boolean;

  useEffect(() => {
    if (successModalOpen) {
      formik.resetForm();
    }
  }, [successModalOpen]);

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: yup.object({
      currentPassword: yup.string().required('Current Password is required'),
      newPassword: yup
        .string()
        .min(8, 'Weak Password!')
        .matches(/\d/, 'Weak Password!')
        .matches(/[a-z]/, 'Weak Password!')
        .matches(/[A-Z]/, 'Weak Password!')
        .matches(/[^\w]/, 'Weak Password!')
        .required('New password is required'),
      confirmNewPassword: yup
        .string()
        .oneOf([yup.ref('newPassword')], 'Please make sure your passwords match!')
        .required('Confirm new password is required'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({ currentPassword, newPassword }, { setSubmitting }) => {
      dispatch(
        authActions.setComponentStatus({
          isSubmitting: true,
          successModalOpen: false,
          isCurrentPasswordIncorrect: false,
          error: '',
        }),
      );
      dispatch(userActions.changePasswordAsync({ password: currentPassword, newPassword: newPassword }));
    },
  });

  const validatePasswordRules = (value: string, rule: string) => {
    const rules: { [rule: string]: boolean } = {
      is8CharLong: value.length >= 8,
      isIncludeNumber: /\d/.test(value),
      isIncludeLowerCase: /[a-z]/.test(value),
      isIncludeUpperCase: /[A-Z]/.test(value),
      isIncludeSpecialCharacter: /[^\w]/.test(value),
    };
    return rules[rule] ? GreenCheckCircleSVG : RedCloseCircleSVG;
  };

  const handlMessageModalClose = () => {
    dispatch(authActions.clearComponentStatus());
    setShowCurrentPassword(false);
    setShowNewPassword(false);
  };

  return (
    <Box>
      <Typography marginBottom={2} variant="h5">
        Security Settings
      </Typography>
      <Grid marginTop={1} container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12}>
          <CustomInputBox
            label="Current Password"
            required={true}
            name="currentPassword"
            type={showCurrentPassword ? 'text' : 'password'}
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.currentPassword && Boolean(formik.errors.currentPassword)) || isCurrentPasswordIncorrect
            }
            helperText={
              (formik.touched.currentPassword && formik.errors.currentPassword) ||
              (isCurrentPasswordIncorrect && 'Wrong Password')
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                  >
                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInputBox
            label="New Password"
            required={true}
            name="newPassword"
            type={showNewPassword ? 'text' : 'password'}
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInputBox
            label="Confirm New Password"
            required={true}
            name="confirmNewPassword"
            type={showNewPassword ? 'text' : 'password'}
            value={formik.values.confirmNewPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
            helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            display={
              formik.values.newPassword || !!formik.errors.newPassword || formik.errors.confirmNewPassword
                ? 'inherit'
                : 'none'
            }
            variant="bodyBold"
          >
            Password must contain the following
          </Typography>
        </Grid>

        <Box
          display={
            formik.values.newPassword || !!formik.errors.newPassword || formik.errors.confirmNewPassword
              ? 'inherit'
              : 'none'
          }
          marginX={1}
          marginTop={1}
        >
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={6} display="flex">
              <Box display="flex">
                <Box
                  component="img"
                  width="20px"
                  src={validatePasswordRules(formik.values.newPassword, 'isIncludeLowerCase')}
                  alt="check-icon"
                ></Box>
                <Typography marginLeft="10px" variant="bodyRegular" textAlign="left">
                  A lowercase letter
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex">
                <Box
                  component="img"
                  width="20px"
                  src={validatePasswordRules(formik.values.newPassword, 'isIncludeSpecialCharacter')}
                  alt="check-icon"
                ></Box>
                <Typography marginLeft="10px" variant="bodyRegular" textAlign="left">
                  A special character
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex">
                <Box
                  component="img"
                  width="20px"
                  src={validatePasswordRules(formik.values.newPassword, 'isIncludeUpperCase')}
                  alt="check-icon"
                ></Box>
                <Typography marginLeft="10px" variant="bodyRegular" textAlign="left">
                  A uppercase letter
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex">
                <Box
                  component="img"
                  width="20px"
                  src={validatePasswordRules(formik.values.newPassword, 'is8CharLong')}
                  alt="check-icon"
                ></Box>
                <Typography marginLeft="10px" variant="bodyRegular" textAlign="left">
                  Minimum 8 characters
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex">
                <Box
                  component="img"
                  width="20px"
                  src={validatePasswordRules(formik.values.newPassword, 'isIncludeNumber')}
                  alt="check-icon"
                ></Box>
                <Typography marginLeft="10px" variant="bodyRegular" textAlign="left">
                  A number
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Grid item xs={12} marginTop={1}>
          <CustomButtonLoadingButton
            text="Update"
            disabled={false}
            loading={isSubmitting}
            variant="contained"
            color="primary"
            onClick={() => formik.handleSubmit()}
          />
        </Grid>
      </Grid>

      {/*update success message*/}
      <CustomModal
        modal={true}
        open={successModalOpen}
        handleClose={handlMessageModalClose}
        handleButtonOnClick={handlMessageModalClose}
        icon={GreenCheckCircleSVG}
        title={'Password Updated!'}
        btnText={'Dismiss'}
        width="374px"
      >
        <Typography
          px={4}
          variant="bodyRegular"
          component="p"
          textAlign="center"
          sx={{ color: theme.palette.info.main }}
        >
          Your password has been successfully updated.
        </Typography>
      </CustomModal>

      {/*update fail message*/}
      <CustomModal
        modal={true}
        open={!!error && !isCurrentPasswordIncorrect}
        handleClose={handlMessageModalClose}
        handleButtonOnClick={handlMessageModalClose}
        icon={RedCloseCircleSVG}
        title={'Password Updated Failed!'}
        btnText={'Dismiss'}
        width="374px"
      >
        <Typography
          mb={1}
          variant="bodyRegular"
          component="p"
          textAlign="center"
          sx={{ color: theme.palette.info.main }}
        >
          Your password failed to update
        </Typography>
        <Typography variant="bodyRegular" component="p" textAlign="center" color="error">
          {error}
        </Typography>
      </CustomModal>
    </Box>
  );
}

export default SecuritySettings;
