import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Constant from '../../constants/constant.json';
import { SortOrder } from '../../utils/enums/sort-order.enum';

export interface AdminPermissions {
  permissionId: number;
  role: string;
  permission: string;
  description: string;
}

export interface AdminType {
  userId: string;
  globalId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  avatar: string;
  permissions: AdminPermissions[];
}

export interface SelectedAdminType {
  userId: string;
  globalId?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  avatar: string;
  permissions: AdminPermissions[];
}

export interface DashboardInfoType {
  activeClinics?: number;
  activeClinicians?: number;
  activePatients?: number;
  treatmentCompletedPatients?: number;
  returningPatients?: number;
}
export interface IAdminSlice {
  totalAdminCount: number;
  adminList: AdminType[];
  permissions: AdminPermissions[];
  selectedAdmin: SelectedAdminType | undefined;
  isAdminPopupOpen: boolean;
  isAdminAlreadyExist: boolean | null;
  adminData: SelectedAdminType;
  componentStatus: { [name: string]: undefined | string | boolean };
  dashboardInfo: DashboardInfoType;
}

const adminData = {
  userId: '',
  globalId: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  avatar: '',
  permissions: [],
};

const initialState: IAdminSlice = {
  totalAdminCount: 0,
  adminList: [],
  selectedAdmin: undefined,
  isAdminPopupOpen: false,
  permissions: [],
  isAdminAlreadyExist: null,
  adminData,
  componentStatus: {},
  dashboardInfo: {},
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setComponentStatus(state, action: PayloadAction<{ [key: string]: undefined | string | boolean }>) {
      state.componentStatus = { ...state.componentStatus, ...action.payload };
    },
    setAddAdminPopupClose(state, action: PayloadAction<boolean>) {
      state.isAdminPopupOpen = action.payload;
      state.selectedAdmin = undefined;
      state.adminData = adminData;
      state.componentStatus = { ...state.componentStatus, selectedTab: Constant.AdminTabs.GENERAL_INFO.slug };
    },
    setIsEmailAlreadyEsist(state, action: PayloadAction<boolean>) {
      state.isAdminAlreadyExist = action.payload;
    },
    setAdminPermissions(state, action: PayloadAction<Partial<any>>) {
      state.permissions = action.payload.data;
    },

    setTotalAdminCount(state, action: PayloadAction<number>) {
      state.totalAdminCount = action.payload;
    },
    setAdminList(state, action: PayloadAction<AdminType[]>) {
      state.adminList = action.payload;
    },
    setSelectedAdmin(state, action: PayloadAction<SelectedAdminType | undefined>) {
      state.selectedAdmin = action.payload;
    },
    setAdminData(state, action: PayloadAction<SelectedAdminType>) {
      state.adminData = { ...state.adminData, ...action.payload };
    },
    setCreateSuccess(state, action: PayloadAction<boolean>) {
      state.isAdminPopupOpen = false;
      state.componentStatus = { ...state.componentStatus, successModalOpen: true };
      state.componentStatus = { ...state.componentStatus, isSubmitting: false };
    },
    setCloseSuccessModel(state, action: PayloadAction<any>) {
      state.componentStatus = { ...state.componentStatus, successModalOpen: false, isRefresh: true };
      state.adminData = adminData;
      state.selectedAdmin = undefined;
    },
    setDashboardInfo(state, action: PayloadAction<DashboardInfoType>) {
      state.dashboardInfo = action.payload;
    },
    getAdminListAsync: (
      state,
      action: PayloadAction<{ skip: number; limit: number; sortBy: string; sortOrder: SortOrder }>,
    ) => {},
    getAdminByUserIdAsync: (state, action: PayloadAction<string>) => {},
    createAdminAsync: (state, action: PayloadAction<Partial<SelectedAdminType>>) => {},
    updateAdminAsync: (state, action: PayloadAction<Partial<SelectedAdminType>>) => {},
    deleteAdminAsync: (state, action: PayloadAction<{ userId: string; password: string }>) => {},
    validateEmailAlreadExist: (state, action: PayloadAction<string>) => {},
    nextToAddAdminPermissionTab: (state, action: PayloadAction<any>) => {},
    getDashboardInfoAsync: (state) => {},
  },
});

export const { actions: adminActions, reducer: adminReducer } = adminSlice;
