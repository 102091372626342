import { all, fork, AllEffect, ForkEffect } from 'redux-saga/effects';
import authSagas from './auth/authSaga';
import userSagas from './user/userSaga';
import adminSagas from './admin/adminSaga';
import clinicSagas from './clinic/clinicSaga';
import generalSagas from './general/generalSaga';
import clinicianSagas from './clinician/clinicianSaga';
import { notificationSaga } from './notification/notificationSaga';
import patientSagas from './patient/patientSaga';
import soundSagas from './sound/soundSaga';

export default function* rootSaga(): Generator<AllEffect<ForkEffect<void>>, void, unknown> {
  yield all([
    fork(authSagas),
    fork(userSagas),
    fork(adminSagas),
    fork(clinicSagas),
    fork(generalSagas),
    fork(clinicianSagas),
    fork(notificationSaga),
    fork(patientSagas),
    fork(soundSagas),
  ]);
}
