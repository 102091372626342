import React from 'react';
import { useTheme } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import {
  BarControllerChartOptions,
  Chart,
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  registerables,
  ScaleChartOptions,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';

Chart.register(...registerables);

export interface ChartData {
  data: number[];
  color: string;
  label?: string;
}

interface BarChartProps {
  data: ChartData[];
  stepSizeX: number;
  stepSizeY: number;
  height?: string | number;
  barThickness: number;
  responsive: boolean;
  stacked: boolean;
  labels: string[];
  maxY: number;
  skipLabelX: boolean;
  defaultbarCount?: number;
  xAxisTitle?: null | string;
  xGridOffSet?: boolean;
}

function BarChart({
  data,
  stepSizeX,
  stepSizeY,
  height,
  barThickness,
  responsive,
  stacked,
  labels,
  maxY,
  skipLabelX,
  defaultbarCount = 90,
  xAxisTitle = null,
  xGridOffSet = true,
}: BarChartProps) {
  const theme = useTheme();

  const options:
    | _DeepPartialObject<
        CoreChartOptions<'bar'> &
          ElementChartOptions<'bar'> &
          PluginChartOptions<'bar'> &
          DatasetChartOptions<'bar'> &
          ScaleChartOptions<'bar'> &
          BarControllerChartOptions
      >
    | undefined = {
    responsive: responsive,
    maintainAspectRatio: responsive,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      y: {
        stacked: stacked,
        min: 0,
        max: maxY,
        ticks: {
          stepSize: stepSizeY,
          color: theme.palette.shades.blackBorder,
          font: {
            size: 14,
          },
        },
      },
      x: {
        offset: true,
        stacked: stacked,
        grid: {
          offset: xGridOffSet,
        },
        ticks: {
          autoSkip: false,
          autoSkipPadding: 0,
          stepSize: 5,
          maxRotation: 0,
          callback: function (tick, index, ticks) {
            if (skipLabelX) {
              return (index + 1) % 5 === 0 ? (index + 1).toString() : null;
            } else {
              return labels[index];
            }
          },
        },
        title: {
          display: !!xAxisTitle,
          text: xAxisTitle as string,
          align: 'end',
        },
      },
    },
  };

  return (
    <Bar
      id="bar-chart-canvas"
      role="bar-chart-canvas"
      height={height}
      width={`${
        (barThickness + 2) * (data[0].data.length < defaultbarCount ? defaultbarCount : data[0].data.length)
      }px`}
      data={{
        labels: labels,
        datasets: data.map((dataset) => {
          return {
            data: dataset.data,
            backgroundColor: dataset.color,
            label: dataset.label,
            borderColor: theme.palette.primary.contrastText,
            barThickness: dataset.data.length > defaultbarCount || dataset.data.length === 1 ? barThickness : 'flex',
          };
        }),
      }}
      options={options}
    />
  );
}

export default BarChart;
