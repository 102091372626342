export function calculateResizeDimensions(
  maxWidth: number,
  maxHeight: number,
  actualWidth: number,
  actualHeight: number,
): { width: number; height: number } {
  let width = actualWidth;
  let height = actualHeight;

  // Check if actual width exceeds the max width
  if (width > maxWidth) {
    const ratio = maxWidth / width;
    width = maxWidth;
    height *= ratio;
  }

  // Check if actual height exceeds the max height
  if (height > maxHeight) {
    const ratio = maxHeight / height;
    height = maxHeight;
    width *= ratio;
  }

  // If actual size is smaller, resize to maximum size within max width and max height
  if (width < maxWidth && height < maxHeight) {
    const widthRatio = maxWidth / width;
    const heightRatio = maxHeight / height;
    const resizeRatio = Math.min(widthRatio, heightRatio);
    width *= resizeRatio;
    height *= resizeRatio;
  }

  return { width: Math.round(width), height: Math.round(height) };
}
