import React, { type ReactElement, type ReactNode, useEffect } from 'react';
import { LayoutType } from '../../utils/enums/layout-type.enum';
import BlankLayout from './BlankLayout';
import DashboardLayout from './DashboardLayout';
import AlertMassage from '../AlertMassage/AlertMassage';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { authActions } from '../../redux/auth/authSlice';

const Layouts = {
  [LayoutType.BLANKLAYOUT]: BlankLayout,
  [LayoutType.DASHBOARD]: DashboardLayout,
};

interface AppLayoutProps {
  layout: LayoutType;
  children: ReactNode;
}

function AppLayout({ layout, children }: AppLayoutProps): ReactElement {
  const Layout = Layouts[layout];

  const dispatch = useAppDispatch();

  const { isLogin, lastActiveTime } = useAppSelector((state: RootState) => state.auth);

  useEffect(() => {
    const sessionStorageTransfer = function (event: any) {
      if (event.key === 'getSessionIsLogin' && isLogin) {
        // another tab asked for the isLogin -> send it
        localStorage.setItem('isLogin', String(true));
        // the other tab should now have it, so we're done with it.
        localStorage.removeItem('isLogin');

        // sync last active time with opened tabs
        const timestamp = new Date().getTime();

        localStorage.setItem('lastActiveTime', String(timestamp));
        localStorage.removeItem('lastActiveTime');
      } else if (event.key === 'isLogin' && Boolean(event.newValue === 'true') !== isLogin) {
        // another tab sent data <- get it
        dispatch(authActions.setLogInStatus(event.newValue === 'true'));
      } else if (event.key === 'lastActiveTime' && lastActiveTime < parseInt(event.newValue)) {
        dispatch(authActions.setLastActiveTime(parseInt(event.newValue)));
      }
    };

    window.addEventListener('storage', sessionStorageTransfer);

    if (!isLogin) {
      localStorage.setItem('getSessionIsLogin', 'isLogin');
      localStorage.removeItem('getSessionIsLogin');
    }

    return () => {
      window.removeEventListener('storage', sessionStorageTransfer);
    };
  }, []);

  return (
    <Layout>
      <AlertMassage />
      <>{children}</>
    </Layout>
  );
}

export default AppLayout;
