import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { generalActions } from './generalSlice';
import { getCountries, getStates } from '../../api/generalApi';
import { AxiosResponse } from 'axios';

function* watchGetCountryList() {
  try {
    const res: AxiosResponse = yield call(getCountries);
    yield put(generalActions.setCountryList(res.data));
  } catch (e) {
    yield put(generalActions.setCountryList([]));
  }
}

function* watchGetStateList({ payload }: { payload: { country: string } }) {
  try {
    const res: AxiosResponse = yield call(getStates, payload.country);
    yield put(generalActions.setStateList(res.data));
  } catch (e) {
    yield put(generalActions.setStateList([]));
  }
}

export function* watchClinicSagas(): Generator<ForkEffect, void> {
  yield takeLatest(generalActions.getCountryListAsync, watchGetCountryList);
  yield takeLatest(generalActions.getStateListAsync, watchGetStateList);
}
const generalSagas = watchClinicSagas;
export default generalSagas;
