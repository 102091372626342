import React, { Fragment } from 'react';
import { Typography, useTheme, Grid, Alert } from '@mui/material';
import * as _ from 'lodash';
import CustomButtonLoadingButton from '../../../components/CustomButtonLoadingButton/CustomButtonLoadingButton';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { adminActions } from '../../../redux/admin/adminSlice';
import Constant from '../../../constants/constant.json';

function Confirmation() {
  const theme = useTheme();
  const admin = useAppSelector((state: RootState) => state.admin);
  const adminData = admin.adminData;
  const dispatch = useAppDispatch();

  const isSubmitting = useAppSelector((state: RootState) => state.admin.componentStatus)['isSubmitting'];
  const error = useAppSelector((state: RootState) => state.admin.componentStatus)['error'];

  const handleSubmit = () => {
    const { firstName, lastName, email, phone, permissions } = adminData;
    const apiData: any = {
      firstName,
      lastName,
      permissionIds: permissions.map((permission) => permission.permissionId),
    };
    if (admin.selectedAdmin) {
      apiData['userId'] = admin.selectedAdmin.userId;
      dispatch(adminActions.updateAdminAsync(apiData));
      dispatch(adminActions.setComponentStatus({ adminAddEditAction: 'Edit' }));
    } else {
      apiData['email'] = email;
      apiData['phone'] = phone;
      dispatch(adminActions.createAdminAsync(apiData));
      dispatch(adminActions.setComponentStatus({ adminAddEditAction: 'Add' }));
    }
  };

  return (
    <Fragment>
      <Grid marginTop={1} container rowSpacing={2} columnSpacing={1}>
        <Grid key={1} item xs={12} sm={12} md={12}>
          {error && (
            <Alert
              severity="error"
              onClose={() => {
                dispatch(adminActions.setComponentStatus({ error: undefined }));
              }}
            >
              {error}
            </Alert>
          )}
          <Typography color={theme.palette.info.main} marginBottom={2} marginTop={2}>
            Please confirm the below details
          </Typography>
        </Grid>
        <Grid key={2} container item xs={12} sm={12} md={2}>
          <AvatarEdit
            editable={false}
            avatar={adminData.avatar}
            name={adminData.firstName}
            name2={adminData.lastName}
            size="100px"
            font={theme.typography.h3}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography key={1} variant="h6" color={theme.palette.black.main} marginBottom={1}>
            {adminData.firstName} {adminData.lastName}
          </Typography>
          <Typography key={2} color={theme.palette.info.main}>
            {adminData.email}
          </Typography>
          <Typography key={3} color={theme.palette.info.main}>
            {adminData.phone}
          </Typography>
        </Grid>
        <Grid key={3} item xs={12} sm={12} md={6}>
          <Typography color={theme.palette.info.main} marginBottom={1}>
            Permissions
          </Typography>
          {admin.permissions.map(({ description, permission, permissionId }, index) => {
            if (_.find(adminData.permissions, { permissionId })) {
              return <Typography key={permissionId}>{description}</Typography>;
            }
            return null;
          })}
        </Grid>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={1}
          marginTop={2}
          justifyContent="space-between"
          sx={{ paddingTop: '30px', borderTop: `1px solid ${theme.palette.shades.purpleBorder}` }}
        >
          <CustomButtonLoadingButton
            text="Back"
            variant="outlined"
            color="primary"
            fullWidth={false}
            onClick={() => {
              dispatch(adminActions.setComponentStatus({ selectedTab: Constant.AdminTabs.CONFIG_PERMISSION.slug }));
            }}
            width="95px"
          />
          <CustomButtonLoadingButton
            text="Confirm"
            variant="contained"
            color="primary"
            fullWidth={false}
            onClick={() => handleSubmit()}
            loading={!!isSubmitting}
            width="118px"
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Confirmation;
