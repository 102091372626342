import React, { Fragment } from 'react';
import { Card, Grid, Stack, Typography, useTheme } from '@mui/material';
import { Theme, styled as materialStyled } from '@mui/material/styles';
import { AvatarDataType, patientActions } from '../../../../../redux/patient/patientSlice';
import LoadingTypography from '../../../../../components/LoadingTypography/LoadingTypography';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import TinnitusAvatarHistoryModal from './TinnitusAvatarHistoryModal';
import _ from 'lodash';
import { RootState } from '../../../../../redux/store';
import HorizontalDirection from './HorizontalDirection';
import VerticalPosition from './VerticalPosition';

const GridItemStyled = materialStyled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'end',
}));

const TypographyStyled = materialStyled(Typography)(({ theme }: { theme: Theme }) => ({
  ...theme.typography.bodyRegular,
  color: theme.palette.info.main,
}));

interface TinitusAvatarProps {
  avatar: AvatarDataType;
  isLoading: boolean;
  patientUserId: string;
}

function TinnitusAvatar({ avatar, isLoading, patientUserId }: TinitusAvatarProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const componentStatus = useAppSelector((state: RootState) => state.patient.componentStatus);
  const isViewTinnitusAvatarHistoryOpen = _.get(componentStatus, 'isViewTinnitusAvatarHistoryOpen', false) as boolean;

  const handleViewHistory = () => {
    dispatch(patientActions.setComponentStatus({ isViewTinnitusAvatarHistoryOpen: true }));
  };

  return (
    <Fragment>
      <Card sx={{ borderRadius: '10px', padding: '24px', minHeight: '209px' }}>
        <Grid container>
          <Grid mb={3} item width="100%">
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h5">Tinnitus Avatar</Typography>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end">
                <Typography
                  variant="button"
                  color={theme.palette.primary.main}
                  onClick={handleViewHistory}
                  sx={{ cursor: 'pointer' }}
                >
                  View History
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} pt={2} pb={2} sx={{ borderTop: '0.5px solid #3800AE4D' }}>
            <Stack spacing={3}>
              <Grid container>
                <Grid item xs={6}>
                  <TypographyStyled>Sound like a </TypographyStyled>
                </Grid>
                <GridItemStyled item xs={6}>
                  <LoadingTypography isLoading={isLoading} variant="bodyBold" sx={{ minWidth: 30 }}>
                    {avatar.tinnitusType}
                  </LoadingTypography>
                </GridItemStyled>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <TypographyStyled>Frequency </TypographyStyled>
                </Grid>
                <GridItemStyled item xs={6}>
                  <LoadingTypography isLoading={isLoading} variant="bodyBold" sx={{ minWidth: 30 }}>
                    {`${avatar.frequency}Hz`}
                  </LoadingTypography>
                </GridItemStyled>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <TypographyStyled>Bandwidth</TypographyStyled>
                </Grid>
                <GridItemStyled item xs={6}>
                  <LoadingTypography isLoading={isLoading} variant="bodyBold" sx={{ minWidth: 30 }}>
                    {`+/-${avatar.bandwidth}Hz`}
                  </LoadingTypography>
                </GridItemStyled>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <TypographyStyled>Right Volume </TypographyStyled>
                </Grid>
                <GridItemStyled item xs={6}>
                  <LoadingTypography isLoading={isLoading} variant="bodyBold" sx={{ minWidth: 30 }}>
                    {avatar.rightVolume}
                  </LoadingTypography>
                </GridItemStyled>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <TypographyStyled>Left Volume </TypographyStyled>
                </Grid>
                <GridItemStyled item xs={6}>
                  <LoadingTypography isLoading={isLoading} variant="bodyBold" sx={{ minWidth: 30 }}>
                    {avatar.leftVolume}
                  </LoadingTypography>
                </GridItemStyled>
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={12} pt={2} pb={2} sx={{ borderTop: '0.5px solid #3800AE4D' }}>
            <Grid container justifyContent="space-around">
              <Grid item md={4} sm={6}>
                <HorizontalDirection isLoading={isLoading} angle={avatar.horizontalDirection} />
              </Grid>
              <Grid item md={4} sm={6}>
                <VerticalPosition isLoading={isLoading} verticalPosition={avatar.verticalPosition} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      {isViewTinnitusAvatarHistoryOpen && <TinnitusAvatarHistoryModal patientUserId={patientUserId} />}
    </Fragment>
  );
}

export default TinnitusAvatar;
