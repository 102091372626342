import React, { Fragment, useEffect, useState } from 'react';
import CustomModal from '../../../components/CustomModal/CustomModal';
import ShieldOutlinedSVG from '../../../assets/img/shield-red-bold.svg';
import CheckCircleBoldDuotoneRed from '../../../assets/img/check-circle-bold-duotone-red.svg';
import { Grid, IconButton, InputAdornment, Skeleton, Stack, Typography } from '@mui/material';
import CustomInputBox from '../../../components/CustomInputBox/CustomInputBox';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';
import { soundActions } from '../../../redux/sound/soundSlice';
import { getFormattedTimeInMinutes } from '../SoundLibrary';
import { TimeConversionVariant } from '../../../utils/enums/time-conversion-variant.enum';

interface SoundDeleteProps {
  open: boolean;
}

const DeleteSound = ({ open }: SoundDeleteProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [popupTitle, setPopupTitle] = useState('Do you want to delete the following audio file?');
  const dispatch = useAppDispatch();

  const selectedSound = useAppSelector((state: RootState) => state.sound.deleteSoundTrack);
  const componentStatus = useAppSelector((state: RootState) => state.sound.componentStatus);
  const selectedSoundId = _.get(componentStatus, 'selectedSoundId', '') as string;
  const isSelectedSoundLoading = _.get(componentStatus, 'isDeleteSoundLoading', true) as boolean;
  const isPasswordIncorrect = _.get(componentStatus, 'isPasswordIncorrect', false) as boolean;
  const isSoundDeleting = _.get(componentStatus, 'isSoundDeleting', false) as boolean;
  const isSoundDeleteSuccess = _.get(componentStatus, 'isSoundDeleteSuccess', false) as boolean;
  const isSoundDeleteConfirmed = _.get(componentStatus, 'isSoundDeleteConfirmed', false) as boolean;
  const error = _.get(componentStatus, 'error', '') as string;

  useEffect(() => {
    setPopupTitle('Do you want to delete the following audio file?');
    formik.setFieldValue('password', '');
  }, [selectedSound]);

  useEffect(() => {
    if (isSoundDeleteSuccess) {
      setPopupTitle('Sound deleted!');
    }
  }, [isSoundDeleteSuccess]);

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: yup.object({
      password: yup.string().required('Password is required'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({ password }) => {
      dispatch(
        soundActions.setComponentStatus({
          isPasswordIncorrect: false,
          isSoundDeleting: true,
          error: '',
        }),
      );
      dispatch(soundActions.deleteSoundAsync({ soundId: selectedSoundId, password }));
    },
  });

  const handleDismiss = () => {
    dispatch(soundActions.clearComponentStatus());
    setPopupTitle('');
    formik.setFieldValue('password', '');
    setShowPassword(false);
  };

  return (
    <Fragment>
      <CustomModal
        modal={true}
        open={open}
        width="374px"
        btnText={isSoundDeleteSuccess ? 'Dismiss' : ''}
        handleButtonOnClick={handleDismiss}
        handleClose={handleDismiss}
        title={popupTitle}
        icon={isSoundDeleteSuccess ? CheckCircleBoldDuotoneRed : ShieldOutlinedSVG}
      >
        <Stack
          display={isSelectedSoundLoading ? 'flex' : 'none'}
          direction="column"
          alignItems="center"
          justifyContent="center"
          marginTop={'36px'}
          marginBottom={'36px'}
        >
          <Skeleton variant="text" animation="wave" sx={{ mt: 1, height: '27px', width: '250px' }} />
          <Skeleton variant="text" animation="wave" sx={{ mt: 1, height: '27px', width: '250px' }} />
          <Skeleton variant="text" animation="wave" sx={{ mt: 1, height: '27px', width: '250px' }} />
          <Skeleton variant="rounded" animation="wave" width="90%" height={70} sx={{ mt: 3 }} />
        </Stack>

        <Stack display={isSelectedSoundLoading ? 'none' : 'flex'} marginTop={'36px'}>
          <Typography component="p" variant="h6" align="center">{`${selectedSound?.title}`}</Typography>
          <Typography mt={1} component="p" variant="h6" align="center">
            {getFormattedTimeInMinutes(selectedSound?.duration, TimeConversionVariant.CAPITALIZED)}
          </Typography>
          <Typography mt={1} component="p" variant="h6" align="center">{`${selectedSound?.type} Audio`}</Typography>

          {isSoundDeleteConfirmed && !isSoundDeleteSuccess && (
            <Stack marginTop={'36px'}>
              <CustomInputBox
                label="Please enter your password"
                labelAlign={'center'}
                placeholder=""
                required={true}
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.password && Boolean(formik.errors.password)) || isPasswordIncorrect}
                helperText={
                  (formik.touched.password && formik.errors.password) || (isPasswordIncorrect && 'Incorrect password, please enter the correct password!')
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          )}
          {!isSoundDeleteSuccess && (
            <Grid container marginTop={'36px'} columnSpacing={2}>
              {error && !isPasswordIncorrect && (
                <Grid item xs={12}>
                  <Typography mb={1} variant="bodyRegular" component="p" textAlign="center" color="error">
                    {error}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={6}>
                <LoadingButton
                  variant="outlined"
                  onClick={handleDismiss}
                  fullWidth
                  sx={{ borderRadius: '10px', height: '43px' }}
                >
                  Dismiss
                </LoadingButton>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton
                  disabled={!selectedSound}
                  loading={isSoundDeleting}
                  onClick={
                    isSoundDeleteConfirmed
                      ? () => formik.handleSubmit()
                      : () => {
                          dispatch(
                            soundActions.setComponentStatus({
                              isSoundDeleteConfirmed: true,
                            }),
                          );
                          setPopupTitle('Confirm deleting the audio');
                        }
                  }
                  variant="contained"
                  fullWidth
                  color="error"
                  sx={{ borderRadius: '10px', height: '43px' }}
                >
                  Delete
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </Stack>
      </CustomModal>
    </Fragment>
  );
};

export default DeleteSound;
