import React, { MouseEvent } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { ButtonPropsColorOverrides, ButtonPropsVariantOverrides } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

interface CustomButtonLoadingButtonProps {
  text: string;
  disabled?: boolean;
  loading?: boolean;
  variant?: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>;
  color?: OverridableStringUnion<
    'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    ButtonPropsColorOverrides
  >;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  width?: string;
  startIcon?: React.ReactNode;
  role?: string;
}

function CustomButtonLoadingButton({
  text,
  disabled = false,
  loading = false,
  variant = 'contained',
  color = 'primary',
  onClick,
  endIcon,
  fullWidth = true,
  width = 'auto',
  startIcon,
  role,
}: CustomButtonLoadingButtonProps) {
  return (
    <LoadingButton
      role={role}
      disabled={disabled}
      loading={loading}
      variant={variant}
      color={color}
      fullWidth={fullWidth}
      onClick={onClick}
      endIcon={endIcon}
      startIcon={startIcon}
      sx={{
        borderRadius: '10px',
        height: '43px',
        gap: '10px',
        textAlign: 'center',
        textTransform: 'none',
        width: width,
      }}
    >
      {text}
    </LoadingButton>
  );
}

export default CustomButtonLoadingButton;
