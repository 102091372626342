import { ForkEffect, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  GetNotificationListParamType,
  getNotificationCount,
  getNotifications,
  getNotificationsList,
  getRequestorNamesList,
  updateLastNotificationViewTime,
} from '../../api/notificationApi';
import { NotificationResponse, notificationAction } from './notificationSlice';
import { AxiosResponse } from 'axios';
import { RootState } from '../store';
import * as dateFns from 'date-fns';

function* watchNotificationsCount() {
  try {
    const defaultLastReadTime = dateFns.sub(new Date(), { months: 1 });
    let lastReadTime: Date = yield select((state: RootState) => state.notification.lastNotificationViewTime);

    lastReadTime = dateFns.isAfter(lastReadTime, defaultLastReadTime) ? lastReadTime : defaultLastReadTime;

    const notificationCount: AxiosResponse<number> = yield call(getNotificationCount, lastReadTime);
    yield put(notificationAction.setNotificationCount(notificationCount.data));
  } catch {}
}

function* watchFetchNotifications({ payload: initCall }: { payload: boolean }) {
  const notificationCount: number = yield select((state: RootState) => state.notification.notificationCount);

  if (notificationCount > 0 && initCall) {
    //reset notification to load new notification
    yield put(
      notificationAction.setNotifications({
        currentPage: 0,
        totalPages: 0,
        notifications: {},
      }),
    );
  }

  const currentPage: number = yield select((state: RootState) => state.notification.currentPage);
  const notifications: { [key: string]: NotificationResponse[] } = yield select(
    (state: RootState) => state.notification.notifications,
  );
  const totalPages: number = yield select((state: RootState) => state.notification.totalPages);
  const existRecordCount = Object.keys(notifications)?.length || 0;
  const recordLimit = 15;
  try {
    if ((currentPage + 1 <= totalPages && !initCall) || existRecordCount === 0) {
      yield put(notificationAction.setNotificationLoading(true));
      const notificationRes: AxiosResponse = yield call(
        getNotifications,
        existRecordCount > 0 ? currentPage + 1 : currentPage,
        recordLimit,
      );
      const responseNotifications = notificationRes.data.data as NotificationResponse[];
      if (responseNotifications?.[0]) {
        const notificationObjClone = { ...notifications };
        for (const nt of responseNotifications) {
          const dayString = dateFns.format(new Date(nt.updatedDate), 'yyyy-MM-dd');
          const storedNotifications = [...(notificationObjClone[dayString] || [])];
          notificationObjClone[dayString] = storedNotifications;
          storedNotifications.push(nt);
        }
        yield put(
          notificationAction.setNotifications({
            currentPage: +notificationRes.data.currentPage,
            totalPages: +notificationRes.data.pages,
            notifications: notificationObjClone,
          }),
        );
      }
      yield put(notificationAction.setNotificationLoading(false));
      yield put(notificationAction.updateLastNotificationViewTimeAsync());
    }
  } catch (err) {
    yield put(notificationAction.setNotificationLoading(false));
  }
}

function* watchFetchNotificationsList({ payload }: { payload: GetNotificationListParamType }) {
  yield put(notificationAction.setComponentStatus({ isNotificationsListLoading: true }));
  try {
    const res: AxiosResponse = yield call(getNotificationsList, payload);
    yield put(notificationAction.setNotificationListCount(res.data['total']));
    yield put(notificationAction.setNotificationsList(res.data['data']));
  } catch (err: any) {
    yield put(notificationAction.setNotificationListCount(0));
    yield put(notificationAction.setNotificationsList([]));
  }
  yield put(notificationAction.setComponentStatus({ isNotificationsListLoading: false }));
}

function* watchFetchRequestorNamesList() {
  yield put(notificationAction.setComponentStatus({ isRequestorsListLoading: true }));
  try {
    const res: AxiosResponse = yield call(getRequestorNamesList);
    yield put(notificationAction.setRequestorNamesList(res.data));
  } catch (err: any) {
    yield put(notificationAction.setRequestorNamesList([]));
  }
  yield put(notificationAction.setComponentStatus({ isRequestorsListLoading: false }));
}

function* watchUpdateLastNotificationViewTime() {
  try {
    const res: AxiosResponse = yield call(updateLastNotificationViewTime);
    yield put(notificationAction.setNotificationViewTime(new Date(res.data.lastNotificationViewTime)));
    yield put(notificationAction.loadNotificationCountAsync());
  } catch (err) {
    console.log(err);
  }
}

function* watchNotificationSaga(): Generator<ForkEffect, void> {
  yield takeLatest(notificationAction.loadNotificationCountAsync, watchNotificationsCount);
  yield takeLatest(notificationAction.loadNotificationAsync, watchFetchNotifications);
  yield takeLatest(notificationAction.getNotificationListAsync, watchFetchNotificationsList);
  yield takeLatest(notificationAction.getAllRequestorNamesAsync, watchFetchRequestorNamesList);
  yield takeLatest(notificationAction.updateLastNotificationViewTimeAsync, watchUpdateLastNotificationViewTime);
}

export const notificationSaga = watchNotificationSaga;
