import { AxiosResponse } from 'axios';
import { backendApiInstance } from './backendApiInstance';

export async function sendEmailOtp(email: string, password: string): Promise<AxiosResponse> {
  return backendApiInstance.post('auth/send-2fa-otp', { username: email, password: password });
}

export async function logIn(
  email: string,
  password: string,
  otp: string | null,
  otpSkip: boolean | null,
): Promise<AxiosResponse> {
  return backendApiInstance.post('auth/login', { type: 'Web', username: email, password, otp, otpSkip });
}

export async function createNewAccessToken(): Promise<AxiosResponse> {
  return backendApiInstance.post('auth/access-token');
}

export async function logOut(): Promise<AxiosResponse> {
  return backendApiInstance.post('auth/logout');
}
