import React, { FocusEvent } from 'react';
import { Box, Typography, useTheme, FormHelperTextProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';

interface CustomDatepickerProps {
  label: string;
  required: boolean;
  name: string;
  value: string | null;
  error: boolean | undefined;
  helperText: string | boolean | undefined;
  maxDate?: Date;
  onBlur: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  onChange: (value: Date | string | null) => void;
  labelAlign?: 'left' | 'center';
  FormHelperTextProps?: Partial<FormHelperTextProps>;
}

function CustomDatepicker({
  label,
  required,
  name,
  value,
  error,
  helperText,
  maxDate,
  onBlur,
  onChange,
  labelAlign = 'left',
  FormHelperTextProps,
}: CustomDatepickerProps) {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" gap="2px">
      <Typography variant="meta" component="p" align={labelAlign}>
        {label}
        <Typography hidden={!required} component="span" color="primary">
          &nbsp;*
        </Typography>
      </Typography>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          format="MM-dd-yyyy"
          value={value ? new Date(value) : null}
          sx={{ '& .MuiInputBase-root': { height: '38px', borderRadius: '10px' } }}
          onChange={(value) => {
            onChange(value);
          }}
          // @ts-ignore
          maxDate={maxDate}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
            textField: {
              name: name,
              error: error,
              helperText: helperText,
              FormHelperTextProps: { style: { ...theme.typography.meta }, ...FormHelperTextProps },
              onBlur: onBlur,
              onKeyDown: (e) => {
                e.preventDefault();
              },
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
}

export default CustomDatepicker;
