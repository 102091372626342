import React from 'react';
import { Divider, List, Typography, Grid, Skeleton, Button, Box } from '@mui/material';
import { Theme, styled as materialStyled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { theme } from '../../theme/theme';
import * as dateFn from 'date-fns';
import CloseCircleBoldSVG from '../../assets/img/close-circle-bold.svg';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { NotificationCard } from './NotificationCard';
import { notificationAction } from '../../redux/notification/notificationSlice';
import { RootState } from '../../redux/store';
import { Roles } from '../../utils/enums/roles.enum';
import { useAuth } from '../../context/AuthContext';
import { NotificationType } from '../../utils/enums/notification-type.enum';
import C2CTransterApproveRejectModal from './Components/C2CTransterApproveRejectModal';
import { v4 as uuidv4 } from 'uuid';

const CustomHeaderName = materialStyled(Typography)(({ theme }: { theme: Theme }) => ({
  textAlign: 'center',
  color: theme.palette.primary.main,
}));

const HeaderContainer = materialStyled('div')(({ theme: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const CostomList = materialStyled(List)(({ theme }: { theme: Theme }) => ({
  overflowX: 'hidden',
  overflowY: 'scroll',
  scrollbarWidth: 'thin', // Hide the scrollbar in Firefox
  '&::-webkit-scrollbar': {
    width: '0.4em', // Hide the scrollbar in WebKit (Chrome, Safari, etc.)
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent', // Hide the scrollbar thumb
  },
}));

export function NotificationWindow() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const notifications = useAppSelector((state: RootState) => state.notification.notifications);
  const loading = useAppSelector((state: RootState) => state.notification.notificationsLoading);

  const dispatch = useAppDispatch();
  const handleClosePane = () => {
    dispatch(notificationAction.setNotificationView(false));
  };

  const dateSelection = (date: string) => {
    const dayDifferent = dateFn.differenceInDays(dateFn.startOfDay(new Date()), dateFn.startOfDay(new Date(date)));
    if (dayDifferent === 0) return 'Today';
    else if (dayDifferent === 1) return 'Yesterday';
    else return date;
  };

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollHeight - scrollTop === clientHeight) {
      dispatch(notificationAction.loadNotificationAsync(false));
    }
  };

  const handleViewAllRequests = () => {
    dispatch(notificationAction.setNotificationView(false));
    navigate('/requests');
  };

  const notificationLoadingSkeliton = () => {
    return (
      <Grid container mt={3}>
        <Grid item xs={2} sm={1}>
          <Skeleton variant="circular" width={32} height={32} />
        </Grid>
        <Grid item mb={3} xs={10} sm={11}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          <Skeleton variant="rounded" height={30} sx={{ marginTop: 1 }} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Box>
      <Box
        paddingX={3}
        sx={{
          position: 'sticky',
          top: '0',
          zIndex: theme.zIndex.drawer + 3,
          backgroundColor: theme.palette.white.main,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: theme.spacing(0, 1) }}>
          <HeaderContainer>
            <CustomHeaderName variant="h5">Requests</CustomHeaderName>
          </HeaderContainer>

          <Box
            component="img"
            alt="close-button"
            src={CloseCircleBoldSVG}
            onClick={handleClosePane}
            sx={{ width: '36px', cursor: 'pointer' }}
          />
        </Box>

        <Divider />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', padding: theme.spacing(0, 3), flex: 1 }}>
        <CostomList
          onScroll={handleScroll}
          sx={{
            height: window.innerHeight - 120,
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {Object.entries(notifications).map(([key, notifications], index) => {
            return (
              <React.Fragment key={uuidv4()}>
                <Typography
                  variant="h6"
                  mt={index === 0 ? 1 : 2}
                  mb={3}
                  sx={{ fontSize: '14px', color: theme.palette.info.main, opacity: '50%' }}
                >
                  {dateSelection(key)}
                </Typography>
                {notifications.map((nt) => {
                  return (
                    <NotificationCard
                      key={nt.notificationId}
                      notificationId={nt.notificationId}
                      isActionNeeded={nt.isActionNeeded}
                      subject={nt.subject}
                      description={nt.description}
                      image={nt.image}
                      createdUser={nt.createdUser}
                      createdUserAvatar={nt.createdUserAvatar}
                      notificationType={nt.type as NotificationType}
                      approveBtnLoading={nt.approveBtnLoading}
                      rejectBtnLoading={nt.rejectBtnLoading}
                      actionDisable={nt.actionDisable}
                      patient={nt.patient}
                    />
                  );
                })}
              </React.Fragment>
            );
          })}

          {loading && notificationLoadingSkeliton()}
        </CostomList>
      </Box>

      {[Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role) && (
        <Box
          paddingX={3}
          py={2}
          sx={{
            position: 'sticky',
            bottom: '0',
            zIndex: theme.zIndex.drawer + 3,
            backgroundColor: theme.palette.white.main,
          }}
        >
          <Button style={{ color: theme.palette.info.main }} onClick={handleViewAllRequests}>
            <Typography variant="button" color={theme.palette.primary.main}>
              View All Requests
            </Typography>
          </Button>
        </Box>
      )}
      <C2CTransterApproveRejectModal />
    </Box>
  );
}
