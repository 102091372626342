import React, { Fragment } from 'react';
import { Typography } from '@mui/material';
import * as _ from 'lodash';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { soundActions } from '../../../redux/sound/soundSlice';

interface ViewAlbumArtProps {
  isOpen: boolean;
}

function ViewAlbumArt({ isOpen }: ViewAlbumArtProps) {
  const dispatch = useAppDispatch();

  const componentStatus = useAppSelector((state: RootState) => state.sound.componentStatus);
  const title = _.get(componentStatus, 'selectedSoundTilte', false) as string;
  const duration = _.get(componentStatus, 'selectedSoundDuration', false) as string;
  const albumArt = _.get(componentStatus, 'selectedSoundAlbumArt', false) as string;

  const handleDismiss = () => {
    dispatch(soundActions.clearComponentStatus());
  };

  return (
    <Fragment>
      <CustomModal modal={true} open={isOpen} handleButtonOnClick={handleDismiss} btnText={'Dismiss'} width="374px">
        <img
          src={`${process.env.REACT_APP_CLOUD_URL}/minio/audio/${albumArt}`}
          alt="album-art"
          width="100%"
          style={{ borderRadius: '15px' }}
        />
        <Typography mt={4} variant="h6" component="p" textAlign="center">
          {title}
        </Typography>
        <Typography mt={0.5} variant="h6" component="p" textAlign="center">
          {duration}
        </Typography>
      </CustomModal>
    </Fragment>
  );
}

export default ViewAlbumArt;
