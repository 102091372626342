import React from 'react';
import CustomModal from '../../../../components/CustomModal/CustomModal';
import { Grid, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import AvatarEdit from '../../../../components/AvatarEdit/AvatarEdit';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { RootState } from '../../../../redux/store';
import * as _ from 'lodash';
import { authActions } from '../../../../redux/auth/authSlice';
import { clinicActions } from '../../../../redux/clinic/clinicSlice';

function ViewClinicAdmin() {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const selectedClinicAdmin = useAppSelector((state: RootState) => state.clinic.selectedClinicAdmin);

  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const isSelectedClinicAdminLoading = _.get(componentStatus, 'isSelectedClinicAdminLoading', false) as boolean;
  const openClinicAdminDetailsModal = _.get(componentStatus, 'openClinicAdminDetailsModal', false) as boolean;

  const handleDismiss = () => {
    dispatch(authActions.setComponentStatus({ openClinicAdminDetailsModal: false }));
    setTimeout(() => {
      dispatch(clinicActions.setSelectedClinicAdmin(undefined));
    }, 500);
  };

  return (
    <CustomModal
      modal={true}
      open={openClinicAdminDetailsModal}
      width="374px"
      title={selectedClinicAdmin && `${selectedClinicAdmin?.firstName} ${selectedClinicAdmin?.lastName}`}
      subTitle="Clinic Admin"
    >
      <Stack
        display={isSelectedClinicAdminLoading ? 'flex' : 'none'}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Skeleton variant="circular" animation="wave" width={72} height={72} />
        <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: '60%', mt: 2 }} />
        <Skeleton variant="rounded" animation="wave" width="90%" height={90} sx={{ mt: 3 }} />
      </Stack>

      <Stack
        display={isSelectedClinicAdminLoading ? 'none' : 'flex'}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <AvatarEdit
          editable={false}
          avatar={selectedClinicAdmin ? selectedClinicAdmin.avatar : ''}
          name={selectedClinicAdmin?.firstName}
          name2={selectedClinicAdmin?.lastName}
          size="72px"
          font={theme.typography.h3}
        />
        <Typography mt={2} component="p" variant="bodyRegular" color={theme.palette.info.main}>
          {selectedClinicAdmin?.email}
        </Typography>
        <Typography component="p" variant="bodyRegular" color={theme.palette.info.main}>
          {selectedClinicAdmin?.phone}
        </Typography>
        <Typography mt={2} mb={1} component="p" variant="bodyBold">
          Permissions
        </Typography>
        {selectedClinicAdmin?.permissions.map((e: any, i: any) => {
          return (
            <Typography key={i} component="p" variant="bodyRegular" color={theme.palette.info.main}>
              {e.description}
            </Typography>
          );
        })}
        <Grid container marginTop={3} columnSpacing={2}>
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              onClick={handleDismiss}
              fullWidth
              sx={{ borderRadius: '10px', height: '43px' }}
            >
              Dismiss
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
    </CustomModal>
  );
}

export default ViewClinicAdmin;
