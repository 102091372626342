import React, { Fragment } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { theme } from '../../../theme/theme';
import { NotificationType } from '../../../utils/enums/notification-type.enum';
import CloseCircleBoldSVG from '../../../assets/img/close-circle-bold.svg';
import GreenCheckCircleSVG from '../../../assets/img/check-circle-bold-duotone.svg';

interface ClinicApproveRejectInformProps {
  notificationType: NotificationType.APPROVAL_INFORM | NotificationType.REJECT_INFORM;
  createdUserName: string;
  subject: string;
  description: string;
}

function ClinicApproveRejectInform(props: ClinicApproveRejectInformProps) {
  return (
    <Fragment>
      <Grid item xs={2} sm={1}>
        <Box
          component="img"
          src={props.notificationType === NotificationType.APPROVAL_INFORM ? GreenCheckCircleSVG : CloseCircleBoldSVG}
          sx={{ width: '28px', height: '28px' }}
        />
      </Grid>
      <Grid item mb={3} xs={10} sm={11}>
        <Typography variant="h6" fontSize="20px" mb={2} sx={{ whiteSpace: 'normal' }}>
          {props.subject}
        </Typography>
        <Typography variant="bodyRegular" component="p" sx={{ whiteSpace: 'normal', color: theme.palette.info.main }}>
          <Typography
            variant="bodyRegular"
            component="span"
            sx={{ whiteSpace: 'normal', color: theme.palette.primary.main, paddingRight: 1 }}
          >
            {props.createdUserName}
          </Typography>
          {props.description}
        </Typography>
      </Grid>
    </Fragment>
  );
}

export default ClinicApproveRejectInform;
