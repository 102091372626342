import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Slider, Typography, useTheme } from '@mui/material';
import SolarPauseBoldSVG from '../../assets/img/solar_pause-bold.svg';
import PlayBoldSVG from '../../assets/img/play-bold.svg';
import * as dateFns from 'date-fns';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import _ from 'lodash';
import { Sound, soundActions } from '../../redux/sound/soundSlice';
import CircularProgress from '@mui/material/CircularProgress';

interface SoundPlayerProps {
  soundId: string;
  src: string;
  sliderMinWidth?: string;
  sound: Sound;
}

function SoundPlayer({ sound, src, sliderMinWidth = '120px' }: SoundPlayerProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlay, setIsPlay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentDuration, setCurrentDuration] = useState<number>(0);
  const [fullDuration, setFullDuration] = useState<number>(0);
  const [bufferedRange, setBufferedRange] = useState<number[]>([]);

  const componentStatus = useAppSelector((state: RootState) => state.sound.componentStatus);
  const currentPlayerSoundId = _.get(componentStatus, 'currentPlayerSoundId');
  const lastPresignedUrlCreatedAt = _.get(componentStatus, 'lastPresignedUrlCreatedAt');

  useEffect(() => {
    if (audioRef.current && currentPlayerSoundId && currentPlayerSoundId !== sound.soundId) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setCurrentDuration(0);
      setIsPlay(false);
      setIsLoading(false);
    } else if (currentPlayerSoundId === sound.soundId && sound.preSignedUrl) {
      handlePlayOnClick();
    }
  }, [currentPlayerSoundId, lastPresignedUrlCreatedAt]);

  const handlePlayOnClick = () => {
    if (!sound.preSignedUrl && sound.name && sound.soundId) {
      dispatch(soundActions.getSoundPresignedUrlAsync({ soundId: sound.soundId, soundName: sound.name }));
      dispatch(soundActions.setComponentStatus({ currentPlayerSoundId: sound.soundId }));
      setIsLoading(true);
    } else if (audioRef?.current) {
      if (isPlay) {
        audioRef.current.pause();
        setIsPlay(false);
      } else {
        dispatch(soundActions.setComponentStatus({ currentPlayerSoundId: sound.soundId }));
        setIsLoading(true);
        audioRef.current.play().catch(() => {
          if (audioRef.current) {
            audioRef.current.currentTime = 0;
          }
          setCurrentDuration(0);
          setIsPlay(false);
          setIsLoading(false);
        });
      }
    }
  };

  const handleSliderChange = (event: Event, value: number | number[]) => {
    const duration = (fullDuration * (value as number)) / 100;
    if (audioRef.current) {
      audioRef.current.currentTime = duration;
      setCurrentDuration(duration);
    }
  };

  const handleAudioEnd = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      setCurrentDuration(0);
      setIsPlay(false);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime;
      setCurrentDuration(currentTime);
    }
  };

  const handleDurationChange = (event: React.SyntheticEvent<HTMLAudioElement, Event>) => {
    setFullDuration(event.currentTarget.duration);
  };

  const handleOnLoadedData = () => {
    setIsLoading(false);
  };

  const handleOnLoadedMetadata = (event: React.SyntheticEvent<HTMLAudioElement, Event>) => {
    setFullDuration(event.currentTarget.duration);
  };

  const handleOnWaiting = () => {
    setIsLoading(true);
  };

  const handleOnPlaying = () => {
    setIsLoading(false);
    setIsPlay(true);
  };

  const handleOnSuspend = () => {
    setIsLoading(false);
  };

  const handleOnProgress = (event: React.SyntheticEvent<HTMLAudioElement, Event>) => {
    if (event.currentTarget.buffered && event.currentTarget.buffered.length > 0 && fullDuration > 0) {
      const ranges = [];
      for (let i = 0; i < event.currentTarget.buffered.length; i++) {
        const startTime = event.currentTarget.buffered.start(i);
        const endTime = event.currentTarget.buffered.end(i);

        const slideRange = Array.from(Array(Math.floor(((endTime - startTime) * 100) / fullDuration))).map((e, i) =>
          Math.floor((startTime * 100) / fullDuration + i),
        );
        ranges.push(...slideRange);
      }
      setBufferedRange(_.uniq(ranges).sort((a, b) => a - b));
    }
  };

  const getTime = () => {
    const currentTime = dateFns.intervalToDuration({ start: 0, end: currentDuration * 1000 });
    return `${String(currentTime.minutes).padStart(2, '0')}:${String(currentTime.seconds).padStart(2, '0')}`;
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {sound.preSignedUrl && (
        <audio
          ref={audioRef}
          src={sound.preSignedUrl}
          preload="none"
          autoPlay={false}
          onEnded={handleAudioEnd}
          onTimeUpdate={handleTimeUpdate}
          onDurationChange={handleDurationChange}
          onLoadedData={handleOnLoadedData}
          onLoadedMetadata={handleOnLoadedMetadata}
          onWaiting={handleOnWaiting}
          onPlaying={handleOnPlaying}
          onSuspend={handleOnSuspend}
          onProgress={handleOnProgress}
        />
      )}

      <Typography component="p" variant="meta" color={theme.palette.info.main} marginRight={1} width="60px">
        {getTime()}
      </Typography>

      <Slider
        sx={{
          width: sliderMinWidth,
          '& .MuiSlider-track': {
            opacity: 1,
            backgroundColor: theme.palette.primary.main,
          },
          '& .MuiSlider-rail': {
            opacity: 0.2,
            backgroundColor: theme.palette.primary.main,
          },
          '& .MuiSlider-mark': {
            opacity: 0.2,
            height: '1px',
            backgroundColor: theme.palette.primary.main,
            '&.MuiSlider-markActive': {
              backgroundColor: 'currentColor',
            },
          },
        }}
        size="small"
        value={fullDuration ? (currentDuration * 100) / fullDuration : 0}
        onChange={handleSliderChange}
        marks={bufferedRange.map((value) => ({ value }))}
      />

      <Box width={30}>
        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <IconButton
            id="play-pause-btn-row"
            role="play-pause-btn-row"
            size="small"
            data-testid="sound-play-btn"
            onClick={handlePlayOnClick}
          >
            <Box component="img" src={isPlay ? SolarPauseBoldSVG : PlayBoldSVG} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

export default SoundPlayer;
