import React from 'react';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { Box, Button, Typography, useTheme } from '@mui/material';
import ShieldOutlinedSVG from '../../../assets/img/shield-red-bold.svg';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';
import { generalActions } from '../../../redux/general/generalSlice';
import { patientActions } from '../../../redux/patient/patientSlice';
import { useNavigate } from 'react-router-dom';

interface CancelEqSettingProps {
  patientUserId: string;
}

function CancelEqSetting({ patientUserId }: CancelEqSettingProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const componentStatus = useAppSelector((state: RootState) => state.general.componentStatus);
  const openCancelEqSettingModal = _.get(componentStatus, 'openCancelEqSettingModal', false) as boolean;

  const handleDismissOnClick = () => {
    dispatch(generalActions.setComponentStatus({ openCancelEqSettingModal: false }));
  };
  const handleConfirmOnClick = () => {
    dispatch(generalActions.clearComponentStatus());
    dispatch(patientActions.clearEqData());
    navigate(`/patients/${patientUserId}`, { replace: true });
  };

  return (
    <CustomModal
      modal={true}
      open={openCancelEqSettingModal}
      title="You’ll lose your settings!"
      icon={ShieldOutlinedSVG}
      maxWidth="374px"
    >
      <Typography component="p" variant="bodyRegular" textAlign="center" color={theme.palette.info.main}>
        If you decide go back, you will forfeit the configured EQ settings so far.
      </Typography>
      <Box marginTop={4} display="flex" columnGap={2}>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          sx={{ borderRadius: '10px', height: '43px' }}
          onClick={handleDismissOnClick}
        >
          Dismiss
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="error"
          sx={{ borderRadius: '10px', height: '43px' }}
          onClick={handleConfirmOnClick}
        >
          Confirm
        </Button>
      </Box>
    </CustomModal>
  );
}

export default CancelEqSetting;
