import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { FormHelperText, Grid, IconButton } from '@mui/material';
import { Accept, DropEvent, useDropzone } from 'react-dropzone';
import CustomButtonLoadingButton from '../CustomButtonLoadingButton/CustomButtonLoadingButton';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CustomLoader from './CustomLoader';
import { Theme, styled as materialStyled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { SoundType } from '../../utils/enums/sound-type.enum';

const FormHelperTextStyled = materialStyled(FormHelperText)(({ theme }: { theme: Theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  fontFamily: 'Satoshi',
  variant: 'filled',
}));

const GridStyled = materialStyled(Grid)(({ theme }: { theme: Theme }) => ({
  backgroundColor: 'rgba(56, 0, 174, 0.05)',
  borderRadius: '15px',
  border: '1px dashed rgba(56, 0, 174, 0.3)',
}));

interface CustomDropzoneProps {
  accept: Accept;
  maxFiles?: number;
  dargActiveText: string;
  dragInactiveText: string;
  invalidFileTypeError: string;
  tooLargeFileError?: string;
  onDropAccepted?: <T extends File>(files: T[], event: DropEvent) => void;
  fileDetails?: ReactNode;
  fileDetailsAction?: ReactNode;
  maxSize?: number;
  handleDelteFiles: () => void;
  formikError: string;
  acceptedFile: File | null;
  soundType?: SoundType;
}

function CustomDropzone({
  accept,
  maxFiles,
  dargActiveText,
  dragInactiveText,
  invalidFileTypeError,
  tooLargeFileError,
  onDropAccepted,
  fileDetails,
  fileDetailsAction,
  maxSize,
  handleDelteFiles,
  formikError,
  acceptedFile,
  soundType,
}: CustomDropzoneProps) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [files, setFiles] = useState<File[]>([]);

  const onDropRejected = useCallback(
    (rejected: any[]) => {
      const error = rejected[0].errors[0].code;
      if (error === 'file-invalid-type') {
        setError(invalidFileTypeError);
      }
      if (error === 'too-many-files') {
        setError('Maximum number of files that can be uploaded is ' + maxFiles);
      }
      if (error === 'file-too-large' && tooLargeFileError) {
        setError(tooLargeFileError);
      }
    },
    [invalidFileTypeError],
  );

  useEffect(() => {
    setError(formikError);
  }, [formikError]);

  useEffect(() => {
    if (acceptedFile?.type === 'audio/mpeg' && soundType === SoundType.RELIEF) {
      setError('Please upload your relief audio file in wav format.');
    } else if (accept.hasOwnProperty('audio/wav')) {
      setError('');
    }
  }, [soundType]);

  const onDrop = useCallback(() => {
    setError('');
    setProgress(0);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    onDrop,
    accept,
    maxFiles,
    maxSize,
  });

  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
      }, 100);
      return () => {
        clearInterval(timer);
      };
    }
  }, [loading]);

  useEffect(() => {
    if (acceptedFile) {
      setFiles([acceptedFile]);
    } else {
      setFiles([]);
    }
  }, [acceptedFile]);

  const removeFiles = () => {
    setFiles([]);
    handleDelteFiles();
  };

  return (
    <div>
      {files.length <= 0 ? (
        <div {...getRootProps()}>
          <input data-testid="dropzone" {...getInputProps()} />
          <GridStyled container p={4} justifyContent="center" alignContent="center">
            <Grid item width="100%" display="flex" justifyContent="center">
              <CustomButtonLoadingButton
                text={isDragActive ? dargActiveText : dragInactiveText}
                variant="text"
                startIcon={<CloudUploadOutlinedIcon />}
              />
            </Grid>
          </GridStyled>
        </div>
      ) : (
        <GridStyled container p={4} justifyContent="center" alignContent="center">
          <Grid item width="100%">
            {!loading ? (
              <Grid display="flex" justifyContent="space-between">
                {fileDetails}
                <Grid container display="flex" alignItems="center" justifyContent="end" spacing={4}>
                  {fileDetailsAction}
                  <Grid item>
                    <IconButton role="delete-file" aria-label="delete" onClick={removeFiles}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid display="flex" justifyContent="center">
                <CustomLoader progress={progress} />
              </Grid>
            )}
          </Grid>
        </GridStyled>
      )}
      <FormHelperTextStyled error>{error}</FormHelperTextStyled>
    </div>
  );
}

export default CustomDropzone;
