import React, { Fragment } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import GreenCheckCircleSVG from '../../../assets/img/check-circle-bold-duotone.svg';

interface CommonInfoProps {
  subject: string;
  description: string;
}

function CommonInfo(props: CommonInfoProps) {
  const theme = useTheme();
  return (
    <Fragment>
      <Grid item xs={2} sm={1}>
        <Box component="img" src={GreenCheckCircleSVG} sx={{ width: '28px', height: '28px' }} />
      </Grid>
      <Grid item mb={3} xs={10} sm={11}>
        <Typography variant="h6" fontSize="20px" mb={2} sx={{ whiteSpace: 'normal' }}>
          {props.subject}
        </Typography>
        <Typography variant="bodyRegular" component="p" sx={{ whiteSpace: 'normal', color: theme.palette.info.main }}>
          {props.description}
        </Typography>
      </Grid>
    </Fragment>
  );
}

export default CommonInfo;
