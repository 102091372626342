import React from 'react';
import { Box, Card, Skeleton, Stack, Typography, useTheme } from '@mui/material';

interface CountDisplayCardProps {
  isLoading: boolean;
  description: string;
  count: number | string;
  icon?: string;
  minHeight?: string;
}

function CountDisplayCard(props: CountDisplayCardProps) {
  const theme = useTheme();

  return (
    <Card sx={{ borderRadius: '10px', minHeight: props.minHeight ?? 'auto', padding: '24px' }}>
      <Stack display={props.isLoading ? 'flex' : 'none'} direction="row" alignItems="center" gap="30px" padding="0px">
        <Stack>
          <Skeleton variant="text" animation="wave" sx={{ fontSize: '4rem', width: 60 }} />
          <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: 100 }} />
        </Stack>
      </Stack>
      <Box sx={{ display: props.isLoading ? 'none' : 'block', padding: 0 }}>
        <Box display="flex" justifyContent="space-between" alignItems="start">
          <Box>
            <Typography gutterBottom variant="h3" component="h3" color={theme.palette.black.main}>
              {props.count}
            </Typography>
            <Typography variant="bodyRegular" color={theme.palette.info.main}>
              {props.description}
            </Typography>
          </Box>
          {props.icon && <Box component="img" src={props.icon}></Box>}
        </Box>
      </Box>
    </Card>
  );
}

export default CountDisplayCard;
