import React, { useRef, useState } from 'react';
import CustomModal from '../../components/CustomModal/CustomModal';
import CheckBoxSvg from '../../assets/img/square-outline.svg';
import CheckedBoxSvg from '../../assets/img/check-square-outline.svg';
import {
  Box,
  IconButton,
  LinearProgress,
  Typography,
  useTheme,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import * as _ from 'lodash';
import { authActions } from '../../redux/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import Countdown from 'react-countdown';
import { SquareInputBox } from '../../components/SquareInputBox/SquareInputBox';
import ArrowLeftSVG from '../../assets/img/arrow-left-linear.svg';

interface OtpProps {
  email: string;
  password: string;
  clearPassword: () => void;
}

function Otp(props: OtpProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [skipOtp, setSkipOtp] = useState(false);
  const [verificationCode, setVerificationCode] = useState<string[]>(Array(6).fill(''));
  const inputRefs = useRef<HTMLInputElement[]>(Array(6).fill(null));

  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const isSubmitting = _.get(componentStatus, 'isSubmitting', false) as boolean;
  const verifyStatus = _.get(componentStatus, 'verifyStatus', undefined) as string; // undefined, verifying, failed
  const error = _.get(componentStatus, 'error', '') as string;
  const countdownStartTime = Number(_.get(componentStatus, 'countdownStartTime', Date.now().toString()));

  const handleVerificationCodeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const value = event.target.value;
    if (!isNaN(Number(value)) && index < 6) {
      const code = [...verificationCode];
      code[index] = event.target.value.slice(-1);
      setVerificationCode(code);

      if (value !== '' && index < 5) {
        inputRefs.current[index + 1].focus();
      }
      if (_.compact(code).length === 6 && index === 5) {
        dispatch(authActions.setComponentStatus({ isSubmitting: true, error: '', verifyStatus: 'verifying' }));
        dispatch(
          authActions.logInAsync({
            email: props.email,
            password: props.password,
            otp: code.join(''),
            otpSkip: skipOtp,
          }),
        );
      }
    }
  };
  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Backspace' && verificationCode[index] === '' && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleResendEmailOtp = () => {
    if (!isSubmitting) {
      setVerificationCode(verificationCode.fill(''));
      dispatch(authActions.setComponentStatus({ error: '', verifyStatus: undefined }));
      dispatch(authActions.sendEmailOtpAsync({ email: props.email, password: props.password }));
    }
  };

  const onClickBackButton = () => {
    dispatch(authActions.clearComponentStatus());
    props.clearPassword();
  };

  const keyMap: number[] = [0, 1, 2, 3, 4, 5];

  return (
    <CustomModal modal={false} maxWidth="340px" padding="0 32px 32px 32px">
      <Box display="flex" justifyContent="start" alignItems="center" marginBottom={2}>
        <IconButton role="back-btn" onClick={onClickBackButton} size="small">
          <Box component="img" src={ArrowLeftSVG}></Box>
        </IconButton>
        <Typography variant="h5" component="h5" textAlign="center" marginLeft={4}>
          Verify Your Identity
        </Typography>
      </Box>

      <Typography variant="bodyRegular" component="p" textAlign="center">
        A verification code has been sent to your email address. Please check your inbox and enter the code below:
      </Typography>
      <Box marginTop={4} display="flex" justifyContent="space-between" columnGap={1}>
        {verificationCode.map((e, index) => {
          return (
            <SquareInputBox
              theme={theme}
              height="56px"
              width="50px"
              autoComplete="off"
              disabled={verificationCode.filter((e) => e).length === 6 && verifyStatus === 'verifying'}
              verifystatus={verifyStatus}
              inputRef={(el) => (inputRefs.current[index] = el as HTMLInputElement)}
              autoFocus={index === 0}
              onKeyDown={(e) => handleKeyDown(index, e)}
              color={(() => {
                if (verifyStatus === undefined) {
                  return 'primary';
                } else if (verifyStatus === 'verifying') {
                  return 'success';
                } else {
                  return 'error';
                }
              })()}
              key={keyMap[index]}
              type="text"
              name={`n${index}`}
              fullWidth
              value={e}
              onChange={(event) => {
                handleVerificationCodeChange(event, index);
              }}
              variant="outlined"
            />
          );
        })}
      </Box>

      {error && (
        <Typography m={1} variant="bodyBold" component="p" textAlign="center" color="error">
          {error}
        </Typography>
      )}

      {isSubmitting && (
        <Box mt={2}>
          <LinearProgress color={verifyStatus === 'verifying' ? 'success' : 'primary'} />
        </Box>
      )}

      <Box marginTop={2} display="flex" justifyContent="center">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                icon={<Box component="img" src={CheckBoxSvg} />}
                checkedIcon={<Box component="img" src={CheckedBoxSvg} />}
                checked={skipOtp}
                onChange={(event) => {
                  setSkipOtp(event.target.checked);
                }}
              />
            }
            label="Don't request the OTP for next 30 days"
          />
        </FormGroup>
      </Box>

      <Box marginTop={2} display="flex" justifyContent="center" alignContent="center">
        <Countdown
          key={countdownStartTime}
          zeroPadTime={2}
          date={countdownStartTime + 60 * 1000}
          renderer={({ minutes, seconds, completed, formatted }) => {
            if (completed) {
              return (
                <>
                  <Typography variant="meta">Didn’t receive the OTP?&nbsp;</Typography>
                  <Typography
                    variant="button"
                    color={theme.palette.primary.main}
                    sx={{ cursor: 'pointer' }}
                    onClick={handleResendEmailOtp}
                  >
                    Resend
                  </Typography>
                </>
              );
            } else {
              return (
                <>
                  <Typography variant="meta" component="p" textAlign="center">
                    Time Remaining to Resend the OTP:&nbsp;
                  </Typography>
                  <Typography variant="meta" component="p" textAlign="start" width="50px">
                    {_.padStart(minutes.toString(), 2, '0')}:{_.padStart(seconds.toString(), 2, '0')}
                  </Typography>
                </>
              );
            }
          }}
        />
      </Box>
    </CustomModal>
  );
}

export default Otp;
