import React, { type ReactElement, useEffect, useRef, useState } from 'react';
import { TextField, Typography, Box, InputAdornment, IconButton, Theme, useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { authActions } from '../../redux/auth/authSlice';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { styled as materialStyled } from '@mui/material/styles';
import { persistor, RootState, store } from '../../redux/store';
import * as _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Otp from './OTP';
import { validation } from '../../schema/validation';

const CustomTextField = materialStyled(TextField)(({ theme }: { theme: Theme }) => ({
  width: '310px',
  '& .MuiInputBase-root': {
    height: '38px',
    borderRadius: '10px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '297px',
  },
}));

const CustomButton = materialStyled(LoadingButton)(({ theme }: { theme: Theme }) => ({
  borderRadius: '10px',
  marginBottom: '40px',
  width: '310px',
  height: '43px',
  padding: '12px 32px',
  gap: '10px',
  textAlign: 'center',
  textTransform: 'none',
  [theme.breakpoints.down('sm')]: {
    width: '297px',
  },
}));

const LogInBox = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor: 'white',
  width: '374px',
  // height:'388px',
  borderRadius: '20px',
  boxShadow: `0px 4px 32px 0px ${theme.palette.shades.shadow}`,
  [theme.breakpoints.down('sm')]: {
    width: '361px',
  },
}));

export default function Login(): ReactElement {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const loginBtn = useRef<HTMLButtonElement>(null);

  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const isOtpSet = _.get(componentStatus, 'isOtpSet', false) as boolean;
  const isSubmitting = _.get(componentStatus, 'isSubmitting', false) as boolean;
  const error = _.get(componentStatus, 'error', undefined);

  const enterKeyDownHandler = (event: { key: string; preventDefault: () => void }) => {
    if (event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (loginBtn.current) {
          loginBtn.current.click();
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', enterKeyDownHandler);
    return () => {
      document.removeEventListener('keydown', enterKeyDownHandler);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: validation.email,
      password: yup.string().required('Password is required'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({ email, password }, { setSubmitting }) => {
      store.dispatch({ type: 'CLEAR_STORE' });
      persistor.purge().then(() => {
        persistor.persist();
        dispatch(authActions.setComponentStatus({ isSubmitting: true, error: undefined }));
        dispatch(authActions.logInAsync({ email, password, otp: null, otpSkip: null }));
      });
    },
  });

  const clearPassword = () => {
    formik.setFieldValue('password', '');
  };

  return (
    <>
      {isOtpSet && <Otp email={formik.values.email} password={formik.values.password} clearPassword={clearPassword} />}
      {!isOtpSet && (
        <LogInBox>
          <Typography mt={2} variant="h5" gutterBottom fontSize="24px" fontWeight="500" textAlign="center">
            Login
          </Typography>
          <Box display="flex" flexDirection="column" justifyContent="space-around" alignItems="center" height="90%">
            <Box display="flex" flexDirection="column" alignItems="center">
              {error && (
                <Typography variant="meta" component="p" gutterBottom textAlign="center" color="error" paddingX={3.7}>
                  {error}
                </Typography>
              )}
              <Box mb={2} display="flex" flexDirection="column">
                <Typography mb={0.5} variant="meta">
                  Email{' '}
                  <Typography component="span" color="primary">
                    &nbsp;*
                  </Typography>
                </Typography>
                <CustomTextField
                  type="email"
                  name="email"
                  placeholder="username@mail.com"
                  fullWidth
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  variant="outlined"
                  FormHelperTextProps={{ style: { ...theme.typography.meta } }}
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography mb={0.5} variant="meta">
                  Password{' '}
                  <Typography component="span" color="primary">
                    &nbsp;*
                  </Typography>
                </Typography>
                <CustomTextField
                  name="password"
                  placeholder="password"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  variant="outlined"
                  FormHelperTextProps={{ style: { ...theme.typography.meta } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>

            <Box mt={5} columnGap={5} display="flex" flexDirection="column" justifyContent="space-around">
              <CustomButton
                ref={loginBtn}
                loading={isSubmitting}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => formik.handleSubmit()}
                endIcon={<ArrowForwardIcon fontSize="small" />}
              >
                Login
              </CustomButton>
              <Box mb={5} display="flex" justifyContent="center">
                <Link style={{ textDecoration: 'none' }} to={'/forgot-password'}>
                  <Typography variant="meta">Forgot Password</Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </LogInBox>
      )}
    </>
  );
}
