import { Fragment, useEffect, useState } from 'react';
import CustomModal from '../../../components/CustomModal/CustomModal';
import SquareSortHorizontalBoldDuotone from '../../../assets/img/square-sort-horizontal-bold-duotone.svg';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
  Autocomplete,
  TextField,
  Skeleton,
} from '@mui/material';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import CustomInputBox from '../../../components/CustomInputBox/CustomInputBox';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';
import { getAgeFromDateOfBirth } from '../../../utils/time-util';
import { patientActions } from '../../../redux/patient/patientSlice';
import { generalActions } from '../../../redux/general/generalSlice';
import { clinicianActions } from '../../../redux/clinician/clinicianSlice';

interface TransferPatientProps {
  userId?: string;
  open: boolean;
}

const TransferPatient = ({ userId, open }: TransferPatientProps) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [clinicianId, setClinicianId] = useState<string | undefined>(undefined);
  const dispatch = useAppDispatch();
  const [transferClinicianUserId, setTransferClinicianUserId] = useState<string | undefined>(undefined);

  const cliniciansDropdownList = useAppSelector((state: RootState) => state.clinician.cliniciansDropdownList);
  const patientsDetails = useAppSelector((state: RootState) => state.patient.patientsDetails);
  const patientIds = useAppSelector((state: RootState) => state.patient.patientIds);
  const transferClinician = useAppSelector((state: RootState) => state.clinician.selectedClinician);
  const componentStatus = useAppSelector((state: RootState) => state.patient.componentStatus);
  const isPasswordIncorrect = _.get(componentStatus, 'isPasswordIncorrect', false) as boolean;
  const transferPatientStep = _.get(componentStatus, 'transferPatientStep', false) as boolean;
  const confirmTransferStep = _.get(componentStatus, 'confirmTransferStep', false) as boolean;
  const isPatientTransfering = _.get(componentStatus, 'isPatientTransfering', false) as boolean;
  const isTransferSuccess = _.get(componentStatus, 'isTransferSuccess', false) as boolean;
  const isTransferLoading = _.get(componentStatus, 'isTransferLoading', false) as boolean;
  const error = _.get(componentStatus, 'error', '') as string;
  const currentClinicianId = useAppSelector((state: RootState) => state.patient.currentClinicianId);

  useEffect(() => {
    formik.setFieldValue('password', '');
    if (userId) {
      dispatch(
        generalActions.setComponentStatus({
          isPasswordIncorrect: false,
          isPatientTransfering: false,
          isTransferSuccess: false,
        }),
      );
    }
  }, [userId]);

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: yup.object({
      password: yup.string().required('Password is required'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({ password }) => {
      dispatch(
        generalActions.setComponentStatus({
          isPasswordIncorrect: false,
          isPatientTransfering: true,
          error: '',
        }),
      );
      dispatch(patientActions.transferPatientAsync({ clinicianId, password, patientsId: patientIds }));
    },
  });

  const handleDismiss = () => {
    formik.setFieldValue('password', '');
    dispatch(patientActions.setComponentStatus({ deactivateClinicianModalOpen: false }));
    dispatch(patientActions.setComponentStatus({ isTransferPatientModalOpen: false }));
    dispatch(patientActions.setComponentStatus({ error: '' }));
    setClinicianId(undefined);
    setTransferClinicianUserId(undefined);
    dispatch(patientActions.setCurrentClinicianId(undefined));
    setShowPassword(false);
  };

  const handleTransfer = () => {
    if (transferClinicianUserId !== undefined) {
      dispatch(patientActions.setComponentStatus({ isTransferLoading: true }));
      dispatch(patientActions.setComponentStatus({ transferPatientStep: false }));
      dispatch(patientActions.setComponentStatus({ confirmTransferStep: true }));
      dispatch(clinicianActions.getClinicianByUserIdAsync(transferClinicianUserId));
      dispatch(patientActions.setComponentStatus({ error: '' }));
    } else {
      dispatch(patientActions.setComponentStatus({ error: 'Please select a Clinician' }));
    }
  };

  const handleSuccessDismiss = () => {
    setClinicianId(undefined);
    setTransferClinicianUserId(undefined);
    formik.setFieldValue('password', '');
    dispatch(patientActions.clearComponentStatus());
    dispatch(patientActions.setCurrentClinicianId(undefined));
    setShowPassword(false);
  };

  const handleBack = () => {
    dispatch(patientActions.setComponentStatus({ confirmTransferStep: false }));
    dispatch(patientActions.setComponentStatus({ transferPatientStep: true }));
    dispatch(patientActions.setComponentStatus({ error: '' }));
    dispatch(patientActions.setComponentStatus({ isTransferLoading: false }));
  };

  const titleWhenNotSuccess = confirmTransferStep ? 'Confirm Patient Transfer' : 'Transfer Patient';
  let title = isTransferSuccess ? 'Patient Transferred' : titleWhenNotSuccess;

  return (
    <Fragment>
      <CustomModal
        modal={true}
        open={open}
        width="374px"
        btnText={isTransferSuccess ? 'Dismiss' : ''}
        handleButtonOnClick={handleSuccessDismiss}
        title={title}
        icon={SquareSortHorizontalBoldDuotone}
      >
        <Stack>
          {transferPatientStep && (
            <Typography mb={2} variant="bodyRegular" component="p" textAlign="center" color={theme.palette.info.main}>
              {patientIds?.length > 1
                ? `Please select a clinician to transfer the selected ${patientIds.length} patients`
                : 'Please select a clinician to transfer the following patient'}
            </Typography>
          )}
          {confirmTransferStep && (
            <Typography mb={2} variant="bodyRegular" component="p" textAlign="center" color={theme.palette.info.main}>
              {patientIds?.length > 1
                ? `Selected ${patientIds.length} patients will be transferred to `
                : `${patientsDetails?.firstName} ${patientsDetails?.lastName} will be transferred to the following clinician`}
            </Typography>
          )}
          {isTransferSuccess && (
            <Typography mb={2} variant="bodyRegular" component="p" textAlign="center" color={theme.palette.info.main}>
              {patientIds?.length > 1
                ? `Selected ${patientIds.length}  patients successfully  transferred to `
                : `${patientsDetails?.firstName} ${patientsDetails?.lastName} successfully transferred to the following clinician`}
            </Typography>
          )}
          {transferPatientStep && patientIds.length === 1 && (
            <Fragment>
              <Box display="flex" justifyContent="center" mt={4} data-testid="patient-avatar">
                <AvatarEdit
                  isLoading={isTransferLoading}
                  editable={false}
                  size="72px"
                  name={patientsDetails?.firstName}
                  name2={patientsDetails?.lastName}
                  avatar={patientsDetails?.avatar}
                  font={theme.typography.h3}
                />
              </Box>
              {isTransferLoading && (
                <Fragment>
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: '1rem', width: 100, margin: '32px auto 0' }}
                  />
                  <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: 170, margin: '2px auto' }} />
                  <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: 120, margin: '2px auto' }} />
                  <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: 170, margin: '2px auto' }} />
                </Fragment>
              )}
              {!isTransferLoading && (
                <Fragment>
                  <Typography mt={4} component="p" variant="h6" align="center" color={theme.palette.info.main}>
                    {`${patientsDetails?.firstName} ${patientsDetails?.lastName}`}
                  </Typography>
                  <Typography
                    mt={0.5}
                    component="p"
                    variant="bodyRegular"
                    align="center"
                    color={theme.palette.info.main}
                  >
                    {patientsDetails?.email}
                  </Typography>
                  <Typography
                    mt={0.5}
                    component="p"
                    variant="bodyRegular"
                    align="center"
                    color={theme.palette.info.main}
                  >
                    {patientsDetails?.phone}
                  </Typography>
                  <Typography
                    mt={0.5}
                    component="p"
                    variant="bodyRegular"
                    align="center"
                    color={theme.palette.info.main}
                  >
                    {`${getAgeFromDateOfBirth(patientsDetails?.birthday)} years old, ${patientsDetails?.gender}`}
                  </Typography>
                </Fragment>
              )}
            </Fragment>
          )}

          {(confirmTransferStep || isTransferSuccess) && (
            <Fragment>
              <Box display="flex" justifyContent="center" mt={4} data-testid="clinician-avatar">
                <AvatarEdit
                  isLoading={isTransferLoading}
                  editable={false}
                  size="72px"
                  name={transferClinician?.firstName}
                  name2={transferClinician?.lastName}
                  avatar={transferClinician?.avatar}
                  font={theme.typography.h3}
                />
              </Box>
              {isTransferLoading && (
                <Fragment>
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: '1rem', width: 100, margin: '32px auto 0' }}
                  />
                  <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: 170, margin: '2px auto' }} />
                  <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: 120, margin: '2px auto' }} />
                  <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: 170, margin: '2px auto' }} />
                  <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: 120, margin: '2px auto' }} />
                </Fragment>
              )}
              {!isTransferLoading && (
                <Fragment>
                  <Typography mt={4} component="p" variant="h6" align="center">
                    {`${transferClinician?.firstName} ${transferClinician?.lastName}`}
                  </Typography>
                  <Typography
                    mt={0.5}
                    component="p"
                    variant="bodyRegular"
                    align="center"
                    color={theme.palette.info.main}
                  >
                    {transferClinician?.email}
                  </Typography>
                  <Typography
                    mt={0.5}
                    component="p"
                    variant="bodyRegular"
                    align="center"
                    color={theme.palette.info.main}
                  >
                    {transferClinician?.phone}
                  </Typography>
                  <Typography
                    mt={0.5}
                    component="p"
                    variant="bodyRegular"
                    align="center"
                    color={theme.palette.info.main}
                  >
                    {`${getAgeFromDateOfBirth(transferClinician?.birthday)} years old`}
                  </Typography>
                  <Typography
                    mt={0.5}
                    component="p"
                    variant="bodyRegular"
                    align="center"
                    color={theme.palette.info.main}
                  >
                    {`${transferClinician?.patientCount} Patients`}
                  </Typography>
                </Fragment>
              )}
            </Fragment>
          )}

          <Box mt={2} />
          {confirmTransferStep && (
            <CustomInputBox
              label="Please enter your password"
              labelAlign={'center'}
              placeholder=""
              required={true}
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={(formik.touched.password && Boolean(formik.errors.password)) || isPasswordIncorrect}
              helperText={formik.errors.password || (isPasswordIncorrect && 'Incorrect password!')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}

          {transferPatientStep && (
            <Autocomplete
              id="clinician-select"
              size="small"
              getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
              onChange={(event, value) => {
                setTransferClinicianUserId(value?.userId);
                setClinicianId(value?.clinicianId);
              }}
              value={_.find(cliniciansDropdownList, { userId: transferClinicianUserId }) ?? null}
              options={cliniciansDropdownList.filter((clinician) => clinician.clinicianId !== currentClinicianId)}
              sx={{ '& .MuiInputBase-root': { alignContent: 'center', height: '40px', borderRadius: '10px' } }}
              renderInput={(params) => <TextField {...params} name="clinicians" label="Clinician" variant="outlined" />}
              renderOption={(props, option, state, ownerState) => {
                const fullName = `${option.firstName} ${option.lastName}`;
                return (
                  <Box component="li" display="flex" columnGap={1} {...props}>
                    <AvatarEdit
                      editable={false}
                      size="24px"
                      name={option.firstName}
                      name2={option.lastName}
                      font={theme.typography.subtitle1}
                    />
                    <Typography variant="bodyRegular" component="p">
                      {fullName}
                    </Typography>
                  </Box>
                );
              }}
            />
          )}
          {error && !isPasswordIncorrect && (
            <Grid item xs={12}>
              <Typography mb={1} variant="bodyRegular" component="p" textAlign="center" color="error">
                {error}
              </Typography>
            </Grid>
          )}
          {!isTransferSuccess && (
            <Grid container marginTop={3} columnSpacing={2}>
              {transferPatientStep && (
                <>
                  <Grid item xs={6}>
                    <LoadingButton
                      variant="outlined"
                      onClick={handleDismiss}
                      fullWidth
                      sx={{ borderRadius: '10px', height: '43px' }}
                    >
                      Dismiss
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={6}>
                    <LoadingButton
                      loading={isPatientTransfering}
                      variant="contained"
                      fullWidth
                      onClick={handleTransfer}
                      sx={{ borderRadius: '10px', height: '43px' }}
                    >
                      Transfer
                    </LoadingButton>
                  </Grid>
                </>
              )}
              {confirmTransferStep && (
                <>
                  <Grid item xs={6}>
                    <LoadingButton
                      variant="outlined"
                      onClick={handleBack}
                      fullWidth
                      sx={{ borderRadius: '10px', height: '43px' }}
                    >
                      Back
                    </LoadingButton>
                  </Grid>

                  <Grid item xs={6}>
                    <LoadingButton
                      loading={isPatientTransfering}
                      onClick={() => formik.handleSubmit()}
                      variant="contained"
                      fullWidth
                      sx={{ borderRadius: '10px', height: '43px' }}
                    >
                      Confirm
                    </LoadingButton>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Stack>
      </CustomModal>
    </Fragment>
  );
};

export default TransferPatient;
