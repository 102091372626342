import { AxiosResponse } from 'axios';
import { backendApiInstance } from './backendApiInstance';
import { SortOrder } from '../utils/enums/sort-order.enum';

export interface GetAllAdminsParamType {
  skip: number;
  limit: number;
  sortBy: string;
  sortOrder: SortOrder;
}

export interface AdminDataProp {
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  permissionIds: number[];
  userId?: string;
}

export async function getPermissions(role: string): Promise<AxiosResponse> {
  return backendApiInstance.get(`admins/permissions`, { params: { role } });
}

export async function getAllAdmins({ skip, limit, sortBy, sortOrder }: GetAllAdminsParamType): Promise<AxiosResponse> {
  return backendApiInstance.get('admins', {
    params: { skip, limit, sortBy, sortOrder },
  });
}
export async function getAdminByUserId(userId: string): Promise<AxiosResponse> {
  return backendApiInstance.get(`admins/${userId}`);
}

export async function createAdmin(adminData: AdminDataProp): Promise<AxiosResponse> {
  return backendApiInstance.post(`admins/true-silence`, { ...adminData });
}

export async function updateAdmin(adminData: AdminDataProp): Promise<AxiosResponse> {
  return backendApiInstance.put(`admins/true-silence`, { ...adminData });
}

export async function deleteAdmin(userId: string, password: string): Promise<AxiosResponse> {
  return backendApiInstance.delete(`admins/true-silence`, { data: { userId, password } });
}

export async function getDashboardInfo(): Promise<AxiosResponse> {
  return backendApiInstance.get('admins/dashboard-details');
}
