import React, { MouseEvent } from 'react';
import { Box, Typography, useTheme, FormControlLabel, Checkbox } from '@mui/material';
import CheckBoxSvg from '../../assets/img/square-outline.svg';
import CheckedBoxSvg from '../../assets/img/check-square-outline.svg';

interface CustomCheckBoxProps {
  index: number;
  label: string;
  name: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  isChecked?: boolean;
  disabled?: boolean;
}

function CustomCheckBox({ index, label, name, onClick, isChecked = false, disabled = false }: CustomCheckBoxProps) {
  const theme = useTheme();
  let color = '';
  if (disabled) {
    color = theme.palette.grey[400];
  } else if (isChecked) {
    color = theme.palette.primary.main;
  } else {
    color = theme.palette.info.main;
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={<Box component="img" src={CheckBoxSvg} />}
          checkedIcon={<Box component="img" src={CheckedBoxSvg} />}
          checked={isChecked}
        />
      }
      label={
        <Typography
          variant="bodyBold"
          sx={{
            color,
            fontWeight: isChecked ? 500 : 400,
          }}
        >
          {label}
        </Typography>
      }
      name={name}
      onClick={(event) => {
        if (!disabled && onClick) {
          onClick(event);
        }
      }}
      disabled={disabled}
      key={index}
    />
  );
}

export default CustomCheckBox;
