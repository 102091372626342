import React, { Fragment, useEffect, useState } from 'react';
import CustomModal from '../../CustomModal/CustomModal';
import ClinicTransferIconApprove from '../../../assets/img/square-sort-horizontal-bold-duotone.svg';
import ClinicTransferIconReject from '../../../assets/img/square-sort-horizontal-bold-duotone-red.svg';
import GreenCheckCircleSVG from '../../../assets/img/check-circle-bold-duotone.svg';
import RedCheckCircleSVG from '../../../assets/img/check-circle-bold-duotone-red.svg';
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import CustomInputBox from '../../CustomInputBox/CustomInputBox';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';
import AvatarEdit from '../../AvatarEdit/AvatarEdit';
import { useAuth } from '../../../context/AuthContext';
import { useDispatch } from 'react-redux';
import { notificationAction } from '../../../redux/notification/notificationSlice';
import LoadingTypography from '../../LoadingTypography/LoadingTypography';
import { CliniciansDropdownItemType, clinicianActions } from '../../../redux/clinician/clinicianSlice';
import { NotificationStatus } from '../../../utils/enums/notification-status.enum';
import { patientActions } from '../../../redux/patient/patientSlice';

const C2CTransterApproveRejectModal = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [popupTitle, setPopupTitle] = useState('Approve Clinic Transfer');
  const [modalIcon, setModalIcon] = useState<string | undefined>(undefined);
  const [selectedClinician, setClinician] = useState<CliniciansDropdownItemType | undefined>(undefined);

  const componentStatus = useAppSelector((state: RootState) => state.notification.componentStatus);
  const cliniciansDropdownList = useAppSelector((state: RootState) => state.clinician.cliniciansDropdownList);
  const pendingC2CTranferDetails = useAppSelector((state: RootState) => state.patient.pendingC2CTranferDetails);
  const isPasswordIncorrect = _.get(componentStatus, 'isPasswordIncorrect', false) as boolean;
  const isC2cSubmitting = _.get(componentStatus, 'isC2cSubmitting', false) as boolean;
  const isConfirmStepSuccess = _.get(componentStatus, 'isConfirmStepSuccess', false) as boolean;
  const isC2CModalOpen = _.get(componentStatus, 'isC2CModalOpen', false) as boolean;
  const isC2CinfoLoding = _.get(componentStatus, 'isC2CinfoLoding', false) as boolean;
  const error = _.get(componentStatus, 'error', '') as string;
  const notificationStatusC2C = _.get(componentStatus, 'notificationStatusC2C', '') as NotificationStatus | '';
  const notificationId = _.get(componentStatus, 'notificationId', '') as string;

  useEffect(() => {
    if (isC2CModalOpen) {
      if (notificationStatusC2C === NotificationStatus.REJECT) {
        setPopupTitle('Reject Clinic Transfer');
        setModalIcon(ClinicTransferIconReject);
      } else {
        setPopupTitle('Approve Clinic Transfer');
        setModalIcon(ClinicTransferIconApprove);
      }
      dispatch(clinicianActions.getAllCliniciansInClinicAsync());
    } else {
      formik.resetForm();
    }
  }, [user, isC2CModalOpen]);

  useEffect(() => {
    if (isConfirmStepSuccess) {
      if (notificationStatusC2C === NotificationStatus.REJECT) {
        setPopupTitle('Transfer Request Rejected');
        setModalIcon(RedCheckCircleSVG);
      } else {
        setPopupTitle('Transfer Request Approved');
        setModalIcon(GreenCheckCircleSVG);
      }
    }
  }, [isConfirmStepSuccess]);

  const formik = useFormik({
    initialValues: {
      password: '',
      ...(notificationStatusC2C === NotificationStatus.APPROVE && { clinicianId: '' }),
    },
    validationSchema: yup.object({
      password: yup.string().required('Incorrect password, please enter the correct password!'),
      ...(notificationStatusC2C === NotificationStatus.APPROVE && {
        clinicianId: yup.string().required('Please select a clinician to continue'),
      }),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({ password, clinicianId }) => {
      if (notificationStatusC2C === NotificationStatus.APPROVE) {
        const selectedClinicianDetails = cliniciansDropdownList.find(
          (clinician) => clinician.clinicianId === clinicianId,
        );
        setClinician(selectedClinicianDetails);
        dispatch(
          patientActions.C2CTransferApproveAsync({
            password,
            clinicianId: clinicianId as string,
            notificationId,
          }),
        );
      } else if (notificationStatusC2C === NotificationStatus.REJECT) {
        dispatch(
          patientActions.C2CTransferRejectAsync({
            password,
            notificationId,
          }),
        );
      }
    },
  });

  const handleDismiss = () => {
    dispatch(notificationAction.clearComponentStatus());
    setPopupTitle('');
    setShowPassword(false);
  };

  return (
    <Fragment>
      <CustomModal
        modal={true}
        open={isC2CModalOpen}
        width="374px"
        btnText={isConfirmStepSuccess ? 'Dismiss' : ''}
        handleButtonOnClick={handleDismiss}
        handleClose={handleDismiss}
        title={popupTitle}
        icon={modalIcon}
      >
        {!isConfirmStepSuccess && (
          <Stack display={'flex'} color={theme.palette.black.main} marginTop={'36px'}>
            <Typography component="p" variant="meta" align="center" mb={1}>
              TSPID
            </Typography>
            <LoadingTypography
              isLoading={isC2CinfoLoding}
              component="p"
              variant="bodyRegular"
              color={theme.palette.info.main}
              align="center"
            >
              {pendingC2CTranferDetails?.clinicBaseId}
            </LoadingTypography>
            <Typography component="p" variant="meta" align="center" mt={2}>
              Current Clinic
            </Typography>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} gap={'4px'} mt={1}>
              <AvatarEdit
                isLoading={isC2CinfoLoding}
                avatar={pendingC2CTranferDetails?.clinic.avatar}
                name={pendingC2CTranferDetails?.clinic.name}
                size={'24px'}
                editable={false}
                font={theme.typography.bodyRegular}
              ></AvatarEdit>
              <LoadingTypography
                isLoading={isC2CinfoLoding}
                component="p"
                variant="bodyRegular"
                align="center"
                sx={{ paddingLeft: 1, width: isC2CinfoLoding ? '150px' : 'inherit' }}
              >
                {pendingC2CTranferDetails?.clinic.name}
              </LoadingTypography>
            </Box>
            <Stack marginTop={'36px'}>
              <CustomInputBox
                label="Please enter your password"
                labelAlign={'center'}
                placeholder=""
                required={true}
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.password && Boolean(formik.errors.password)) || isPasswordIncorrect}
                helperText={
                  (formik.touched.password && formik.errors.password) || (isPasswordIncorrect && 'Incorrect password!')
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {notificationStatusC2C === NotificationStatus.APPROVE && (
                <Fragment>
                  <Typography variant="meta" component="p" align="center" mt={2}>
                    Clinician
                    <Typography component="span" color="primary">
                      &nbsp;*
                    </Typography>
                  </Typography>
                  <Autocomplete
                    id="clinician-input"
                    size="small"
                    getOptionLabel={(option) => `${option.clinicBaseId} - ${option.firstName} ${option.lastName}`}
                    onChange={(event, value) => {
                      formik.setFieldValue('clinicianId', value ? value.clinicianId : '');
                    }}
                    options={cliniciansDropdownList}
                    sx={{
                      '& .MuiInputBase-root': {
                        alignContent: 'center',
                        height: '40px',
                        borderRadius: '10px',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Clinician" name="clinicians" variant="outlined" />
                    )}
                    renderOption={(props, option, state, ownerState) => {
                      const fullName = `${option.clinicBaseId} - ${option.firstName} ${option.lastName}`;
                      return (
                        <Box component="li" display="flex" columnGap={1} {...props}>
                          <AvatarEdit
                            editable={false}
                            size="24px"
                            avatar={option.avatar}
                            name={option.firstName}
                            name2={option.lastName}
                            font={theme.typography.subtitle1}
                          />
                          <Typography variant="bodyRegular" component="p">
                            {fullName}
                          </Typography>
                        </Box>
                      );
                    }}
                  />
                  {Boolean(formik.errors.clinicianId) && (
                    <Typography paddingLeft={2} variant="meta" component="p" color="error">
                      {formik.errors.clinicianId}
                    </Typography>
                  )}
                </Fragment>
              )}
            </Stack>
            {error && !isPasswordIncorrect && (
              <Grid item mt={2} xs={12}>
                <Typography mb={1} variant="meta" component="p" textAlign="center" color="error">
                  {error}
                </Typography>
              </Grid>
            )}
            <Grid container marginTop={'36px'} columnSpacing={2}>
              <Grid item xs={6}>
                <LoadingButton
                  variant="outlined"
                  onClick={handleDismiss}
                  fullWidth
                  sx={{ borderRadius: '10px', height: '43px' }}
                >
                  Dismiss
                </LoadingButton>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton
                  disabled={isC2CinfoLoding}
                  loading={isC2cSubmitting}
                  onClick={() => formik.handleSubmit()}
                  variant="contained"
                  fullWidth
                  sx={{ borderRadius: '10px', height: '43px' }}
                >
                  Confirm
                </LoadingButton>
              </Grid>
            </Grid>
          </Stack>
        )}
        {isConfirmStepSuccess && notificationStatusC2C === NotificationStatus.APPROVE && (
          <Stack display={'flex'} color={theme.palette.black.main} marginTop={'36px'}>
            <Typography component="p" variant="bodyRegular" color={theme.palette.info.main} align="center" mb={1}>
              Patient assigned to
            </Typography>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} gap={'4px'} mt={1}>
              <AvatarEdit
                isLoading={isC2CinfoLoding}
                avatar={selectedClinician?.avatar}
                name={selectedClinician?.firstName}
                name2={selectedClinician?.lastName}
                size={'24px'}
                editable={false}
                font={theme.typography.bodyRegular}
              ></AvatarEdit>
              <LoadingTypography
                isLoading={isC2CinfoLoding}
                component="p"
                variant="bodyRegular"
                align="center"
                sx={{ paddingLeft: 1, width: isC2CinfoLoding ? '150px' : 'inherit' }}
              >
                {`${selectedClinician?.firstName} ${selectedClinician?.lastName}`}
              </LoadingTypography>
            </Box>
          </Stack>
        )}
      </CustomModal>
    </Fragment>
  );
};

export default C2CTransterApproveRejectModal;
