import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { AddSoundParamType, soundActions } from './soundSlice';
import {
  addSound,
  addSoundMinio,
  GetAllSoundParamType,
  getSoundList,
  getSoundById,
  deleteSound,
  addAlbumArtMinio,
  getSoundPresignedUrl,
} from '../../api/soundApi';
import { AxiosResponse } from 'axios';
import { authActions } from '../auth/authSlice';
import * as _ from 'lodash';

function* watchGetSoundList({ payload }: { payload: GetAllSoundParamType }) {
  yield put(soundActions.setComponentStatus({ isSoundListListLoading: true }));
  try {
    const res: AxiosResponse = yield call(getSoundList, payload);
    yield put(soundActions.setSoundCount(res.data['total']));
    yield put(soundActions.setSoundList(res.data['data']));
  } catch (err) {}
  yield put(soundActions.setComponentStatus({ isSoundListListLoading: false }));
}

function* watchGetDeleteSound({ payload }: { payload: string }) {
  yield put(soundActions.setComponentStatus({ isDeleteSoundLoading: true }));
  try {
    const res: AxiosResponse = yield call(getSoundById, payload);
    yield put(soundActions.setDeleteSoundTrack(res.data));
    yield put(soundActions.setComponentStatus({ isDeleteSoundLoading: false }));
  } catch (err) {}
}

function* watchDeleteSound({ payload }: { payload: { soundId: string; password: string } }) {
  yield put(soundActions.setComponentStatus({ isSoundDeleting: true }));
  try {
    yield call(deleteSound, payload);
    yield put(
      soundActions.setComponentStatus({
        isSoundDeleting: false,
        isSoundDeleteSuccess: true,
        isSoundListRefreshed: true,
      }),
    );
  } catch (err: any) {
    if (err.response.status === 403 && _.get(err, 'response.data.message', '').toLowerCase().includes('password')) {
      yield put(soundActions.setComponentStatus({ isPasswordIncorrect: true }));
    }
    yield put(
      soundActions.setComponentStatus({
        isSoundDeleting: false,
        error: _.get(err, 'response.data.message', 'Something went wrong'),
      }),
    );
  }
}

function* watchAddSound({ payload }: { payload: AddSoundParamType }) {
  try {
    const response: AxiosResponse = yield call(addSoundMinio, payload);
    const { fileName } = response.data;
    let data = { ...payload.soundData, fileName };
    if (payload.image) {
      const responseAlbumArt: AxiosResponse = yield call(addAlbumArtMinio, payload);
      const albumArtFileName = responseAlbumArt.data.fileName;
      data.albumArtFileName = albumArtFileName;
    }

    yield call(addSound, data);
    yield put(soundActions.setComponentStatus({ isSubmitSoundSuccess: true, isSoundListRefreshed: true }));
  } catch (err: any) {
    if (err.response.status === 403 && _.get(err, 'response.data.message', '').toLowerCase().includes('password')) {
      yield put(authActions.setComponentStatus({ isPasswordIncorrect: true }));
    }
    yield put(authActions.setComponentStatus({ error: _.get(err, 'response.data.message', 'Something went wrong') }));
  }
  yield put(soundActions.setComponentStatus({ isSoundSubmitting: false }));
}

function* watchGetSoundPresignedUrl({ payload }: { payload: { soundId: string; soundName: string } }) {
  yield put(soundActions.setComponentStatus({ isPresignedUrlLoading: true }));
  try {
    const res: AxiosResponse = yield call(getSoundPresignedUrl, payload.soundName);
    yield put(soundActions.setSoundPresignedUrl({ soundId: payload.soundId, url: _.get(res, 'data.url') }));
    yield put(soundActions.setComponentStatus({ lastPresignedUrlCreatedAt: Date.now().toString() }));
  } catch (err) {
    // pass
  }
  yield put(soundActions.setComponentStatus({ isPresignedUrlLoading: false }));
}

export function* watchSoundSagas(): Generator<ForkEffect, void> {
  yield takeLatest(soundActions.getSoundListAsync, watchGetSoundList);
  yield takeLatest(soundActions.getDeleteSoundAsync, watchGetDeleteSound);
  yield takeLatest(soundActions.deleteSoundAsync, watchDeleteSound);
  yield takeLatest(soundActions.addSoundTrackAsync, watchAddSound);
  yield takeLatest(soundActions.getSoundPresignedUrlAsync, watchGetSoundPresignedUrl);
}

const soundSagas = watchSoundSagas;
export default soundSagas;
