import React, { useState, Fragment, useEffect } from 'react';
import { useTheme, Box, Typography, Stack, IconButton, Grid, Skeleton } from '@mui/material';
import CustomTable, { ColumnProps } from '../../components/CustomTable/CustomTable';
import AvatarEdit from '../../components/AvatarEdit/AvatarEdit';
import EyeBoldSVG from '../../assets/img/eye-bold.svg';
import SolarPenBoldSVG from '../../assets/img/solar_pen-bold.svg';
import TrashBinBoldSVG from '../../assets/img/trash-bin-2-bold.svg';
import CustomModal from '../../components/CustomModal/CustomModal';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import * as _ from 'lodash';
import { adminActions, AdminType } from '../../redux/admin/adminSlice';
import { useAuth } from '../../context/AuthContext';
import { Roles } from '../../utils/enums/roles.enum';
import AddEditAdmin from './Add/AddEditAdmin';
import { authActions } from '../../redux/auth/authSlice';
import AdminDelete from './AdminDelete/AdminDelete';
import { SIDEBAR_OPTIONS } from '../../utils/sidebar-schema';
import { SidebarOption } from '../../utils/enums/sidebar-option.enum';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { SortOrder } from '../../utils/enums/sort-order.enum';
import { useSearchParams } from 'react-router-dom';

function ManageAdmins() {
  const theme = useTheme();
  const admin = useAppSelector((state: RootState) => state.admin);
  const dispatch = useAppDispatch();
  const { user } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page');
  const sortByParam = searchParams.get('sort-by');
  const rowsPerPageParam = searchParams.get('rows-per-page');
  const sortOrderParam = searchParams.get('sort-order');

  const [page, setPage] = useState<number>(pageParam ? parseInt(pageParam) : 1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageParam ? parseInt(rowsPerPageParam) : 10);
  const [sortBy, setSortBy] = useState<string>(sortByParam ?? 'globalId');
  const [sortOrder, setSortOrder] = useState<SortOrder>((sortOrderParam as SortOrder) ?? SortOrder.DESC);
  const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
  const [openAdmimDetailsModal, setOpenAdmimDetailsModal] = useState<boolean>(false);
  const [deleteUserId, setDeleteUserId] = useState<string>('');
  const [selectedUserId, setSelectedUserId] = useState<string>('');

  const totalAdminCount = useAppSelector((state: RootState) => state.admin.totalAdminCount);
  const adminList = useAppSelector((state: RootState) => state.admin.adminList);
  const selectedAdmin = useAppSelector((state: RootState) => state.admin.selectedAdmin);

  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const shouldAdminListLoad = _.get(componentStatus, 'shouldAdminListLoad', '');
  const isTableLoading = _.get(componentStatus, 'isTableLoading', false) as boolean;
  const isSelectedAdminLoading = _.get(componentStatus, 'isSelectedAdminLoading', true) as boolean;
  const deleteModalOpen = _.get(componentStatus, 'deleteModalOpen', false) as boolean;

  useEffect(() => {
    dispatch(
      authActions.setTopBarView({
        text: SIDEBAR_OPTIONS[SidebarOption.MANAGE_ADMINS].name,
        path: SIDEBAR_OPTIONS[SidebarOption.MANAGE_ADMINS].path,
        sideMenuPath: SIDEBAR_OPTIONS[SidebarOption.MANAGE_ADMINS].path,
        button:
          user.role === Roles.SUPER_ADMIN
            ? {
                text: 'Add New Admin',
                icon: <AddCircleOutlineIcon fontSize="small" />,
                color: 'primary',
                onClick: () => {
                  dispatch(adminActions.setAddAdminPopupClose(true));
                },
              }
            : undefined,
      }),
    );
  }, []);

  useEffect(() => {
    if (!isAddOpen) {
      setSelectedUserId('');
    }
  }, [isAddOpen]);

  useEffect(() => {
    setIsAddOpen(admin.isAdminPopupOpen);
  }, [admin.isAdminPopupOpen]);

  useEffect(() => {
    setSearchParams({
      page: page.toString(),
      'rows-per-page': rowsPerPage.toString(),
      'sort-by': sortBy,
      'sort-order': sortOrder,
    });
    const skip = (page - 1) * rowsPerPage;
    dispatch(adminActions.getAdminListAsync({ skip, limit: rowsPerPage, sortBy, sortOrder }));
  }, [page, rowsPerPage, sortBy, sortOrder, shouldAdminListLoad]);

  const columns: ColumnProps[] = [
    { key: 'globalId', name: 'No.', enableSort: true, align: 'left', width: '5%', minWidth: 70, resizable: true },
    { key: 'name', name: 'Name', enableSort: true, align: 'left', width: 'auto', minWidth: 160, resizable: true },
    { key: 'email', name: 'Email', enableSort: false, align: 'left', width: 'auto', minWidth: 140, resizable: true },
    { key: 'phone', name: 'Phone', enableSort: false, align: 'left', width: 'auto', minWidth: 80, resizable: true },
    { key: 'action', name: '', enableSort: false, align: 'right', width: '10%', minWidth: 100, resizable: false },
  ];

  const getFormattedAdminList = (adminList: AdminType[]) => {
    return adminList.map((admin) => {
      return {
        globalId: admin.globalId,
        name: (
          <Box display="flex" gap={1}>
            <AvatarEdit
              editable={false}
              avatar={admin.avatar}
              name={admin.firstName}
              name2={admin.lastName}
              size="24px"
              font={theme.typography.subtitle1}
            />
            <Typography variant="bodyRegular">{`${admin.firstName} ${admin.lastName}`} </Typography>
          </Box>
        ),
        email: admin.email,
        phone: admin.phone,
        action: (
          <Stack direction="row" justifyContent="center" spacing={1}>
            <IconButton onClick={() => handleViewAdminDetails(admin.userId)} size="small">
              <Box component="img" src={EyeBoldSVG}></Box>
            </IconButton>
            {user.role === Roles.SUPER_ADMIN && (
              <IconButton
                onClick={() => {
                  handleEditAdmin(admin.userId);
                }}
                size="small"
              >
                <Box component="img" src={SolarPenBoldSVG}></Box>
              </IconButton>
            )}
            {user.role === Roles.SUPER_ADMIN && (
              <IconButton
                onClick={() => {
                  handleDeleteAdmin(admin.userId);
                }}
                size="small"
              >
                <Box component="img" src={TrashBinBoldSVG}></Box>
              </IconButton>
            )}
          </Stack>
        ),
      };
    });
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setRowsPerPage(rowsPerPage);
    setPage(1);
  };

  const handleEditAdmin = (adminId: string) => {
    setSelectedUserId(adminId);
    dispatch(adminActions.setAddAdminPopupClose(true));
    dispatch(adminActions.getAdminByUserIdAsync(adminId));
  };

  const handleViewAdminDetails = (userId: string) => {
    setOpenAdmimDetailsModal(true);
    dispatch(adminActions.getAdminByUserIdAsync(userId));
  };

  const handleDismiss = () => {
    setOpenAdmimDetailsModal(false);
    setTimeout(() => {
      dispatch(adminActions.setSelectedAdmin(undefined));
    }, 500);
  };

  const handleDeleteAdmin = (userId: string) => {
    dispatch(authActions.clearComponentStatus());
    setOpenAdmimDetailsModal(false);
    setDeleteUserId(userId);
    dispatch(authActions.setComponentStatus({ deleteModalOpen: true }));
  };

  return (
    <Fragment>
      <CustomTable
        isLoading={isTableLoading}
        totalDataRows={totalAdminCount}
        enablePagination={true}
        selectedPage={page}
        enablePagePerRows={true}
        rowsPerList={[10, 20, 50, 100]}
        selectedRowsPerPage={rowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        columns={columns}
        rows={getFormattedAdminList(adminList)}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSortByChange={setSortBy}
        handleSortOrderChange={setSortOrder}
        minBodyRowSpace={10}
      />

      <CustomModal
        modal={true}
        open={openAdmimDetailsModal}
        width="374px"
        title={selectedAdmin && `${selectedAdmin?.firstName} ${selectedAdmin?.lastName}`}
      >
        <Stack
          display={isSelectedAdminLoading ? 'flex' : 'none'}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Skeleton variant="circular" animation="wave" width={72} height={72} />
          <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: '60%', mt: 2 }} />
          <Skeleton variant="rounded" animation="wave" width="90%" height={90} sx={{ mt: 3 }} />
        </Stack>

        <Stack
          display={isSelectedAdminLoading ? 'none' : 'flex'}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <AvatarEdit
            editable={false}
            avatar={selectedAdmin ? selectedAdmin.avatar : ''}
            name={selectedAdmin?.firstName}
            name2={selectedAdmin?.lastName}
            size="72px"
            font={theme.typography.h3}
          />
          <Typography mt={2} component="p" variant="bodyRegular" color={theme.palette.info.main}>
            {selectedAdmin?.email}
          </Typography>
          <Typography component="p" variant="bodyRegular" color={theme.palette.info.main}>
            {selectedAdmin?.phone}
          </Typography>
          <Typography mt={2} mb={1} component="p" variant="bodyBold">
            Permissions
          </Typography>
          {selectedAdmin?.permissions.map((permission, i) => {
            return (
              <Typography
                key={permission.permissionId}
                component="p"
                variant="bodyRegular"
                color={theme.palette.info.main}
              >
                {permission.description}
              </Typography>
            );
          })}
          <Grid container marginTop={3} columnSpacing={2}>
            <Grid hidden={user.role !== Roles.SUPER_ADMIN} item xs={4}>
              <LoadingButton
                variant="contained"
                onClick={() => {
                  selectedAdmin && handleDeleteAdmin(selectedAdmin.userId);
                }}
                fullWidth
                color="error"
                sx={{ borderRadius: '10px', height: '43px' }}
              >
                Delete
              </LoadingButton>
            </Grid>
            <Grid hidden={user.role !== Roles.SUPER_ADMIN} item xs={4}>
              <LoadingButton
                variant="contained"
                onClick={() => {
                  handleEditAdmin(selectedAdmin?.userId ?? '');
                  setOpenAdmimDetailsModal(false);
                }}
                fullWidth
                sx={{ borderRadius: '10px', height: '43px' }}
              >
                Edit
              </LoadingButton>
            </Grid>
            <Grid item xs={user.role === Roles.SUPER_ADMIN ? 4 : 12}>
              <LoadingButton
                variant="outlined"
                onClick={handleDismiss}
                fullWidth
                sx={{ borderRadius: '10px', height: '43px' }}
              >
                Dismiss
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
      </CustomModal>

      <AddEditAdmin userId={selectedUserId} isAddOpen={isAddOpen} />

      <AdminDelete userId={deleteUserId} setDeleteUserId={setDeleteUserId} open={deleteModalOpen} />
    </Fragment>
  );
}

export default ManageAdmins;
