import { AxiosResponse } from 'axios';
import { backendApiInstance } from './backendApiInstance';
import { AddClinicProps, ClinicInfo } from '../redux/clinic/clinicSlice';
import { Roles } from '../utils/enums/roles.enum';
import { SortOrder } from '../utils/enums/sort-order.enum';

export interface GetAllClinicParamType {
  offset: number;
  limit: number;
  sortBy: string;
  sortOrder: SortOrder;
  searchText: string;
}

export interface GetAllClinicAdminParamType {
  offset: number;
  limit: number;
  sortBy: string;
  sortOrder: SortOrder;
  clinicId: string;
  searchText?: string;
  type?: string;
}

export interface AdminDataProp {
  clinicId: string;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  permissionIds: number[];
  userId?: string;
  avatar?: string;
}

export async function getAllClinics({
  offset,
  limit,
  sortBy,
  sortOrder,
  searchText,
}: GetAllClinicParamType): Promise<AxiosResponse> {
  return backendApiInstance.get('clinics', {
    params: { offset, limit, sortBy, sortOrder, query: searchText },
  });
}

export async function getAllClinicAdmins({
  offset,
  limit,
  sortBy,
  sortOrder,
  clinicId,
  searchText,
  type,
}: GetAllClinicAdminParamType): Promise<AxiosResponse> {
  return backendApiInstance.get(`clinics/${clinicId}/clinic-admins`, {
    params: { offset, limit, sortBy, sortOrder, query: searchText, type },
  });
}

export async function validateEmailOrDomain(email: string): Promise<AxiosResponse> {
  return backendApiInstance.get('clinics/validate-email-domain', { params: { email } });
}

export async function getClinicAdminByUserId(userId: string): Promise<AxiosResponse> {
  return backendApiInstance.get(`clinics/admin/${userId}`);
}

export async function deactivateClinic(clinicId: string, password: string): Promise<AxiosResponse> {
  return backendApiInstance.post(`clinics/${clinicId}/deactivate`, { password });
}

export async function reactivateClinic(clinicId: string, password: string): Promise<AxiosResponse> {
  return backendApiInstance.post(`clinics/${clinicId}/reactivate`, { password });
}

export async function createClinic(addClinicData: AddClinicProps, clinicAvatar: string): Promise<AxiosResponse> {
  return backendApiInstance.post(`clinics`, {
    name: addClinicData.name,
    email: addClinicData.email,
    phone: addClinicData.phone,
    addressLine1: addClinicData.addressLine1,
    addressLine2: addClinicData.addressLine2,
    domain: addClinicData.domain,
    city: addClinicData.city,
    state: addClinicData.state,
    country: addClinicData.country,
    zipCode: addClinicData.zipCode,
    avatar: clinicAvatar,
    // clinic admin
    firstName: addClinicData.clinicAdmin?.firstName,
    lastName: addClinicData.clinicAdmin?.lastName,
    userEmail: addClinicData.clinicAdmin?.email,
    userPhoneNumber: addClinicData.clinicAdmin?.phone,
  });
}

export async function getClinicInfo(clinicId: string): Promise<AxiosResponse> {
  return backendApiInstance.get(`clinics/${clinicId}/info`);
}

export async function getClinicAdminPermissions(userId: string): Promise<AxiosResponse> {
  return backendApiInstance.get(`admins/permissions`, { params: { role: Roles.CLINIC_ADMIN } });
}

export async function createClinicAdmin(adminData: AdminDataProp): Promise<AxiosResponse> {
  return backendApiInstance.post(`clinics/${adminData.clinicId}/clinic-admin`, { ...adminData });
}

export async function updateClinicAdmin(adminData: AdminDataProp): Promise<AxiosResponse> {
  return backendApiInstance.put(`clinics/${adminData.clinicId}/clinic-admin`, { ...adminData });
}

export async function getClinicIntermediateInfo(clinicId: string): Promise<AxiosResponse> {
  return backendApiInstance.get(`clinics/intermediate/${clinicId}`);
}

export async function updateClinic(clinicId: string, clinicInfo: Partial<ClinicInfo>): Promise<AxiosResponse> {
  return backendApiInstance.put(`clinics`, { ...clinicInfo, clinicId });
}

export async function approveIntermediateClinicInfo(notificationId: string): Promise<AxiosResponse> {
  return await backendApiInstance.post(`clinics/intermediate/approve/${notificationId}`);
}

export async function rejectIntermediateClinicInfo(notificationId: string): Promise<AxiosResponse> {
  return await backendApiInstance.post(`clinics/intermediate/reject/${notificationId}`);
}

export async function deleteClinicAdmin(userId: string, password: string): Promise<AxiosResponse> {
  return backendApiInstance.delete(`clinics/admin/${userId}`, { data: { password } });
}

export async function getAllClinicNames(status: string | undefined): Promise<AxiosResponse> {
  return backendApiInstance.get('clinics/all-clinic-names', { params: { status } });
}
