import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { generalActions } from '../../redux/general/generalSlice';

function AlertMassage() {
  const dispatch = useAppDispatch();
  const alertMessage = useAppSelector((state: RootState) => state.general.alertMessage);

  function handleClose() {
    dispatch(generalActions.clearAlertMessage());
  }

  const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(generalActions.clearAlertMessage());
  };

  return (
    <>
      {alertMessage && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={!!alertMessage}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
        >
          <Alert onClose={handleClose} severity={alertMessage.severity} variant="filled" sx={{ width: '100%' }}>
            {alertMessage.msg}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default AlertMassage;
