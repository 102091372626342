import React, { Fragment, useEffect } from 'react';
import { useTheme, Grid } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as _ from 'lodash';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import CustomInputBox from '../../../components/CustomInputBox/CustomInputBox';
import CustomButtonLoadingButton from '../../../components/CustomButtonLoadingButton/CustomButtonLoadingButton';
import { adminActions } from '../../../redux/admin/adminSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { validation } from '../../../schema/validation';

function GeneralInfo() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const adminData = useAppSelector((state: RootState) => state.admin.adminData);
  const selectedAdmin = useAppSelector((state: RootState) => state.admin.selectedAdmin);

  const componentStatus = useAppSelector((state: RootState) => state.admin.componentStatus);
  const isSubmitting = _.get(componentStatus, 'isSubmitting', false) as boolean;
  const isEmailAlreadyExists = _.get(componentStatus, 'isEmailAlreadyExists', false);

  const formik = useFormik({
    initialValues: {
      firstName: adminData.firstName,
      lastName: adminData.lastName,
      email: adminData.email,
      phone: adminData.phone,
      avatar: adminData.avatar,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      firstName: validation.firstName,
      lastName: validation.lastName,
      email: validation.email,
      phone: yup
        .string()
        .matches(/^\+?\d{6,14}$/, { message: 'Invalid phone number' })
        .required('Phone number is required'),
      avatar: yup.string().optional().nullable(),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const userId = selectedAdmin?.userId;
      dispatch(adminActions.setComponentStatus({ isEmailAlreadyExists: false }));
      dispatch(adminActions.nextToAddAdminPermissionTab({ ...values, userId }));
    },
  });

  useEffect(() => {
    if (isEmailAlreadyExists) {
      formik.setFieldTouched('email', true);
      formik.setFieldError('email', 'Email address already exists');
    }
  }, [isEmailAlreadyExists]);

  return (
    <Fragment>
      <Grid container marginTop={2}>
        <Grid container item xs={12} sm={12} md={12} margin={'15px, 0, 10px, 0'}>
          <AvatarEdit
            avatar={adminData.avatar}
            editable={false}
            name={formik.values.firstName}
            name2={formik.values.lastName}
            font={theme.typography.h2}
            size="100px"
          />
        </Grid>
        <Grid marginTop={1} container rowSpacing={2} columnSpacing={1}>
          <Grid item xs={12} sm={6} md={6}>
            <CustomInputBox
              label="First Name"
              required={true}
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <CustomInputBox
              label="Last Name"
              required={true}
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <CustomInputBox
              label="Email"
              required={true}
              type="email"
              name="email"
              placeholder="username@mail.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              disabled={selectedAdmin !== undefined}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <CustomInputBox
              label="Phone Number"
              required={true}
              type="text"
              name="phone"
              placeholder="Phone Number"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              disabled={selectedAdmin !== undefined}
            />
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={1}
          marginTop={2}
          justifyContent="space-between"
          sx={{ paddingTop: '30px', borderTop: `1px solid ${theme.palette.shades.purpleBorder}` }}
        >
          <CustomButtonLoadingButton
            text="Dismiss"
            variant="outlined"
            color="primary"
            fullWidth={false}
            onClick={() => {
              dispatch(adminActions.setAddAdminPopupClose(false));
            }}
            width="118px"
          />
          <CustomButtonLoadingButton
            loading={isSubmitting}
            text="Next"
            variant="contained"
            color="primary"
            fullWidth={false}
            onClick={() => formik.handleSubmit()}
            width="95px"
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default GeneralInfo;
