import React, { Fragment } from 'react';
import { Grid, Typography, Box, useTheme } from '@mui/material';
import GreenCheckCircleSVG from '../../../assets/img/check-circle-bold-duotone.svg';
import AvatarEdit from '../../AvatarEdit/AvatarEdit';
import reactStringReplace from 'react-string-replace';

interface PatientAddInformProps {
  subject: string;
  description: string;
  clinicAdminName: string;
  clinicAdminAvatar: string;
  patientFirstName: string;
  patientLastName: string;
  patientAvatar: string;
}

function PatientAddInform(props: PatientAddInformProps) {
  const theme = useTheme();
  const userNameWithAvatar = (firstName: string, lastName: string, avatar: string) => {
    const array = new Uint32Array(1);
    window.crypto?.getRandomValues(array);
    return (
      <Fragment key={firstName + array[0].toString()}>
        &nbsp;
        <AvatarEdit
          needImgTag={true}
          editable={false}
          avatar={avatar}
          size="20px"
          name={firstName}
          name2={lastName}
          font={theme.typography.subtitle1}
        />
        &nbsp;
        {`${firstName} ${lastName}`}
      </Fragment>
    );
  };

  const formattedDescription = () => {
    const description = props.description;
    let replacedText;
    replacedText = reactStringReplace(description, '$patient', () =>
      userNameWithAvatar(props.patientFirstName, props.patientLastName, props.patientAvatar),
    );
    replacedText = reactStringReplace(replacedText, '$clinicAdmin', () =>
      userNameWithAvatar(props.clinicAdminName, '', props.clinicAdminAvatar),
    );

    return replacedText;
  };

  return (
    <Fragment>
      <Grid item xs={2} sm={1}>
        <Box component="img" src={GreenCheckCircleSVG} sx={{ width: '28px', height: '28px' }} />
      </Grid>
      <Grid item mb={3} xs={10} sm={11}>
        <Typography variant="h6" fontSize="20px" mb={2} sx={{ whiteSpace: 'normal' }}>
          {props.subject}
        </Typography>
        <Typography
          variant="bodyRegular"
          component="div"
          sx={{ display: 'inline', whiteSpace: 'normal', color: theme.palette.info.main }}
        >
          {formattedDescription()}
        </Typography>
      </Grid>
    </Fragment>
  );
}

export default PatientAddInform;
